import APP_CONFIG from 'config/App.config';
import { getHashed256Email, getHashedEmailFromCookie } from 'utils/cookies.util';

import { GTMEventCategories } from '@mafc/common';
import {
  getGTMShipmentType,
  getJourneySelectionParams,
  getJourneySelection,
  getUserGTMProps,
} from './gtm.utils';
import { GTMEvents } from 'constants/gtm.constants';
const { currency: currencyCode, language, storeId } = APP_CONFIG;
const hashedEmail = getHashedEmailFromCookie();

declare var dataLayer: any;

const arrayOfTrackedCampaigns: any = [];

const commonTrackingData = {
  currencyCode,
  market: storeId,
  language,
};
const journeySelection = getJourneySelection();
const journeySelectionParams = getJourneySelectionParams();

const isFoodProductInCart = (prodData: any[]) => prodData.filter(item => item.isFoodProduct);
const isMarketPlaceProductInCart = (prodData: any[]) =>
  prodData.filter(item => item.isMarketPlaceProduct);
const isSubsituteProductInCart = (prodData: any[]) => prodData.filter(item => item.isSubstituted);

abstract class Tracker {
  static gtmCustomEvent({
    eventCategory = GTMEventCategories.user_engagement,
    eventAction,
    eventLabel,
    screenName = '',
    screenType = '',
    extraObj = {},
  }: {
    eventCategory?: string;
    eventAction?: string;
    eventLabel: string;
    screenName?: string;
    screenType?: string;
    extraObj?: any;
  }) {
    const userProps = getUserGTMProps();

    const screenProps =
      screenName && screenType
        ? {
            screen_name: screenName,
            screen_type: screenType,
          }
        : {};

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
      journey_selection: journeySelection,
      ga4: true,
      ...screenProps,
      ...journeySelectionParams,
      ...userProps,
      ...extraObj,
    });
  }
  static gtmContinueShoppingClick(data: any) {
    try {
      dataLayer.push({
        event: GTMEvents.custom_event,
        event_category: GTMEventCategories.user_engagement,
        event_label: data.label,
        event_action: 'continue_shopping',
        page_url: window.location.href,
        screen_type: 'order_confirmation',
        continue_shopping: '1',
        market: storeId,
      });
    } catch (error) {
      // tslint:disable: no-console
      console.info(error);
    }
  }
  static gtmAddToCart(
    products: any[],
    updateType?: string,
    ga4: boolean = false,
    countryCode: string = '',
    extraObj: Record<string, string> = {}
  ) {
    const { id, stock_status, scalable_weights } = products[0];
    if (!ga4) {
      dataLayer.push({
        event: GTMEvents.add_to_cart,
        event_action: GTMEvents.add_to_cart,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: updateType,
        market: storeId,
        ecommerce: {
          currencyCode,
          add: {
            products,
          },
        },
        page_url: window.location.href,
        screen_type: 'cart',
        ga4: 'false',
      });
    } else {
      const userProps = getUserGTMProps();

      dataLayer.push({
        event: GTMEvents.add_to_cart,
        event_action: GTMEvents.add_to_cart,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: `${id}_${countryCode}`,
        value: extraObj.cartValue,
        ecommerce: {
          items: products,
        },
        page_url: window.location.href,
        screen_type: 'cart',
        screen_name: 'cart',
        ga4: 'true',
        stock_status,
        scalable_weights,
        ...userProps,
        ...journeySelectionParams,
      });
    }
  }

  static gtmRemoveFromCart(
    products: any[],
    updateType?: string,
    ga4: boolean = false,
    countryCode: string = '',
    extraObj: Record<string, string> = {}
  ) {
    const { id, stock_status, scalable_weights } = products[0];
    try {
      if (!ga4) {
        dataLayer.push({
          event: GTMEvents.remove_from_cart,
          event_action: GTMEvents.remove_from_cart,
          event_category: GTMEventCategories.enhanced_ecommerce,
          event_label: updateType,
          market: storeId,
          ecommerce: {
            currencyCode,
            remove: {
              actionField: {
                products,
              },
            },
          },
          page_url: window.location.href,
          screen_type: 'cart',
          ga4: 'false',
        });
      } else {
        const userProps = getUserGTMProps();

        dataLayer.push({
          event: GTMEvents.remove_from_cart,
          event_action: GTMEvents.remove_from_cart,
          event_category: GTMEventCategories.enhanced_ecommerce,
          event_label: `${id}_${countryCode}`,
          ecommerce: {
            items: products,
          },
          value: extraObj.cartValue,
          page_url: window.location.href,
          screen_type: 'cart',
          screen_name: 'cart',
          ga4: 'true',
          stock_status,
          scalable_weights,
          ...userProps,
          ...journeySelectionParams,
        });
      }
    } catch (error) {
      // tslint:disable: no-console
      console.error(error);
    }
  }

  static gtmCartPageLoad(
    products: any[],
    cartId: string = '',
    ga4: boolean = false,
    extraObj: Record<string, string> = {}
  ) {
    const isFoodProductExists = isFoodProductInCart(products).length ? 'true' : 'false';
    const isMktPlaeProductExists = isMarketPlaceProductInCart(products).length ? 'true' : 'false';
    const isSubstituteProdExists = isSubsituteProductInCart(products).length
      ? 'allowed'
      : 'not_allowed';
    if (!ga4) {
      dataLayer.push({
        event: GTMEvents.cart_review,
        event_action: GTMEvents.cart_review,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: GTMEvents.cart_review,
        market: storeId,
        ecommerce: {
          currencyCode,
          checkout: {
            products,
          },
        },
        page_url: window.location.href,
        screen_type: 'cart',
        ga4: 'false',
      });
    } else {
      const userProps = getUserGTMProps();

      dataLayer.push({
        event: GTMEvents.view_cart,
        event_action: GTMEvents.view_cart,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: cartId, //
        value: extraObj.cartValue,
        cart_id: cartId, //
        ecommerce: {
          items: products,
        },
        page_url: window.location.href,
        screen_type: 'cart',
        screen_name: 'cart',
        ga4: 'true',
        substitution: isSubstituteProdExists,
        cart_food: isFoodProductExists,
        cart_nonfood: isFoodProductExists,
        cart_mkt: isMktPlaeProductExists,
        ...userProps,
        ...journeySelectionParams,
      });
    }
  }

  static binBannerViewPromotion = (
    binBannerItem: Record<string, string | number>,
    extraObj: Record<string, string | number> = {}
  ) => {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.view_promotion,
      event_category: GTMEventCategories.enhanced_ecommerce,
      ga4: 'true',
      ...userProps,
      ...journeySelectionParams,
      ecommerce: {
        items: [binBannerItem],
      },
      value: extraObj.cartValue,
      screen_type: extraObj.screenType,
      screen_name: extraObj.screenName,
    });
  };

  static gtmCheckoutCta(allowGrocery = {}, express = {}) {
    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: 'checkout_cta',
      event_category: GTMEventCategories.user_engagement,
      event_label: 'cart_review',
      market: storeId,
      page_url: window.location.href,
      screen_type: 'cart',
      ...express,
      ...allowGrocery,
      cart_id: 'current', // as discussed with analytics team
    });
  }

  static gtmCheckoutPageReview(pageReview: any) {
    const {
      delivery_area_code,
      delivery_city,
      hashed_email,
      loyalty_point,
      logged_in_status,
      my_club_member_id,
      page_url,
    } = pageReview;
    dataLayer.push({
      event: GTMEvents.page_view,
      business_type: 'B2C',
      currency: currencyCode,
      delivery_area_code,
      delivery_city,
      first_transaction: 'false',
      hashed_email,
      home_pageview: 1,
      loyalty_point,
      language,
      logged_in_status,
      my_club_member_id,
      market: storeId,
      page_url,
      screen_type: 'checkout',
      screen_type_sessionDimension: 'checkout',
    });
  }

  static gtmForFlagship = (flagshipTrackingData: any) => {
    if (!arrayOfTrackedCampaigns.includes(flagshipTrackingData.flagship_campaign_id)) {
      dataLayer.push(flagshipTrackingData);
      arrayOfTrackedCampaigns.push(flagshipTrackingData.flagship_campaign_id);
    }
  };

  static gtmCheckoutPageLoad(
    {
      payment,
      cartId,
      products,
      screenType,
      screenName,
      isNewJourney,
      shipmentIdentifire,
      stepCount,
    }: any,
    ga4: boolean = false,
    eventData: any = {},
    shipmentCount: number = 0
  ) {
    const isSubstituteProdExists = isSubsituteProductInCart(products).length
      ? 'allowed'
      : 'not_allowed';
    let eventObj = {};
    if (!ga4) {
      eventObj = {
        event: GTMEvents.checkout,
        event_action: GTMEvents.checkout,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: cartId,
        market: storeId,
        cart_id: cartId,
        ecommerce: {
          currencyCode,
          checkout: {
            products,
          },
        },
        page_url: window.location.href,
        screen_type: screenType,
        ga4: 'false',
      };
    } else {
      const userProps = getUserGTMProps();

      eventObj = {
        event: GTMEvents.begin_checkout,
        event_action: GTMEvents.begin_checkout,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: cartId,
        cart_id: cartId,
        ecommerce: {
          items: products,
        },
        page_url: window.location.href,
        screen_type: screenType,
        screen_name: screenName,
        ga4: 'true',
        substitution: isSubstituteProdExists,
        shipments_count: shipmentCount,
        ...eventData,
        ...journeySelectionParams,
        ...userProps,
      };
    }

    if (isNewJourney) {
      eventObj = {
        ...eventObj,
        event_label_2: getGTMShipmentType(shipmentIdentifire),
        event_label_3: `checkout_step${stepCount}`,
        hashed_email: hashedEmail,
      };
    } else {
      eventObj = {
        ...eventObj,
        payment_selected: payment,
      };
    }

    dataLayer.push(eventObj);
  }
  static gtmCartSubstitution(status: boolean, productName: string = 'all_basket', data = {}) {
    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: GTMEventCategories.user_engagement,
      event_action: `substitution_toggle_${status ? 'activate' : 'deactivate'}`,
      event_label: productName,
      ...data,
      journey_selection: journeySelection,
      ...journeySelectionParams,
    });
  }

  // `dynamicCurrencyCode = currencyCode` - may not be needed after all
  static gtmVirtualPage(
    pageType: string,
    data = {},
    dynamicCurrencyCode = currencyCode,
    sieveOutProperties?: string[]
  ) {
    let properties: any = {
      event: 'virtual_page_event',
      currencyCode: dynamicCurrencyCode,
      first_transaction: 'false',
      initial_delivery_time: '',
      page_type: pageType,
      business_type: 'B2C',
      page_url: window.location.href,
      language,
      market: storeId,
      order_type: '',
      delivery_city: '',
      delivery_area_code: '',
    };
    if (sieveOutProperties) {
      const pickedProps: { [key: string]: any } = { ...properties };
      sieveOutProperties.forEach(key => {
        pickedProps[key] = undefined;
      });
      properties = pickedProps;
    }
    dataLayer.push({ properties, ...data });
  }

  static gtmPlaceOrderCta(label: string, savedCard: boolean, extraObj: any = {}) {
    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: GTMEventCategories.user_engagement,
      event_action: 'checkout_details_successfull',
      event_label: label,
      page_url: window.location.href,
      screen_type: 'checkout',
      saved_card: savedCard,
      ...extraObj,
      ...commonTrackingData,
    });
  }

  static checkoutProgress = (
    products: any[],
    payment: string,
    step: number,
    cartId: string,
    store: string
  ) => {
    dataLayer.push({
      event: GTMEvents.checkout_progress,
      event_category: GTMEventCategories.enhanced_ecommerce,
      event_action: 'checkout',
      event_label: 'checkout_completed',
      cart_id: cartId,
      store_name: store,
      store_id: store,
      market: storeId,
      language,
      ecommerce: {
        currencyCode,
        checkout: {
          actionField: { step, option: payment },
          products,
        },
      },
    });
  };

  static gtmPageLoad(
    event: string,
    payload = {
      hashedEmail: '',
      loggedInStatus: '0',
      totalPoints: '',
      cardNumber: '',
      sessionArea: '-',
      ecommerce: {},
    }
  ) {
    const hasBeenPushed = dataLayer.filter((data: { event: string }) => data.event === event);
    // TODO need to verify currently its a quick fix
    const area = payload.sessionArea ? payload.sessionArea.split('-') : '';
    const deliveryCity = area.length > 1 ? area[1].trim() : '';
    if (!hasBeenPushed.length) {
      dataLayer.push({
        event,
        first_transaction: 'false',
        initial_delivery_time: '',
        delivery_area_code: payload.sessionArea,
        delivery_city: deliveryCity,
        order_type: '',
        business_type: 'B2C',
        hashed_email: payload.hashedEmail,
        logged_in_status: payload.loggedInStatus,
        loyalty_point: payload.totalPoints,
        my_club_member_id: payload.cardNumber,
        nationality_id: '', // TODO will be integrated later
        page_url: window.location.href,
        screen_type: 'cart',
        ecommerce: payload.ecommerce,
        ...commonTrackingData,
      });
    }
  }
  static gtmPostOrderPageLoad(screenType: string, payload: any) {
    const hasBeenPushed = dataLayer.filter(
      (data: { screen_type: string }) => data.screen_type === screenType
    );
    const area = payload.sessionArea ? payload.sessionArea.split('-') : '';
    const deliveryCity = area.length > 1 ? area[1].trim() : '';
    if (!hasBeenPushed.length) {
      dataLayer.push({
        first_transaction: 'false',
        initial_delivery_time: '',
        delivery_area_code: payload.sessionArea,
        delivery_city: deliveryCity,
        order_type: '',
        business_type: 'B2C',
        hashed_email: payload.hashedEmail,
        logged_in_status: payload.loggedInStatus,
        loyalty_point: payload.totalPoints,
        my_club_member_id: payload.cardNumber,
        page_url: window.location.href,
        screen_type: screenType,
        ...commonTrackingData,
      });
    }
  }
  static gtmButtonClick(identifier: string, payload: any) {
    let combinedECommPayload = { currencyCode };
    const { ecommerce, ...otherPayload } = payload;
    if (ecommerce) {
      combinedECommPayload = { ...ecommerce };
    }
    dataLayer.push({
      event: identifier,
      ecommerce: {
        ...combinedECommPayload,
      },
      ...commonTrackingData,
      ...otherPayload,
    });
  }

  static purchaseEvent = ({
    payment,
    id,
    cartId,
    revenue,
    shipping,
    coupon,
    products,
    deliveryTime,
    deliveryDate,
    orderType,
    deliveryArea,
    deliveryRegion,
    shipmentType,
    loyalityPoint,
    slotCode,
    currency,
    shareFlag,
    ga4,
    shipmentCount,
    eventData,
    paymentMode,
  }: any) => {
    const isSubstituteProdExists = isSubsituteProductInCart(products).length
      ? 'allowed'
      : 'not_allowed';
    if (!ga4) {
      dataLayer.push({
        event: GTMEvents.purchase,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_action: GTMEvents.purchase,
        event_label: 'txn_' + id + '_' + revenue,
        payment_selected: payment,
        ...commonTrackingData,
        cart_id: cartId,
        transaction_id: id,
        transaction_using_share: shareFlag,
        order_type: orderType,
        actual_delivery_time: deliveryTime,
        initial_delivery_time: '',
        logged_in_status: 'TRUE',
        business_type: 'b2c',
        hashed_email: hashedEmail,
        delivery_area_code: deliveryArea,
        delivery_city: deliveryRegion,
        loyalty_point: loyalityPoint,
        business_source: shipmentType ? shipmentType.join(',').replace(',', '|') : '',
        screen_type: 'order_confirmation',
        page_url: window.location.href,
        delivery_date: deliveryDate,
        store_deliver_used: slotCode,
        search_type: '',
        first_transaction: 'FALSE',
        ga4: 'false',
        couponPrice: '',
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              id: parseInt(id, 10),
              revenue,
              shipping,
              coupon,
              tax: 0,
              action: 'purchase',
              currency,
            },
            products,
          },
        },
      });
    } else {
      dataLayer.push({
        event: GTMEvents.ecommerce_purchase,
        event_action: GTMEvents.ecommerce_purchase,
        event_category: GTMEventCategories.enhanced_ecommerce,
        event_label: 'txn_' + id + '_' + revenue,
        transaction_id: id,
        value: revenue,
        coupon_code: coupon,
        cart_id: cartId,
        market: storeId,
        ecommerce: {
          items: products,
        },
        page_url: window.location.href,
        screen_type: 'order_confirmation',
        screen_name: 'order_confirmation',
        ga4: 'true',
        currency: currencyCode,
        language,
        module_name: 'c4_main',
        substitution: isSubstituteProdExists,
        shipments_count: shipmentCount,
        payment_selected: paymentMode,
        hashed_email_sha256: getHashed256Email(),
        ...eventData,
      });
    }
  };
  static emptyDataLayer() {
    dataLayer = [];
  }
}

export default Tracker;
