import crypto from 'crypto';
import { getEncryptionKey, getIV } from './cryptoHelper';
const decryptData = (encryptedData: string, orderNumber: string, uuId: string) => {
  try {
    const iv = getIV(orderNumber);
    const decryptionKey = getEncryptionKey(uuId, orderNumber);

    const decipher = crypto.createDecipheriv('AES-256-CBC', decryptionKey, iv);
    let decrypted = decipher.update(encryptedData, 'hex', 'utf8');
    decrypted += decipher.final('utf8');
    return decrypted;
  } catch (error) {
    return new Error('Error while decrypting data');
  }
};

export default decryptData;
