export enum EStepsProgress {
  deliverTo = 'deliverTo',
  shipments = 'shipments',
  payment = 'payment',
}

export type StepsProgressType = keyof typeof EStepsProgress;

export default interface IStepsProgress {
  [EStepsProgress.deliverTo]: boolean;
  [EStepsProgress.shipments]: boolean;
  [EStepsProgress.payment]: boolean;
}

export enum EStepsStatus {
  disabled = 'disabled',
  active = 'active',
  completed = 'completed',
}
export type StepsStatusType = keyof typeof EStepsStatus;
