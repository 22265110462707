import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'config/redux/reducers';

const useNotification = (): any[] => {
  const { type, message, variant } = useSelector(({ notification }: IAppState) => notification);

  useEffect(() => {
    if (message) {
      window.scrollTo(0, 0);
    }
  }, [message]);

  return [type, typeof message === 'string' ? [message] : message, variant];
};

export default useNotification;
