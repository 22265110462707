import { CookieUtils } from '@mafc/common';

declare global {
  interface Window {
    newrelic: {
      addPageAction(eventName: string, data: unknown): void;
    };
  }
}

export const newRelicCustomEvent = (eventName: string, data: any) => {
  if (!window.newrelic) {
    return;
  }
  if (!window.newrelic.addPageAction) {
    return;
  }
  if (CookieUtils.getCustomCookie('userId')) {
    data.userId = CookieUtils.getCustomCookie('userId');
  }
  if (CookieUtils.getCustomCookie('storeInfo')) {
    data.storeInfo = CookieUtils.getCustomCookie('storeInfo');
  }
  window.newrelic.addPageAction(eventName, data);
};
