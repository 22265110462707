import { AnyAction } from 'redux';
import { EBlankOrderDataActions } from '../returnData.type';

export const blankReturnsInitialState: any = {
  isEmpty: false,
};

const getBlankReturns = (state: any, payload: any) => {
  return {
    ...payload,
    isEmpty: true,
  };
};

const actionHandlers: { [Key: string]: any } = {
  [EBlankOrderDataActions.GET_BLANK_RETURN_LIST]: getBlankReturns,
};

export default function blankReturnsReducer(state = blankReturnsInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
