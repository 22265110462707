import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum EUserActionTypes {
  USER_FETCH_GET_ADDRESSES = 'User/Fetch/ADDRESSES',
  USER_FETCH_GET_ADDRESSES_SUCCESS = 'User/Fetch/ADDRESSES_SUCCESS',
  USER_FETCH_SET_LOADING = 'User/Fetch/SET_LOADING',
  USER_FETCH_SET_ADDRESS_LOADED = 'User/Fetch/ADDRESSESS_LOADED',

  USER_ADD_POST_ADDRESS = 'User/Add/POST_ADDRESS',
  USER_ADD_POST_ADDRESS_SUCCESS = 'User/Add/POST_ADDRESS_SUCCESS',
  USER_ADD_SET_ERROR = 'User/Add/SET_ERROR',
  USER_ADD_SET_LOADING = 'User/Add/SET_LOADING',
}

export interface ICountry {
  isocode: string;
}

export interface IDeliveryArea {
  code: string;
  foodDeliveryStore: boolean;
  isSlotsAvailable: boolean;
  name: string;
  posFoodName?: string;
  posNonFoodName?: string;
}

export interface IRegion {
  countryIso: string;
  isocode: string;
  name: string;
}

export interface IAddress {
  appartment?: string;
  billingAddress: boolean;
  building?: string;
  country: ICountry;
  defaultAddress: boolean;
  deliveryArea: IDeliveryArea;
  addressLabel?: string;
  firstName: string;
  id: string;
  landmark?: string;
  lastName: string;
  latitude: string;
  line1: string;
  longitude: string;
  phone: string;
  region: IRegion;
  shippingAddress: boolean;
  ssoType: string;
  streetName: string;
  town: string;
  emirate?: string;
}

export type TAddresses = IAddress[];

export interface IUserState {
  loading: boolean;
  addresses: TAddresses;
  add: {
    error?: any;
    loading: boolean;
  };
  addressLoaded: boolean;
}

export type TUserGetAddressesAPI = Pick<IUserState, 'addresses'>;

export type TUserAction = IAction<
  Partial<IUserState> & Partial<IUserState['add']>,
  Exclude<
    EUserActionTypes,
    EUserActionTypes.USER_FETCH_GET_ADDRESSES & EUserActionTypes.USER_ADD_POST_ADDRESS
  >
>;
export type TUserDispatch = TDispatch<TUserAction>;
