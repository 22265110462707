import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const refundMethodsInitialState: any = {
  returnPaymentTypes: [],
  displayCashFlag: false,
  loading: false,
  error: false,
};

const RefundMethods = (state: any, payload: any) => {
  return { ...state, ...payload };
};

const refundMethodsLoadingStart = (state: any) => {
  return { ...state, loading: true, error: false };
};

const refundMethodsLoadingEnd = (state: any) => {
  return { ...state, loading: false };
};

const refundMethodsError = (state: any) => {
  return { ...state, error: true };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.REFUND_METHODS]: RefundMethods,
  [EPostOrderDataActions.REFUND_METHODS_LOADING_START]: refundMethodsLoadingStart,
  [EPostOrderDataActions.REFUND_METHODS_LOADING_END]: refundMethodsLoadingEnd,
  [EPostOrderDataActions.REFUND_METHODS_ERROR]: refundMethodsError,
};

export default function RefundMethodsReducer(state = refundMethodsInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
