/**
 *  This file initializes i18n and it should only be imported one time at the app level
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './strings';
import APP_CONFIG from '../App.config';
import { initStorageManager } from 'utils/customStorage.util';

const getBrowserLanguage = () => {
  return APP_CONFIG.language;
};

const options: any = {
  lng: getBrowserLanguage(),
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

i18n
  .use(initReactI18next)
  .init(options)
  .then(() => {
    languageSettings(getBrowserLanguage());
  });

export const isRTL = () => i18n.dir() === 'rtl';

export const getDir = () => i18n.dir();

export const isLocaleStrEqual = (first: string, second: string) => {
  const collator = new Intl.Collator(i18n.language);
  const order = collator.compare(first, second);
  // console.log(order, first, second);
  /**
   * If the strings are equal, the function returns 0.
   * If they are not equal the function returns either 1 or -1,
   * which also indicates the alphabetical order of the strings.
   */
  if (!order) {
    return true;
  } else {
    return false;
  }
};

const storage = initStorageManager(() => localStorage);

export const languageSettings = (lang: string) => {
  window.document.dir = i18n.dir();
  storage.setItem('language', lang);
};

export const toggleLanguage = () => {
  const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
  i18n.changeLanguage(newLanguage);
  languageSettings(newLanguage);
};

export default i18n;
