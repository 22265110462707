import { TMergeCartsLoadingAction, EMergeCartsTypes } from '../../types/mergeCarts.types';
import { ICartState } from '.';

export const setMergeCartsLoading = (cartState: ICartState, action: TMergeCartsLoadingAction) => {
  return {
    ...cartState,
    ...action.payload,
  };
};

export default {
  [EMergeCartsTypes.MERGE_CARTS_SET_LOADING]: setMergeCartsLoading,
};
