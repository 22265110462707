import { Dispatch } from 'redux';
import { showNotification } from 'common/Notification/redux/notification.action';
import i18n from 'config/localisation';
import { generateOptionObj } from 'utils/quantity.util';
import { notificationTypes } from 'common/Notification/redux/notification.type';
const qtyRestrictionHandler = ({
  statusCode,
  maxAllowedQuantity,
  subFamilyName,
  entry,
}: {
  statusCode: string;
  maxAllowedQuantity?: number;
  subFamilyName?: string;
  entry: any;
}) => (dispatch: Dispatch) => {
  if (Object.values(IQtyRestriction).indexOf(statusCode) >= 0) {
    const quantity = maxAllowedQuantity ? maxAllowedQuantity : 0;

    const formattedQuantity = generateOptionObj(quantity, entry.product.soldByWeight).label;
    let message = i18n.t('quantityRestriction.defaultMessage', {
      maxAllowedQuantity: formattedQuantity,
    });

    if (statusCode === IQtyRestriction.MAX_ORDER_QUANTITY_EXCEEDED && subFamilyName !== null) {
      message = i18n.t('quantityRestriction.categoryRestriction', {
        maxAllowedQuantity: formattedQuantity,
        subFamilyName,
      });
    }
    if (statusCode === IQtyRestriction.TOTAL_MAX_QTY_EXCEEDED) {
      message = i18n.t('quantityRestriction.defaultMessage', {
        maxAllowedQuantity,
      });
    }
    dispatch(
      showNotification({
        type: notificationTypes.errorCheckout,
        message,
      })
    );
    window.scrollTo(0, 0); // scroll to top for showing notification as the product can be below in the list
  }
};

export default qtyRestrictionHandler;

export const IQtyRestriction = {
  MAX_ORDER_QUANTITY_EXCEEDED: 'maxOrderQuantityExceeded',
  TOTAL_MAX_QTY_EXCEEDED: 'TOTALMAXQTYEXCEEDED',
  TOTAL_SCALABLE_QTY_EXCEEDED: 'TOTALSCALABLEQTYEXCEEDED',
  TOTAL_NON_SCALABLE_MAX_QTY_EXCEEDED: 'TOTALNONSCALABLEMAXQTYEXCEEDED',
};
