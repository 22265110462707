import UserTypes from './User.type';
import IAction from 'config/redux/actions/interface';
import UserAddress from 'network/response/UserAddress';

export interface INicField {
  isRequired: boolean;
  hasError: boolean;
  errorReason: string;
  value: string;
}
export interface IUserState {
  firstName?: string;
  addresses: UserAddress[];
  selectedAddress?: any;
  customerId?: string;
  currency?: string;
  language?: any;
  defaultAddress?: any;
  name?: any;
  nicField?: INicField;
}

export const initialUserState = {
  addresses: [],
  selectedAddress: null,
  nicField: {
    isRequired: false,
    hasError: false,
    errorReason: '',
    value: '',
  },
} as IUserState;

const userReducer = (state: IUserState = initialUserState, action: IAction) => {
  const { type, payload } = action;
  switch (type) {
    case UserTypes.USER_GET_DATA_SUCCESS:
      return {
        ...state,
        firstName: payload.firstName,
        customerId: payload.customerId,
        currency: payload.currency,
        language: payload.language,
        defaultAddress: payload.defaultAddress,
        name: payload.name,
      };
    case UserTypes.USER_UPDATE_DELIVERY_ADDRESS:
      return { ...state, selectedAddress: payload.updatedDeliveryAddress };
    case UserTypes.USER_UPDATE_NEWLY_ADDED_ADDRESS: {
      return { ...state, selectedAddress: payload, addresses: [payload, ...state.addresses] };
    }
    case UserTypes.USER_UPDATE_NIC_VALUE: {
      return {
        ...state,
        nicField: {
          isRequired: true,
          value: payload,
          hasError: /^$|\s+/.test(payload || ''),
          errorReason: 'Invalid or empty NIC. This field is mandatory',
        },
      };
    }
    case UserTypes.USER_RESET_NIC_VALUE: {
      return {
        ...state,
        nicField: {
          isRequired: true,
          value: payload,
          hasError: false,
          errorReason: '',
        },
      };
    }
    case UserTypes.USER__DISABLE_ENABLE_NIC: {
      return {
        ...state,
        nicField: {
          ...state.nicField,
          isRequired: payload,
        },
      };
    }
  }

  return state;
};

export default userReducer;
