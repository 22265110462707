import { Dispatch } from 'redux';

import { TDispatch } from 'config/redux/types/redux.types';
import { IAppState } from 'config/redux/reducers';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import EDeliverySlotTypes, {
  ISelectedDeliverySlots,
  IDeliverySlots,
  ISlotItem,
} from './deliverySlot.type';
import {
  FETCH_SELECTED_DELIVERY_SLOT_API,
  PUT_SELECTED_DELIVERY_SLOT_API,
  FETCH_SLOTS_API,
  FETCH_SLOTS_API_NEW,
  PUT_SELECTED_DELIVERY_SLOT_API_NEW,
  GET_DEFAULT_DRAWER_STATE,
} from '../constants/apis.constant';
import { isObject, qs } from 'utils/helpers.util';

import { EShipmentTabs } from 'pages/Checkout/types/cnc.types';
import { getStore, setCNCSlotAndStore, setCncError } from 'pages/Checkout/redux/actions/cnc.action';
import { getCartId } from 'pages/Cart/helpers/url.helpers';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import USER_CONFIG from 'config/User.config';
import { updateCart } from 'pages/Cart/redux/actions';
import { getErrorMessage } from 'utils/error.util';

// This is to avoid dispatching unnecessary action for Notification
let localErrorDispatchFlag: boolean = false;

export const resetSlots = () => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_RESET,
});

export const setError = (error: string = '') => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_SET_ERROR,
  payload: { error },
});

export const toggleLoading = (loading: boolean) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_LOADING,
  payload: { loading },
});

export const toggleVisibility = (visible: boolean) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_VISIBLE,
  payload: { visible },
});
export const updateCartData = (payload: any) => ({
  type: 'DeliverySlot/UPDATE_CART',
  payload,
});
export const toggleCoachMark = (showCoachMark: boolean = false) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_COACH_MARK,
  payload: { showCoachMark },
});

export const toggleDrawerCoachMark = (showDrawerCoachMark: boolean = false) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_DRAWER_COACH_MARK,
  payload: { showDrawerCoachMark },
});
export const setDefaultDrawer = (payload: any) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_SET_DEFAULT_DRAWER_STATE,
  payload,
});
export const setSelectedSlot = (payload: Partial<ISelectedDeliverySlots>) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_SET_SELECTED_SLOT,
  payload,
});

export const setPickupSelectedSlot = (payload: Partial<ISlotItem>) => ({
  type: EDeliverySlotTypes.DELIVERY_SLOT_PICKUP_SELECTED_SLOT,
  payload,
});

export const setShipmentDeliverySlot = (deliverySlot: any) => (dispatch: Dispatch) => {
  const deliverySlotData = {
    selectedSlot: deliverySlot,
    slotValidationRequired: true,
  };

  dispatch(setSelectedSlot(deliverySlotData));
};

export const fetchSelectedSlot = (
  areacode: string,
  cartId: string,
  successCallback?: () => void,
  errorCallback?: (...args: any[]) => void
) => (dispatch: Dispatch) => {
  dispatch(toggleLoading(true));
  const errorCallbackHandler = (errMsg?: string) => {
    const errMsgWithFallback = errMsg || 'Something went wrong, please try again.';

    if (errorCallback) {
      errorCallback(errMsgWithFallback);
    } else {
      dispatch(resetSlots());
      dispatch(setError(errMsgWithFallback));
    }
  };

  dispatch({
    type: EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SELECTED_SLOT,
    payload: {
      enableProgress: false,
      onSuccess: ({
        alertMessages,
        selectedSlot,
        slotValidationRequired,
        deliveryMessage,
      }: ISelectedDeliverySlots) => {
        // Testing the type here coz Typescript arent meant for runtime and APIs never fail to surprise the client-side
        if (isObject(selectedSlot) || deliveryMessage) {
          let dispatchObject = {};

          if (selectedSlot) {
            dispatchObject = {
              selectedSlot,
              slotValidationRequired,
            };
          }
          if (deliveryMessage) {
            dispatchObject = {
              deliveryMessage,
              slotValidationRequired,
              selectedSlot: {},
              showCoachMark: false,
            };
          }
          dispatch(resetSlots() as any);
          dispatch(setSelectedSlot(dispatchObject));
          if (successCallback) {
            successCallback();
          }

          // Hide and reset Notification
          dispatch(setError());
        } else {
          // Successful request but with an error like the below, or could be something else, so better catch that bug than sorry:
          // {
          //  "alertMessages" : "There is no slot selected for the cart or selected slot is expired.",
          //  "slotValidationRequired" : true
          // }
          errorCallbackHandler(alertMessages);
        }
      },
      onFailure: (msg: any, error: any) => {
        const errMsg = (((error.response || {}).data || {}).meta || {}).message;
        errorCallbackHandler(errMsg);
      },
      onFinally: () => dispatch(toggleLoading(false)),
      params: { areacode, cartId },
      url: FETCH_SELECTED_DELIVERY_SLOT_API,
    },
  });
};
export const deliverySlotDrawerDefaultState = ({ posNo = '', zone = '' }) => (
  dispatch: TDispatch<any>,
  getState: () => IAppState
) => {
  dispatch({
    type: EDeliverySlotTypes.DELIVERY_SLOT_GET_DEFAULT_DRAWER_STATE,
    payload: {
      params: { posNo, zone },
      method: EApiMiddlewareMethod.GET,
      url: GET_DEFAULT_DRAWER_STATE,
      onSuccess: (res: any) => {
        dispatch({ type: EDeliverySlotTypes.DELIVERY_SLOT_SET_DEFAULT_DRAWER_STATE, payload: res });
        dispatch(toggleVisibility(res.data.openDefaultDrawer));
      },
    },
  });
};
export const getAllData = (cartData: any) => {
  const { code: cartId, site: siteId } = cartData;
  const { currencyName } = cartData.subtotalFood;
  const {
    pos = '',
    zone = '',
    shipmentTotal: { value = '' },
  } = cartData.shipments.find(
    (shipment: any) => shipment.shipmentType === EShipmentTypes.STANDARD_FOOD
  );
  return {
    cartId,
    siteId,
    options: ['delivery-fees'],
    shipments: [
      {
        serviceType: 'STANDARD',
        posNo: pos,
        zone,
        totalShipmentPrice: {
          amount: value,
          denomination: currencyName,
        },
      },
    ],
  };
};
export const fetchSlots = (areacode: string) => (dispatch: Dispatch, getState: () => IAppState) => {
  const { checkout = {} } = getState().appConfig.countryConfiguration;
  const { isSurchargeSupported = false } = checkout;

  const { activeTab } = getState().checkout.shipment.cnc;
  const data = getAllData(getState().cart.cartData);

  if (activeTab === EShipmentTabs.CNC_TAB) {
    return;
  }

  dispatch({
    type: EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS,
    payload: {
      enableProgress: false,
      isAbsUrl: isSurchargeSupported,
      data: isSurchargeSupported ? data : '',
      onSuccess: (payload: IDeliverySlots) => {
        dispatch({
          type: EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS_SUCCESS,
          payload: { ...payload, apigeFlag: isSurchargeSupported },
        });
      },
      onFailure: () => dispatch(toggleVisibility(false)),
      params: { areacode },
      url: isSurchargeSupported ? FETCH_SLOTS_API_NEW : FETCH_SLOTS_API,
      method: isSurchargeSupported ? EApiMiddlewareMethod.POST : EApiMiddlewareMethod.GET,
    },
  });
};

export const putSelectedSlot = ({
  slotItem,
  addressId,
  areaCode,
  successCallback,
  failureCallback,
}: {
  slotItem: ISlotItem;
  addressId: string;
  areaCode: string;
  successCallback: () => void;
  failureCallback?: () => void;
}) => (dispatch: TDispatch<any>, getState: () => IAppState) => {
  dispatch(toggleLoading(true));
  const { countryConfiguration = {} } = getState().appConfig;
  const { checkout: { isSurchargeSupported = false } = {} } = countryConfiguration;
  const bodyData = isSurchargeSupported
    ? {}
    : {
        slotCode: slotItem.deliverySlotCode,
      };
  const { activeTab, isTabClicked, pickupStores } = getState().checkout.shipment.cnc;
  if (activeTab === EShipmentTabs.CNC_TAB) {
    const { selectedStore } = pickupStores || { selectedStore: '' };
    dispatch(
      setCNCSlotAndStore({
        // will pick the selected store if store is not came from shipment
        cncStore: getStore(getState()).name || selectedStore,
        slotCode: slotItem.deliverySlotCode,
        isFetchSlot: false,
        checkStock: isTabClicked,
        onSuccessCallback: () => {
          dispatch(setSelectedSlot({ selectedSlot: slotItem }));
          dispatch(toggleLoading(false));
          dispatch(setCncError());
          successCallback();
          // Hide and reset Notification
          if (localErrorDispatchFlag) {
            dispatch(setError());
            localErrorDispatchFlag = false;
          }
        },
        onFailureCallback: errMsg => {
          dispatch(setError(errMsg));
          dispatch(toggleLoading(false));
          localErrorDispatchFlag = true;
          if (failureCallback) {
            failureCallback();
          }
        },
      })
    );

    return;
  }

  dispatch({
    type: EDeliverySlotTypes.DELIVERY_SLOT_PUT_SELECTED_SLOT,
    payload: {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs(bodyData),
      enableProgress: false,
      onSuccess: (payload: any) => {
        dispatch(setSelectedSlot({ selectedSlot: slotItem }));
        if (isSurchargeSupported) {
          dispatch(updateCart(dispatch, payload));
        }
        successCallback();

        // Hide and reset Notification
        if (localErrorDispatchFlag) {
          dispatch(setError());
          localErrorDispatchFlag = false;
        }
      },
      onFailure: (msg: any, error: any) => {
        dispatch(setError(getErrorMessage(error)));
        localErrorDispatchFlag = true;
      },
      onFinally: () => dispatch(toggleLoading(false)),
      params: {
        app_id: process.env.REACT_APP_ID,
        areaCode,
        cartId: getCartId(getState()),
        slotCode: slotItem.deliverySlotCode,
        token: USER_CONFIG.reactUserToken,
        userId: USER_CONFIG.userId,
      },
      method: EApiMiddlewareMethod.PUT,
      url: isSurchargeSupported
        ? PUT_SELECTED_DELIVERY_SLOT_API_NEW
        : PUT_SELECTED_DELIVERY_SLOT_API,
    },
  });
};
