import { I3DSREQUESTPARAM, IPaymentTypes } from 'services/payments/Payments.types';
import { Dispatch } from 'redux';
import { storePaymentDetails } from 'pages/Checkout/redux/actions/payment.action';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import { EStepsProgress } from 'pages/Checkout/interfaces/stepsProgress.interface';
import { setStepStatus } from 'pages/Checkout/redux/actions/stepsProgress.action';
import { isNonEmptyObject } from 'utils/helpers.util';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import {
  COMPLETE_TRANSACTION,
  THREE_DS_ENROLLMENT_CHECK,
} from 'pages/Checkout/constants/apis.constant';
import PaymentServiceActionTypes, {
  I3DSDATA,
  I3DSDATASUCCESS,
  ITRANSACTIONCOMPLETE,
  InitPaymentActionTypes,
} from '../paymentService.types';
import { hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import APP_CONFIG from 'config/App.config';
import { IAppState } from 'config/redux/reducers';
import history from 'routes/customHistory';
import { hideNotification, showNotification } from 'common/Notification/redux/notification.action';
import { removeCashbackPoints } from 'pages/Checkout/redux/actions/order.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { InitPaymentResponse } from 'services/payments/core/PaymentsManagerSDK';
export const processMPGSPaymentSessionDetails = (response: any) => {
  const cardInfo = response.card;
  return {
    newCardDetails: {
      card_type: cardInfo.brand,
      card_expiry_date: `${cardInfo.expiry.month} / ${cardInfo.expiry.year}`,
      card_cvn: '',
      card_number: cardInfo.number,
    },
    saved: false,
    savePaymentInfo: false, // when save card option will come will update this flag
    isValid: true,
    sessionId: response.session.id,
  };
};
export const savePaymentDetails = (response: any) => (dispatch: Dispatch) => {
  const cardInfo = response.card;
  if (isNonEmptyObject(cardInfo)) {
    const sessionPaymentDetails = processMPGSPaymentSessionDetails(response);
    dispatch(storePaymentDetails(EPaymentMethods.CREDIT_CARD, sessionPaymentDetails));
    dispatch(setStepStatus(EStepsProgress.payment, true));
  }
  dispatch(saveSessionAndPaymentDetails(response));
};

export const initiateOtpVerification = ({ code, ...restParams }: I3DSREQUESTPARAM) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  dispatch({
    type: PaymentServiceActionTypes.PAYMENT_SERVICE_THREE_DS_ENROLLMENT,
    payload: {
      method: EApiMiddlewareMethod.PUT,
      url: THREE_DS_ENROLLMENT_CHECK,
      data: restParams,
      params: { code },
      onSuccess: ({ data, errorData }: I3DSDATA) => {
        if (data) {
          dispatch(save3dsData(data));
          if (!data.cardEnrolled) {
            const {
              checkout: { orderDetails },
              paymentService,
            } = getState();
            dispatch<any>(
              completeTransaction({
                amount: orderDetails.orderWsDto.totalPriceWithTax.value,
                cardType: paymentService.card.brand,
                currency: orderDetails.orderWsDto.totalPriceWithTax.currencyIso,
                orderId: orderDetails.orderNumber,
                paymentMethod: orderDetails.orderWsDto.paymentMode,
                secureId: paymentService.otpVerificationData._3dSecureId,
                sessionId: paymentService.session.id,
                siteId: APP_CONFIG.storeId,
                transactionType: orderDetails.transactionType,
                userId: orderDetails.orderWsDto.user.uid,
                signature: restParams.signature,
              })
            );
          } else {
            dispatch(hidePageLoader());
          }
        }
        if (errorData) {
          dispatch(hidePageLoader());
          // error handling
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: errorData.message,
            })
          );
          dispatch(removeCashbackPoints() as any);
        }
      },
      onFailure: (
        errorMessage: string,
        {
          response: {
            data: { error },
          },
        }: any
      ) => {
        dispatch(hidePageLoader());
        dispatch(removeCashbackPoints() as any);
        savedPaymentErrors(error);
      },
    },
  });
};

export const completeTransaction = (params: any) => (dispatch: Dispatch) => {
  dispatch({
    type: PaymentServiceActionTypes.PAYMENT_SERVICE_COMPLETE_TRANSACTION,
    payload: {
      method: EApiMiddlewareMethod.PUT,
      url: COMPLETE_TRANSACTION,
      data: params,
      params: { code: params.orderId, uId: params.userId },
      onSuccess: ({ data, result, errorData }: ITRANSACTIONCOMPLETE) => {
        if (result === 'SUCCESS' && data) {
          dispatch(hideNotification());
          history.push(`/app/checkout/orderConfirmation/${data.orderId}`);
        }
        if (errorData) {
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: errorData.message,
            })
          );
        }
      },
      onFailure: (errorMessage: any) => {
        dispatch(
          showNotification({
            type: notificationTypes.errorCheckout,
            message: errorMessage,
          })
        );
      },
      onFinally: () => {
        dispatch(hidePageLoader());
      },
    },
  });
};

export const save3dsData = (data: I3DSDATASUCCESS) => ({
  type: PaymentServiceActionTypes.PAYMENT_SERVICE_THREE_DS_DATA_SAVE,
  payload: {
    ...data,
  },
});

export const saveSessionAndPaymentDetails = (response: any) => ({
  type: IPaymentTypes.SESSION_FETCHED_SUCCESS,
  payload: { ...response },
});

export const savedPaymentErrors = (response: any) => ({
  type: IPaymentTypes.PAYMENT_DETAILS_ERROR,
  payload: { ...response },
});

export const saveInputFieldsError = (error: any) => ({
  type: PaymentServiceActionTypes.PAYMENT_SERVICE_INPUT_FIELDS_ERROR_SAVE,
  payload: error,
});

export const setInitPayment = (payload: InitPaymentResponse) => {
  return {
    type: InitPaymentActionTypes.INIT_PAYMENT_SUCCESS,
    payload,
  };
};

export const setInitPaymentError = (error: any) => {
  return {
    type: InitPaymentActionTypes.INIT_PAYMENT_FAILURE,
    payload: error,
  };
};
