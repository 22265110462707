import { Dispatch } from 'redux';

import { IAppState } from 'config/redux/reducers';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';

import CartActionTypes, { CART_FETCH_CART_DATA_SUCCESS } from '../Cart.types';
import {
  GET_CART_API,
  GET_EXPRESS_CART_API,
  GET_EXPRESS_CART_API_SPLIT_PAYMENT,
  UPDATE_CART_ADDRESS,
} from '../../constants/apis.constant';

import { setOrderSummaryData } from 'common/OrderSummary/redux/orderSummary.action';

import { setShipments } from 'services/shipments/redux/shipments.actions';
import { setSessionArea } from 'services/sessionArea/redux/sessionArea.actions';
import { MiniCartActions } from '@mafc/common';
import { convertQuantityToKG } from 'utils/quantity.util';
import { batch } from 'react-redux';
import { getGUID, getUserTokenFromCookie } from 'utils/cookies.util';
import CONSTANTS from 'config/constants';
import { updateShareCartUrl } from '../../components/ShareCart/redux/shareCart.action';
import { GET_EARNED_POINTS } from 'pages/OrderDetail/constants/apis.constants';
import USER_CONFIG from 'config/User.config';
import { updateNIC, toggleNIC } from 'common/User/redux/User.actions';
import { newFetchCartAction } from './newCart.actions';
import APP_CONFIG from 'config/App.config';
import { checkoutType, getCartId, isExpressSPC } from 'pages/Cart/helpers/url.helpers';
import isPaymentV2Enabled from 'pages/Checkout/components/Payment/helpers/isPaymentV2Enabled';
import { EShipmentTypes, IShipment } from 'services/shipments/types/shipments.types';
import { getAvailableServiceTypes } from 'pages/Cart/helpers/getServiceType.helper';

export const fetchedCartData = (data: { [key: string]: any }) => {
  return {
    type: CART_FETCH_CART_DATA_SUCCESS,
    payload: data,
  };
};

export const resetPackingChargesCartData = () => {
  return {
    type: CartActionTypes.CART_RESET_PACKING_CHARGE,
  };
};

export const updateMiniCart = (data: { [key: string]: any }, dispatch: Dispatch) => {
  const { entries = [], totalUnitCount = 0, totalItems = 0 } = data;
  const productEntries: { [k: string]: any } = {};
  // in v3 cart we are not getting totalUnitCount
  const totalProductCount = totalUnitCount || totalItems;

  entries.forEach((entry: any, index: number) => {
    const {
      product: { code, soldByWeight },
      quantity,
      intent,
    } = entry;

    productEntries[code] = {
      entry: code,
      intent,
      quantity: convertQuantityToKG(quantity, soldByWeight),
    };
  });
  dispatch(
    MiniCartActions.setMiniCartData({
      entries: productEntries,
      totalUnitCount: totalProductCount,
      updatedCart: null,
    })
  );
};

export const updateCart = (
  dispatch: Dispatch,
  data: { [key: string]: any },
  isUpdateCartRequired = true
) => {
  const {
    carrefourDeliveryCost,
    deliveryCost,
    deliveryMode,
    expressFee,
    subTotal,
    cartTotal,
    totalDiscounts,
    totalPriceWithTax,
    freeDelivery,
    freeDeliveryMessage,
    freeDeliveryThreshold,
    totalItems,
    totalUnitCount,
    codFee = {},
    appliedVouchers,
    loyaltyVouchers = [],
    paymentMode,
    marketPlaceDeliveryCost,
    subtotalMarketPlace,
    shipments,
    sessionArea,
    orderPaymentCost,
    marketplacePaymentCost,
    totalCodFee,
    nicNumber,
    showNIC,
    guid,
    totalServiceFee,
    totalSurCharge,
    totalDeliveryCost,
    totalFees,
    totalAppliedDiscounts,
    totalBundleDiscount,
    totalSavings,
  } = data;
  // to support compatibility with new cart journey
  // TODO_C4JOURNEY
  if (!data.totalPrice) {
    data.totalPrice = data.totalPriceWithTax;
  }
  batch(() => {
    if (isUpdateCartRequired) {
      // CCX-14: Need now to always call the "selected-slots" API
      // in order to check whether the selected area is elible for slot or not
      // if (isObject(deliverySlot)) {
      //   dispatch(setSelectedSlot(deliverySlot));
      // }
      const { isSurchargeIncluded = false } = data;
      dispatch(
        fetchedCartData({
          isSurchargeIncluded,
          ...data,
          loyaltyVouchers,
        })
      );
    }
    dispatch(setShipments(shipments || [])); // In case shipment is not present in the Cart API response, which also means cart is empty, hence passing `[]` as a fallback

    // Store `sessionArea` only if present in the API response object
    if (sessionArea) {
      dispatch(setSessionArea(sessionArea));
    }
    updateMiniCart(data, dispatch);

    const { serviceFee } =
      shipments.find(
        ({ shipmentType }: { shipmentType: string }) => shipmentType === EShipmentTypes.EXPRESS
      ) || ({} as IShipment);

    dispatch(
      setOrderSummaryData({
        carrefourDeliveryCost,
        deliveryCost,
        deliveryMode,
        expressFee,
        subTotal,
        cartTotal,
        totalDiscounts,
        totalPriceWithTax,
        freeDelivery,
        freeDeliveryMessage,
        freeDeliveryThreshold,
        totalItems,
        totalUnitCount,
        codFee,
        appliedVouchers,
        loyaltyVouchers,
        paymentMode,
        marketPlaceDeliveryCost,
        subtotalMarketPlace,
        orderPaymentCost,
        marketplacePaymentCost,
        totalCodFee,
        serviceFee,
        totalServiceFee,
        totalSurCharge,
        totalDeliveryCost,
        totalFees,
        totalAppliedDiscounts,
        totalBundleDiscount,
        totalSavings,
      })
    );

    if (guid !== undefined) {
      dispatch(cartToggleError({ error: false, errorMessage: '' }));
    }
    dispatch(updateShareCartUrl(''));
    if (nicNumber) {
      dispatch(updateNIC(nicNumber));
    }
    dispatch(toggleNIC(showNIC));
  });
};

export const cartToggleError = ({
  error = true,
  errorMessage = '',
}: {
  error?: boolean;
  errorMessage?: string;
}) => ({
  type: CartActionTypes.CART_TOGGLE_ERROR,
  payload: { error, errorMessage },
});

export const cartAction = (
  url: string,
  successCallback?: () => void,
  completedCallback?: () => void
) => (dispatch: Dispatch, getState: () => IAppState) => {
  if (getUserTokenFromCookie() || getGUID()) {
    batch(() => {
      const { areaCode } = getState().appConfig;
      dispatch({
        type: CartActionTypes.CART_FETCH_CART_DATA,
        payload: {
          enableProgress: false,
          method: EApiMiddlewareMethod.GET,
          onSuccess: (data: { [key: string]: any }) => {
            updateCart(dispatch, data);
            if (successCallback) {
              successCallback();
            }
            dispatch(getEarnedPoints() as any);
          },
          url,
          params: { areaCode },
          onFailure: (errorMessage: string) => {
            dispatch(cartToggleError({ errorMessage }));
          },
          onFinally: () => {
            if (completedCallback) {
              completedCallback();
            }
          },
        },
      });
    });
  } else {
    dispatch(cartToggleError({ errorMessage: CONSTANTS.guidUnavailable }));
    if (completedCallback) {
      completedCallback();
    }
  }
};

export const fetchCartAction = (successCallback?: () => void, completedCallback?: () => void) => (
  dispatch: Dispatch
) => {
  const url = isExpressSPC() ? GET_EXPRESS_CART_API : GET_CART_API;
  dispatch(cartAction(url, successCallback, completedCallback) as any);
};

export const expressCartAction = (successCallback?: () => void, completedCallback?: () => void) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const store = getState();

  const isWebPaymentArchitectureV2Enabled = isPaymentV2Enabled(store);

  const url = isWebPaymentArchitectureV2Enabled
    ? GET_EXPRESS_CART_API_SPLIT_PAYMENT
    : GET_EXPRESS_CART_API;
  dispatch(cartAction(url, successCallback, completedCallback) as any);
};

export const updateAreaCode = (areacode?: string) => (dispatch: Dispatch) => ({
  type: CartActionTypes.CART_FETCH_CART_DATA,
  payload: {
    enableProgress: false,
    method: EApiMiddlewareMethod.GET,
    onSuccess: (data: { [key: string]: any }) => updateCart(dispatch, data),
    onFailure: () => dispatch(cartToggleError({})),
    url: UPDATE_CART_ADDRESS,
    params: { areacode },
  },
});

export const fetchCart = (successCallback?: () => void, completedCallback?: () => void) => (
  dispatch: any,
  getState: () => IAppState
) => {
  if (getState().cart.error) {
    dispatch(cartToggleError({ error: false }));
  }
  const hasCart = window.location.href.indexOf('/cart') !== -1;
  if (APP_CONFIG.isNewEndpoint && hasCart) {
    dispatch(newFetchCartAction(successCallback, completedCallback));
  } else {
    dispatch(fetchCartAction(successCallback, completedCallback));
  }
};

export const getEarnedPoints = (isLoyaltyApplied = false) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const hasCart = window.location.href.indexOf('/cart') !== -1;
  if ((APP_CONFIG.isNewEndpoint && hasCart) || !getUserTokenFromCookie()) {
    return;
  }
  const nsp = getAvailableServiceTypes(getState);
  dispatch({
    type: CartActionTypes.CART_EARNED_POINTS,
    payload: {
      enableProgress: false,
      method: EApiMiddlewareMethod.GET,
      url: GET_EARNED_POINTS,
      isAbsUrl: true,
      params: {
        checkoutType: checkoutType(),
        isLoyaltyApplied,
        nsp,
      },
      onSuccess: (response: any) => {
        dispatch(
          setOrderSummaryData({
            loyaltyPoints: response.pointsEarned,
          } as any)
        );
      },
      onFailure: () =>
        dispatch(
          setOrderSummaryData({
            loyaltyPoints: 0,
          } as any)
        ),
    },
  });
};
