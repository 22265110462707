import {
  ESupportedPaymentStrategy,
  PaymentService_EVENTS,
  Payments3DSStatus,
} from './../Payments.types';
import {
  PaymentManagerInitError,
  PaymentServiceResponseError,
  PaymentManagerInternalError,
} from './errors';
import PaymentService from './PaymentService.abstract';
import { parseQueryString } from '../utils/url.util';
import MPGSLog from '../utils/logger';

type MPGSListener = (payload?: any) => void;

// tslint:disable-next-line: variable-name
let _internalInstance_: PaymentsManager | null = null;

export default class PaymentsManager {
  service!: PaymentService;
  isSessionInitialized: boolean = false;
  storage: { [key: string]: any } = {};
  private serviceUpdateListener?: (response: any) => void;
  private isServiceInstalled = false;

  constructor(strategy: PaymentService) {
    if (_internalInstance_) {
      return _internalInstance_;
    }
    if (
      Object.values(ESupportedPaymentStrategy).includes(strategy.name as ESupportedPaymentStrategy)
    ) {
      _internalInstance_ = this;
      this.service = strategy;
      this.service.installEventListener(this.serviceEventsListener.bind(this));
      MPGSLog('initialized Manager');
    } else {
      throw new PaymentManagerInitError(strategy.name);
    }
  }

  get currentStrategy() {
    return this.service.name;
  }

  set serviceUpdatesListener(listener: (response: any) => void) {
    this.serviceUpdateListener = listener;
  }

  refreshPaymentService() {
    this.isServiceInstalled = false;
    this.isSessionInitialized = false;
  }

  createOrder() {
    this.service.createOrder();
  }

  initService() {
    if (!this.isServiceInstalled) {
      this.isServiceInstalled = true;
      this.service.loadScript();
    }
  }

  pay() {
    MPGSLog('initiated capture');
    this.service.initiateCapture();
  }

  saveSessionData(sessionData: any) {
    this.storage.session = sessionData;
  }

  get sessionData() {
    return this.storage.session;
  }

  serviceEventsListener(name: string, payload: any) {
    const self = this;
    const eventHandlerMaps: { [key: string]: MPGSListener } = {
      [PaymentService_EVENTS.hostedSessionInitialized](isReady: boolean) {
        const isSessionInitSuccessfully = isReady;
        self.isSessionInitialized = isSessionInitSuccessfully;
      },
      [PaymentService_EVENTS.receivedSessionResponse](response) {
        self.handleServiceResponses(response);
      },
      [PaymentService_EVENTS.receivedSessionFailure](response) {
        throw new PaymentServiceResponseError('Session update failed' + JSON.stringify(response));
      },
      [PaymentService_EVENTS.cardInputEvent](response) {
        self.handleServiceResponses(response);
      },
    };

    if (eventHandlerMaps[name]) {
      eventHandlerMaps[name](payload);
    }
  }

  parse3DSResponse(searchString: string) {
    const params: { [key: string]: any } = parseQueryString(searchString);
    const ok = params.acsResult === Payments3DSStatus.SUCCESS;
    const failedValidation = params.acsResult === Payments3DSStatus.DECLINE;
    return {
      ok,
      failedValidation,
      debugMsg: 'Decline: validation failed from gateway | ERROR: error in API connection',
      ...params,
    };
  }

  private handleServiceResponses(response: any) {
    if (this.serviceUpdateListener) {
      MPGSLog('handleServiceResponses', response);
      this.serviceUpdateListener(response);
    } else {
      throw new PaymentManagerInternalError(
        'serviceUpdateListener is undefined | Meta available',
        this
      );
    }
  }

  private setStrategy(target: PaymentService) {
    if (
      Object.values(ESupportedPaymentStrategy).includes(target.name as ESupportedPaymentStrategy)
    ) {
      this.service = target;
    } else {
      throw new PaymentManagerInitError(target.name);
    }
  }
}
