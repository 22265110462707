import {
  ESessionAreaActionTypes,
  TSessionAreaAction,
  TSessionArea,
} from '../types/sessionArea.types';

export const setSessionArea = (areaSession: TSessionArea): TSessionAreaAction => ({
  type: ESessionAreaActionTypes.SESSION_AREA_SET_STATE,
  payload: areaSession,
});
