import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import { CardType } from 'pages/Checkout/types/cnc.types';
import {
  ICheckMultiItemReturnability,
  ICheckMultiItemReturnabilityEligibleItem,
} from 'pages/OrderDetail/orderData.type';

export const isCardVisa = (bin: string): boolean => bin.startsWith('4') || false;
export const isCardMaster = (bin: string): boolean =>
  bin.startsWith('5') || bin.startsWith('2') || false;
export const isCardAmex = (bin: string): boolean => bin.startsWith('3') || false;

export const getCardRepresentationCodeFromCardNumber = (cardNumber: string): string => {
  if (isCardVisa(cardNumber)) {
    return CardType.VISA;
  }
  if (isCardMaster(cardNumber)) {
    return CardType.MASTER_CARD;
  }
  if (isCardAmex(cardNumber)) {
    return CardType.AMEX;
  }
  return '';
};

export const transformPaymentInfo = (info: any, paymentMode: string) => {
  let encryptInfo = info;
  if (paymentMode === EPaymentMethods.CREDIT_CARD && !info.cardId) {
    const [expiryMonth, expiryYear] = info.card_expiry_date.split('/');
    encryptInfo = {
      cardNumber: info.card_number,
      expiryMonth,
      expiryYear,
      cvv: info.card_cvn,
      cardHolderName: info.card_holder_name || '',
      cardType: getCardRepresentationCodeFromCardNumber(info.card_number),
    };
  }
  if (paymentMode === EPaymentMethods.COD) {
    return null;
  }
  return encryptInfo;
};

export const returnEligibleProducts = (
  multiItemReturnability: ICheckMultiItemReturnability
): ICheckMultiItemReturnabilityEligibleItem | any => {
  if (multiItemReturnability.itemsEligibleForSSR.length > 0) {
    return multiItemReturnability.itemsEligibleForSSR;
  }
  return multiItemReturnability.itemsEligibleForC4C;
};
