import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import IAction from 'config/redux/actions/interface';
import CheckoutActionTypes, {
  CHECKOUT_FETCH_CREDIT_CARDS_START,
  CHECKOUT_FETCH_CREDIT_CARDS_END,
  CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS,
  CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS,
  CHECKOUT_SET_PAYMENT_MODE_START,
  CHECKOUT_SET_PAYMENT_MODE_SUCCESS,
  CHECKOUT_FETCH_PAYMENT_MODE_START,
} from '../Checkout.types';

const fetchCreditCardsStart = (state: ICheckoutState, action: IAction) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      loading: true,
      creditCards: {
        ...state.payment.creditCards,
      },
    },
  };
};

const fetchCreditCardsEnd = (state: ICheckoutState, action: IAction) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      loading: false,
      creditCards: {
        ...state.payment.creditCards,
      },
    },
  };
};

const fetchCreditCardsSuccess = (state: ICheckoutState, action: IAction) => {
  const { payload } = action;

  return {
    ...state,
    payment: {
      ...state.payment,
      creditCards: {
        ...state.payment.creditCards,
        list: [...payload.payments],
      },
    },
  };
};

const fetchPaymentModesStart = (state: ICheckoutState, action: IAction) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      loading: true,
    },
  };
};

const fetchPaymentModesSuccess = (state: ICheckoutState, action: IAction) => {
  const { payload } = action;
  const { modes, secondary = [] } = payload;
  return {
    ...state,
    payment: {
      ...state.payment,
      modes,
      secondary,
      loading: false,
    },
  };
};

const setPaymentModeStart = (state: ICheckoutState, action: IAction) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      loading: true,
      paymentMode: {
        ...state.payment.paymentMode,
        loading: true,
      },
    },
  };
};

const setPaymentModeSuccess = (state: ICheckoutState, action: IAction) => {
  const {
    payload: { paymentMode, paymentDetails },
  } = action;

  return {
    ...state,
    payment: {
      ...state.payment,
      loading: false,
      paymentMode: {
        loading: false,
        type: paymentMode,
      },
      paymentDetails: { ...paymentDetails },
    },
  };
};

const togglePaymentLoading = (state: ICheckoutState, { payload: { loading } }: IAction) => {
  return {
    ...state,
    payment: {
      ...state.payment,
      loading,
    },
  };
};

export default {
  [CHECKOUT_FETCH_CREDIT_CARDS_START]: fetchCreditCardsStart,
  [CHECKOUT_FETCH_CREDIT_CARDS_END]: fetchCreditCardsEnd,
  [CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS]: fetchCreditCardsSuccess,
  [CHECKOUT_FETCH_PAYMENT_MODE_START]: fetchPaymentModesStart,
  [CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS]: fetchPaymentModesSuccess,
  [CHECKOUT_SET_PAYMENT_MODE_SUCCESS]: setPaymentModeSuccess,
  [CHECKOUT_SET_PAYMENT_MODE_START]: setPaymentModeStart,
  [CheckoutActionTypes.CHECKOUT_PAYMENT_LOADING]: togglePaymentLoading,
};
