import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@mafc/appshell';
import createOrder from 'pages/Checkout/redux/actions/order.action';
import FEATURES_CONFIG from 'config/features';
import { PaymentHookError } from '../core/errors';
import PaymentsManager from '../core/PaymentsManager';
import MasterCard from '../mastercard';
import { showPageLoader } from 'common/PageLoader/redux/pageLoader.action';
import PlaceOrderLoadingText from 'pages/Checkout/components/SOPForm/PlaceOrderLoadingText';
import { IAppState } from 'config/redux/reducers';
import {
  completeTransaction,
  initiateOtpVerification,
  save3dsData,
  saveInputFieldsError,
  savePaymentDetails,
  setInitPayment,
  setInitPaymentError,
} from '../redux/actions/payments.action';
import MPGSLog from '../utils/logger';
import APP_CONFIG from 'config/App.config';
import { showNotification } from 'common/Notification/redux/notification.action';
import { I3DSDATASUCCESS } from '../redux/paymentService.types';
import { useTranslation } from 'react-i18next';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import {
  InputErrorType,
  IValidationCallback,
  MasterCardStatusType,
  PaymentService_EVENTS,
} from '../Payments.types';
import { initiateWalletTransaction } from '../mobileWallet';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { createUpdateOrder } from 'pages/Checkout/redux/actions/orderV3.action';
import { FormType, InitPaymentResponse } from '../core/PaymentsManagerSDK';
import history from 'routes/customHistory';

const sessionStorage = (() => {
  const storage: { [key: string]: any } = {};
  return {
    save(key: string, val: any) {
      storage[key] = val;
    },
    retrieve(key: string) {
      return storage[key];
    },
  };
})();

export default () => {
  const dispatch = useDispatch();
  const isCheckoutDotComSupported = useFeature('checkout-dot-com-supported').on;
  const mpgs = useMemo(() => {
    if (!sessionStorage.retrieve('mpgs')) {
      sessionStorage.save(
        'mpgs',
        new MasterCard({
          cardConfig: {
            nameOnCard: '#cardholder-name',
            securityCode: '#security-code',
            expiryMonth: '#expiry-month',
            expiryYear: '#expiry-year',
            number: '#card-number',
          },
        })
      );
    }
    return sessionStorage.retrieve('mpgs');
  }, []);
  const isMasterCardEnabled = !!FEATURES_CONFIG.settings.enableMasterCard;
  const isVisaEnabled = !!FEATURES_CONFIG.settings.enableVisa;
  const serviceChoiceAmbiguous = isVisaEnabled === isMasterCardEnabled;
  const paymentsManager = useMemo(() => new PaymentsManager(mpgs), [mpgs]);
  const { t } = useTranslation();

  const {
    checkout: { orderDetails },
    paymentService,
    orderSummary,
  } = useSelector((state: IAppState) => state);
  if (serviceChoiceAmbiguous) {
    throw new PaymentHookError('FATAL! More than one or no method enabled for payment service', {
      mpgs: isMasterCardEnabled,
      cs: isVisaEnabled,
    });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const successCallback = (data: any) => {
    // if mastercard
    if (
      FEATURES_CONFIG.settings.enableMasterCard &&
      !isCheckoutDotComSupported &&
      data.paymentModeSelected === EPaymentMethods.CREDIT_CARD &&
      !!paymentsManager.sessionData
    ) {
      // hit 3ds api and get the otp screen
      const sessionData = paymentsManager.sessionData;
      dispatch(showPageLoader(<PlaceOrderLoadingText />));
      dispatch(
        initiateOtpVerification({
          cardType: sessionData.card.brand,
          sessionId: sessionData.session.id,
          transactionType: data.orderFieldMap.transaction_type,
          amount: data.orderWsDto.totalPriceWithTax.value,
          currency: orderSummary.totalPriceWithTax.currencyIso,
          code: data.orderWsDto.code,
          signature: data.orderFieldMap.signature,
        })
      );
    }
    if (data.paymentModeSelected === EPaymentMethods.MOBILE_WALLET) {
      // initiate wallet transaction
      dispatch(initiateWalletTransaction(data));
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const failureCallback = () => {
    // order failure handling
  };
  const successV3Callback = useCallback(
    (data: InitPaymentResponse) => {
      if (data.action === FormType.REDIRECT) {
        window.location.href = data.url;
      }
      if (data.action === FormType.NO_OP) {
        history.push(`/app/checkout/orderConfirmation`);
      }
      dispatch(setInitPayment(data));
    },
    [dispatch]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const failureV3Callback = (error: any) => {
    // order failure handling
    dispatch(setInitPaymentError(error));
  };
  const createOrderAction = useCallback(
    (args: any) =>
      dispatch(
        createOrder({
          ...args,
          params: {
            ...args.params,
            successCallback,
            failureCallback,
          },
        })
      ),
    [dispatch, successCallback, failureCallback]
  );

  const createUpdateOrderAction = useCallback(
    (args: any) =>
      dispatch(
        createUpdateOrder({
          ...args,
          params: {
            ...args.params,
            successV3Callback,
            failureV3Callback,
          },
        })
      ),
    [dispatch, successV3Callback, failureV3Callback]
  );

  // this will be fixed in coming commits
  const masterCardCreateOrder = useCallback(() => {
    paymentsManager.pay();
  }, [paymentsManager]);

  const initService = useCallback(() => paymentsManager.initService(), [paymentsManager]);

  const registerServiceListener = useCallback(
    (listener: (val: any) => void) => (paymentsManager.serviceUpdatesListener = listener),
    [paymentsManager]
  );

  const reload = useCallback(() => paymentsManager.refreshPaymentService(), [paymentsManager]);

  const updateStoreWith3DSResponse = useCallback(
    (searchString: string) => {
      const params = paymentsManager.parse3DSResponse(searchString);
      MPGSLog('updateStoreWith3DSResponse', params);

      if (params.ok) {
        // initiate complete transaction api to place order
        dispatch(showPageLoader(<PlaceOrderLoadingText />));
        dispatch(
          completeTransaction({
            amount: orderDetails.orderWsDto.totalPriceWithTax.value,
            cardType: paymentService.card.brand,
            currency: orderDetails.orderWsDto.totalPriceWithTax.currencyIso,
            orderId: orderDetails.orderNumber,
            paymentMethod: orderDetails.orderWsDto.paymentMode,
            secureId: paymentService.otpVerificationData._3dSecureId,
            sessionId: paymentService.session.id,
            siteId: APP_CONFIG.storeId,
            transactionType: orderDetails.transactionType,
            userId: orderDetails.orderWsDto.user.uid,
            signature: orderDetails.signature,
          })
        );
      } else {
        // show error message on 3ds failure
        let errorMessage = t('otpVerification.connectionError');
        if (params.failedValidation) {
          errorMessage = t('otpVerification.otpValidationError');
        }
        dispatch(
          showNotification({
            type: notificationTypes.errorCheckout,
            message: errorMessage,
          })
        );
      }
      dispatch(save3dsData({ htmlBodyContent: '' } as I3DSDATASUCCESS)); // clearing 3ds html content to hide pop up
    },
    [paymentsManager, dispatch, orderDetails, paymentService, t]
  );

  const checkCardValidation = (callback: (data: IValidationCallback) => void) => {
    const masterCardEventListener = (response: any) => {
      if (response.event && response.event === PaymentService_EVENTS.receivedSessionResponse) {
        switch (response.status) {
          case MasterCardStatusType.ok:
            const cardDetails = response.payload.card.card;
            const sessionData = { ...response.payload, card: cardDetails };
            if (!cardDetails.securityCode) {
              dispatch(
                saveInputFieldsError({
                  securityCode: InputErrorType.missing,
                })
              );
              callback({
                isValid: false,
                error: {
                  error: {
                    event: MasterCardStatusType.fieldInError,
                    securityCode: InputErrorType.missing,
                    message: MasterCardStatusType.fieldInError,
                  },
                },
              });
              return;
            }

            saveSessionData(sessionData);
            dispatch(savePaymentDetails(sessionData)); // save card and session details in state
            callback({ isValid: true, successData: response.payload });
            break;
          case MasterCardStatusType.fieldInError:
            dispatch(saveInputFieldsError(response.payload.error));
            callback({
              isValid: false,
              error: {
                event: response.status,
                error: response.payload.error,
                message: response.payload.message,
              },
            });
            break;
          case MasterCardStatusType.requestTimeOut:
          case MasterCardStatusType.systemError:
            callback({
              isValid: false,
              error: {
                event: response.status,
                error: response.payload.error,
                message: response.payload.message,
              },
            });
            dispatch(
              showNotification({
                type: notificationTypes.errorCheckout,
                message: t('errorPlacingOrder'),
              })
            );
            window.scrollTo(0, 0);
            break;
        }
      }
    };
    registerServiceListener(masterCardEventListener);
    masterCardCreateOrder();
  };

  const saveSessionData = (sessionData: any) => {
    paymentsManager.saveSessionData(sessionData);
  };

  return {
    captureSession: masterCardCreateOrder,
    createOrderAction,
    initService,
    registerServiceListener,
    html3Ds:
      paymentService &&
      paymentService.otpVerificationData &&
      paymentService.otpVerificationData.htmlBodyContent,
    isMasterCardEnabled,
    updateStoreWith3DSResponse,
    reload,
    checkCardValidation,
    saveSessionData,
    createUpdateOrderAction,
  };
};
