import { CookieUtils } from '@mafc/common';
import { useFlagship } from '@flagship.io/react-sdk';
import Tracker from 'utils/Tracker';
import constants from 'config/constants';
import {
  FLAGSHIP_EVENTS_GTM,
  FLAGSHIP_IDENTIFIER,
} from 'pages/NewCart/constants/flagship.constant';

export const useGTMForFlagshipCampaigns = () => {
  const { getFlag } = useFlagship();
  const { campaigns = [], visitorId = '' } = getCampaignsAndVisitorId();
  campaigns.forEach((campaign: { flags: {} }) => {
    const key = Object.keys(campaign.flags)[0];
    const defaultvalueOfKey = getDefaultValueOfKey(key);
    const flag = getFlag(key, defaultvalueOfKey);
    const {
      campaignId = '',
      campaignType = '',
      variationGroupId = '',
      variationId = '',
      campaignName = '',
      variationName = '',
      isReference = false,
    } = flag.metadata;
    if (campaignId) {
      Tracker.gtmForFlagship({
        event: FLAGSHIP_EVENTS_GTM.VIEWED_EXPERIMENT,
        experiment_key: campaignName,
        flagship_visitor_id: visitorId,
        flagship_campaign_id: campaignId,
        flagship_campaign_type: campaignType,
        flagship_variation_group_id: variationGroupId,
        flagship_variation_id: variationId,
        flagship_variation_name: variationName,
        flagship_campaign_name: campaignName,
        variation_id: isReference ? 0 : 1,
        flagship_platform: FLAGSHIP_EVENTS_GTM.PLATFORM,
      });
    }
  });
};
export const getCampaignsAndVisitorId = () => {
  const mafUserId = CookieUtils.getCustomCookie(constants.mafUserId);
  const mafSessionId = CookieUtils.getCustomCookie(constants.mafSessionId);
  const visitorId = mafUserId || mafSessionId;
  const { data: { campaigns } = { campaigns: [] } } = JSON.parse(
    localStorage.getItem(`FS_VISITOR_CACHE_${visitorId}`) || '{}'
  );
  return { campaigns, visitorId };
};
export const getDefaultValueOfKey = (key: string) => {
  const typeOfDeafaultValue = key.split(':')[1];
  switch (typeOfDeafaultValue) {
    case FLAGSHIP_IDENTIFIER.TEXT:
      return '';
    case FLAGSHIP_IDENTIFIER.OBJECT:
      return {};
    case FLAGSHIP_IDENTIFIER.ARRAY:
      return [];
    case FLAGSHIP_IDENTIFIER.NUMBER:
      return 0;
    default:
      return false;
  }
};
