import { PaymentServiceStrategyError, PaymentServiceMethodError } from './errors';

export default class PaymentService {
  name: string = '_GENERIC_';
  constructor() {
    if (this.constructor === PaymentService) {
      throw new PaymentServiceStrategyError('Attempted instantiating an abstract class');
    }
  }

  createOrder() {
    throw new PaymentServiceMethodError('createOrder');
  }

  setupSession() {
    throw new PaymentServiceMethodError('setupSession');
  }

  installEventListener(listener: (name: string, payload: any) => void) {
    throw new PaymentServiceMethodError('installEventListener');
  }

  initiateCapture() {
    throw new PaymentServiceMethodError('initateCapture');
  }

  loadScript() {
    throw new PaymentServiceMethodError('loadScript');
  }

  get isEnabledFromConfig() {
    throw new PaymentServiceMethodError('isEnabledFromConfig');
  }
}
