import ELoyaltyPointsTypes, { CHECKOUT_GET_LOYALTY_POINTS_SUCCESS } from './LoyaltyPoints.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import {
  GET_LOYALTY_INFO,
  GET_LOYALTY_SUMMARY,
  REEDEEM_LOYALTY_CASHBACK,
} from 'pages/Checkout/constants/apis.constant';
import { Dispatch } from 'redux';
import { getEarnedPoints, updateCart } from 'pages/Cart/redux/actions';
import {
  togglePaymentLoading,
  // setPaymentMode,
  fetchPaymentModes,
  // fetchCreditCards,
  storePaymentDetails,
} from 'pages/Checkout/redux/actions/payment.action';
import EPaymentMethods from '../../Payment/PamentMethods.enum';
import { showNotification, hideNotification } from 'common/Notification/redux/notification.action';
import { IAppState } from 'config/redux/reducers';
import FEATURES_CONFIG from 'config/features';
import { getCartId } from 'pages/Cart/helpers/url.helpers';
import { isNonEmptyObject } from 'utils/helpers.util';
import i18n from 'config/localisation';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { isShareEnabledGrowthBook, loyaltyPointResponseShaper } from '../loyalty.helper';
import { createGrowthBookInstance } from '@mafc/appshell';
const { processPoints } = FEATURES_CONFIG.settings;
export const toggleLoyaltySwitch = (payload: boolean) => ({
  payload,
  type: ELoyaltyPointsTypes.CHECKOUT_LOYALTY_TOGGLE_SWITCH,
});
export const getLoyaltyPoints = () => (dispatch: Dispatch, getState: () => IAppState) => {
  const isShare = isShareEnabledGrowthBook(getState());
  dispatch({
    type: ELoyaltyPointsTypes.CHECKOUT_GET_LOYALTY_POINTS,
    payload: {
      method: EApiMiddlewareMethod.GET,
      url: isShare ? GET_LOYALTY_INFO : GET_LOYALTY_SUMMARY,
      apiVersion: isShare ? 2 : 1,
      onSuccess: (response: any) => {
        dispatch(hideNotification());
        const data = loyaltyPointResponseShaper(response);
        const { loyaltyVouchers } = getState().cart.cartData;
        if (processPoints && loyaltyVouchers && loyaltyVouchers.length > 0) {
          data.totalPointsValue = loyaltyVouchers[0].currencyValue.value;
        }
        dispatch({
          type: CHECKOUT_GET_LOYALTY_POINTS_SUCCESS,
          payload: {
            ...data,
            isShare,
          },
        });
      },
      onFailure: (error: any, response: any) => onRedemptionError(response, dispatch),
    },
  });
};
export const onRedemptionError = ({ response }: any, dispatch: Dispatch) => {
  const data = isNonEmptyObject(response) ? response.data : {};
  const [{ message = i18n.t('loyaltyPoint.loadingError') } = {}] = (data && data.errors) || [];
  dispatch(showNotification({ type: notificationTypes.errorCheckout, message }));
  dispatch(togglePaymentLoading(false));
};
export const redeemLoyaltyPoints = (redeemedAmount: number, isFullCartRedemption: boolean) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const appConfig = getState().appConfig || {};
  const growthBook = appConfig.growthBook || {};
  const growthBookViaInstance = createGrowthBookInstance(
    {
      ...growthBook,
    },
    getState()
  );
  const isLoyaltyAppliedEnabled =
    growthBookViaInstance.feature('checkout.loyalty_applied.enable').on || false;
  dispatch(togglePaymentLoading(true));
  dispatch(toggleLoyaltySwitch(true));
  dispatch({
    type: ELoyaltyPointsTypes.CHECKOUT_REDEEM_LOYALTY_POINTS,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url: REEDEEM_LOYALTY_CASHBACK,
      onSuccess: (data: any) => {
        dispatch(hideNotification());
        updateCart(dispatch, data);
        if (isLoyaltyAppliedEnabled) {
          getEarnedPoints(true)(dispatch, getState);
        }
        dispatch(getLoyaltyPoints() as any);
        dispatch(togglePaymentLoading(false));
        const state = getState();
        const paymentDetails =
          state.checkout && state.checkout.payment && state.checkout.payment.paymentDetails
            ? state.checkout.payment.paymentDetails
            : {};
        const cartTotalAmount =
          state.cart &&
          state.cart.cartData &&
          state.cart.cartData.totalPrice &&
          state.cart.cartData.totalPrice.value;
        const isFullPayment = cartTotalAmount === 0;
        if (isFullPayment) {
          dispatch(storePaymentDetails(data.paymentMode, { ...paymentDetails }));
        }
        // if (isFullCartRedemption) {
        //   setPaymentMode(EPaymentMethods.CASHBACK, { ...paymentDetails })(dispatch, getState);
        // } else if (data.paymentMode === EPaymentMethods.CREDIT_CARD) {
        //   dispatch(fetchCreditCards() as any);
        // } else {
        //   setPaymentMode(data.paymentMode, { ...paymentDetails })(dispatch, getState);
        // }
      },
      onFailure: (error: any, response: any) => {
        onRedemptionError(response, dispatch);
        dispatch(toggleLoyaltySwitch(false));
      },
      data: {
        redeemedAmount,
      },
      params: {
        cartId: getCartId(getState()),
      },
    },
  });
};
export const removeRedeemedLoyaltyPoints = (isFullCartRedemption: boolean) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const appConfig = getState().appConfig || {};
  const growthBook = appConfig.growthBook || {};
  const growthBookViaInstance = createGrowthBookInstance(
    {
      ...growthBook,
    },
    getState()
  );
  const isLoyaltyAppliedEnabled =
    growthBookViaInstance.feature('checkout.loyalty_applied.enable').on || false;
  dispatch(togglePaymentLoading(true));
  dispatch(toggleLoyaltySwitch(false));
  dispatch({
    type: ELoyaltyPointsTypes.CHECKOUT_REMOVE_LOYALTY_POINTS,
    payload: {
      method: EApiMiddlewareMethod.DELETE,
      url: REEDEEM_LOYALTY_CASHBACK,
      onSuccess: (data: any) => {
        dispatch(hideNotification());
        updateCart(dispatch, data);
        if (isLoyaltyAppliedEnabled) {
          getEarnedPoints(false)(dispatch, getState);
        }
        dispatch(getLoyaltyPoints() as any);
        dispatch(togglePaymentLoading(false));
        if (isFullCartRedemption) {
          // Refreshes payments
          fetchPaymentModes({
            currentPaymentMode: data.paymentType as EPaymentMethods,
            isFetchCreditCards: true,
          })(dispatch, getState);
        }
      },
      onFailure: (error: any, response: any) => {
        onRedemptionError(response, dispatch);
        dispatch(toggleLoyaltySwitch(true));
      },
      params: {
        cartId: getCartId(getState()),
      },
    },
  });
};
