import APP_CONFIG, { EStoreID } from 'config/App.config';
import FEATURES_CONFIG from './Features.config';

const { storeId = EStoreID.MAFUAE } = APP_CONFIG;

// Dynamically obtain the features JSON based on the `storeId`
import(
  /* webpackChunkName: "[request]" */
  `./${storeId}.json`
).then(countryFeaturesJSON => {
  // Extracts the default features object
  const defaultFeaturesJSON = countryFeaturesJSON[FEATURES_CONFIG.__defaults__.env];
  // Extracts the current env features object
  const envFeaturesJSON = countryFeaturesJSON[process.env.REACT_APP_ENV || ''] || {};
  // Combine default and current to get the full set of features
  const featuresJSON = { ...defaultFeaturesJSON, ...envFeaturesJSON };

  // Finally, sets the features object to the `settings` property
  FEATURES_CONFIG.settings = featuresJSON;
});

export default FEATURES_CONFIG;
