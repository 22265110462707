import { IAppState } from '@mafc/common';
import { CHECKOUT_DOT_COM_URL } from '../constants/apis.constant';
import { getCheckoutDotComParams } from './checkoutDotCom.helper';
import extractQuantityProps from 'pages/Cart/components/UpdateQuantity/helpers/extractQuantityProps.helper';

const getExtraParams = (
  entries: Array<{ key: string; value: string }>
): { [key: string]: string } => {
  const extraParams: { [key: string]: string } = {};
  entries.forEach(({ key, value }) => {
    extraParams[key] = value ? value : '';
  });
  return extraParams;
};

export const getTrackingData = (entries: any = [], needCurrency = true) => {
  let price: any = [];
  let quantity: any = [];
  let item: any = [];
  let currency: string = '';

  entries.forEach((entry: any) => {
    const { totalPrice = { value: '', currencyIso: '' }, product = { code: '' } } = entry || {};
    const { quantity: qty = 1, delta = 1 } = extractQuantityProps(entry, product.code) || {};
    const quantityInUnits = Math.round(qty / delta);
    price.push(totalPrice.value);
    quantity.push(quantityInUnits);
    item.push(product.code);
    currency = totalPrice.currencyIso;
  });
  price = price.join('|');
  quantity = quantity.join('|');
  item = item.join('|');
  const trackingData: any = { price, quantity, item };

  if (needCurrency) {
    trackingData.currency = currency;
  }
  return trackingData;
};
export const modifiyOrderData = (
  store: IAppState,
  orderData: any,
  isCheckoutDotComFlow: boolean
) => {
  let modifiedOrderData = orderData;
  const guid = store.cart.cartData.guid;

  if (orderData.fieldsMap) {
    const cybersourceUrl = isCheckoutDotComFlow
      ? {
          cybersource_request_url: CHECKOUT_DOT_COM_URL,
        }
      : {};

    modifiedOrderData = {
      ...orderData,
      ...cybersourceUrl,
      orderFieldMap: isCheckoutDotComFlow
        ? getCheckoutDotComParams(store, orderData, guid)
        : getExtraParams(orderData.fieldsMap.entry),
      isCheckoutDotComFlow,
      guid,
    };
  }

  return modifiedOrderData;
};
