import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';
import { IReturnList } from '../returnData.type';

export const returnsInitialHistory: IReturnList = {
  returns: [],
  currentPage: 0,
  totalPages: 1,
  isLoading: false,
};

const getReturnList = (state: IReturnList, payload: any) => {
  if (payload.pagination.currentPage !== 0) {
    return [...state.returns, ...payload.returns];
  }
  return payload.returns;
};

const getReturnHistory = (state: IReturnList, payload: any) => {
  if (!payload.returns) {
    return;
  }
  return {
    ...state,
    returns: getReturnList(state, payload),
    currentPage: payload.pagination.currentPage,
    totalPages: payload.pagination.totalPages,
    isLoading: true,
  };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.GET_RETURN_LIST]: getReturnHistory,
};

export default function returnListingReducer(state = returnsInitialHistory, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
