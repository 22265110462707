import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import IAction from 'config/redux/actions/interface';
import { CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS } from '../Checkout.types';

const fetchPaymentArchitecture = (state: ICheckoutState, action: IAction) => {
  return {
    ...state,
    paymentArchitecture: {
      ...action.payload,
    },
  };
};

export default {
  [CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS]: fetchPaymentArchitecture,
};
