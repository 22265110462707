import { ICheckoutState, initialCheckoutState } from 'pages/Checkout/redux/reducer/index';
import screenReducer from './screen.reducer';
import validateCartReducer from './validateCart.reducer';

import { EDeliverToActionTypes, TDeliverToAction } from 'pages/Checkout/types/deliverTo.types';

export const updateDeliverToState = (state: ICheckoutState, action: TDeliverToAction) => {
  return {
    ...state,
    delivery: {
      ...state.delivery,
      deliverTo: {
        ...state.delivery.deliverTo,
        ...action.payload,
      },
    },
  };
};

export const resetDeliverToState = (state: ICheckoutState) => {
  return {
    ...state,
    delivery: {
      ...initialCheckoutState.delivery,
    },
  };
};

export default {
  [EDeliverToActionTypes.DELIVER_TO_SET_CACHE]: updateDeliverToState,
  [EDeliverToActionTypes.DELIVER_TO_SET_ERROR]: updateDeliverToState,
  [EDeliverToActionTypes.DELIVER_TO_SET_LOADING]: updateDeliverToState,
  [EDeliverToActionTypes.DELIVER_TO_SET_VALUE]: updateDeliverToState,
  [EDeliverToActionTypes.DELIVER_TO_RESET_VALUE]: resetDeliverToState,
  ...screenReducer,
  ...validateCartReducer,
};
