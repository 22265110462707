import IAction from 'config/redux/actions/interface';
import { ICartState } from './index';

export const fetchSubstitutesError = (cartState: ICartState) => {
  return { ...cartState, errorFetchSubstitutes: true };
};

export const fetchSubstitutesSuccess = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    substitutes: action.payload.data,
  };
};

export const fetchSubstitutesLoadingStart = (cartState: ICartState) => {
  return {
    ...cartState,
    loadingFetchSubstitutes: true,
  };
};

export const fetchSubstitutesLoadingEnd = (cartState: ICartState) => {
  return {
    ...cartState,
    errorFetchSubstitutes: false,
    loadingFetchSubstitutes: false,
  };
};

export const substitutesReplacementLoadingStart = (cartState: ICartState) => {
  return {
    ...cartState,
    errorSubstituteReplacement: false,
    loadingSubstituteReplacement: true,
  };
};

export const substitutesReplacementLoadingEnd = (cartState: ICartState) => {
  return {
    ...cartState,
    loadingSubstituteReplacement: false,
  };
};

export const substitutesReplacementError = (cartState: ICartState) => {
  return {
    ...cartState,
    errorSubstituteReplacement: true,
  };
};

export const updateOOSSelectedItems = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    oosSelectedItemsIds: action.payload,
  };
};

export const updateOOSCurrentItemIndex = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    errorSubstituteReplacement: false,
    oosCurrentItemIndex: action.payload,
  };
};
