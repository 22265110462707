import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum EPickupStoresActionTypes {
  PICKUP_STORES_GET_LIST = 'PickupStores/GET_LIST',
  PICKUP_STORES_GET_LIST_SUCCESS = 'PickupStores/GET_LIST_SUCCESS',
  PICKUP_STORES_SET_ERROR = 'PickupStores/SET_ERROR',
  PICKUP_STORES_SET_SELECTED_STORE = 'PickupStores/SET_SELECTED_STORE',
}

export interface IPickupStores {
  error?: any;
  loading: boolean;
  stores?: IPointOfService[];
  selectedStore: string;
  selectedStoreObj?: IPointOfService;
}

export type TPickupStoresAction = IAction<
  Partial<IPickupStores>,
  Exclude<EPickupStoresActionTypes, EPickupStoresActionTypes.PICKUP_STORES_GET_LIST>
>;

export type TPickupStoresDispatch = TDispatch<TPickupStoresAction>;

export interface IAddress {
  billingAddress: boolean;
  country: {
    isocode: string;
  };
  defaultAddress: boolean;
  firstName: string;
  id: string;
  line1: string;
  phone: string;
  shippingAddress: boolean;
  streetName: string;
  town: string;
}

export interface IGeoPoint {
  latitude: number;
  longitude: number;
}

export interface ITimings {
  formattedHour?: string;
  hour?: number;
  minute?: number;
}

export interface WeekDayOpeningList {
  closingTime: ITimings;
  closingTime2: ITimings;
  openingTime: ITimings;
  openingTime2: ITimings;
  closed: boolean;
  weekDay: string;
}

export interface IOpeningHours {
  code: string;
  specialDayOpeningList: any[];
  weekDayOpeningList: WeekDayOpeningList[];
}
export interface IPointOfService {
  address: IAddress;
  displayName: string;
  geoPoint: IGeoPoint;
  name: string;
  openingHours: IOpeningHours;
  storeFormat: string;
  storeFormatName: string;
}

export interface IPickupStoresApiResponse {
  pointOfServices: IPointOfService[];
}
