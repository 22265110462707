import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';
import { IEligibleFlags } from './moreDetails.reducer';

export const eligibleFlagsInitialState: any = {
  loading: false,
  error: false,
  returnEligibleFlags: [],
  uploadImageLoading: false,
  uploadImageError: false,
};

export interface IMultiReturnEligibleFlags
  extends Omit<IEligibleFlags, 'quantityEditable' | 'returnMethodEnabled' | 'imageMandatory'> {
  name: string;
  reasonId: string;
  imageUploadMandatory: boolean;
}

export interface IMultiReturnEligible {
  loading: boolean;
  error: boolean;
  returnEligibleFlags: IMultiReturnEligibleFlags[];
  uploadImageLoading: boolean;
  uploadImageError: boolean;
}

const multiReturnEligibleFlags = (state: any, payload: IMultiReturnEligibleFlags[]) => {
  return { ...state, returnEligibleFlags: payload };
};

const multiReturnEligibleFlagsLoadingStart = (state: any) => {
  return { ...state, loading: true, error: false };
};

const multiReturnEligibleFlagsLoadingEnd = (state: any) => {
  return { ...state, loading: false };
};

const multiReturnEligibleFlagsError = (state: any) => {
  return { ...state, error: true };
};

const multiReturnImageUploadLoadingStart = (state: any) => {
  return { ...state, uploadImageLoading: true, uploadImageError: false };
};

const multiReturnImageUploadLoadingEnd = (state: any) => {
  return { ...state, uploadImageLoading: false };
};

const multiReturnImageUploadError = (state: any) => {
  return { ...state, uploadImageError: true };
};

const multiReturnImageUploadErrorReset = (state: any) => {
  return { ...state, uploadImageError: false };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.MULTI_RETURN_ELIGIBLE_FLAGS]: multiReturnEligibleFlags,
  [EPostOrderDataActions.MULTI_RETURN_ELIGIBLE_FLAGS_LOADING_START]: multiReturnEligibleFlagsLoadingStart,
  [EPostOrderDataActions.MULTI_RETURN_ELIGIBLE_FLAGS_LOADING_END]: multiReturnEligibleFlagsLoadingEnd,
  [EPostOrderDataActions.MULTI_RETURN_ELIGIBLE_FLAGS_ERROR]: multiReturnEligibleFlagsError,
  [EPostOrderDataActions.UPLOAD_RETURN_IMAGES_LOADING_START]: multiReturnImageUploadLoadingStart,
  [EPostOrderDataActions.UPLOAD_RETURN_IMAGES_LOADING_END]: multiReturnImageUploadLoadingEnd,
  [EPostOrderDataActions.UPLOAD_RETURN_IMAGES_ERROR]: multiReturnImageUploadError,
  [EPostOrderDataActions.UPLOAD_RETURN_IMAGES_ERROR_RESET]: multiReturnImageUploadErrorReset,
};

export default function multiReturnEligibleFlagsReducer(
  state = eligibleFlagsInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
