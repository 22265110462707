import APP_CONFIG from './App.config';
import { getUserTokenFromCookie, getUserEmailFromCookie } from 'utils/cookies.util';

const API_CONFIG = {
  baseURL: {
    v1: process.env.REACT_APP_API_ENDPOINT_V1,
    v2: process.env.REACT_APP_API_ENDPOINT,
    v3: process.env.REACT_APP_API_ENDPOINT,
  },
  StoreId: APP_CONFIG.storeId,
  language: APP_CONFIG.language,
  Currency: APP_CONFIG.currency,
  appId: process.env.REACT_APP_ID,
};

export default API_CONFIG;

export const API_HEADERS_COMMON_CONFIG = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  token: getUserTokenFromCookie(),
  userId: getUserEmailFromCookie(),
  appId: process.env.REACT_APP_ID,
  env: process.env.REACT_APP_ENV,
};
