// cookie-related utility helpers

// Re-evaluate cookie lib later
import Cookies from 'universal-cookie';
import Constants from '../config/constants';

import APP_CONFIG from 'config/App.config';
import { isExisty } from './helpers.util';
import crypto from 'crypto';

const cookies = new Cookies();

// getters
export const getAuthTokenFromCookie = () => {
  return cookies.get(Constants.reactAuthToken);
};

export const getReactUserTokenFromCookie = () => {
  return cookies.get(Constants.userToken);
};

export const getUserTokenFromCookie = () => {
  if (isExisty(getReactUserTokenFromCookie())) {
    return getReactUserTokenFromCookie();
  }
  return getAuthTokenFromCookie();
};

export const getCustomCookie = (key: string) => {
  return cookies.get(key);
};

export const getUserEmailFromCookie = () => {
  const userId = cookies.get(Constants.userId);
  return userId ? userId.toLowerCase() : '';
};
export const getHashedEmailFromCookie = () => {
  return cookies.get(decodeURIComponent(Constants.mafUserId)) || '';
};

export const getHashed256Email = (): string => {
  const userEmail = cookies.get('userId');
  if (userEmail) {
    const hashEmailId: any = crypto
      .createHash('sha256')
      .update(userEmail)
      .digest('hex');
    return hashEmailId;
  }

  return '';
};

export const getGUID = () => {
  return cookies.get(`${APP_CONFIG.storeId}-cart`);
};

export const removeGUID = () => {
  // `{ path: '/' }`: https://github.com/reactivestack/cookies/issues/137
  cookies.remove(`${APP_CONFIG.storeId}-cart`, { path: '/' });
};

export const removeCustomCookie = (key: string) => {
  return cookies.remove(key);
};

export const getGuestSessionToken = () => {
  return cookies.get(Constants.guestSessionToken);
};
export const getGAId = () => {
  return cookies.get(Constants.gaId);
};
// setters
export const setGuestSessionToken = (token: string, expiresIn: number) => {
  return cookies.set(Constants.guestSessionToken, token, { maxAge: expiresIn });
};

export const setUserTokenFromCookie = (token: string) => {
  return cookies.set(Constants.userToken, token);
};

export const removeCookie = (cookie: string) => {
  cookies.remove(cookie);
};

export const setCustomCookie = (key: string, value: string) => {
  return cookies.set(key, value);
};

export const getLoggedInStatus = (): string => {
  if (typeof document !== 'undefined') {
    const token = cookies.get('reactUserToken') || cookies.get('authToken'); // hybris or react login
    if (token) {
      return '1';
    }
  }
  return '0';
};

export const getHashedEmail = (): string => {
  if (typeof document !== 'undefined') {
    const useremail = cookies.get('maf-user-id');
    if (useremail) {
      return decodeURIComponent(useremail);
    }
  }
  return '';
};

export const getAddressIdFromCookie = (): string => {
  if (typeof document !== 'undefined') {
    const userAddressId = cookies.get(Constants.userAddressId);
    if (userAddressId) {
      return userAddressId;
    }
  }
  return '';
};
