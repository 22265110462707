import axios from 'config/redux/middlewares/api.middleware.helper';
import decryptData from 'pages/Checkout/helpers/decryption.helper';
import encrypt from 'pages/Checkout/helpers/encryption.helper';

const INIT_PAYMENT_URL = '/payments/transactions/initiate';
enum ApiHeaders {
  CHANNEL_CODE = 'channel-code',
  CARREFOUR = 'carrefour',
}

export enum FormType {
  FORM = 'FORM',
  REDIRECT = 'REDIRECT',
  NO_OP = 'NO_OP',
}

export type TFormAction = FormType.FORM | FormType.REDIRECT | FormType.NO_OP;
export interface InitPaymentResponse {
  status: string;
  action: TFormAction;
  url: string;
  params: any;
  encParams: string;
  additionalInfo: any;
}
interface IinitPaymentData {
  data: InitPaymentResponse;
}
export default class PaymentsManagerManagerSDK {
  async initService({
    token,
    vector,
    info,
    transactionId,
    successCallback,
    failureCallback,
  }: {
    token?: string;
    vector?: string;
    info?: any;
    transactionId: string;
    successCallback: (data: any) => void;
    failureCallback: (data: any) => void;
  }) {
    try {
      const body = {
        transactionId,
        ...(info &&
          token &&
          vector && {
            payload: {
              keyId: vector,
              data: encrypt(info, token, vector),
            },
          }),
      };
      const response: IinitPaymentData = await axios({
        requestUrl: INIT_PAYMENT_URL,
        apiVersion: 3,
        apiOptions: {
          method: 'POST',
          data: body,
          headers: {
            [ApiHeaders.CHANNEL_CODE]: ApiHeaders.CARREFOUR,
          },
        },
      });
      const initPaymentResponse = response && response.data;
      if (
        initPaymentResponse &&
        initPaymentResponse.action === FormType.FORM &&
        initPaymentResponse.encParams.length > 0 &&
        token &&
        vector
      ) {
        const decryptedParams: any = decryptData(initPaymentResponse.encParams, token, vector);
        if (decryptedParams.length > 0) {
          initPaymentResponse.params = {
            ...initPaymentResponse.params,
            ...JSON.parse(decryptedParams),
          };
        }
      }
      successCallback(initPaymentResponse);
    } catch (error) {
      failureCallback(error);
    }
  }
}
