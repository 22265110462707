/**
 * Pseudo-random string generator
 * http://stackoverflow.com/a/27872144/383904
 * Default: return a random alpha-numeric string
 *
 * @param {Integer} len Desired length
 * @param {String} an Optional (alphanumeric), "a" (alpha), "n" (numeric)
 * @return {String}
 */
function randomString(len: number, an?: string) {
  an = an && an.toLowerCase();
  let str = '';
  const min = an === 'a' ? 10 : 0;
  const max = an === 'n' ? 10 : 62;
  for (let i = 0; i++ < len; ) {
    // tslint:disable-next-line no-bitwise
    let r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }
  return str;
}
export default randomString;
