import EOrderSummaryTypes from './orderSummary.type';
import { ICartOrderSummaryState, IOrderSummaryState } from './orderSummary.reducer';

export const setOrderSummaryData = (payload: IOrderSummaryState) => ({
  type: EOrderSummaryTypes.ORDER_SUMMARY_SET_DATA,
  payload,
});

export const setCartOrderSummaryData = (payload: ICartOrderSummaryState) => ({
  type: EOrderSummaryTypes.ORDER_SUMMARY_SET_DATA,
  payload,
});

export const setOrderTotalCurrency = (payload: { orderTotalCurrency: string }) => ({
  type: EOrderSummaryTypes.ORDER_SUMMARY_SET_DATA,
  payload,
});
