import { IAddress } from 'services/user/types/user.types';
import { TShipments } from 'services/shipments/types/shipments.types';

export interface CarrefourDeliveryCost {
  currencyIso: string;
  currencyName?: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface FinalPrice {
  currencyIso: string;
  formattedValue: string;
  mafCurrency: string;
  value: number;
}

export interface BasePrice {
  currencyIso: string;
  currencyName?: string;
  finalPrice: FinalPrice;
  formattedValue: string;
  mafCurrency: string;
  priceType: string;
  value: number;
}

export interface Category {
  code: string;
  url: string;
  level: number;
  id: string;
  name: string;
  name_ar: string;
}

export interface Image {
  altText: string;
  format: string;
  imageType: string;
  url: string;
}

export interface Price {
  currencyIso: string;
  finalPrice: FinalPrice;
  formattedValue: string;
  mafCurrency: string;
  priceType: string;
  value: number;
}
export interface PlasticBagPackingCharges {
  currencyIso: string;
  currencyName?: string;
  formattedValue: string;
  priceType: string;
  value: number;
}
export interface PlasticBagPackingInfo {
  calculated: boolean;
  quantity: number;
  totalCharges: PlasticBagPackingCharges;
}
export interface Stock {
  stockLevel: number;
  stockLevelStatus: string;
}

export interface Variant {
  name: string;
  value: string;
}

export interface Product {
  availableForPickup: boolean;
  averageRating: number;
  baseOptions: any[];
  brandName: string;
  cashbackStickerVisible: boolean;
  categories: Category[];
  code: string;
  deliveryCode: string;
  deliveryTime: string;
  deliveryTimeDescription: string;
  ean: string;
  eligibleForGiftWrapping: boolean;
  freeDelivery: boolean;
  freeDeliveryThreshold: number;
  freeInstallation: boolean;
  genuineStock: boolean;
  images: Image[];
  isSubstituted: boolean;
  isExpressProduct?: boolean;
  mainOffer?: {
    code: string;
    defaultDeliveryMode: string;
    leadTimeToShip: number;
    maxDeliveryDay: number;
    minDeliveryDay: number;
    price: Price;
    productCode: string;
    quantity: number;
    shopCode: string;
    shopGrade: number;
    shopName: string;
    state: string;
    isOffer?: boolean;
  };
  maxToOrder: number;
  name: string;
  nbrOfMonth: number;
  numberOfPoints?: number;
  onDemand: boolean;
  onlineName: string;
  preorder: boolean;
  price: Price;
  productCategoriesHearchi: string;
  productColor?: string;
  productType: string;
  purchasable: boolean;
  soldByWeight?: boolean;
  stock: Stock;
  supplier?: string;
  serviceProducts?: ServiceProductsType;
  yearOfWarranty?: number;
  url: string;
  warranty: boolean;
  selectedVariantInfo?: Variant[];
  measure?: string;
}

export type ServiceProductsType = ExtendWarranty[];

export interface ExtendWarranty {
  cashbackStickerVisible: boolean;
  code: string;
  eligibleForGiftWrapping: boolean;
  freeDelivery: boolean;
  freeDeliveryThreshold: number;
  freeInstallation: boolean;
  genuineStock: boolean;
  isSubstituted?: boolean;
  onDemand: boolean;
  onlineName: string;
  preorder: boolean;
  price: Price;
  productType: string;
  stock: {
    stockLevelStatus: string;
  };
  warranty: boolean;
  yearOfWarranty: number;
}

export interface TotalPrice {
  currencyIso: string;
  currencyName?: string;
  finalPrice?: FinalPrice;
  formattedValue: string;
  mafCurrency?: string;
  priceType: string;
  value: number;
  originalPrice?: IOfferDiscountPrice;
}

export interface IOffer {
  leadTimeToShip?: number;
  price: IOfferPrice;
  quantity?: number;
}

export interface IOfferPrice {
  currencyIso?: string;
  currencyName?: string;
  differenceInPercentForPromo?: number;
  discountPrice?: IOfferDiscountPrice;
  formattedValue?: string;
  originalPrice?: IOfferDiscountPrice;
  priceType?: string;
  value: number;
}

export interface IOfferDiscountPrice {
  currencyIso?: string;
  currencyName?: string;
  formattedValue?: string;
  value: number;
}

export interface IEntryLevelPromotion {
  balanceQtyToAvailPromo: number;
  discountAmount: number;
  discountType: string;
  maximumDiscount: number;
  minQtyToAvailPromo: number;
  promoApplied: boolean;
  promotionId: string;
  promotionMessage: string;
  promotionType: string;
  scalable: boolean;
}

export interface Entry {
  basePrice: BasePrice;
  entryNumber: number;
  bundleId?: string;
  campaignId?: string;
  hasOfferWarranty: boolean;
  isSubstituted: boolean;
  loading?: boolean;
  offer?: IOffer;
  product: Product;
  quantity: number;
  serviceEntries: ServiceEntriesType;
  totalPrice: TotalPrice;
  updateable: boolean;
  shopName?: string;
  isExpressNow?: boolean;
  offerId?: string;
  entryLevelPromotions?: IEntryLevelPromotion[];
  isFreebie?: boolean;
}

export type ServiceEntriesType = ServiceEntries[];

export interface ServiceEntries {
  basePrice: BasePrice;
  entryNumber: number;
  hasOfferWarranty: boolean;
  product: Product;
  quantity: number;
  totalPrice: TotalPrice;
  updateable: number;
}
export interface CarrefourEntry {
  code: string;
  entries: Entry[];
  soldBy: string;
}

export interface DeliveryCost {
  currencyIso: string;
  currencyName?: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface DeliveryMode {
  code: string;
  deliveryCost: DeliveryCost;
  name: string;
}

export interface TotalPriceWithTax {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface DeliveryOrderGroup {
  entries: Entry[];
  totalPriceWithTax: TotalPriceWithTax;
}

export interface FoodDeliveryCost {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface NonFoodDeliveryCost {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface NonFoodEntry {
  basePrice: BasePrice;
  entryNumber: number;
  hasOfferWarranty: boolean;
  isSubstituted: boolean;
  product: Product;
  quantity: number;
  serviceEntries: any[];
  totalPrice: TotalPrice;
  updateable: boolean;
}

export interface OrderDiscounts {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface OrderPaymentCost {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface ProductDiscounts {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface RemainingPriceToPay {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface RemainingPriceToPayWithTax {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface SubTotal {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface SubtotalAllNonFood {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface SubtotalFood {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface SubtotalMarketPlace {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface SubtotalNonFood {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface TotalDiscounts {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface TotalLoyaltyCards {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface TotalPriceUSD {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface TotalTax {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface TotalVarianceCostScalable {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface CodFee {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface AppliedVoucher {
  code: string;
  currency: Currency;
  description: string;
  freeShipping: boolean;
  name: string;
  type: string;
  value: number;
  valueFormatted: string;
  valueString: string;
  voucherCode: string;
  isVoucherAppropriated?: boolean;
}

export enum VoucherType {
  bin = 'BIN',
}

export enum CardType {
  master = 'master',
  visa = 'visa',
  amex = 'amex',
}
export interface Currency {
  active: boolean;
  isocode: string;
  name: string;
  symbol: string;
}
export interface CurrencyValue {
  currencyIso: string;
  currencyName: string;
  formattedValue: string;
  priceStringValue: string;
  priceType: string;
  value: number;
}

export interface LoyaltyVoucher {
  barCode: string;
  currencyValue: CurrencyValue;
  date: number;
  transactionUid: number;
  voucherCode: string;
  voucherValue: CurrencyValue;
}

export interface IMarketPlaceDeliveryCost {
  deliveryCost: DeliveryCost;
  shopId: string;
  shopName: string;
  isFbc: boolean;
}

export type MarketPlaceDeliveryCostType = IMarketPlaceDeliveryCost[];

export enum CheckoutAction {
  standard = 'STANDARD_CHECKOUT',
  express = 'NOW_CHECKOUT',
}

export enum CheckoutLabel {
  all = 'CHECKOUT ALL',
  now = 'NOW ONLY',
}

export interface CheckoutOption {
  label: string;
  action: string;
  localisedLabel?: string;
}

export default interface ICartResponse {
  loyaltyVouchers: LoyaltyVoucher[];
  checkoutOptions?: CheckoutOption[];
  codFee: CodFee;
  type: string;
  appliedOrderPromotions: any[];
  appliedProductPromotions: any[];
  appliedVouchers: AppliedVoucher[];
  calculated: boolean;
  cartTotal: SubTotal;
  carrefourDeliveryCost: CarrefourDeliveryCost;
  carrefourEntries: CarrefourEntry[];
  code: string;
  convertedLoyaltyPointsValue: number;
  deliveryAddress?: IAddress;
  deliveryCost: DeliveryCost;
  deliveryItemsQuantity: number;
  deliveryMode: DeliveryMode;
  deliveryOrderGroups: DeliveryOrderGroup[];
  entries: Entry[];
  origEntries: Entry[];
  foodDeliveryCost: FoodDeliveryCost;
  foodEntries: any[];
  guid: string;
  isSurchargeIncluded?: boolean;
  loyaltyExtraPoints: number;
  loyaltyPoints: number;
  marketPlaceDeliveryCost: MarketPlaceDeliveryCostType;
  net: boolean;
  nonFoodDeliveryCost: NonFoodDeliveryCost;
  nonFoodEntries: NonFoodEntry[];
  orderDiscounts: OrderDiscounts;
  orderPaymentCost: OrderPaymentCost;
  pickupItemsQuantity: number;
  pickupOrderGroups: any[];
  productDiscounts: ProductDiscounts;
  remainingPriceToPay: RemainingPriceToPay;
  remainingPriceToPayWithTax: RemainingPriceToPayWithTax;
  sellersEntries: any[];
  site: string;
  store: string;
  subTotal: SubTotal;
  subtotalAllNonFood: SubtotalAllNonFood;
  subtotalFood: SubtotalFood;
  subtotalMarketPlace: SubtotalMarketPlace;
  subtotalNonFood: SubtotalNonFood;
  totalDiscounts: TotalDiscounts;
  totalItems: number;
  totalLoyaltyCards: TotalLoyaltyCards;
  totalPrice: TotalPrice;
  totalPriceUSD: TotalPriceUSD;
  totalPriceWithTax: TotalPriceWithTax;
  totalTax: TotalTax;
  totalVarianceCostScalable: TotalVarianceCostScalable;
  codAvailable: boolean;
  freeDelivery: boolean;
  freeDeliveryMessage: string;
  freeDeliveryThreshold: number;
  minFoodOrderThreshold: number;
  minOrderThreshold: number;
  potentialOrderPromotions: any[];
  potentialProductPromotions: any[];
  totalUnitCount: number;
  expressCount?: number;
  expressFee?: DeliveryCost;
  expressDeliveryMessage?: string;
  shipments: TShipments;
  sessionArea: string;
  paymentMode: string;
  clickNCollect: {
    isEnable: boolean;
  };
  isSustainabilityConsentRequired?: boolean;
  sustainabilityConsent?: boolean;
  packingCharges?: PlasticBagPackingInfo;
  isNewUser?: boolean;
}
