import { IAppState, HelpersUtil } from '@mafc/common';
import { CREATE_ORDER_URL_V3 } from 'pages/Checkout/constants/apis.constant';
import { Dispatch } from 'redux';
import CheckoutActionTypes, { CHECKOUT_CREATE_ORDER_SUCCESS } from '../Checkout.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { appendQueryParams, getCartId } from 'pages/Cart/helpers/url.helpers';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import { hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import { handleCreateOrderErrors } from './order.action';
import PaymentsManagerManagerSDK, {
  FormType,
  InitPaymentResponse,
} from 'services/payments/core/PaymentsManagerSDK';
import { transformPaymentInfo } from 'utils/card.utils';
import { initStorageManager } from 'utils/customStorage.util';
import {
  NEW_RELIC_EVENT,
  ORDER_ID,
  TRANSACTION_ID,
  TRANSACTION_DATA,
} from 'pages/Checkout/constants/checkout.constant';
import { newRelicCustomEvent } from 'utils/newRelicTracker';
import Constants from 'config/constants';
import { isNewOrderConfirmationApiDeployed } from 'pages/OrderConfirmation/utils/orderConfirmation.transform';
import { getCardTypeForIcon } from 'pages/Checkout/components/Payment/helpers/payment.helpers';
import customHistory from 'routes/customHistory';
import { ORDER_CONFIRMATION_V3 } from 'config/Urls.config';
import { showNotification } from 'common/Notification/redux/notification.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import i18n from 'config/localisation';
import TrackerCheckout from 'utils/Tracker.checkout';
import Transform from 'utils/TransformData';
import { getTrackingData } from 'pages/Checkout/helpers/orderAction.helper';
import { getCustomCookie } from 'utils/cookies.util';
import { TRACKING_TRANSACTION } from 'pages/Cart/constants/new.apis.constant';
import { ETrackingTypes } from 'pages/Checkout/types/screen.types';

const storage = initStorageManager(() => sessionStorage);
const lStorage = initStorageManager(() => localStorage);
export const createOrderSuccess = (
  dispatch: Dispatch<any>,
  orderData: any,
  state: any,
  initPaymentResponse: InitPaymentResponse,
  successCallback?: (data: any) => void
) => {
  const transformOrderDetailsData = helperCreateOrderTransform(orderData, state);
  const isNewOrderConfirmationApi: boolean = isNewOrderConfirmationApiDeployed(state);
  if (!isNewOrderConfirmationApi) {
    storage.setItem(Constants.orderDetails, JSON.stringify(transformOrderDetailsData));
  }
  lStorage.removeItem(Constants.redeemedSharePoints);
  // here the call for initate api
  dispatch({ type: CHECKOUT_CREATE_ORDER_SUCCESS, payload: orderData });
  dispatch(hidePageLoader());

  if (initPaymentResponse.action === FormType.NO_OP) {
    customHistory.push(ORDER_CONFIRMATION_V3);
  }
  if (initPaymentResponse.action === FormType.REDIRECT) {
    window.location.href = initPaymentResponse.url;
  }
  newRelicCustomEvent(NEW_RELIC_EVENT.ORDER_SUCCESS, {
    ...initPaymentResponse,
  });
  if (successCallback) {
    successCallback(initPaymentResponse);
  }
};
export const helperCreateOrderTransform = (orderData: any, state: IAppState) => {
  const {
    code,
    cartId,
    deliveryAddress,
    subTotal,
    totalPrice,
    deliveryCost,
    loyaltyVouchers,
    totalItems,
    user,
    paymentInfo,
    paymentMode,
  } = orderData;
  const {
    shipments = [],
    orderSummary,
    checkout: { payment: { paymentDetails = {} } = {} },
    cart: {
      cartData: { isNewUser },
    },
  } = state;
  // once we use saved cards then we have to modify this

  const {
    carrefourDeliveryCost,
    marketPlaceDeliveryCost,
    appliedVouchers = [],
    totalAppliedDiscounts,
    totalFees,
    codFee,
  } = orderSummary;
  const { newCardDetails: { card_number = '' } = {} } = paymentDetails;
  const type = getCardTypeForIcon(card_number);
  const updatedPaymentInfo = paymentInfo
    ? paymentInfo
    : {
        billingAddress: {
          ...deliveryAddress,
        },
        cardType: {
          code: type,
          name: type,
        },
      };
  return {
    code,
    cartId,
    deliveryAddress,
    appliedVouchers,
    subTotal,
    totalPrice,
    deliveryCost,
    loyaltyVouchers,
    totalItems,
    user,
    paymentMode,
    shipments,
    carrefourDeliveryCost,
    marketPlaceDeliveryCost,
    paymentInfo: updatedPaymentInfo,
    totalAppliedDiscounts,
    totalFees,
    codFee,
    isNewUser,
  };
};

export const createUpdateOrder = ({
  params = {},
}: {
  params: {
    successV3Callback?: (data: any) => void;
    failureV3Callback?: (data: any) => void;
    [key: string]: any;
  };
}) => (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const {
    successV3Callback,
    failureV3Callback,
    paymentMode,
    info,
    nicNumber,
    ...restParams
  } = params;
  let body = {};
  params.cartId = getCartId(getState());
  if (paymentMode === EPaymentMethods.COD || paymentMode === EPaymentMethods.CARD_ON_DELIVERY) {
    body = {
      paymentMode,
    };
    delete restParams.isSavedCard;
    delete restParams.isCheckoutDotComSupported;
  }
  const transactionData = getTrackingData(getState().cart.cartData.entries);
  storage.setItem(TRANSACTION_DATA, JSON.stringify(transactionData));

  const url = appendQueryParams(CREATE_ORDER_URL_V3, restParams);
  dispatch({
    type: CheckoutActionTypes.CHECKOUT_CREATE_ORDER,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url,
      apiVersion: 2,
      ...(nicNumber && {
        headers: {
          NICnumber: nicNumber,
        },
      }),
      params,
      ...(Object.keys(body).length && { data: body }), // if body is empty, it will not be added to the request (which is what we want
      onSuccess: (data: any) => {
        const { transactionId = '', code } = data;
        const orderData = { ...data };
        lStorage.removeItem(TRANSACTION_ID);
        lStorage.removeItem(ORDER_ID);
        newRelicCustomEvent(NEW_RELIC_EVENT.CREATE_AND_UPDATE_API_SUCCESS, {
          transactionId,
          orderId: code,
          paymentMode,
        });
        lStorage.setItem(TRANSACTION_ID, transactionId);
        lStorage.setItem(ORDER_ID, code);
        let paymentPayload: any = {};
        if (
          paymentMode === EPaymentMethods.COD ||
          paymentMode === EPaymentMethods.CASHBACK ||
          paymentMode === EPaymentMethods.CARD_ON_DELIVERY ||
          paymentMode === EPaymentMethods.DEBIT_CARD
        ) {
          paymentPayload = {
            transactionId,
          };
        }
        if (paymentMode === EPaymentMethods.MOBILE_WALLET) {
          const {
            checkout: {
              payment: {
                paymentDetails: {
                  easyPaisaDetails: { emailAddress = '', mobileNumber = '' } = {},
                } = {},
              } = {},
            } = {},
          } = getState() || {};
          paymentPayload = {
            transactionId,
            token: code,
            vector: HelpersUtil.generateUuid(),
            info: {
              emailId: emailAddress,
              contactNumber: decodeURIComponent(mobileNumber),
            },
          };
        }
        if (paymentMode === EPaymentMethods.CREDIT_CARD) {
          const encryptInfo = transformPaymentInfo(info, paymentMode);
          paymentPayload = {
            transactionId,
            info: encryptInfo,
            token: code,
            vector: HelpersUtil.generateUuid(),
          };
        }

        const paymentsManager = new PaymentsManagerManagerSDK();
        const currentState = getState();

        paymentsManager.initService({
          ...paymentPayload,
          successCallback: (initPaymentResponse: InitPaymentResponse) => {
            newRelicCustomEvent(NEW_RELIC_EVENT.INIT_PAYMENT_SUCCESS, {
              data: initPaymentResponse,
              paymentMode,
            });
            createOrderSuccess(
              dispatch,
              orderData,
              currentState,
              initPaymentResponse,
              successV3Callback
            );
          },
          failureCallback: (error: any) => {
            const items = Transform.productData(currentState.cart.cartData, undefined, undefined, {
              ga4: true,
            });
            TrackerCheckout.purchaseFailed(items, {
              cartValue: currentState.cart.cartData.subTotal.value,
              paymentType: paymentMode,
              failedReason:
                (error && error.response && error.response.data) || i18n.t('errorGeneral'),
              orderId: transactionId,
            });
            dispatch(hidePageLoader());
            newRelicCustomEvent(NEW_RELIC_EVENT.INIT_PAYMENT_FAILURE, {
              error: error && error.response ? error.response.data : error,
              paymentMode,
            });
            dispatch(
              showNotification({
                type: notificationTypes.errorCheckout,
                message: i18n.t('errorGeneral'),
              })
            );
          },
        });
      },
      onFailure: (
        error: any,
        { response: { data: { errors = [] } = {} } = { data: { errors: '' } } }: any
      ) => {
        newRelicCustomEvent(NEW_RELIC_EVENT.CREATE_AND_UPDATE_API_FAILURE, {
          errors,
          url,
          paymentMode,
        });
        dispatch(handleCreateOrderErrors(errors, params));
        dispatch(hidePageLoader());
        if (failureV3Callback) {
          failureV3Callback(error);
        }
      },
    },
  });
};

export const trackTransaction = (transactionId: string) => (dispatch: Dispatch) => {
  const userId = getCustomCookie(Constants.mafUserId) || '';
  const visitorId = getCustomCookie(Constants.mafSessionId);
  try {
    const trackingData = JSON.parse(storage.getItem(TRANSACTION_DATA) || '{}');
    dispatch({
      payload: {
        headers: {
          userId,
          visitorId,
        },
        apiVersion: 1,
        method: EApiMiddlewareMethod.POST,
        data: { ...trackingData, 'transaction-id': transactionId },
        url: TRACKING_TRANSACTION,
      },
      type: ETrackingTypes.TRACKING_TRANSACTION,
    });
  } catch (error) {
    // error handling
  }
};
