import APP_CONFIG from 'config/App.config';
import qat from './qatar';
import uae from './uae';
import lbn from './lbn';
import egy from './egypt';
import jor from './jordan';
import sau from './saudi';
import pak from './pak';
import ken from './ken';
import bhr from './bhr';
import kwt from './kwt';
import omn from './omn';

const countries: any = {
  qat,
  uae,
  lbn,
  egy,
  jor,
  sau,
  pak,
  ken,
  bhr,
  kwt,
  omn,
  // add more countries here
};

const getCountryConfig = (country: string, locale = APP_CONFIG.language): any => {
  if (countries[country]) {
    return countries[country][locale];
  }
  return {};
};
export default getCountryConfig;
