export enum GTMEvents {
  custom_event = 'custom_event',
  add_to_cart = 'add_to_cart',
  remove_from_cart = 'remove_from_cart',
  checkout_progress = 'checkout',
  purchase = 'purchase',
  purchase_failed = 'purchase_failed',
  cart_review = 'cart_review',
  checkout = 'checkout',
  view_cart = 'view_cart',
  view_promotion = 'view_promotion',
  order_cancelled = 'order_cancelled',
  add_payment_info = 'add_payment_info',
  refund = 'refund',
  begin_checkout = 'begin_checkout',
  ecommerce_purchase = 'ecommerce_purchase',
  page_view = 'page_view',
  driver_tips_clicked = 'driver_tips_clicked',
  all_vouchers = 'all_vouchers',
  apply_coupon_code_clicked = 'apply_coupon_code_clicked',
  apply_coupon_code_manual_clicked = 'apply_coupon_code_manual_clicked',
  enter_manual_code_clicked = 'enter_manual_code_clicked',
  select_voucher_clicked = 'select_voucher_clicked',
  voucher_section = 'voucher_section',
  previous_coupon_removed_when_payment_method_changed = 'previous_coupon_removed_when_payment_method_changed',
}

export enum GTMEventType {
  interaction = 'interaction',
}
