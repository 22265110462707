// Global helper for Product Quantity
import { isUndefined, setDecimalPoint, roundDecimalNumber } from 'utils/helpers.util';
import i18n from 'config/localisation';
const KG_VALUE = 1000;

export interface IQty {
  delta: number;
  soldByWeight: boolean;
  max: number;
  min: number;
  price?: number;
}

export class CQtyDefault {
  delta: number;
  soldByWeight: boolean;
  max: number;
  min: number;
  price?: number;

  constructor({ delta, soldByWeight, max, min, price }: IQty) {
    this.delta = isUndefined(delta) ? 1 : delta ? delta : 1;
    this.soldByWeight = isUndefined(soldByWeight) ? false : soldByWeight;
    this.max = isUndefined(max) ? 10 : max;
    this.min = isUndefined(min) ? 1 : min;
    this.price = isUndefined(price) ? 0 : price;
  }
}

export interface QuantityOption {
  id?: string;
  label: any;
  value: any;
}
export type QuantityListType = QuantityOption[];

export const formatQtyKG = (quantity: number, isRoundUp = true) =>
  setDecimalPoint(roundDecimalNumber(quantity, isRoundUp));

export const convertQuantityToKG = (quantity: number, soldByWeight: boolean = false): number =>
  soldByWeight ? parseFloat(setDecimalPoint(quantity / KG_VALUE) as string) : quantity;

export const addKGLabel = (quantity: string | number) => `${quantity} ${i18n.t('KG_LABEL')}`;

export const convertQuantityToKGWithLabel = (
  quantity: number,
  soldByWeight: boolean = false
): number | string => {
  let productQnty: number | string = convertQuantityToKG(quantity, soldByWeight);
  if (soldByWeight) {
    productQnty = addKGLabel(productQnty);
  }

  return productQnty;
};

export const generateOptionObj = (qtyRaw: number, soldByWeight: boolean = false) => {
  const qty: any = soldByWeight ? formatQtyKG(qtyRaw) : qtyRaw;
  const value = parseFloat(qty);

  return {
    id: `qty-${value}`,
    value,
    label: soldByWeight ? addKGLabel(qty) : qty,
  };
};

export const generateQuantityList = (params: IQty): QuantityListType => {
  const { delta, soldByWeight, max, min } = new CQtyDefault(params);

  const quantityList: QuantityListType = [];

  let i = min;
  while (i <= max) {
    const optionObj = generateOptionObj(i, soldByWeight);
    quantityList.push(optionObj);
    i = optionObj.value + delta;

    // Make sure to add the max quantity into the list
    if (optionObj.value < max && i > max) {
      i = max;
    }
  }

  return quantityList;
};

export const getIndex = (quantityList: QuantityListType, qty: any): number => {
  if (!quantityList || !qty) {
    return 0;
  }

  // Choice of for loop:
  // https://jsperfcom-gffcctkcvo.now.sh/for-vs-forin-vs-forof-vs-foreach
  let i = 0;
  for (; i < quantityList.length; i++) {
    const currentQty = quantityList[i].value;
    const nextQtyObj = quantityList[i + 1]; // qty as fallback

    if (qty === currentQty) {
      return i;
    }

    if (nextQtyObj && qty > currentQty && qty < nextQtyObj.value) {
      return i;
    }
  }

  return i;
};
