import { EUserActionTypes, IUserState, TUserAction } from '../types/user.types';

export const userV2InitialState = {
  loading: false,
  addresses: [],
  add: {
    loading: false,
  },
  addressLoaded: false,
} as IUserState;

const userV2Reducer = (state: IUserState = userV2InitialState, action: TUserAction) => {
  switch (action.type) {
    case EUserActionTypes.USER_FETCH_GET_ADDRESSES_SUCCESS:
    case EUserActionTypes.USER_FETCH_SET_LOADING:
    case EUserActionTypes.USER_FETCH_SET_ADDRESS_LOADED:
      return {
        ...state,
        ...action.payload,
      };

    case EUserActionTypes.USER_ADD_SET_ERROR:
    case EUserActionTypes.USER_ADD_SET_LOADING:
      return {
        ...state,
        add: {
          ...state.add,
          ...action.payload,
        },
      };

    case EUserActionTypes.USER_ADD_POST_ADDRESS_SUCCESS:
      const newAddresses = [...action.payload.addresses, ...state.addresses];
      return {
        ...state,
        addresses: newAddresses,
      };

    default:
      return state;
  }
};

export default userV2Reducer;
