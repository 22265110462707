import { Box, customStyled } from '@mafc/trolly';
import FONTFAMILY from 'theme/fontFamily';

const PageMaxWidth = customStyled(Box)`
  max-width: ${props => props.theme.maxLayoutWidth}px;
  margin: 0 auto;
  font-family:  ${FONTFAMILY.PRIMARY};
  font-style: normal;
`;

export default PageMaxWidth;
