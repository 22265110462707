export const parseQueryString = (searchString: string) => {
  const paramDict: { [key: string]: any } = searchString
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});

  return paramDict;
};
