export enum EasyPaisaTypes {
  PAYMENT_SERVICE_INITIATE_EASY_PAISA_TRANSACTION = 'PAYMENT_SERVICE/INITIATE_EASY_PAISA_TRANSACTION',
}

export interface IEASYPAISADATA {
  meta: {
    code: string;
    message: string;
  };
  data: {
    responseCode: string;
    responseDesc: string;
    orderId: string;
    storeId: string;
    transactionId: string;
    transactionDateTime: string;
  };
}
