import APP_CONFIG, { EStoreID } from 'config/App.config';
import { initiateEasyPaisaTransaction } from '../easyPaisa/easyPaisa.action';
import { initiateMpesaTransaction } from '../Mpesa/mpesa.actions';

export const initiateWalletTransaction = (data: any) => {
  const transactionMap: { [key: string]: (data: any) => void } = {
    [EStoreID.MAFPAK]: initiateEasyPaisaTransaction,
    [EStoreID.MAFKEN]: initiateMpesaTransaction,
  };
  return transactionMap[APP_CONFIG.storeId](data);
};
