import { Dispatch } from 'redux';

import CheckoutActionTypes, {
  CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS,
} from '../Checkout.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { PAYMENT_ARCHITECURE_URL } from '../../constants/apis.constant';
import { batch } from 'react-redux';
import { showNotification } from 'common/Notification/redux/notification.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { newRelicCustomEvent } from 'utils/newRelicTracker';
import i18n from 'config/localisation';
import { NEW_RELIC_EVENT } from 'pages/Checkout/constants/checkout.constant';
import { hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import customHistory from 'routes/customHistory';
import { GROWTHBOOK_FLAGS, IAppState } from '@mafc/common';
import { createGrowthBookInstance } from '@mafc/appshell';
import { getCartPageUrl } from 'utils/cartRevamp.util';

export enum EPaymentArchitectureTypes {
  V1 = 'v1',
  V3 = 'v3',
}

export type TPaymentVersion = 'v1' | 'v3';

export interface IPaymentArchitecture {
  pv: TPaymentVersion;
}

export const setPyamentArchitecture = (payload: IPaymentArchitecture) => {
  return {
    type: CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS,
    payload,
  };
};

export const fetchPaymentArchitecture = () => async (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );
  const { storeId = '', language = '' } = store.appConfig || {};
  const isCartRevampEnabled = growthbook.feature(GROWTHBOOK_FLAGS.CART_REVAMP_ENABLED).on;
  const NEW_CART_PAGE = getCartPageUrl(storeId, language, isCartRevampEnabled);
  await dispatch({
    type: CheckoutActionTypes.CHECKOUT_PAYMENT_ARCHITECTURE,
    payload: {
      method: EApiMiddlewareMethod.GET,
      apiVersion: 3,
      url: PAYMENT_ARCHITECURE_URL,
      onSuccess: (data: { [key: string]: any }) => {
        if (!data.pv) {
          throw new Error('Payment architecture response does not have pv');
        }
        newRelicCustomEvent(NEW_RELIC_EVENT.PAYMENT_ARCHITECURE_API_SUCCESS, {
          paymentArchitecture: data,
        });
        dispatch(setPyamentArchitecture(data as IPaymentArchitecture) as any);
      },
      onFailure: (msg: any, error: any) => {
        // tslint:disable-next-line:no-console
        console.log('payment architecture api failure', error, msg);
        newRelicCustomEvent(NEW_RELIC_EVENT.PAYMENT_ARCHITECURE_API_FAILURE, {
          error: error && error.response && error.response.data ? error.response.data : error,
          message: msg,
          url: PAYMENT_ARCHITECURE_URL,
        });
        batch(() => {
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: i18n.t('errorGeneral'),
            })
          );
          dispatch(hidePageLoader());
        });
        setTimeout(() => {
          if (isCartRevampEnabled) {
            window.location.href = NEW_CART_PAGE;
          } else {
            customHistory.push(NEW_CART_PAGE);
          }
        }, 5000);
      },
    },
  });
};
