enum ENotificationTypes {
  NOTIFICATION_SHOW = 'Notification/SHOW_NOTIFICATION',
  NOTIFICATION_HIDE = 'Notification/HIDE_NOTIFICATION',
}

export default ENotificationTypes;
export enum notificationTypes {
  errorCheckout = 'errorCheckout',
  success = 'success',
  warning = 'warning',
  info = 'info',
  error = 'error',
}
export type NotificationTypes =
  | notificationTypes.error
  | notificationTypes.info
  | notificationTypes.success
  | notificationTypes.warning
  | notificationTypes.errorCheckout;
export type NotificationVariant = 'sticky' | 'floating';
