import { IAction, TDispatch } from 'config/redux/types/redux.types';
import { Entry } from 'network/response/ICart';

export enum EValidateCartActionTypes {
  DELIVER_TO_VALIDATE_CART_SET_LOADING = 'DeliverTo/ValidateCart/SET_LOADING',
  DELIVER_TO_VALIDATE_CART_SET_ERROR = 'DeliverTo/ValidateCart/SET_ERROR',
  DELIVER_TO_VALIDATE_CART_GET_ENTRIES = 'DeliverTo/ValidateCart/GET_ENTRIES',
  DELIVER_TO_VALIDATE_CART_GET_ENTRIES_SUCCESS = 'DeliverTo/ValidateCart/GET_ENTRIES_SUCCESS',
}

export type TValidateCartAction = IAction<
  Partial<IValidateCart>,
  Exclude<EValidateCartActionTypes, EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES>
>;
export type TValidateCartDispatch = TDispatch<TValidateCartAction>;

export enum EValidateCartEntryStockTypes {
  UNAVAILABLE = 'unavailable',
  NO_STOCK = 'noStock',
  LOW_STOCK = 'lowStock',
  EXPRESS_ONLY = 'expressOnly',
  SUCCESS = 'success',
}

export interface IValidateCart {
  loading: boolean;
  error?: any;
  entries: TEntries;
  modifiedEntriesCount: number;
}

export type TEntries = IEntries[];

export interface IEntries {
  entry: Entry;
  freeDelivery: boolean;
  freeDeliveryMessage: string;
  freeDeliveryThreshold: number;
  quantity: number;
  quantityAdded: number;
  statusCode: string;
  totalUnitCount: number;
}

export interface IOffer {
  leadTimeToShip?: number;
  price: IOfferPrice;
  quantity?: number;
}

export interface IOfferPrice {
  currencyIso?: string;
  currencyName?: string;
  differenceInPercentForPromo?: number;
}

export interface Price {
  currencyIso: string;
  currencyName: string;
  finalPrice: FinalPrice;
  formattedValue: string;
  mafCurrency: string;
  priceType: string;
  value: number;
}

export interface FinalPrice {
  currencyIso: string;
  currencyName: string;
  formattedValue: string;
  mafCurrency: string;
  value: number;
}

export interface Product {
  availableForPickup: boolean;
  averageRating?: number;
  baseOptions: any[];
  brandName?: string;
  cashbackStickerVisible: boolean;
  categories: Category[];
  code: string;
  deliveryCode?: string;
  deliveryTime?: string;
  deliveryTimeDescription?: string;
  ean: string;
  eligibleForGiftWrapping: boolean;
  freeDelivery: boolean;
  freeDeliveryThreshold: number;
  freeInstallation: boolean;
  genuineStock: boolean;
  images?: Image[];
  isExpressProduct: boolean;
  isSubstituted: boolean;
  soldByWeight: boolean;
  mainOffer?: {
    code: string;
    defaultDeliveryMode: string;
    leadTimeToShip: number;
    maxDeliveryDay: number;
    minDeliveryDay: number;
    price: Price;
    productCode: string;
    quantity: number;
    shopCode: string;
    shopGrade: number;
    shopName: string;
    state: string;
    isOffer: boolean;
  };
  maxToOrder?: number;
  name: string;
  nbrOfMonth?: number;
  onDemand: boolean;
  onlineName: string;
  preorder: boolean;
  price?: Price;
  productCategoriesHearchi: string;
  productColor?: string;
  productType: string;
  purchasable: boolean;
  stock: Stock;
  supplier?: string;
  url: string;
  warranty: boolean;
}

export interface Category {
  code: string;
  url: string;
}

export interface Image {
  altText: string;
  format: string;
  imageType: string;
  url: string;
}

export interface Stock {
  stockLevel?: number;
  stockLevelStatus?: string;
}
