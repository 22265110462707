import createReducer from 'config/redux/helpers/createReducer';
import CartActionTypes, {
  CART_FETCH_CART_DATA_SUCCESS,
  CART_FETCH_CART_LOYALTY_POINTS_SUCCESS,
} from '../Cart.types';
import ICartResponse from 'network/response/ICart';

import {
  cartToggleError,
  fetchedCartData,
  resetCartPackingData,
  setCartPaymentModeData,
} from './getCart.reducer';
import { updateCartLoyaltyPoints } from './cartLoyaltyPoints.reducer';
import { updateCartItemSubstitution } from './enableSubstitution';

import updateQuantityReducers from 'pages/Cart/components/UpdateQuantity/redux/updateQuantity.reducer';
import mergeCartsReducer from './mergeCarts.reducer';

export interface IModifiedCartState {
  loading: boolean;
  entries: any[];
  isModified: boolean;
  numModifiedProduct: number;
  totalProducts: number;
  areacode?: string;
}

export interface ICartState {
  cartData: ICartResponse;
  error?: boolean;
  errorMessage?: string;
  loyaltyPoints: number;
  modifiedCart: IModifiedCartState;
  loadingEntries: number[];
  loading: boolean;
}

export const initialCartState = {
  cartData: {} as ICartResponse,
  loyaltyPoints: 0,
  modifiedCart: {
    loading: false,
    entries: [],
    isModified: false,
    numModifiedProduct: 0,
    totalProducts: 0,
  },
  loadingEntries: [],
  loading: false,
};

const cartReducer = createReducer(initialCartState, {
  [CART_FETCH_CART_DATA_SUCCESS]: fetchedCartData,
  [CartActionTypes.CART_PAYMENT_MODE_SET]: setCartPaymentModeData,
  [CartActionTypes.CART_UPDATE_PRODUCT_SUBSTITUTION_STATE]: updateCartItemSubstitution,
  [CartActionTypes.CART_TOGGLE_ERROR]: cartToggleError,
  [CartActionTypes.CART_RESET_PACKING_CHARGE]: resetCartPackingData,
  [CART_FETCH_CART_LOYALTY_POINTS_SUCCESS]: updateCartLoyaltyPoints,
  ...updateQuantityReducers,
  ...mergeCartsReducer,
});
export default cartReducer;
