// TODO: Remove the cart cookie-check logic from redux middleware and put it here instead

import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

const CustomRoute = Route as any;

const AuthenticatedRoute: React.FC<RouteProps & {
  nonAuthCheck: () => boolean;
  redirectUrl?: string;
}> = ({ nonAuthCheck, redirectUrl, children, ...rest }) => (
  <CustomRoute
    {...rest}
    // tslint:disable-next-line: jsx-no-lambda
    render={() => {
      if (nonAuthCheck()) {
        window.location.assign(redirectUrl || window.location.origin);
        return null;
      }

      return children;
    }}
  />
);

export default AuthenticatedRoute;
