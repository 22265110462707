/**
 * This contains the entire logic related to User Cookie + Configuration
 */

import Cookies from 'universal-cookie';
import { isExisty } from 'utils/helpers.util';

// Reads the cookie
const cookies = new Cookies();
const getauthTokenCookie = (): string => cookies.get(USER_CONFIG.__cookie__.reactAuthUserToken);

const getReactUserTokenCookie = (): string =>
  cookies.get(USER_CONFIG.__cookie__.reactUserTokenCookieName);

const normalizeCase = (candidate: string | undefined): string | undefined =>
  candidate ? candidate.toLowerCase() : candidate;

const getUserId = (): string =>
  normalizeCase(cookies.get(USER_CONFIG.__cookie__.reactUserId)) || '';

const USER_CONFIG = Object.freeze({
  __cookie__: {
    reactAuthUserToken: 'authToken',
    reactUserTokenCookieName: 'reactUserToken',
    reactUserId: 'userId',
  },

  get userToken(): ReturnType<typeof getReactUserTokenCookie> {
    return getReactUserTokenCookie();
  },

  get authToken(): ReturnType<typeof getauthTokenCookie> {
    return getauthTokenCookie();
  },

  get reactUserToken(): ReturnType<typeof getReactUserTokenCookie> {
    if (!isExisty(USER_CONFIG.authToken)) {
      return USER_CONFIG.userToken;
    }
    return USER_CONFIG.authToken;
  },

  get isLoggedIn(): boolean {
    return isExisty(USER_CONFIG.reactUserToken);
  },

  get userId(): ReturnType<typeof getUserId> {
    return getUserId();
  },
});

export default USER_CONFIG;
