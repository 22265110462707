interface IInstallScriptConfig {
  scriptLoadTimeout?: number;
  onSuccessEventName?: string;
  loadAsync?: boolean;
}

const installScript = (
  resourceLocation: string,
  configObject: IInstallScriptConfig = {
    scriptLoadTimeout: 5000,
    onSuccessEventName: 'SCRIPT_LOAD_SUCCESS',
  }
) => {
  if (!document) {
    return Promise.reject();
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject();
    }, configObject.scriptLoadTimeout);

    const prevScript = document.querySelector(`script[src="${resourceLocation}"]`);

    if (prevScript) {
      prevScript.remove();
    }

    const customScriptTag = document.createElement('script');

    customScriptTag.src = resourceLocation;
    customScriptTag.async = configObject.loadAsync || true;
    customScriptTag.onerror = reject;
    customScriptTag.onload = () => resolve(configObject.onSuccessEventName);
    document.body.appendChild(customScriptTag);
  });
};

export default installScript;
