import IAction from 'config/redux/actions/interface';
import EShareCartTypes, { IApiResponse } from './shareCart.type';

export interface IShareCartState extends Omit<IApiResponse, 'url'> {
  maxShare: number;
  shareUrl?: IApiResponse['url'];
  visible: boolean;
}

export const shareCartInitialState = {
  maxShare: 3, // #TODO: Should come from config later on, once ENV variables is fixed
  visible: false,
} as IShareCartState;

const shareCartReducer = (state: IShareCartState = shareCartInitialState, action: IAction) => {
  switch (action.type) {
    case EShareCartTypes.SHARE_CART_GENERATE_URL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case EShareCartTypes.SHARE_CART_TOGGLE_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
  }
  return state;
};

export default shareCartReducer;
