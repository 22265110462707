import {
  EShipmentsActionTypes,
  TShipmentsAction,
  IShipmentsState,
  TShipments,
} from '../types/shipments.types';

export const shipmentsInitialState = {
  shipments: null,
} as IShipmentsState;

export default {
  shipments: (
    state: TShipments = shipmentsInitialState.shipments,
    action: TShipmentsAction
  ): TShipments => {
    switch (action.type) {
      case EShipmentsActionTypes.SHIPMENTS_SET_STATE:
        return action.payload;

      default:
        return state;
    }
  },
};
