import { useSelector } from 'react-redux';
import { ICheckoutState } from 'pages/Checkout/redux/reducer';
import EPaymentMethods from '../PamentMethods.enum';
import { ICartState } from 'pages/Cart/redux/reducer';

const usePayment = () => {
  const { appliedVoucher, payment } = useSelector(
    (state: { cart: ICartState; checkout: ICheckoutState }) => {
      return {
        appliedVoucher: state.cart.cartData.appliedVouchers
          ? state.cart.cartData.appliedVouchers[0]
          : null,
        payment: state.checkout.payment,
      };
    }
  );
  const { paymentMode, paymentDetails, modes: configuredPaymentModes } = payment;

  const paymentModes = configuredPaymentModes;

  const isPaymentMethodAllowed = (paymentMethod: string) =>
    paymentModes.indexOf(paymentMethod) > -1;

  const isNewCard = () => {
    // checks for credit card
    return isPaymentMethodAllowed(EPaymentMethods.CREDIT_CARD);
  };

  const isNewDebitCard = () => {
    return paymentMode.type === EPaymentMethods.DEBIT_CARD;
  };

  const isMobileWallet = () => {
    return paymentMode.type === EPaymentMethods.MOBILE_WALLET;
  };

  return {
    payment: {
      ...payment,
      modes: paymentModes,
    },
    appliedVoucher,
    isNewCard,
    isNewDebitCard,
    isMobileWallet,
    isPaymentMethodAllowed,
  };
};

export default usePayment;
