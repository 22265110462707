export default {
  // add any qatar specific strings here or to override base strings
  en: {
    fullCartRedemption: 'Congratulations, your grand total will be covered using SHARE Discount',
    loyaltyPoints: 'SHARE pts.',
    orderSummaryPointsEarned: "SHARE points you'll earn with this order",
    orderSummaryShareDiscount: 'Paid by SHARE points',
    points: 'SHARE Points',
    redeemPoints: 'Redeem',
    shareDiscount: 'Use your SHARE points',
  },
  ar: {
    fullCartRedemption: ' تهانينا! سيتم تغطية المبلغ الكلي لفاتورتك باستخدام خصم شير',
    loyaltyPoints: 'نقاط ماي كلوب',
    orderSummaryPointsEarned: 'عدد نقاط شير التي ستكسبها من هذا الطلب',
    orderSummaryShareDiscount: 'تم الدفع بنقاط شير',
    points: 'نقاط ',
    redeemPoints: 'استبدال',
    shareDiscount: 'استخدام نقاط شير',
  },
};
