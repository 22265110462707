import IAction from 'config/redux/actions/interface';
import CheckoutActionTypes from '../Checkout.types';
import { StepsProgressType } from '../../interfaces/stepsProgress.interface';

export const setStepStatus = (step: StepsProgressType, flag: boolean): IAction => ({
  type: CheckoutActionTypes.CHECKOUT_SET_STEP_STATUS,
  payload: {
    [step]: flag,
  },
});
