import {
  GET_PAYMENT_TRANSACTION_STATUS_SUCCESS,
  GET_SECONDARY_PAYMENT_STATUS_SUCCESS,
} from 'pages/OrderDetail/orderData.type';
import { DeliveryCost, PlasticBagPackingInfo } from 'network/response/ICart';
import { AnyAction } from 'redux';
import {
  EOrderConfirmationActions,
  EsecondaryPaymentStatus,
  IOrderConfirmation,
} from './../OrderConfirmation.types';
import { IFinalPrice } from 'utils/price.util';

export const orderConfirmationInitialState: IOrderConfirmation = {
  appliedVouchers: [],
  contactDetails: {},
  codFee: {} as DeliveryCost,
  convertedLoyaltyPointsValue: 0,
  shipmentsData: [],
  shippingAddress: '',
  loyaltyVouchers: [],
  paymentInfo: {},
  paymentMode: '',
  purchasedProductData: { gaProductList: [], productIDs: [], quantities: [], prices: [] },
  orderMeta: {},
  secondaryPaymentStatus: EsecondaryPaymentStatus.PENDING,
  paymentTransactionStatus: EsecondaryPaymentStatus.PENDING,
  orderDetailLoading: true,
  sustainabilityConsent: null,
  plasticBagPackingInfo: {} as PlasticBagPackingInfo,
  totalAppliedDiscounts: {} as IFinalPrice,
  totalFees: {} as IFinalPrice,
  totalPriceWithTax: {} as IFinalPrice,
  subTotal: {} as IFinalPrice,
};

const actionHandlers: { [Key: string]: any } = {
  [EOrderConfirmationActions.HYDRATE_CONFIRMATION_DETAILS]: (
    state: IOrderConfirmation,
    payload: any
  ) => ({
    ...state,
    ...payload,
  }),
  [EOrderConfirmationActions.CLEAR_CONFIRMATION_DETAILS]: () => orderConfirmationInitialState,
  [GET_SECONDARY_PAYMENT_STATUS_SUCCESS]: (state: IOrderConfirmation, payload: any) => {
    return {
      ...state,
      ...payload,
    };
  },
  [GET_PAYMENT_TRANSACTION_STATUS_SUCCESS]: (state: IOrderConfirmation, payload: any) => {
    return {
      ...state,
      ...payload,
    };
  },
  [EOrderConfirmationActions.ORDER_DETAIL_LOADING]: (state: IOrderConfirmation, payload: any) => {
    return {
      ...state,
      ...payload,
    };
  },
};

export default function confirmOrderReducer(
  state = orderConfirmationInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  const updatedState = (actionHandler && actionHandler(state, payload)) || state;
  return updatedState;
}
