const NEW_API_BASE = '/carts/{{StoreId}}/{{language}}';
const CART_API_BASE = `${NEW_API_BASE}/STANDARD`;

export const NEW_GET_CART_API = `${CART_API_BASE}/getCart?refreshCart=true&fields=FULL&areaCode={{areaCode}}`;
export const NEW_ADD_TO_CART = `${CART_API_BASE}/addItem?isCartRequired=true&areaCode={{areaCode}}`;

export const NEW_UPDATE_QUANTITY_API = `${CART_API_BASE}/entries/{{cartEntryNumber}}/updateItem?isCartRequired=true&areaCode={{areaCode}}`;
export const NEW_DELETE_ITEM_FROM_CART = `${CART_API_BASE}/entries/{{cartEntryNumber}}/deleteItem?isCartRequired=true&areaCode={{areaCode}}`;
export const NEW_DELETE_ITEMS_FROM_CART = `${CART_API_BASE}/deleteItem?entryNumbers={{cartEntryNumber}}&isCartRequired=true&areaCode={{areaCode}}`;
export const NEW_DELETE_FROM_CART = `${CART_API_BASE}/deleteItem?isCartRequired=true&areaCode={{areaCode}}`;
export const NEW_SUBSTITUTION_API = `${CART_API_BASE}/substitution?areaCode={{areaCode}}`;
export const NEW_CART_VALIDATION = `${CART_API_BASE}/validateCart?areaCode={{areaCode}}`;
export const NEW_GET_MERGE_CARTS_API = `${NEW_API_BASE}/mergeCart?areaCode={{areaCode}}&guid={{toMergeCartGuid}}&isCartRequired=true&cartType=STANDARD`;
export const TRACKING_VIEW_BASKET = `/adtech/rmp/track-event/view-basket`;
export const TRACKING_TRANSACTION = `/adtech/rmp/track-event/track-transaction`;

export const NEW_GET_MERGE_CARTS_API_V3 = `${CART_API_BASE}/mergeCart?areaCode={{areaCode}}&guid={{toMergeCartGuid}}&isCartRequired=true&lm={{lm}}`;
export const ADTECH_CAROUSEL_API = `/adtech/rmp/cart/adtech-cart?latitude={{latitude}}&longitude={{longitude}}`;
