import IAction from 'config/redux/actions/interface';
import { ICartState } from './index';

export const cartToggleError = (cartState: ICartState, action: IAction) => {
  return { ...cartState, error: action.payload.error, errorMessage: action.payload.errorMessage };
};

export const cartDataSuccess = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    cartData: {
      ...cartState.cartData,
      ...action.payload,
    },
  };
};

export const cartToggleLoading = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    loading: action.payload,
  };
};
