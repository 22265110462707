import EApiActionTypes from 'config/redux/types/api.type';

const NEW_CART_PREFIX = 'NEW_';

/**
 * FETCH CART ACTIONS
 */
export const CART_TOGGLE_ERROR = `${NEW_CART_PREFIX}Cart/TOGGLE_ERROR`;

export const FETCH_CART_DATA = `${NEW_CART_PREFIX}Cart/FETCH_DATA`;

export const FETCH_CART_ADTECH_CAROUSEL = `${NEW_CART_PREFIX}Cart/FETCH_ADTECH_CAROUSEL`;

export const FETCH_CART_TOGGLE_LOADING = `${NEW_CART_PREFIX}Cart/FETCH_CART_TOGGLE_LOADING`;

export const FETCH_CART_DATA_SUCCESS = `${FETCH_CART_DATA}${EApiActionTypes.API_SUCCESS}`;

/**
 * UPDATE CART ACTION
 */
export const CART_UPDATE_ENTRY_DETAIL = `${NEW_CART_PREFIX}Cart/UPDATE_ENTRY_DETAIL`;

export const CART_UPDATE_QUANTITY = `${NEW_CART_PREFIX}Cart/UPDATE_QUANTITY`;

export const CART_UPDATE_ENTRY_TOGGLE_LOADING = `${NEW_CART_PREFIX}Cart/UPDATE_ENTRY_TOGGLE_LOADING`;
