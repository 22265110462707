import styled from '@emotion/styled/macro';
import COLORS from 'theme/colors';

const variants: { [key: string]: string } = {
  normal: COLORS.BLUE,
  error: COLORS.RED,
  success: COLORS.GREEN,
};

export const TextFieldContainer = styled.div`
  display: block;
  margin-bottom: 1px; /** change to control margins */
  iframe {
    display: block;
    background: ${COLORS.GREY_SHADE};
  }
`;

export const TextFieldControl = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const TextFieldLabel = styled.label`
  -webkit-transform: translateY(-14px);
  transform: translateY(-14px);
  display: block;
  color: ${COLORS.DARK_GREY};
  left: 0;
  margin: 0;
  padding: 18px 12px 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 100%;
`;

export const TextFieldUnderline = styled.div<{ styling: any }>`
  ${(props: any) => {
    const { styling } = props;
    return `
        border-bottom: 1px solid ${variants[styling]};
    `;
  }}
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  width: 1%;
`;

export const TextFieldInput = styled.input<{ styling: any }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  direction: ltr;
  border: 0;
  ${(props: any) => {
    const { styling } = props;
    return `
      border-bottom: 1px solid ${variants[styling]};

    `;
  }}
  display: block;
  margin-top: 24px;
  outline: 0;
  padding: 10px 12px;
  width: 100%;
  &:-ms-input-placeholder ~ ${TextFieldLabel} {
    transform: translateY(0);
  }
  &:placeholder-shown ~ ${TextFieldLabel} {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  &:focus ~ ${TextFieldLabel} {
    color: ${COLORS.DARK_GREY};

    -webkit-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  &:focus ~ ${TextFieldUnderline} {
    -webkit-transform: scaleX(150);
    transform: scaleX(150);
    ${(props: any) => {
      const { styling } = props;
      return `
      border-bottom: 1px solid ${variants[styling]};
      background: ${variants[styling]};
    `;
    }}
  }
  background: ${COLORS.GREY_SHADE};
`;
