import { Category } from 'network/response/ICart';
import { getJourneySelectionParamsForItem } from './gtm.utils';
import { trimString } from './helpers.util';

abstract class Transform {
  static getScalableProductQty(product: any, quantity?: number) {
    let qty = 1;
    if (quantity && product.soldByWeight) {
      const qtyInKg = quantity / 1000;
      const qtyDifference = qtyInKg - product.minimumWeightToOrder;
      qty = qtyDifference / (product.weightIncrement || 1) + 1;
    } else if (quantity) {
      qty = quantity;
    }
    return parseInt(qty.toFixed(0), 10);
  }

  /** Transforming product data to fit GTM events */
  static productData(cartData: any, entryNumbers?: number[], quantity?: number, rest: any = {}) {
    const { ga4 = false, countryCode = '' } = rest;
    const entries = entryNumbers
      ? cartData
        ? cartData.entries.filter((item: any) => entryNumbers.includes(item.entryNumber))
        : []
      : cartData.entries; // put all entries if no entryNumbers passed
    const products: any = [];
    (entries || []).forEach((item: any) => {
      const { product, totalPrice, shopName, bundleId, campaignId } = item;
      products.push(
        this.getProductDimensions(
          product,
          totalPrice,
          this.getScalableProductQty(product, item.quantity),
          product.soldByWeight ? item.quantity : undefined,
          shopName,
          ga4,
          countryCode,
          bundleId,
          campaignId,
          {
            ...rest,
            dimension58: cartData.code,
          }
        )
      );
    });
    return products;
  }

  static productDataFrom(
    entry: any,
    rest: any = {},
    ga4: boolean = false,
    countryCode: string = ''
  ) {
    const {
      product,
      totalPrice,
      quantity,
      product: { soldByWeight },
      shopName,
      bundleId,
      campaignId,
    } = entry;
    return this.getProductDimensions(
      product,
      totalPrice,
      this.getScalableProductQty(product, quantity),
      soldByWeight ? quantity : undefined,
      shopName,
      ga4,
      countryCode,
      bundleId,
      campaignId,
      rest
    );
  }

  static getBundleData(bundleId?: string, campaignId?: string) {
    return {
      is_bundle_deal: !!(bundleId || campaignId),
      bundle_deal_applied: !!bundleId,
      promotion_tag: bundleId ? 'bundle deal' : '',
      discount_type: bundleId ? 'bundle deal' : '',
      bundle_id: bundleId || '',
    };
  }
  private static itemJourneySelectionParams = getJourneySelectionParamsForItem();

  private static getProductDimensions(
    product: any,
    totalPrice: any,
    quantity?: number,
    quantityInWeight?: number,
    shopName?: string,
    ga4: boolean = false,
    countryCode: string = '',
    bundleId?: string,
    campaignId?: string,
    rest: any = {}
  ) {
    const {
      onlineName: name,
      code,
      brandName,
      productCategoriesHearchi,
      productTypeDM51,
      productType,
      stock,
      marketplaceProduct,
      mainOffer,
      isExpressProduct,
      categories = [],
    } = product;

    if (!ga4) {
      return {
        name,
        id: code,
        ...this.getBundleData(bundleId, campaignId),
        price: String(totalPrice.value),
        ...(brandName && { brand: brandName }),
        ...(productCategoriesHearchi && { category: productCategoriesHearchi }),
        ...(productTypeDM51 && { variant: productTypeDM51 }),
        dimension7: productType,
        dimension14: stock.stockLevelStatus,
        dimension23: totalPrice.differenceInPercentForPromo ? true : false,
        dimension52: marketplaceProduct,
        ...(marketplaceProduct && { dimension53: shopName }),
        dimension54: mainOffer && mainOffer.code,
        dimension93: code,
        dimesion96: '',
        dimension108: isExpressProduct,
        ...(quantity ? { quantity } : {}),
        ...rest,
      };
    } else {
      return {
        id: code,
        item_name: trimString(name),
        item_id: `${code}_${countryCode}`,
        ...this.getBundleData(bundleId, campaignId),
        price: totalPrice.value,
        item_brand: `${productType === 'FOOD' ? 'Food' : 'NonFood'}_${brandName ? brandName : ''}`,
        ...this.getCategory(categories),
        item_category5: productType === 'FOOD' ? 'Food' : 'NonFood',
        currency: totalPrice.currencyName,
        quantity,
        item_variant: `now=${isExpressProduct ? true : false}_spon=false_var=_end`,
        discount:
          (totalPrice.originalPrice && totalPrice.originalPrice.value - totalPrice.value) || '',
        item_list_name: 'not_applicable',
        item_list_id: 'not_applicable',
        affiliation: marketplaceProduct
          ? trimString(`${mainOffer.code}|${mainOffer.shopCode}`)
          : 'Carrefour',
        stock_status: stock.stockLevelStatus ? stock.stockLevelStatus.toLowerCase() : '',
        ...(quantityInWeight ? { scalable_weights: quantityInWeight } : {}),
        ...this.itemJourneySelectionParams,
      };
    }
  }

  private static getCategory = (categories: Category[]) => {
    const category1 = categories.find((category: any) => category.level === 1);
    const category2 = categories.find((category: any) => category.level === 2);
    const category3 = categories.find((category: any) => category.level === 3);
    const category4 = categories.find((category: any) => category.level === 4);

    return {
      item_category: category1 ? category1.name : '',
      item_category2: category2 ? category2.name : '',
      item_category3: category3 ? category3.name : '',
      item_category4: category4 ? category4.name : '',
    };
  };
}

export default Transform;
