import React from 'react';
import { Dispatch } from 'redux';
import { hidePageLoader, showPageLoader } from 'common/PageLoader/redux/pageLoader.action';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { EasyPaisaTypes, IEASYPAISADATA } from './easyPaisa.types';
import history from 'routes/customHistory';
import { showNotification } from 'common/Notification/redux/notification.action';
import EasyPaisaLoader from 'pages/Checkout/components/Payment/components/EasyPaisa/EasyPaisaLoader';
import i18n from 'config/localisation';
import { EASY_PAISA_TRANSACT } from 'pages/Checkout/constants/apis.constant';
import { notificationTypes } from 'common/Notification/redux/notification.type';
export const initiateEasyPaisaTransaction = ({
  cybersource_request_url,
  orderFieldMap,
  orderNumber,
}: {
  cybersource_request_url: string;
  orderFieldMap: { [key: string]: string };
  orderNumber: string;
  [key: string]: any;
}) => (dispatch: Dispatch) => {
  dispatch(showPageLoader(<EasyPaisaLoader />));
  dispatch({
    type: EasyPaisaTypes.PAYMENT_SERVICE_INITIATE_EASY_PAISA_TRANSACTION,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url: EASY_PAISA_TRANSACT,
      data: {
        ...orderFieldMap,
      },
      params: { orderNumber },
      onSuccess: (easyPaisaData: IEASYPAISADATA) => {
        dispatch(hidePageLoader());
        if (easyPaisaData.meta.code === '000') {
          // success
          history.push(`/app/checkout/orderConfirmation/${orderNumber}`);
        } else {
          // failure
          dispatch(handleError(easyPaisaData.meta.message));
        }
      },
      onFailure: () => {
        dispatch(hidePageLoader());
        dispatch(handleError(i18n.t('errorPlacingOrder')));
      },
      timeout: 80000,
    },
  });
};

const handleError = (message: string) =>
  showNotification({
    type: notificationTypes.errorCheckout,
    message,
  });
