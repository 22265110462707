import { ICartState } from './index';
import IAction from 'config/redux/actions/interface';
import { Entry } from 'network/response/ICart';

export const updateCartItemSubstitution = (
  state: ICartState,
  action: IAction<Array<{ entryNumber: number; isSubstitute: boolean }>>
) => {
  const { payload: cartEntries } = action;
  if (cartEntries) {
    const {
      cartData: { entries },
    } = state;
    const cartEntryNumberArray: number[] = [];
    cartEntries.forEach(item => {
      cartEntryNumberArray.push(item.entryNumber);
    });

    let updatedCartData: Entry[];
    updatedCartData = entries.map(item => {
      // iterating through cart items and updating the isSubstitute flag
      if (cartEntryNumberArray.indexOf(item.entryNumber) >= 0) {
        return { ...item, isSubstituted: cartEntries[0].isSubstitute };
      }
      return { ...item };
    });

    return { ...state, cartData: { ...state.cartData, entries: updatedCartData } };
  }
  return state;
};
