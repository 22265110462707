import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const eligibleFlagsInitialState: any = {};

export interface IEligibleFlags {
  imageUploadEnabled: boolean;
  imageMandatory: boolean;
  maxImages: number;
  quantityEditable: boolean;
  returnMethodEnabled: boolean;
}

const returnEligibleFlags = (state: any, payload: any) => {
  if (payload && payload.data[0]) {
    return { ...state, ...payload.data[0] };
  }
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.RETURN_ELIGIBLE_FLAGS]: returnEligibleFlags,
};

export default function returnEligibleFlagsReducer(
  state = eligibleFlagsInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
