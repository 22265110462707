import { IMapString } from 'common/interfaces/common.interface';
import IAction from 'config/redux/actions/interface';
import ApigeeLabelTypes from './ApigeeLabel.type';

export interface IApigeeLabelState {
  loading: boolean;
  error: boolean;
  data: IMapString;
}

export const initialLabelState = {
  loading: false,
  error: false,
  data: {},
} as IApigeeLabelState;

const ApigeeLabelReducer = (state: IApigeeLabelState = initialLabelState, action: IAction) => {
  const { type, payload } = action;
  switch (type) {
    case ApigeeLabelTypes.FETCH_APIGEE_LABEL_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case ApigeeLabelTypes.FETCH_APIGEE_LABEL_ERROR:
      return {
        ...state,
        error: true,
      };
    case ApigeeLabelTypes.FETCH_APIGEE_LABEL_START:
      return { ...state, error: false, loading: true };
    case ApigeeLabelTypes.FETCH_APIGEE_LABEL_END:
      return { ...state, loading: false };
  }

  return state;
};

export default ApigeeLabelReducer;
