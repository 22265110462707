import { batch } from 'react-redux';
import { Dispatch } from 'redux';

import CartActionTypes, { CART_DELETE_PRODUCT_SUCCESS } from '../Cart.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { DELETE_ITEM_API } from '../../constants/apis.constant';
import { updateCart, getEarnedPoints } from './getCart.action';
import { showPageLoader, hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import { hideNotification, showNotification } from 'common/Notification/redux/notification.action';
import { i18n } from '@mafc/common';
import { notificationTypes } from 'common/Notification/redux/notification.type';

export const deleteProductSuccess = (data: { [key: string]: any }) => {
  return {
    type: CART_DELETE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const deleteProduct = (entryNumbers: number[]) => (dispatch: Dispatch) => {
  dispatch(showPageLoader());
  dispatch(dispatch(hideNotification()));

  dispatch({
    type: CartActionTypes.CART_DELETE_PRODUCT,
    payload: {
      method: EApiMiddlewareMethod.DELETE,
      url: DELETE_ITEM_API,
      enableProgress: false,
      params: {
        entryNumbers: entryNumbers.join(','),
      },
      onSuccess: (data: { [key: string]: any }) => {
        updateCart(dispatch, data);
        batch(() => {
          dispatch(getEarnedPoints() as any);
          dispatch(hidePageLoader());
        });
      },
      onFailure: () => {
        batch(() => {
          dispatch(hidePageLoader());
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: i18n.t('deletionFailedMessage'),
            })
          );
        });
      },
    },
  });
};
