const COLORS: { [key: string]: string } = {
  ALTO: '#cfcfcf',
  BODY: '#FAFAFA',
  BLUE: '#0e5aa7',
  BLUE_30: 'rgba(14, 90, 167, 0.3)',
  BLUE_100: '#1a4f9b',
  BLUE_600: '#157296',
  BLUE_25: '#F4FBFE',
  BLUE_BORDER: '#AEC8E4',
  CYAN_BLUE: '#C2D5E8',
  BG_BLUE: '#E6F1F4',
  BROWN: '#31261D',
  GREEN: '#009688',
  LIGHT_GREEN: '#019840',
  SUCCESS_GREEN: '#E9F6F1',
  SUCCESS_LIGHT_GREEN: '#3ABA71',
  SUCCESS_GREEN_DARK: '#267949',
  MERCURY: '#e6e6e6',
  MINE_SHAFT: '#252525',
  MYCLUB: '#690A4F',
  RED: '#ee2527',
  C4RED: '#BC1E20',
  DARK_RED: '#DD2326',
  THUNDORA: '#4d4d4d',
  SILVER_CHALICE: '#b2b2b2',
  WHITE: '#fff',
  WILD_SAND: '#f5f5f5',
  YELLOW: '#ffdf28',
  TRANPARENT_YELLOW: 'rgba(255, 171, 69, 0.5)',
  YELLOW_LIGHT: '#FCFCFC',
  YELLOW_BROWN: '#d9810e',
  YELLOW_GOLDEN: '#E79832',
  TRANSPARENT_BLUE_LIGHT: 'rgba(14, 90, 167, 0.05)',
  POLO_BLUE_25: '#F2F5F8',
  POLO_BLUE_50: '#DFE8F2',
  POLO_BLUE_300: '#92B3D4',
  PERSIAN_GREEN: '#007F73',
  TRANSPARENT_BLUE: 'rgba(14, 90, 167, 0.2)',
  TRANSPARENT_SECONDARY: 'rgb(98, 147, 197, 0.2)',
  LIGHT_BORDER: '#f5f5f5',
  DARK_GREY: '#4D4D4D',
  LIGHT_GRAY: '#EAEAEA',
  GRAYSCALE_200: '#C3C3C3',
  ORANGE: '#E3870F',
  CHERRY: '#8F1639',
  BURGUNDY: '#83243A',
  TRANSPARENT_RED: '#FDECEC',
  MAROON: '#891737',
  PURPLE: '#6F1C60',
  AQUA: '#0A437C',
  LIGHT_PINK: '#FEF0F0',
  DARK_PINK: '#F34041',
  ERROR_RED: '#CE3637',
  CLARET: '#8A1538',
  LIGHT_BLUE: '#ECF4FD',
  GRAY: '#e5e5e5',
  GRAY_SCALE: '#505050',
  GRAY_SCALE_500: '#747474',
  GRAY_SCALE_100: '#DCDCDC',
  LIGHT_GREY_BLUE: '#6293C5',
  LIGHT_GREY_BLUE_100: '#E4EEF9',
  LIGHT_GREY_BLUE_200: '#EFF4F9',
  BLUE_500: '#6C8DAE',
  GREISH: '#e0e0e0',
  BLACK: '#000',
  LIGHT_AZURE: '#F2F9F8',
  TRANSPARENT_LIGHT_BLUE: '#F2F7FE',
  LIGHT_GREY: '#f3f3f3',
  GREY_100: '#F3F7FA',
  GREY_500: '#979797',
  GREY_600: '#6c6c6c',
  GREY_700: '#666',
  GREY_900: '#383838',
  GREY_SHADE: '#FAFBFD',
  RED_BROWN: '#8E1437',
  WARNING: '#E38710',
  BLACK_SHADE: '#000408',
  SHIMMER_COLOR: '#ecebeb',
  GRAYSCALE: '#f4f4f4',
  GRAYSCALE_LIGHT: '#f7f8f9',
  GREYISH_WHITE: '#fbfbfb',
  GRAYSCALE_800: '#141414',
  INFO_25: '#F1FAFE',
  INFO_600: '#157296',
  SUCCESS_25: '#E9F6F1',
  SUCCESS_600: '#267949',
  WHITE_25: '#FAFAFB',
  ALERT_TEXT_ORANGE: '#CF9E36',
  MAGENTA: '#B5064D',
  ALICE_BLUE: '#F7F8F9',
  GOLDEN_BELL_25: '#FDF8F2',
  GOLDEN_BELL_50: '#F7E0C2',
  GOLDEN_BELL_500: '#C1720C',
  GOLDEN_BELL_600: '#935709',
  ERROR_RED_25: '#FDF4F4',
  ERROR_RED_50: '#FBCFCF',
  ERROR_RED_500: '#CE3637',
  ERROR_RED_400: '#F34041',
  PERSIA_GREEN: '#59BAB1',
  SYSTEM_ERROR: '#F45C5D',
  ERROR_RED_700: '#6D1C1D',
  PRIMARY_INTERACTION: '#093A6C',
};

export default COLORS;
