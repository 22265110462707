import MPGSLog from 'services/payments/utils/logger';
export default function iframeURLChange(iframe: any, callback: (arg: any) => void) {
  let lastDispatched: any = null;

  const dispatchChange = () => {
    let location;
    try {
      location = iframe.contentWindow.location;
      MPGSLog(iframe.contentWindow.location);
    } catch (e) {
      // silence the error
    }

    if (location !== lastDispatched) {
      try {
        callback(location);
      } catch (e) {
        // silence the error
      }
      lastDispatched = location;
    }
  };

  const unloadHandler = () => {
    // Timeout needed because the URL changes immediately after
    // the `unload` event is dispatched.
    setTimeout(dispatchChange, 0);
  };

  const attachUnload = () => {
    // Remove the unloadHandler in case it was already attached.
    iframe.contentWindow.removeEventListener('unload', unloadHandler);
    iframe.contentWindow.addEventListener('unload', unloadHandler);
  };

  iframe.addEventListener('load', () => {
    try {
      attachUnload();
    } catch (e) {
      // silence the error
    }

    // Just in case the change wasn't dispatched during the unload event...
    dispatchChange();
  });

  attachUnload();
}
