import {
  SHIPMENT_CNC_FETCH_SLOTS,
  SHIPMENT_CNC_VALIDATE_CART_GET_API,
  SHIPMENT_CNC_SET_SLOT_STORE_API,
} from 'pages/Checkout/constants/apis.constant';
import { IAddress } from 'services/user/types/user.types';
import { EShipmentTabs, ECncActionTypes, ICnc, TCncAction } from 'pages/Checkout/types/cnc.types';
import { TApiMiddlewareDispatch } from 'config/redux/middlewares/api.middleware.types';
import { IAppState } from 'config/redux/reducers';
import { TDispatch } from 'config/redux/types/redux.types';

import { fetchCart, updateCart } from 'pages/Cart/redux/actions/getCart.action';

import { fetchPaymentModes, getPaymentMode } from './payment.action';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';

import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import extractShipment from 'services/shipments/helpers/extractShipment';

import {
  toggleLoading,
  fetchSelectedSlot,
  setSelectedSlot,
  toggleVisibility,
  toggleDrawerCoachMark,
  setError,
} from 'common/DeliverySlot/redux/deliverySlot.action';
import EDeliverySlotTypes, { ISlotItem } from 'common/DeliverySlot/redux/deliverySlot.type';

import { setScreen, setScreenToHide } from 'pages/Checkout/redux/actions/screen.actions';
import { EScreens } from 'pages/Checkout/types/screen.types';

import {
  setValidateCartLoading,
  setValidateCartError,
  setValidateCartEntries,
} from './validateCart.actions';
import {
  EValidateCartActionTypes,
  EValidateCartEntryStockTypes,
  TEntries,
  TValidateCartDispatch,
} from 'pages/Checkout/types/validateCart.types';

import {
  setPickupStoresError,
  setSelectedStore,
} from 'pages/Checkout/components/PickupStores/redux/PickupStores.actions';

import showDeliverySlotDrawer from 'pages/Checkout/components/Shipments/helpers/showDeliverySlotDrawer.helper';
import OrderErrors from 'pages/Checkout/types/orderError.types';
import { formatSlotInfo, CncTrack, CncGTMEvents, setDefaultSlot } from 'pages/Checkout/helpers';
import { removeRedeemedLoyaltyPoints } from 'pages/Checkout/components/LoyaltyPoints/redux/LoyaltyPoints.actions';
import { isExisty } from 'utils/helpers.util';
import { getCartId } from 'pages/Cart/helpers/url.helpers';
import i18n from 'config/localisation';
import { hideNotification } from 'common/Notification/redux/notification.action';
import { extractErrorProps } from 'utils/error.util';
import { IDelivery } from '../reducer';

// import mock from './__mocks__/validateCart.mock';
export const getAreacode = (state: IAppState) => {
  const {
    checkout: { delivery: { deliverTo } = {} as IDelivery },
  } = state;
  return ((((deliverTo && deliverTo.value) || {}) as IAddress).deliveryArea || {}).code || '';
};

// get store code/name from reducer
export const getStore = (state: IAppState) => {
  const { store, deliverySlot } = extractShipment(state.shipments, EShipmentTypes.CNC);
  return {
    name: (store || {}).name || '',
    displayName: (store || {}).displayName || '',
    deliverySlot,
  };
};

export const checkForLoyaltyVoucher = (state: IAppState) => {
  const { loyaltyVouchers } = state.cart.cartData;

  if (isExisty(loyaltyVouchers)) {
    if (loyaltyVouchers[0]) {
      return true;
    }
    return false;
  }
  return false;
};

export const setCncLoading = (loading: ICnc['loading']): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_SET_LOADING,
  payload: { loading },
});

export const setCncError = (error: ICnc['error'] = ''): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_SET_ERROR,
  payload: { error },
});

export const setActiveTab = (activeTab: ICnc['activeTab']): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_SET_ACTIVE_TAB,
  payload: { activeTab },
});

export const resetCNCShipment = (): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_RESET,
  payload: {},
});
export const setCNCTabClicked = (isTabClicked: ICnc['isTabClicked']): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_SET_ACTIVE_TAB,
  payload: { isTabClicked },
});

export const setValidateStore = (validateStore: ICnc['validateStore']): TCncAction => ({
  type: ECncActionTypes.SHIPMENT_CNC_SET_VALIDATE_STORE,
  payload: { validateStore },
});

// fetch all possible delivery slots for cnc store
export const fetchCncSlots = (
  cncStore: string = '',
  deliverySlot?: ISlotItem,
  successCallback?: () => void
) => (dispatch: TDispatch<any>, getState: () => IAppState) => {
  dispatch(toggleLoading(true) as any);

  dispatch({
    type: EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS,
    payload: {
      enableProgress: false,
      onSuccess: (payload: any) => {
        if (!payload.slots.length || !payload.nextAvailableDeliverySlot) {
          const error = `${i18n.t('cnc.pickupStores.selectStoreErrorHeader')}. ${i18n.t(
            'cnc.pickupStores.selectStoreErrorMsg'
          )}`;
          dispatch(setPickupStoresError(error));
        } else {
          if (successCallback) {
            successCallback();
          }
          dispatch({
            type: EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS_SUCCESS,
            payload,
          });
          dispatch(
            setSelectedSlot({
              selectedSlot: deliverySlot || payload.nextAvailableDeliverySlot,
              slotValidationRequired: true,
            })
          );
        }
      },
      onFinally: () => dispatch(toggleLoading(false) as any),
      params: {
        storename: cncStore || getStore(getState()).name,
      },
      url: SHIPMENT_CNC_FETCH_SLOTS,
    },
  });
};

export const enableCNCFallback = (data: any, fallbackCallback: (storeName: string) => void) => (
  dispatch: TDispatch<any>
) => {
  const storeName = getStore(data).name;
  // Means API has not successfully enable CNC since CNC is still not coming in the response!💩💩💩
  if (!storeName) {
    dispatch(setActiveTab(EShipmentTabs.HOME_DELIVERY_TAB));
  } else {
    fallbackCallback(storeName);
  }
};

// Handles CNC Tab click
export const enableCNC = (isTabClick: boolean) => (
  dispatch: TDispatch<any>,
  getState: () => IAppState
) => {
  // CNC error cleanup
  dispatch(dispatch(setCncError()));

  const store = getState();

  const { name, deliverySlot = {} } = (!isTabClick ? getStore(store) : {}) as ReturnType<
    typeof getStore
  >;

  const paymentType = getPaymentMode(store);
  // Sets CNC as shipment, also sets default store + slot
  dispatch(
    setCNCSlotAndStore({
      cncStore: name,
      slotCode: deliverySlot.deliverySlotCode || '',
      checkStock: true,
      deliverySlot,
      onSuccessCallback: data => {
        // Delivery Slot CleanUp
        dispatch(dispatch(setError()));

        // Track Enable CNC
        const { deliverySlot: d, name: n, displayName: dN } = getStore(data);
        const slot = formatSlotInfo(d);
        setDefaultSlot(slot);
        CncTrack(CncGTMEvents.cnc_enabled, {
          cc_store_id: n,
          cc_store_name: dN,
          cc_selected_timeslot: slot,
          cc_default_timeslot: slot,
        });

        dispatch(
          enableCNCFallback(data, async storeName => {
            if (!name || isTabClick) {
              dispatch(fetchCncSlots(storeName));
            }

            // Should call only on tab clicked; while on page load is handled in PaymentMethodsList.tsx file
            if (isTabClick) {
              if (checkForLoyaltyVoucher(store)) {
                // On Tab click, remove loyalty points if it exists
                await removeRedeemedLoyaltyPoints(false)(dispatch, getState);
              }
              // Refreshes payments
              fetchPaymentModes({
                currentPaymentMode: paymentType as EPaymentMethods,
                isFetchCreditCards: true,
                onSuccessCallback: () => {
                  dispatch(toggleVisibility(true) as any);
                  dispatch(toggleDrawerCoachMark(true) as any);
                },
              })(dispatch, getState);
            }
          })
        );
      },
      onFailureCallback: (genericErr, errObj) => {
        const { reason, subject, message } = extractErrorProps(errObj);

        // Possible OOS issue, run cart validate
        if (reason && subject === 'CNC101') {
          dispatch(
            validateCart({
              cncStore: reason,
              enableProgress: true,
              noStockCartCallback: () => dispatch(toggleDrawerCoachMark(true) as any),
            })
          );
        } else {
          if (isTabClick) {
            dispatch(setActiveTab(EShipmentTabs.HOME_DELIVERY_TAB));
          } else {
            // Could be: "The delivery slot you selected is no longer available, please select again before placing order"
            if (subject === 'CNC_ERROR' && name && deliverySlot) {
              // Calls setSlot API again but this time passing only the store to set a default slot
              dispatch(
                setCNCSlotAndStore({
                  cncStore: name,
                  onSuccessCallback: () => {
                    dispatch(setCncError(OrderErrors.EXPIRED_SLOT));
                  },
                  // Last resort is to disable CNC ugghhhh!!!
                  onFailureCallback: () => {
                    dispatch(disableCNC());
                    dispatch(setActiveTab(EShipmentTabs.HOME_DELIVERY_TAB));
                  },
                })
              );
            } else {
              dispatch(setCncError(message || genericErr));
            }
          }
        }
      },
    }) as any
  );
};

// Handles Home Delivery Tab click
export const disableCNC = (resetTab?: EShipmentTabs) => (
  dispatch: TDispatch<any>,
  getState: () => IAppState
) => {
  // CNC error cleanup
  dispatch(dispatch(setCncError()));
  // Show loader
  dispatch(setCncLoading(true));

  // Refreshes home delivery slot
  const store = getState();

  const areacode = getAreacode(store);
  const paymentType = getPaymentMode(store);
  dispatch(
    fetchSelectedSlot(
      areacode,
      getCartId(store, true),
      async () => {
        if (checkForLoyaltyVoucher(store)) {
          // Remove loyalty points if it exists
          await removeRedeemedLoyaltyPoints(false)(dispatch, getState);
        }
        // Refreshes payments
        fetchPaymentModes({
          currentPaymentMode: paymentType as EPaymentMethods,
          isFetchCreditCards: true,
        })(dispatch, getState);
        // Refreshes cart list
        dispatch(
          fetchCart(undefined, () => {
            dispatch(setCncLoading(false));
            // Delivery Slot CleanUp
            dispatch(dispatch(setError()));

            // Track CNC Disabled
            CncTrack(CncGTMEvents.cnc_disabled, {
              cc_store_id: '',
              cc_store_name: '',
              cc_selected_timeslot: '',
              cc_default_timeslot: '',
            });
          })
        );
      },
      errMsg => {
        dispatch(setCncError(errMsg));
        if (resetTab) {
          dispatch(setActiveTab(resetTab));
        }
        dispatch(setCncLoading(false));
      }
    )
  );
};

// Validates cart in 2 ways:
// 1. switching from HD to CNC - cnc.action.ts/enableCNC() function
// 2. changing the store - PickupStores.actions.ts/savePickupStore() function
export const validateCart = ({
  cncStore,
  successCartCallback,
  noStockCartCallback,
  onFailureCallback,
  enableProgress = false,
}: {
  cncStore: string;
  successCartCallback?: () => void;
  noStockCartCallback?: () => void;
  onFailureCallback?: (...args: any[]) => void;
  enableProgress?: boolean;
}) => (dispatch: TApiMiddlewareDispatch & TValidateCartDispatch, getState: () => IAppState) => {
  const store = getState();
  if (!enableProgress) {
    dispatch(setValidateCartLoading(true));
  }

  dispatch({
    type: EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES,
    payload: {
      enableProgress,
      onSuccess: ({ cartModifications }: { cartModifications: TEntries }) => {
        const modifiedEntries = [...cartModifications].filter(
          ({ statusCode }) =>
            statusCode === EValidateCartEntryStockTypes.UNAVAILABLE ||
            statusCode === EValidateCartEntryStockTypes.NO_STOCK
        );

        const modifiedEntriesCount = modifiedEntries.length;

        if (modifiedEntriesCount) {
          dispatch(setScreen(EScreens.CNC_VALIDATE_CART) as any);
          dispatch(
            setValidateCartEntries({
              entries: cartModifications,
              modifiedEntriesCount,
            })
          );

          if (noStockCartCallback) {
            noStockCartCallback();
          }

          dispatch(setValidateStore(cncStore) as any);
        } else {
          dispatch(
            setValidateCartEntries({
              entries: [],
              modifiedEntriesCount: 0,
            })
          );

          if (successCartCallback) {
            successCartCallback();
          }
        }
      },
      onFailure: (errorMessage: any) => {
        if (onFailureCallback) {
          onFailureCallback(errorMessage);
        } else {
          dispatch(setValidateCartError(errorMessage));
        }
      },
      onFinally: () => {
        if (!enableProgress) {
          dispatch(setValidateCartLoading(false));
        }
      },
      params: {
        cncStore,
        areacode: store.appConfig.areaCode,
        cartId: getCartId(store),
      },
      url: SHIPMENT_CNC_VALIDATE_CART_GET_API,
    },
  });
};

// Sets slot and store for CNC in 4 ways:
// 1. switching from HD to CNC - cnc.action.ts/enableCNC() function
// 2. changing the store, after cart has validated - PickupStores.actions.ts/savePickupStore() function
// 3. proceed button on "OOS Drawer" - useItemStatus.hook.ts
// 4. changing slot - deliverySlot.action.ts/putSelectedSlot() function
export const setCNCSlotAndStore = ({
  slotCode,
  cncStore,
  deliverySlot,
  checkStock = false,
  isFetchSlot = true,
  onSuccessCallback,
  onFailureCallback,
}: {
  slotCode?: string;
  cncStore?: string;
  deliverySlot?: ISlotItem;
  checkStock?: boolean;
  isFetchSlot?: boolean;
  onSuccessCallback?: (...args: any[]) => void;
  onFailureCallback?: (...args: any[]) => void;
} = {}) => (dispatch: TDispatch<any>, getState: () => IAppState) => {
  const store = getState();
  dispatch(setCncLoading(true));

  dispatch({
    type: ECncActionTypes.SHIPMENT_CNC_SET_SLOT_AND_STORE,
    payload: {
      enableProgress: false,
      onSuccess: (data: any) => {
        dispatch(updateCart(dispatch, data));

        if (isFetchSlot && cncStore) {
          // Bec default slot (setslot api) and next available slot (fetchslot api - up next)
          // are not the same during the initial CNC switch!
          const freshDeliverySlot = getStore(data).deliverySlot;
          dispatch(fetchCncSlots(cncStore, deliverySlot || freshDeliverySlot));
        }
        dispatch(setCncError());
        if (onSuccessCallback) {
          const isCNCShipmentExist = data.shipments.find(
            (entry: { shipmentType: EShipmentTypes }) => entry.shipmentType === EShipmentTypes.CNC
          );
          onSuccessCallback(data);
          if (isCNCShipmentExist) {
            // call this api everytime when cnc is enabled
            fetchPaymentModes({
              isFetchCreditCards: true,
            })(dispatch, getState);
          } else {
            dispatch(setActiveTab(EShipmentTabs.HOME_DELIVERY_TAB));
            dispatch(setSelectedStore(''));
          }
          dispatch(setCNCTabClicked(false));
        }
      },
      onFailure: (genericErr: any, errObj: any) => {
        const { reason, subject, message } = extractErrorProps(errObj);

        // Possible OOS issue, run cart validate
        if (reason && subject === 'CNC101') {
          // 1 hide delivery slot screen
          // 2 then call validate cart api
          dispatch(toggleVisibility(false) as any);
          dispatch(
            validateCart({
              cncStore: reason,
              enableProgress: true,
              noStockCartCallback: () => dispatch(toggleDrawerCoachMark(true) as any),
            })
          );
          dispatch(hideNotification());
        }
        // CPOS-568 fix: Note that the subject💩 has be corrected by BE, it should be some kind of a code
        else if (subject.toUpperCase() === 'CNC IS NOT ENABLED') {
          // Acc to QA, normal shipments w/o tabs is used
          dispatch(disableCNC());
          // Make sure we hide any active drawer
          dispatch(setScreenToHide());

          // Otherwise do normal handling of error
        } else {
          const errMsg = message || genericErr;
          if (onFailureCallback) {
            onFailureCallback(errMsg, errObj);
          } else {
            dispatch(setCncError(errMsg));
          }
        }
        // delivery slot loading false
        dispatch(toggleLoading(false));
      },
      onFinally: () => dispatch(setCncLoading(false)),
      params: {
        areacode: store.appConfig.areaCode,
        checkStock,
        cartId: getCartId(store),
      },
      url: `${SHIPMENT_CNC_SET_SLOT_STORE_API}${slotCode ? `&slotCode=${slotCode}` : ''}${
        cncStore ? `&cncStore=${cncStore}` : ''
      }`,
    },
  });
};

// Handles OOS Proceed button click
export const handleOOSProceed = () => (dispatch: TDispatch<any>, getState: () => IAppState) => {
  const { checkout, deliverySlot } = getState();
  const {
    pickupStores: { selectedStore },
    validateStore,
  } = checkout.shipment.cnc;
  const {
    pickupSelectedSlot: { deliverySlotCode = '' },
  } = deliverySlot || { pickupSelectedSlot: { deliverySlotCode: '' } };

  dispatch(
    selectedStore // Means call is from "Change Store" module
      ? setCNCSlotAndStore({
          cncStore: selectedStore,
          isFetchSlot: false,
          slotCode: deliverySlotCode,
          onSuccessCallback: () => {
            dispatch(setScreenToHide());
          },
          onFailureCallback: errMsg => {
            dispatch(setScreen(EScreens.CNC_PICKUP_STORES));
            dispatch(setPickupStoresError(errMsg));
          },
        })
      : setCNCSlotAndStore({
          cncStore: validateStore,
          onSuccessCallback: data => {
            dispatch(
              enableCNCFallback(data, storeName => {
                // Hides all drawers
                dispatch(setScreenToHide());
                // Slots preps
                dispatch(fetchCncSlots(storeName));
                showDeliverySlotDrawer();
                // Validation is done, cleanup validateStore
                dispatch(setValidateStore(''));
              })
            );
          },
        })
  );
};

export const handleExpiredSlotOnCreateOrder = () => (
  dispatch: TDispatch<any>,
  getState: () => IAppState
) => {
  dispatch(
    setCNCSlotAndStore({
      cncStore: getStore(getState()).name,
      onSuccessCallback: () => {
        dispatch(setCncError(OrderErrors.EXPIRED_SLOT));
      },
    })
  );
};
