import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import { EExpressActionTypes, TExpressAction } from 'pages/Checkout/types/express.types';

export const updateExpressState = (state: ICheckoutState, action: TExpressAction) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      express: {
        ...state.shipment.express,
        ...action.payload,
      },
    },
  };
};

export default {
  [EExpressActionTypes.SHIPMENT_EXPRESS_SET_ERROR]: updateExpressState,
  [EExpressActionTypes.SHIPMENT_EXPRESS_SET_LOADING]: updateExpressState,
};
