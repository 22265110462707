import EAddToListTypes from './addToList.type';
import IAction from 'config/redux/actions/interface';

export interface IListItem {
  pk: string;
  name: string;
  description: string;
  defaultWishlist: boolean;
}

export interface IList {
  list?: IListItem[] | undefined;
}

export interface IAddToListState extends IList {
  error?: string | null;
  loading: boolean;
  success?: string | null;
}

export const initialAddToListState = { loading: false } as IAddToListState;

const addToListReducer = (
  state: IAddToListState = initialAddToListState,
  action: IAction
): IAddToListState => {
  switch (action.type) {
    case EAddToListTypes.ADD_TO_LIST_SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case EAddToListTypes.ADD_TO_LIST_TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EAddToListTypes.ADD_TO_LIST_FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case EAddToListTypes.ADD_TO_LIST_SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case EAddToListTypes.ADD_TO_LIST_CLEAR_ERROR_AND_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
      };
  }

  return state;
};

export default addToListReducer;
