export enum ESupportedPaymentStrategy {
  masterCard = 'MASTER_CARD_mpgs',
}

export interface ICardConfiguration {
  number: string;
  securityCode: string;
  expiryMonth: string;
  expiryYear: string;
  nameOnCard: string;
}

export enum PaymentService_EVENTS {
  hostedSessionInitialized = 'p_s.session_init',
  receivedSessionResponse = 'p_s.session_response_received',
  receivedSessionFailure = 'p_s.session_failure',
  receivedUserEntriesError = 'p_s.session_user_entries_error',
  receivedRequestTimeOutError = 'p_s.request_timeout_error',
  reportedSystemError = 'p_s.reported_system_error',
  cardInputEvent = 'p_s.card_input_event',
}

export interface IPaymentServiceMessage {
  event: string;
  status: string;
  payload: {
    message?: string;
    card?: any;
    session?: any;
    type?: 'card';
    ok: boolean;
    securityCodeProvided?: boolean;
    error?: any;
    containsValidationErrors?: boolean;
  };
}

export enum IPaymentTypes {
  SESSION_FETCHED_SUCCESS = 'PAYMENTS/SESSION_FETCHED_SUCCESS',
  PAYMENT_DETAILS_ERROR = 'PAYMENT/PAYMENT_DETAILS_ERROR',
}

export enum InputEventType {
  onFocus = 'INPUT_EVENT/ON_FOCUS',
  onBlur = 'INPUT_EVENT/ON_BLUR',
  onChange = 'INPUT_EVENT/ON_CHANGE',
}

export interface I3DSREQUESTPARAM {
  amount: number;
  cardType: string;
  sessionId: string;
  transactionType: string;
  currency?: string;
  code: number;
  signature: string;
}

export enum Payments3DSStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DECLINE = 'DECLINE',
}

export enum InputErrorType {
  invalid = 'invalid',
  missing = 'missing',
}

export enum MasterCardStatusType {
  ok = 'ok',
  fieldInError = 'fields_in_error',
  requestTimeOut = 'request_timeout',
  systemError = 'system_error',
}

export enum MasterCardSessionErrors {
  sessionExpired = 'Form Session not found or expired',
}

export interface IValidationCallback {
  isValid: boolean;
  error?: any;
  successData?: any;
}
