import { Dispatch } from 'redux';
import INewCart, { INewCartEntry, INewCartShipment } from 'pages/NewCart/ICart';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import { updateCart } from './fetchCart.actions';

export const mergeAllShipmentEntries = (data: { [K: string]: any }) => {
  const { shipments = [] } = data;
  if (shipments && !shipments.length) {
    return data;
  }

  data.entries = shipments.reduce((accumulator: INewCartEntry[], item: INewCartShipment) => {
    if (item.entries) {
      return [...accumulator, ...item.entries];
    }
    return [...accumulator];
  }, []);

  return data;
};

const addFoodSubTotal = (data: { [K: string]: any }) => {
  const foodShipment = data.shipments.find(
    (shipment: INewCartShipment) => shipment.shipmentType === EShipmentTypes.STANDARD_FOOD
  );
  if (foodShipment && foodShipment.subTotal) {
    data.subtotalFood = foodShipment.subTotal;
  } else {
    data.subtotalFood = {
      value: 0,
    };
  }

  return data;
};

export const cartReponseShaper = (data: { [K: string]: any }) => {
  const dataWithEntries = mergeAllShipmentEntries(data);
  return addFoodSubTotal(dataWithEntries);
};

export const toggleSubstitionOfAllShipment = (cartData: INewCart, dispatch: Dispatch) => {
  const shipments = cartData.shipments.map(shipment => {
    shipment.entries = shipment.entries.map(entry => {
      if (entry.product.isSubstituted) {
        entry.isSubstituted = !entry.isSubstituted;
      }
      return entry;
    });
    return shipment;
  });

  const updatedCart = {
    ...cartData,
    shipments,
  };

  updateCart(dispatch, updatedCart);
};
