import history from 'routes/customHistory';
import { MAF_CHECKOUT_REFERRER_URI } from 'pages/Cart/constants/cart.constants';
import { initStorageManager } from 'utils/customStorage.util';
import { IAppState } from 'config/redux/reducers';
import { IAddress } from 'services/user/types/user.types';
import { getStore } from '@mafc/appshell';
import { getCartPageUrl } from 'utils/cartRevamp.util';

const storage = initStorageManager(() => sessionStorage);

export const goToCartHandler = (isCartRevampEnabled?: boolean) => {
  // Either SPC is accessed directly or user came from React Cart
  const {
    appConfig: { language, storeId },
  } = getStore().getState();
  const NEW_CART_PAGE = getCartPageUrl(storeId, language, isCartRevampEnabled);

  const referrerUriCached = storage.getItem(MAF_CHECKOUT_REFERRER_URI);
  if (!referrerUriCached || referrerUriCached.indexOf(NEW_CART_PAGE) !== -1) {
    if (isCartRevampEnabled) {
      window.location.href = NEW_CART_PAGE;
    } else {
      return history.replace(NEW_CART_PAGE);
    }
  }

  // Redirects to Hybris Cart
  // @ts-ignore
  // window.location.replace(referrerUriCached);
};

export const isExpressSPC = () => window.location.href.indexOf('/express') !== -1;
export const checkoutType = () => {
  const searchParams = new URL(window.location.href).searchParams;

  const checkoutTypeParam = searchParams.get('checkoutType') || 'DEFAULT';

  return checkoutTypeParam;
};
export const getCartId = (store: IAppState, flag: boolean = false) => {
  if ((isExpressSPC() || flag) && store && store.cart) {
    const {
      cart: {
        cartData: { code = 'current' },
      },
    } = store;
    return code;
  }
  return 'current';
};

export const getAreaCode = (store: IAppState, address: IAddress | any) => {
  const { deliveryArea: { code = '' } = {} } = address;
  if (code) {
    return code;
  }
  return store.appConfig.areaCode;
};

export const appendQueryParams = (url: string, params: any) => {
  const hasQuery = url.includes('?');
  const queryParams = [];
  if (!params || !Object.keys(params).length) {
    return url;
  }
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    }
  }
  const queryString = queryParams.join('&');
  const finalUrl = hasQuery ? `${url}&${queryString}` : `${url}?${queryString}`;

  return finalUrl;
};
