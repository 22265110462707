import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum ESessionAreaActionTypes {
  SESSION_AREA_SET_STATE = 'SessionArea/SET_STATE',
}

export type TSessionArea = string;

export interface ISessionAreaState {
  sessionArea: TSessionArea;
}

export type TSessionAreaAction = IAction<TSessionArea, ESessionAreaActionTypes>;
export type TSessionAreaDispatch = TDispatch<TSessionAreaAction>;
