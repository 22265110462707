const API_BASE = '/carts/{{StoreId}}/{{language}}/{{cartId}}';
const apiV3 = `${process.env.REACT_APP_RETAIL_SSO_BASE}/v3/`;
export const CHECKOUT_DOT_COM_URL = `${process.env.REACT_APP_CHECKOUT_DOT_COM_BASE}/v1/card-payments/initiate?channelcode=${process.env.REACT_APP_CHANNEL_CODE}`;
export const SPLIT_PAYMENT_API = `${process.env.REACT_APP_RETAIL_SSO_BASE}${process.env.REACT_APP_API_ENDPOINT}/payments/{{StoreId}}/{{language}}/split/initiate?requestTime={{requestTime}}&requestId={{requestId}}`;
export const SAVED_CREDIT_CARDS_URL =
  'payments/{{StoreId}}/{{language}}/paymentdetails?fields=FULL&saved=true&areacode={{areaCode}}';
export const SAVED_CREDIT_CARDS_URL_CYBERSOURCE = `${apiV3}customers/{{StoreId}}/{{language}}/cards?paymentGateway={{paymentType}}`;
export const GET_DELIVERY_AREAS = `stores/{{StoreId}}/{{language}}/delivery-areas?areacode={{areaCode}}`;
export const PROMO_URL = `${API_BASE}/vouchers?areacode={{areaCode}}&fields=FULL&binCode={{binCode}}`;
export const AVAILABLE_PROMOTION_LIST_URL = `/carts/listcoupons?appliedCode={{appliedCode}}&notab={{notab}}`;
export const REMOVE_PROMO_URL = `${API_BASE}/vouchers?areacode={{areaCode}}&fields=FULL`;
export const GET_LOYALTY_INFO = 'customers/loyalty-info';
export const GET_LOYALTY_SUMMARY = 'loyalty/{{StoreId}}/{{language}}/summary';
export const PAYMENT_MODE_URL =
  'payments/{{StoreId}}/{{language}}/modes?cartId={{cartId}}&fields=OPTIMIZEDCART&areacode={{areaCode}}&binCode={{binCode}}';
export const GET_PAYMENT_MODE_URL =
  'payments/{{StoreId}}/{{language}}/paymentmodes?cartId={{cartId}}&fields=OPTIMIZEDCART&areacode={{areaCode}}&binCode={{binCode}}';
// TODO:update the create order api
export const CREATE_ORDER_URL =
  'carts/{{StoreId}}/{{language}}/current/createOrder?fields=DEFAULT&paymentInfoId={{paymentInfoId}}&paymentModeSelected={{paymentModeSelected}}&saveTheCard={{saveTheCard}}&baseSiteId={{StoreId}}&areacode={{areaCode}}&nicNumber={{nicNumber}}';

// Delivery APIs
// Cookie update is not working with this API, instead need to call another API just to set it! 😒😒😒
// export const DELIVER_TO_PUT_ADDRESS_API = `${API_BASE}/addresses?addressId={{addressId}}&setcookie=true`;

// will rename this when split payment is rollout for all countries
export const DELIVER_TO_PUT_ADDRESS_API_SPLIT_PAYMENT = `${API_BASE}/addresses?addressId={{addressId}}&areacode={{areaCode}}&isNewCartService={{isNewCartService}}&payment_arch=v2&sustainabilityConsent={{sustainabilityConsent}}`;

export const DELIVER_TO_PUT_ADDRESS_API = `${API_BASE}/addresses?addressId={{addressId}}&areacode={{areaCode}}&isNewCartService={{isNewCartService}}&sustainabilityConsent={{sustainabilityConsent}}`;

//  new jorney
export const DELIVER_TO_PUT_ADDRESS_API_SPLIT_PAYMENT_CHECKOUT_TYPE = `${API_BASE}/addresses?addressId={{addressId}}&areacode={{areaCode}}&isNewCartService={{isNewCartService}}&payment_arch=v2&sustainabilityConsent={{sustainabilityConsent}}&checkoutType={{checkoutType}}&nsp={{nsp}}`;

export const DELIVER_TO_PUT_ADDRESS_API_CHECKOUT_TYPE = `${API_BASE}/addresses?addressId={{addressId}}&areacode={{areaCode}}&isNewCartService={{isNewCartService}}&sustainabilityConsent={{sustainabilityConsent}}&checkoutType={{checkoutType}}&nsp={{nsp}}`;

export const DELIVER_TO_PUT_ADDRESS_COOKIE_API =
  'users/{{StoreId}}/{{language}}/defaultArea?areacode={{areaCode}}';
export const GET_VALIDATE_CART_ENTRIES_API = `${API_BASE}/validate?areacode={{areaCode}}`;

// Express
export const SHIPMENT_EXPRESS_GET_ENABLE = `${API_BASE}/express?fields=OPTIMIZEDCART&areacode={{areaCode}}&expressEnable={{expressEnable}}&binCode={{binCode}}`;
export const REEDEEM_LOYALTY_CASHBACK = `${API_BASE}/cashback?isCartRequired=true&fields=FULL&areacode={{areaCode}}`;

// CNC
export const SHIPMENT_CNC_FETCH_SLOTS =
  'stores/{{StoreId}}/{{language}}/{{storename}}/deliveryslots?areacode={{areaCode}}';
export const SHIPMENT_CNC_VALIDATE_CART_GET_API = `${GET_VALIDATE_CART_ENTRIES_API}&cncStore={{cncStore}}&areacode={{areaCode}}`;
export const SHIPMENT_CNC_SET_SLOT_STORE_API = `${API_BASE}/setSlotsClickAndCollect?areacode={{areaCode}}&includeShipments=true&checkStock={{checkStock}}`;

// mastercard
export const THREE_DS_ENROLLMENT_CHECK = `payments/{{StoreId}}/{{language}}/{{code}}/3DSEnrollmentcheck?areacode={{areaCode}}`;
export const COMPLETE_TRANSACTION = `payments/{{StoreId}}/{{language}}/{{code}}/completetransaction?userId={{uId}}&areacode={{areaCode}}`;
export const CREATE_ORDER_URL_V2 =
  'carts/{{StoreId}}/{{language}}/{{cartId}}/v2/createOrder?fields=DEFAULT&paymentInfoId={{paymentInfoId}}&paymentModeSelected={{paymentModeSelected}}&saveTheCard={{saveTheCard}}&areacode={{areaCode}}&baseSiteId={{StoreId}}';
// 'carts/{{StoreId}}/{{language}}/current/v2/createOrder?fields=DEFAULT&paymentInfoId={{paymentInfoId}}&paymentModeSelected={{paymentModeSelected}}&saveTheCard={{saveTheCard}}&areacode={{areaCode}}&baseSiteId={{StoreId}}';
export const CREATE_ORDER_URL_V3 =
  'orders/{{StoreId}}/{{language}}/createAndUpdate?cartId={{cartId}}&fields=DEFAULT';
export const CREATE_VDAY_NOTES = 'orders/{{StoreId}}/{{language}}/valentine&areacode={{areaCode}}';
export const CREATE_ORDER_URL_EASY_PAISA = `${CREATE_ORDER_URL_V2}&accountEmailId={{accountEmailId}}&accountNumber={{accountNumber}}&areacode={{areaCode}}`;
export const EASY_PAISA_TRANSACT =
  'payments/mafpak/{{language}}/{{orderNumber}}/wallet&areacode={{areaCode}}';

export const MPESA_TRANSACT_INIT = '/wallet/{{orderId}}/payment';
export const MPESA_TRANSACT_FINAL = '/wallet/transact';

export const ORDER_CONFIRMATION =
  '/v2/{{StoreId}}/{{language}}/submitOrder?orderId={{orderId}}&fields=DEFAULT&app_id={{appId}}';
// BIN Banners
export const BIN_DISCOUNT_BANNER = `${process.env.REACT_APP_RETAIL_SSO_BASE}/v1/frame/stores/{{StoreId}}/{{language}}/bin-promotion-banners`;

export const USER_PACKGING_CONSENT = `${API_BASE}/packingCharges?sustainabilityConsent={{sustainabilityConsent}}&areacode={{areaCode}}`;

// this api is to fetch labels of order summary at checkout page
export const APIGEE_LABELS_URL = '/carts/{{StoreId}}/{{language}}/checkout/summaryItems';
export const PAYMENT_ARCHITECURE_URL = `/payments/arch`;
