import { IAction, TDispatch } from 'config/redux/types/redux.types';
import { IPickupStores } from '../components/PickupStores/types/PickupStores.types';

export enum ECncActionTypes {
  SHIPMENT_CNC_SET_ERROR = 'Shipment/Cnc/SET_ERROR',
  SHIPMENT_CNC_SET_LOADING = 'Shipment/Cnc/SET_LOADING',
  SHIPMENT_CNC_SET_ACTIVE_TAB = 'Shipment/Cnc/SET_ACTIVE_TAB',
  SHIPMENT_CNC_GET_CNC_ENABLE = 'Shipment/Cnc/GET_CNC_ENABLE', // API[GET METHOD]
  SHIPMENT_CNC_SET_SLOT_AND_STORE = 'Shipment/Cnc/SET_SLOT_AND_STORE',
  SHIPMENT_CNC_SET_VALIDATE_STORE = 'Shipment/Cnc/SET_VALIDATE_STORE',
  SHIPMENT_CNC_RESET = 'Shipment/Cnc/RESET_VALUE',
}

export enum EShipmentTabs {
  HOME_DELIVERY_TAB = 'HOME_DELIVERY_TAB',
  CNC_TAB = 'CNC_TAB',
}

export interface ICnc {
  activeTab: EShipmentTabs;
  isTabClicked?: boolean;
  error?: any;
  loading: boolean;
  pickupStores: IPickupStores;
  validateStore?: string;
}

export type TCncAction = IAction<
  Partial<ICnc>,
  Exclude<ECncActionTypes, ECncActionTypes.SHIPMENT_CNC_GET_CNC_ENABLE>
>;
export type TCncDispatch = TDispatch<TCncAction>;

export enum CardType {
  VISA = 'visa',
  MASTER_CARD = 'Mastercard',
  AMEX = 'amex',
}

export enum DeviceType {
  MWEB = 'mweb',
  DESKTOP = 'desktop',
}
