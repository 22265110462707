import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const returnConfirmationInitialState: any = {
  undefined,
  loading: false,
  error: false,
};

const returnConfirmation = (state: any, payload: any) => {
  return { ...payload };
};

const returnConfirmationLoadingStart = (state: any) => {
  return { ...state, loading: true, error: false };
};

const returnConfirmationLoadingEnd = (state: any) => {
  return { ...state, loading: false };
};

const returnConfirmationError = (state: any) => {
  return { ...state, error: true };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.CREATE_RMA]: returnConfirmation,
  [EPostOrderDataActions.CREATE_RMA_LOADING_START]: returnConfirmationLoadingStart,
  [EPostOrderDataActions.CREATE_RMA_LOADING_END]: returnConfirmationLoadingEnd,
  [EPostOrderDataActions.CREATE_RMA_ERROR]: returnConfirmationError,
};

export default function returnConfirmationReducer(
  state = returnConfirmationInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
