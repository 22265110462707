import EApiActionTypes from 'config/redux/types/api.type';

enum ELoyaltyPointsTypes {
  CHECKOUT_GET_LOYALTY_POINTS = 'Checkout/GET_LOYALTY_POINTS',
  SET_LOYALTY_POINTS = 'SET_LOYALTY_POINTS',
  ERROR_LOYALTY_POINTS = 'ERROR_LOYALTY_POINTS',
  CHECKOUT_REDEEM_LOYALTY_POINTS = 'Checkout/REDEEM_LOYALTY_POINTS',
  CHECKOUT_REMOVE_LOYALTY_POINTS = 'Checkout/REMOVE_LOYALTY_POINTS',
  CHECKOUT_LOYALTY_TOGGLE_SWITCH = 'Checkout/CHECKOUT_LOYALTY_TOGGLE_SWITCH',
}

export const CHECKOUT_GET_LOYALTY_POINTS_SUCCESS = `${ELoyaltyPointsTypes.CHECKOUT_GET_LOYALTY_POINTS}${EApiActionTypes.API_SUCCESS}`;

export const CHECKOUT_REDEEM_LOYALTY_POINTS_SUCCESS = `${ELoyaltyPointsTypes.CHECKOUT_REDEEM_LOYALTY_POINTS}${EApiActionTypes.API_SUCCESS}`;

export default ELoyaltyPointsTypes;
