import { isExisty, isNumber, isObject } from 'utils/helpers.util';
import { convertQuantityToKG, generateQuantityList, CQtyDefault } from 'utils/quantity.util';

export const extractMaxFromQuantityList = (quantityList: any[], maxFallback: number): number =>
  (quantityList.slice(-1)[0] || {}).value || maxFallback;

const memoizedLists: { [key: string]: any } = {};

const extractQuantityProps = (item: { [key: string]: any }, offerId?: string) => {
  const { entryNumber, quantity, product, updateable } = item;
  if (!isExisty(entryNumber) || !isExisty(quantity) || !isExisty(product)) {
    return;
  }
  const { maxToOrder, minimumWeightToOrder, soldByWeight, stock, weightIncrement: delta } = product;
  let max = maxToOrder;

  // Extracts stock level value
  if (isObject(stock)) {
    const { stockLevel, stockLevelStatus, maxQuantity = 0 } = stock;
    // maxQuantity for new cart service
    // stockLevel for hybris cart
    if (isNumber(stockLevel) && (!isNumber(maxToOrder) || stockLevelStatus === 'lowStock')) {
      // Overrides max with stock level for LOS items
      // Smaller value should be the limit
      max = Math.min(stockLevel, maxToOrder ? maxToOrder : 10);
    }

    if (isNumber(maxQuantity) && maxQuantity && isNumber(maxToOrder) && maxToOrder) {
      // Overrides max with stock level for LOS items
      // Smaller value should be the limit
      max = Math.min(maxToOrder ? maxToOrder : 10, maxQuantity);
    }
  }

  const qtyDefault = new CQtyDefault({
    delta,
    soldByWeight,
    max,
    min: minimumWeightToOrder,
  });
  // memoized list
  const quantityList =
    memoizedLists[`${product.code}${offerId || ''}`] || generateQuantityList(qtyDefault);
  memoizedLists[`${product.code}${offerId || ''}`] = quantityList;
  return {
    entryNumber,
    quantity: convertQuantityToKG(quantity, soldByWeight),
    quantityList,
    updateable,
    ...qtyDefault,
    max: extractMaxFromQuantityList(quantityList, qtyDefault.max),
  };
};

export default extractQuantityProps;
