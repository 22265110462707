import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { IAppState } from 'config/redux/reducers';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';

import CartActionTypes from '../Cart.types';
import { getGUID, getUserTokenFromCookie } from 'utils/cookies.util';
import { cartToggleError, fetchCartAction, getEarnedPoints, updateCart } from './getCart.action';

import CONSTANTS from 'config/constants';
import {
  NEW_ADD_TO_CART,
  NEW_DELETE_FROM_CART,
  NEW_GET_CART_API,
  NEW_SUBSTITUTION_API,
  NEW_UPDATE_QUANTITY_API,
} from 'pages/Cart/constants/new.apis.constant';
import {
  IParams,
  IUpdateQuantity,
  IUpdateQuantityTracking,
  trackCartUpdation,
  updateEntryToggleLoading,
} from 'pages/Cart/components/UpdateQuantity/redux/updateQuantity.action';
import EUpdateQuantityTypes from 'pages/Cart/components/UpdateQuantity/redux/updateQuantity.type';
import { hideNotification, showNotification } from 'common/Notification/redux/notification.action';
import qtyRestrictionHandler from './qtyRestriction';
import { i18n } from '@mafc/common';
import { IQuantityUpdateSuccess } from 'pages/Cart/components/UpdateQuantity/UpdateQuantity.interface';
import { hidePageLoader, showPageLoader } from 'common/PageLoader/redux/pageLoader.action';
import { handleSubstitutionFailure, updateItemSubstitution } from './enableSubstitution.action';
import { IShipment } from 'services/shipments/types/shipments.types';
import { Entry } from 'network/response/ICart';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { createGrowthBookInstance } from '@mafc/appshell';

export const transfromGetCart = (data: { [K: string]: any }) => {
  const { shipments = [] } = data;
  if (shipments && !shipments.length) {
    return data;
  }

  data.entries = shipments.reduce((accumulator: any, item: IShipment) => {
    if (item.entries) {
      return [...accumulator, ...item.entries];
    } else if (item.sellersEntries) {
      let entries: Entry[] = [];
      item.sellersEntries.forEach(sellerEntry => {
        entries = [...entries, ...sellerEntry.entries];
      });
      return [...accumulator, ...entries];
    }
    return [...accumulator];
  }, []);
  return data;
};

export const transformBundleEntries = (data: { [k: string]: any }) => {
  const { entries, campaigns } = data;
  const groupedEntries: any = {};
  entries.forEach((entry: any) => {
    const { bundleId, campaignId } = entry;
    const groupByKey = bundleId || campaignId;
    if (!groupedEntries[groupByKey]) {
      groupedEntries[groupByKey] = [];
    }
    let campaign = campaigns.find((item: any) => item.id === campaignId);
    if (campaign && campaign.bundles && campaign.bundles.length > 0) {
      const bundleItem = campaign.bundles.find((bundle: any) => bundle.bundleId === bundleId);
      if (bundleItem) {
        campaign = { ...campaign, ...bundleItem };
      }
    }
    let entryWithCampaign = entry;
    if (campaign && bundleId) {
      entryWithCampaign = { ...entry, campaign };
    }
    if (campaign && !bundleId && campaignId) {
      entryWithCampaign = { ...entry, bundleText: campaign.shortText };
    }
    groupedEntries[groupByKey].push(entryWithCampaign);
  });
  const resultArray = Object.values(groupedEntries);
  return resultArray;
};

export const transformCheckoutBundleEntries = (entries: Array<{ bundleId?: string }>) => {
  const groupedEntries: { [key: string]: Array<{ bundleId?: string }> } = {};
  entries.forEach(entry => {
    const { bundleId } = entry;
    const groupByKey = bundleId || '';
    if (!groupedEntries[groupByKey]) {
      groupedEntries[groupByKey] = [entry];
    } else {
      groupedEntries[groupByKey].push(entry);
    }
  });
  const resultArray = Object.values(groupedEntries);
  return resultArray;
};

export const newFetchCartAction = (
  successCallback?: () => void,
  completedCallback?: () => void
) => (dispatch: Dispatch, getState: () => IAppState) => {
  const guid = getGUID();
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );
  const isCartBundlingEnabled = growthbook.feature('common.cart_bundling.enabled').on;
  if (getUserTokenFromCookie() || guid) {
    let url = guid ? `${NEW_GET_CART_API}&guid=${guid}` : NEW_GET_CART_API;
    url = url + (isCartBundlingEnabled ? '&cp=true' : '');
    batch(() => {
      const { areaCode } = getState().appConfig;
      dispatch({
        type: CartActionTypes.CART_FETCH_CART_DATA,
        payload: {
          enableProgress: false,
          method: EApiMiddlewareMethod.GET,
          onSuccess: (data: { [key: string]: any }) => {
            const transformedData = transfromGetCart(data);
            updateCart(dispatch, transformedData);
            dispatch(getEarnedPoints() as any);
            if (successCallback) {
              successCallback();
            }
          },
          url,
          params: { areaCode },
          onFailure: (errorMessage: string) => {
            dispatch(cartToggleError({ errorMessage }));
          },
          onFinally: () => {
            if (completedCallback) {
              completedCallback();
            }
          },
        },
      });
    });
  } else {
    dispatch(cartToggleError({ errorMessage: CONSTANTS.guidUnavailable }));
    if (completedCallback) {
      completedCallback();
    }
  }
};

export const newAddProduct = (productId: string, qty: number, params: IParams) => (
  dispatch: Dispatch,
  state: () => IAppState
) => {
  const store = state();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );
  const isCartBundlingEnabled = growthbook.feature('common.cart_bundling.enabled').on;
  const { areaCode } = store.appConfig;
  const { isMarketPlace = false, offerId, productName } = params;
  const guid = getGUID();
  let url = guid ? `${NEW_ADD_TO_CART}&guid=${guid}` : NEW_ADD_TO_CART;
  url = url + (isCartBundlingEnabled ? '&cp=true' : '');
  dispatch({
    type: EUpdateQuantityTypes.CART_ADD_ITEM,
    payload: {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url,
      method: EApiMiddlewareMethod.POST,
      params: {
        areaCode,
      },
      data: isMarketPlace
        ? `code=${offerId}&qty=${qty}&isOffer=true`
        : `code=${productId}&qty=${qty}&isOffer=false`,
      onSuccess: (cartResponse: { [key: string]: any }) => {
        cartResponse.cart = transfromGetCart(cartResponse.cart);
        const { entry, cart, statusCode, maxAllowedQuantity, subFamilyName } = cartResponse;

        if (statusCode.toLowerCase() === 'success') {
          dispatch(getEarnedPoints() as any);
          updateCart(dispatch, cart);
          trackCartUpdation(true, entry);
          if (params.successCallback) {
            params.successCallback(cartResponse);
          }
        } else {
          dispatch<any>(
            qtyRestrictionHandler({ statusCode, maxAllowedQuantity, subFamilyName, entry })
          );
          if (params.failureCallback) {
            params.failureCallback(productId);
          }
        }
      },
      onFailure: (errorMessage: string, error: any) => {
        const {
          response: {
            data: { meta },
          },
        } = error;

        dispatch(
          showNotification({
            type: notificationTypes.errorCheckout,
            message:
              meta && meta.message ? meta.message : i18n.t('addToCartError', { productName }),
          })
        );
        if (params.failureCallback) {
          params.failureCallback(productId);
        }
      },
    },
  });
};

export const newUpdateQuantityCartEntry = (
  dispatch: any,
  { cartEntryNumber, qty, isOffer = false, offerId = '', loadingEntryNumber, cp }: IUpdateQuantity,
  entries: any[],
  areaCode: string,
  trackingData: IUpdateQuantityTracking
) => {
  const guid = getGUID();
  let apiUrl = guid ? `${NEW_UPDATE_QUANTITY_API}&guid=${guid}` : NEW_UPDATE_QUANTITY_API;
  apiUrl = apiUrl + (cp ? '&cp=true' : '');
  dispatch(
    updateEntryToggleLoading(loadingEntryNumber ? loadingEntryNumber : cartEntryNumber, true)
  );
  dispatch(hideNotification());
  dispatch({
    type: EUpdateQuantityTypes.CART_UPDATE_QUANTITY,
    payload: {
      enableProgress: false,
      data: isOffer
        ? `qty=${qty}&isOffer=${isOffer}&offerId=offer_${offerId}`
        : `qty=${qty}&isOffer=${isOffer}&offerId=${offerId}`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      method: EApiMiddlewareMethod.PUT,
      onSuccess: ({
        cart,
        entry,
        statusCode,
        maxAllowedQuantity,
        subFamilyName,
      }: IQuantityUpdateSuccess) => {
        trackCartUpdation(
          trackingData.actionType === 'add',
          entry,
          undefined,
          trackingData.updateType
        );
        // Update affected entry
        dispatch(qtyRestrictionHandler({ statusCode, maxAllowedQuantity, subFamilyName, entry }));
        dispatch({
          type: EUpdateQuantityTypes.CART_UPDATE_ENTRY_DETAIL,
          payload: { entry },
        });
        updateCart(dispatch, transfromGetCart(cart));
        dispatch(getEarnedPoints());
      },
      onFailure: (msg: any, error: any) => {
        const isOOSItem =
          ((((error.response || {}).data || {}).errors || [])[0] || {}).reason === 'notFound';

        if (isOOSItem) {
          // Make sure to update overall cart data including order summary's
          dispatch(fetchCartAction());

          if (entries) {
            // Extracts entry name and url from Cart's entries array
            const { onlineName = '', url = '' } =
              (entries.filter(entry => entry.entryNumber === cartEntryNumber)[0] || {}).product ||
              {};

            dispatch(
              showNotification({
                type: notificationTypes.errorCheckout,
                message: `Unfortunately ${
                  onlineName && url
                    ? `<a href="${url}" style="color:#0E5AA7">${onlineName}</a>`
                    : 'a product'
                } was removed from your cart as it is out of stock.`,
              })
            );
          }
        }
      },
      onFinally: () => {
        dispatch(
          updateEntryToggleLoading(loadingEntryNumber ? loadingEntryNumber : cartEntryNumber, false)
        );
      },
      params: {
        cartEntryNumber,
        areaCode,
      },
      url: apiUrl,
    },
  });
};

export const newDeleteCartEntry = (
  dispatch: Dispatch,
  { cartEntryNumber, offerId, oldCartState = {}, bundleId, originalQuantity, cp }: IUpdateQuantity,
  areaCode: string
) => {
  const guid = getGUID();
  let url = guid ? `${NEW_DELETE_FROM_CART}&guid=${guid}` : NEW_DELETE_FROM_CART;
  url = url + (cp ? '&cp=true' : '');
  dispatch(showPageLoader());
  dispatch(hideNotification());
  dispatch({
    type: EUpdateQuantityTypes.CART_UPDATE_QUANTITY,
    payload: {
      enableProgress: false,
      method: EApiMiddlewareMethod.DELETE,
      onSuccess: (data: { [key: string]: any }) => {
        let index = 0;
        oldCartState.cartData.entries.map(({ entryNumber }: any, counter: number) => {
          if (entryNumber === cartEntryNumber) {
            index = counter;
          }
          return null;
        });

        trackCartUpdation(false, oldCartState.cartData, index, 'button_remove');
        updateCart(dispatch, transfromGetCart(data));
        dispatch(getEarnedPoints() as any);
        dispatch(hidePageLoader());
      },
      onFailure: () => {
        dispatch(hidePageLoader());
      },
      data: {
        deleteProductRequestData: [
          {
            productId: cartEntryNumber,
            offerId: offerId ? `offer_${offerId}` : '',
            ...(bundleId && { bundleId }),
            ...(originalQuantity && { originalQuantity }),
          },
        ],
      },
      params: {
        areaCode,
      },
      url,
    },
  });
};

export const newDeleteProducts = (entryNumbers: any[]) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const guid = getGUID();
  const url = guid ? `${NEW_DELETE_FROM_CART}&guid=${guid}` : NEW_DELETE_FROM_CART;
  const { areaCode } = getState().appConfig;
  dispatch(showPageLoader());
  dispatch(dispatch(hideNotification()));

  dispatch({
    type: CartActionTypes.CART_DELETE_PRODUCT,
    payload: {
      method: EApiMiddlewareMethod.DELETE,
      enableProgress: false,
      params: {
        areaCode,
      },
      data: {
        deleteProductRequestData: entryNumbers.map(({ offerId, productCode }) => ({
          productId: productCode,
          offerId,
        })),
      },
      url,
      onSuccess: (data: { [key: string]: any }) => {
        batch(() => {
          updateCart(dispatch, transfromGetCart(data));
          dispatch(getEarnedPoints() as any);
          dispatch(hidePageLoader());
        });
      },
      onFailure: () => {
        batch(() => {
          dispatch(hidePageLoader());
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: i18n.t('deletionFailedMessage'),
            })
          );
        });
      },
    },
  });
};

export const newEnableSubstitution = ({
  cartEntries,
  failureCallback,
}: {
  cartEntries: Array<{ entryNumber: number; isSubstitute: boolean }>;
  failureCallback: () => void;
}) => (dispatch: Dispatch, getState: () => IAppState) => {
  const { areaCode } = getState().appConfig;
  const guid = getGUID();
  const url = guid ? `${NEW_SUBSTITUTION_API}&guid=${guid}` : NEW_SUBSTITUTION_API;

  dispatch({
    type: CartActionTypes.CART_ENABLE_PRODUCT_SUBSTITUTION,
    payload: {
      data: {
        substitutionRequestData: cartEntries.map((entry: any) => ({
          productId: Number(entry.entryNumber),
          isSubstitute: entry.isSubstitute,
          offerId: entry.offerId ? `offer_${entry.offerId}` : null,
        })),
      },
      method: EApiMiddlewareMethod.PUT,
      params: {
        cartEntries,
        areaCode,
      },
      url,
      onSuccess: ({ result }: any) => {
        if (result !== 'success') {
          if (failureCallback) {
            failureCallback();
          }
          dispatch(
            updateItemSubstitution({ cartEntries: handleSubstitutionFailure({ cartEntries }) })
          );
        }
      },
      onFailure: () => {
        if (failureCallback) {
          failureCallback();
        }
        dispatch(
          updateItemSubstitution({ cartEntries: handleSubstitutionFailure({ cartEntries }) })
        );
      },
    },
  });
};
