import ENotificationTypes, { NotificationTypes, NotificationVariant } from './notification.type';
import IAction from 'config/redux/actions/interface';

export interface INotificationState {
  type?: NotificationTypes;
  message?: string | any[];
  variant?: NotificationVariant;
}

export const initialNotificationState = {} as INotificationState;

const notificationReducer = (
  state: INotificationState = initialNotificationState,
  action: IAction
): INotificationState => {
  switch (action.type) {
    case ENotificationTypes.NOTIFICATION_SHOW:
      return action.payload;

    case ENotificationTypes.NOTIFICATION_HIDE:
      return initialNotificationState;
  }

  return state;
};

export default notificationReducer;
