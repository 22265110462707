import { Types } from '@mafc/trolly';
import { AppliedVoucher, DeliveryCost, LoyaltyVoucher, TotalPrice } from 'network/response/ICart';
import { PlasticBagPackingInfo } from 'network/response/ICart';
import { IFinalPrice } from 'utils/price.util';
export interface IVariousFee {
  shipmentDeliveryFee?: IFinalPrice;
  shipmentServiceFee?: IFinalPrice;
  shipmentSurCharge?: IFinalPrice;
}
export interface IOrderConfirmationShipment extends IVariousFee, IShipmentAndFeeLabels {
  type: string;
  shipmentTotal: IFinalPrice;
  products: IOrderConfirmationShipmentProduct[];
  deliveryDescription?: string;
  isExpressNow?: boolean;
  isFbc?: boolean;
}

type Colors = Types.BaseThemeInterface['colors'];
export type ThemeColors = keyof Omit<Colors, 'blacks' | 'whites'>;

export enum EOrderConfirmationActions {
  HYDRATE_CONFIRMATION_DETAILS = 'OrderConfirmation/HYDRATE_CONFIRMATION_DETAILS',
  CLEAR_CONFIRMATION_DETAILS = 'OrderConfirmation/CLEAR_CONFIRMATION_DETAILS',
  ORDER_DETAIL_LOADING = 'OrderConfirmation/ORDER_DETAIL_LOADING',
}

export interface IOrderConfirmation {
  appliedVouchers?: AppliedVoucher[];
  contactDetails: any;
  codFee?: DeliveryCost;
  convertedLoyaltyPointsValue: number;
  loyaltyVouchers: LoyaltyVoucher[];
  paymentMode: string;
  orderMeta: any;
  paymentInfo: any | [];
  shipmentsData: any;
  shippingAddress: string;
  secondaryPaymentStatus: EsecondaryPaymentStatus;
  paymentTransactionStatus: EsecondaryPaymentStatus;
  purchasedProductData: {
    gaProductList: any[];
    productIDs: string[];
    prices: string[];
    quantities: string[];
  };
  orderDetailLoading: boolean;
  sustainabilityConsent: boolean | null;
  plasticBagPackingInfo: PlasticBagPackingInfo;
  totalAppliedDiscounts: IFinalPrice;
  totalBundleDiscount?: IFinalPrice;
  totalFees: IFinalPrice;
  totalPriceWithTax: IFinalPrice;
  subTotal: IFinalPrice;
  isNewUser?: boolean;
  purchasedGa4ProductData?: any;
}

export interface IOrderConfirmationShipmentProduct {
  productName: string;
  productType: string;
  quantity: number;
  imageURL: string;
  totalPrice: TotalPrice;
  bundleId?: string;
  isFreebie?: boolean;
}

export interface IOrderConfirmationPaymentInfo {
  accountHolderName: string;
  cardNumber: string;
  cardType: any;
  defaultPayment: boolean;
  expiryMonth: string;
  expiryYear: string;
  id: string;
  paymentDetails: IPaymentDetails[] | [];
  orderMeta: any;
  sustainabilityConsent: boolean | null;
  plasticBagPackingInfo: PlasticBagPackingInfo;
}

export interface IShipmentAndFeeLabels {
  shipmentNameLabel: string;
  deliveryFeeLabel?: string;
  serviceFeeLabel?: string;
  surchargeFeeLabel?: string;
}

export const ORDER_CONFIRMATION_DETAILS_KEY = 'STAGED_ORDER_DETAILS';

export enum EsecondaryPaymentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  DECLINED = 'DECLINED',
}
export interface IPaymentDetails {
  descriptionText: string;
  value: string;
  type?: string;
  key: string;
  numberOfBags?: number;
  cardDetails?:
    | false
    | {
        cardNumber: string;
        cardType: 'master' | 'visa';
      };
}

export type ExcludesFalse = <T>(x: T | false) => x is T;

export enum ORDER_SUMMARY_LABEL_TYPE {
  DELIVERY_FEE = 'DELIVERY_FEE',
  SERVICE_FEE = 'SERVICE_FEE',
  SURCHARGE = 'SURCHARGE',
  SHIPMENT = 'SHIPMENT',
}
