/**
 * This file contains mapping of error code and i18 keys.  Error code is being received from BE.
 */

export const ERROR_CODES: { [key: string]: string } = {
  SLOT_400: 'deliverySlot.deliverySlotExpired',
  SLOT_404: 'deliverySlot.allDeliverySlotExpired',
};

export default ERROR_CODES;
