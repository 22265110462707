enum EApiActionTypes {
  API = 'API',
  API_ERROR = 'API_ERROR',
  ACCESS_DENIED = 'ACCESS_DENIED',
  API_START = '_START',
  API_END = '_END',
  API_SUCCESS = '_SUCCESS',
  API_FAIL = '_FAIL',
}

export default EApiActionTypes;
