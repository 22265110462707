// CSS reset ref: https://alligator.io/css/minimal-css-reset/

import React from 'react';
import { Global, css } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';

import COLORS from 'theme/colors';
import TYPOGRAPHY from 'theme/typography';

const GlobalStyles = (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

      ${emotionNormalize}

      html {
        box-sizing: border-box;
        font-size: 16px;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ol,
      ul {
        color: ${COLORS.THUNDORA};
        font-size: ${TYPOGRAPHY.SIZE[14]};
        font-weight: ${TYPOGRAPHY.WEIGHT[400]};
        line-height: 1.3;
        margin: 0;
        padding: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      body {
        background: ${COLORS.BODY};
      }

      html,
      body {
        height: 100%;
      }

      .skeleton-loader {
        display: block;
      }
    `}
  />
);

export default GlobalStyles;
