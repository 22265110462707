import { ERROR_INITIAL_STATE } from './index';
import { isNonEmptyObject } from 'utils/helpers.util';
import { MasterCardStatusType } from 'services/payments/Payments.types';

export const getInputFieldErrorType = (inputField: string, fieldName: string, t: any) => {
  switch (inputField) {
    case 'invalid':
      return t('errorForm.valid', { param: fieldName });
    case 'missing':
      return t('errorForm.required');
    case 'expiry':
      return t('errorForm.expiryDate');
    default:
      return '';
  }
};

export const handleAllInputError = (error: any, t: any) => {
  const updatedState: any = { ...ERROR_INITIAL_STATE };
  if (isNonEmptyObject(error)) {
    Object.keys(error).forEach(currentInput => {
      updatedState[currentInput] = getInputFieldErrorType(
        error[currentInput],
        t(`paymentForm.${currentInput}`),
        t
      );
    });
  }
  return updatedState;
};

const convertExpiryYearInYYYY = (expiryYear: number, currentYear: number) => {
  const year = currentYear.toString();

  const convertedExpiryYear =
    expiryYear.toString().length === 2
      ? year.replace(/.{0,2}$/, expiryYear.toString())
      : year.replace(/.{0,2}$/, '0' + expiryYear);

  return parseInt(convertedExpiryYear, 10);
};

export const generateExpiredCardErrors = (
  currentMonth: number,
  currentYear: number,
  expiryMonth: number,
  expiryYear: number
) => {
  let errors: any = { expiryYear: 'expiry' };

  const isMonthInvalid = currentYear === expiryYear && currentMonth > expiryMonth;
  if (isMonthInvalid) {
    errors = { expiryMonth: 'expiry' };
  }

  return errors;
};

export const handleExpiryDateValidations = (response: any) => {
  const { status, sourceOfFunds } = response;
  if (isNonEmptyObject(sourceOfFunds) && status === MasterCardStatusType.ok) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const {
      provided: {
        card: {
          expiry: { month: expiryMonth, year },
        },
      },
    } = sourceOfFunds;
    const expiryYear = convertExpiryYearInYYYY(year, currentYear);

    const currentMonthTimeStamp = new Date(`${currentYear}-${currentMonth}-01`).getTime();
    const expiryDateTimeStamp = new Date(`${expiryYear}-${expiryMonth}-01`).getTime();

    const isCardExpire = expiryDateTimeStamp < currentMonthTimeStamp;
    if (isCardExpire) {
      const errors: any = generateExpiredCardErrors(
        currentMonth,
        currentYear,
        expiryMonth,
        expiryYear
      );
      return {
        ...response,
        status: MasterCardStatusType.fieldInError,
        message: MasterCardStatusType.fieldInError,
        errors,
        containsValidationErrors: true,
      };
    }
  }

  return response;
};

// Seems like these function are not using anywhere , but we are not deleting it might be usefull in future.

/*
 export const validateCardExpiry = (
   card: ICard
 ): { isValid: boolean; error: { [key: string]: string } } => {
   const { expiry } = card;
   if (expiry) {
     const { month, year } = expiry;
     const expiryDate = `${month.length > 1 ? month : `0${month}`}/${year}`;
     const isValid = validateExpiryDate(expiryDate);

     const validationObject: any = { isValid, error: {} };
     if (!isValid) {
       validationObject.error.expiryYear = InputErrorType.invalid;
     }
     return validationObject;
   }
   return {
     isValid: false,
     error: {
       expiryMonth: InputErrorType.invalid,
       expiryYear: InputErrorType.invalid,
     },
   };
 };

 export const handleFieldValidation = ({
   cardData,
   onSuccess,
   onFailure,
 }: {
   cardData: ICard;
   onSuccess: () => void;
   onFailure: (error: any) => void;
 }) => {
   const { isValid, error } = validateCardExpiry(cardData);
   if (isValid) {
     onSuccess();
   } else {
     onFailure(error);
   }
 };*/
