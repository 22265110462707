import { AnyAction } from 'redux';
import {
  EPostOrderDataActions,
  IPostOrderDetailsResponse,
  IPostOrderList,
  IPostOrderListData,
} from '../orderData.type';
import { isNonEmptyArray } from 'utils/helpers.util';

export const postOrderHistoryInitialState: IPostOrderList = {
  orders: [],
  currentPage: 0,
  totalPages: 1,
  isLoading: false,
};

const getOrderHistory = (state: IPostOrderList, payload: IPostOrderListData) => {
  if (!payload) {
    return {
      ...state,
      isLoading: true,
    };
  }
  return {
    ...state,
    orders: [...state.orders, ...payload.orders],
    currentPage: payload.pagination.currentPage,
    totalPages: payload.pagination.totalPages,
    isLoading: true,
  };
};

const updateConsignment = (
  state: IPostOrderList,
  { consignmentId, consignmentProperties }: { consignmentId: number; consignmentProperties: object }
) => {
  const { orders } = state;
  if (orders && isNonEmptyArray(orders)) {
    const order = updatedOrders(orders, consignmentId, consignmentProperties);
    return { ...state, orders: order };
  }
  return state;
};

const updatedOrders = (
  orders: IPostOrderDetailsResponse[],
  consignmentId: number,
  consignmentProperties: object
) => {
  return orders.map(order => {
    const consignments = updatedConsignment(
      order.consignments || [],
      consignmentId,
      consignmentProperties
    );
    return { ...order, consignments };
  });
};
const updatedConsignment = (
  consignments: any[],
  consignmentId: number,
  consignmentProperties: object
) => {
  return consignments.map(consignment => {
    if (consignment.code === consignmentId) {
      return { ...consignment, ...consignmentProperties };
    }
    return { ...consignment };
  });
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.GET_ORDER_LIST]: getOrderHistory,
  [EPostOrderDataActions.CANCEL_CONSIGNMENT]: updateConsignment,
};

export default function postOrderHistoryReducer(
  state = postOrderHistoryInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
