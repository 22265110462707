import styled from '@emotion/styled';
import { customStyled } from '@mafc/trolly';

const Header = customStyled.header<any>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;

  box-shadow: none;
  background: ${props => props.theme.colors.white}
`;

export const Spacer = styled.i`
  flex: 1;
`;

export default Header;
