enum EShareCartTypes {
  SHARE_CART_GENERATE_URL = 'ShareCart/GENERATE_URL',
  SHARE_CART_GENERATE_URL_SUCCESS = 'ShareCart/GENERATE_URL_SUCCESS',
  SHARE_CART_TOGGLE_VISIBLE = 'ShareCart/TOGGLE_VISIBLE',
}

export default EShareCartTypes;

export interface IApiResponse {
  counter: number;
  url: string;
  token: string;
}
