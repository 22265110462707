import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Box } from '@mafc/trolly';

const DotKeyframe = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const Dot = styled.span<{ delay: number }>`
  opacity: 0;
  animation: ${DotKeyframe} 1.3s infinite;
  animation-delay: ${({ delay }: any) => delay}s;
  :after {
    display: inline-block;
    vertical-align: bottom;
    content: '.';
  }
`;

const dotsArray = [0, 0.2, 0.3];
export const LoadingEllipsis: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Box>
    {children}
    {dotsArray.map(item => (
      <Dot delay={item} key={item} />
    ))}
  </Box>
);

export default LoadingEllipsis;
