export const GROWTHBOOK_FLAGS = {
  CRITEO_AD_ENABLED: 'criteo_ad_enabled',
  LOYALTY_POINT_TYPE: 'loyalty-point-type',
  OOS_REPLACEMENT_ENABLED: 'cart.oos_replacement.enabled',
  CART_BUNDLING_ENABLED: 'common.cart_bundling.enabled',
  MULTI_ITEM_RETURN_ENABLED: 'postorder.multi_item_returns.enabled',
  ADTECH_SAMPLING_PRODUCT_ENABLED: 'adtech.sampling_products.enabled',
  ADTECH_CAROUSEL_ENABLED: 'cart.criteo_sponsored_product.enabled',
  ADTECH_CAROUSEL_CONFIG: 'cart.adtech_placements.config',
  CART_REVAMP_ENABLED: 'cart.cart_revamp_flutter.enabled',
};
