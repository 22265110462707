import { EApiMiddlewareMethods } from 'config/redux/middlewares/api.middleware.types';
import { IAppState } from 'config/redux/reducers';
import { TDispatch } from 'config/redux/types/redux.types';
import { CREATE_VDAY_NOTES } from 'pages/Checkout/constants/apis.constant';
import {
  CLEAR_MKP_NOTE,
  CREATE_MKP_NOTE,
  IMKPNote,
  SET_MKP_NOTE,
} from 'pages/Checkout/types/mkp.types';

export const setMKPNote = (payload: IMKPNote[]) => ({
  type: SET_MKP_NOTE,
  payload,
});

export const clearMKPNote = () => ({
  type: CLEAR_MKP_NOTE,
});

export const createVDayNote = (orderId: string) => (
  dispatch: TDispatch<any>,
  getState: () => IAppState
) => {
  const { notes } = getState().checkout.shipment.mkp;
  notes.forEach((note: IMKPNote) => {
    if (note.message.trim()) {
      return dispatch({
        type: CREATE_MKP_NOTE,
        payload: {
          enableProgress: false,
          data: {
            ...note,
            orderId,
          },
          method: EApiMiddlewareMethods.POST,
          url: CREATE_VDAY_NOTES,
        },
      });
    }
    return null;
  });

  dispatch(clearMKPNote());
};
