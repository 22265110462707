import FEATURES_CONFIG from 'config/features';

export const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
export const mastercardRegEx = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
export const creditCardExpiryDate = /^(0\d|1[0-2])\/\d{2}$/;

// Moved to Feature Toggle module for dynamic obtaining of value
// export const mobileNumberRegex = {
//   mafuae: /^[5][0-9]{8,8}$/,
//   mafegy: /^[1][0-9]{9,9}$/,
//   maflbn: /^([0][3][0-9]{6,6})$|^([3][0-9]{6,6})$|^([7][01689][0-9]{6,6})$|^([8][1][0-9]{6,6})$/,
//   mafsau: /^5(0|3|4|5|6|8|9)([0-9]{7})$|^57(0|1|2|6|7|8)([0-9]{6})$/,
//   mafjor: /^(7)(7|8|9)([0-9]{7})$/,
//   mafqat: /^[3|5|6|7][0-9]{7}$/,
// };

export const mobileNumberRegex = new RegExp(FEATURES_CONFIG.settings.mobileNumberRegex);
export const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const pakNumberRegex = /(\+92)(3|03)[0-6][0-9]{8}$/;
export const nonSpaceAlphabetsOnlyRegEx = /^\S[a-zA-Z '.,\u0621-\u064A]+$/;
export const addressInputRegex = /^\S[a-zA-Z0-9\s,'-.\u0621-\u064A]*$/;
