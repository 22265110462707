import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const reasonsInitialState: any = {
  reasons: undefined,
};

const returnreasons = (state: any, payload: any) => {
  return { ...state, reasons: payload };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.RETURN_REASONS]: returnreasons,
};

export default function returnReasonsReducer(state = reasonsInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
