import { Dispatch } from 'redux';

import CheckoutActionTypes, {
  CHECKOUT_SET_PAYMENT_MODE_SUCCESS,
  CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS,
  CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS,
} from '../Checkout.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import {
  GET_PAYMENT_MODE_URL,
  PAYMENT_MODE_URL,
  SAVED_CREDIT_CARDS_URL_CYBERSOURCE,
} from '../../constants/apis.constant';
import { updateCart } from 'pages/Cart/redux/actions';
import { extractBinFromCard } from 'pages/Checkout/components/Payment/helpers/payment.helpers';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import { IAppState } from 'config/redux/reducers';
import FEATURES_CONFIG from 'config/features';
import { getAreaCode, getCartId } from 'pages/Cart/helpers/url.helpers';
import { isNonEmptyArray, isNonEmptyObject } from 'utils/helpers.util';
import extractShipment from 'services/shipments/helpers/extractShipment';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import {
  deliverySlotDrawerDefaultState,
  fetchSelectedSlot,
  getAllData,
  setShipmentDeliverySlot,
} from 'common/DeliverySlot/redux/deliverySlot.action';
import { batch } from 'react-redux';
import getSelectedAddress from 'utils/getSelectedAddress.util';
import CartActionTypes from 'pages/Cart/redux/Cart.types';
import { showNotification } from 'common/Notification/redux/notification.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import i18n from 'config/localisation';
import { CHECKOUT_ERRORS_TYPE, GATEWAY_TYPE } from 'pages/Checkout/constants/checkout.constant';
import { transfromGetCart } from 'pages/Cart/redux/actions/newCart.actions';
import { createGrowthBookInstance } from '@mafc/appshell';
import ICreditCard from 'pages/Checkout/components/Payment/CreditCard.interface';
import { setOrderTotalCurrency } from 'common/OrderSummary/redux/orderSummary.action';
import TrackerCheckout from 'utils/Tracker.checkout';

export const getPaymentMode = (state: IAppState) => {
  const {
    checkout: { payment: { paymentMode: { type = '' } = {} } = {} },
  } = state;
  return type;
};

// https://mafretail.atlassian.net/browse/CHEC-1717
// will remove false  when we have to fetch save cards for web
export const fetchCreditCards = (type: string = '') => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const currentState = getState();
  const { paymentDetails, modes } = currentState.checkout.payment;

  const growthbook = createGrowthBookInstance(
    {
      ...currentState.appConfig.growthBook,
    },
    currentState
  );

  const { value: isSavedCardEnabled } = growthbook.feature('checkout.saved_cards.enabled');
  const { value: isCheckoutDotComSupported } = growthbook.feature('checkout-dot-com-supported');

  if (type !== '' && type !== EPaymentMethods.CREDIT_CARD) {
    setPaymentMode(type, { saved: false })(dispatch, getState);
  } else if (type === EPaymentMethods.CREDIT_CARD || type === '') {
    if (isSavedCardEnabled && type === EPaymentMethods.CREDIT_CARD) {
      dispatch({
        type: CheckoutActionTypes.CHECKOUT_FETCH_CREDIT_CARDS,
        payload: {
          isAbsUrl: true,
          method: EApiMiddlewareMethod.GET,
          url: SAVED_CREDIT_CARDS_URL_CYBERSOURCE,
          params: {
            paymentType: isCheckoutDotComSupported
              ? GATEWAY_TYPE.CHECKOUT
              : GATEWAY_TYPE.CYBER_SOURCE,
          },
          onSuccess: (response: { [key: string]: any }) => {
            const { data, result } = response;
            if (result) {
              const cards =
                data.length > 0
                  ? data.filter((card: ICreditCard) => card.isDefault && !card.isExpired)
                  : [];
              if (cards.length > 0) {
                setPaymentMode(EPaymentMethods.CREDIT_CARD, { ...cards[0], saved: true })(
                  dispatch,
                  getState
                );
              } else {
                let paymentMethod: any = EPaymentMethods.CREDIT_CARD;
                if (modes.length > 0) {
                  paymentMethod = modes[0];
                }
                setPaymentMode(paymentMethod, { ...paymentDetails, saved: false })(
                  dispatch,
                  getState
                );
              }
              dispatch({
                type: CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS,
                payload: {
                  payments: data,
                },
              });
            }
          },
          onFailure: () => {
            if (type === EPaymentMethods.CREDIT_CARD || type === '') {
              setPaymentMode(EPaymentMethods.CREDIT_CARD, { ...paymentDetails, saved: false })(
                dispatch,
                getState
              );
            } else {
              dispatch(storePaymentDetails(type, {}));
            }
          },
        },
      });
    } else {
      setPaymentMode(EPaymentMethods.CREDIT_CARD, { ...paymentDetails, saved: false })(
        dispatch,
        getState
      );
    }
  }
};

export const fetchPaymentModes = ({
  currentPaymentMode,
  isFetchCreditCards = false,
  setDefaultMode = false,
  onSuccessCallback,
}: {
  currentPaymentMode?: EPaymentMethods;
  isFetchCreditCards?: boolean;
  setDefaultMode?: boolean;
  onSuccessCallback?: () => void;
} = {}) => (dispatch: Dispatch, getState: () => IAppState) => {
  const { areaCode = '' } = getState().appConfig;
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );

  const { value: isCardOnDelivery } = growthbook.feature('card_on_delivery');
  const { value: isSavedCardEnabled } = growthbook.feature('checkout.saved_cards.enabled');
  dispatch({
    type: CheckoutActionTypes.CHECKOUT_FETCH_PAYMENT_MODE,
    payload: {
      method: EApiMiddlewareMethod.GET,
      url: GET_PAYMENT_MODE_URL,
      params: {
        areacode: areaCode,
        cartId: getCartId(getState()),
      },
      onSuccess: (res: any) => {
        const { data: paymentMethods, secondary = [] } = res;
        batch(() => {
          let data = paymentMethods;
          if (!isCardOnDelivery && paymentMethods) {
            data = paymentMethods.filter(
              (item: string) => item !== EPaymentMethods.CARD_ON_DELIVERY
            );
          }
          const paymentMode = data ? data[0] : '';
          if (paymentMode !== '') {
            dispatch(storePaymentMode(paymentMode) as any);
          }

          if (isSavedCardEnabled && data.includes(EPaymentMethods.CREDIT_CARD)) {
            dispatch(fetchCreditCards(EPaymentMethods.CREDIT_CARD) as any);
          } else {
            dispatch(fetchCreditCards(paymentMode) as any);
          }
          // if (
          //   isSavedCardEnabled &&
          //   data.includes(EPaymentMethods.CREDIT_CARD) &&
          //   (data.includes(EPaymentMethods.COD) || data.includes(EPaymentMethods.CARD_ON_DELIVERY))
          // ) {
          //   dispatch(fetchCreditCards(EPaymentMethods.CREDIT_CARD) as any);
          // } else if (isFetchCreditCards) {
          //   dispatch(fetchCreditCards(paymentMode) as any);
          // } else if (currentPaymentMode && !data.includes(currentPaymentMode)) {
          //   setDefaultMode
          //     ? dispatch(fetchCreditCards(paymentMode) as any)
          //     : dispatch(storePaymentDetails('', {})); // checkout
          // }
          dispatch({
            type: CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS,
            payload: { modes: data, secondary },
          });
          if (onSuccessCallback) {
            onSuccessCallback();
          }
        });
      },
      onFailure: () => {
        batch(() => {
          const {
            countryConfiguration: { defaultPaymentMode = EPaymentMethods.CREDIT_CARD } = {},
          } = getState().appConfig;
          if (defaultPaymentMode !== '') {
            dispatch(storePaymentMode(defaultPaymentMode) as any);
          }
          dispatch(storePaymentDetails(defaultPaymentMode, {}));
          dispatch({
            type: CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS,
            payload: { modes: [defaultPaymentMode], secondary: [] },
          });
          if (onSuccessCallback) {
            onSuccessCallback();
          }
        });
      },
    },
  });
};
const deliverySlotDrawerCallback = (
  currentStore: any,
  getState: () => IAppState,
  dispatch: any
) => {
  const { countryConfiguration = {} } = currentStore.appConfig;
  const { checkout: { isSurchargeSupported = false } = {} } = countryConfiguration;

  if (!currentStore.deliverySlot.defaultDrawer.data && isSurchargeSupported) {
    deliverySlotDrawerOperation(getState(), dispatch);
  }
};
export const setPaymentMode = (
  paymentMode: string,
  paymentDetails: any,
  binCode?: string,
  showLoader: boolean = true
) => (dispatch: Dispatch, getState: () => IAppState) => {
  const { areaCode = '' } = getState().appConfig;
  const { disableBinCode = false } = FEATURES_CONFIG.settings;
  let binCodeParam = '';
  if (!disableBinCode) {
    if (paymentDetails.id) {
      binCodeParam = paymentDetails.bin;
    } else if (binCode) {
      binCodeParam = binCode;
    } else if (paymentDetails.card_number) {
      binCodeParam = extractBinFromCard(paymentDetails.card_number);
    }
  }
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );
  const isCartBundlingEnabled = growthbook.feature('common.cart_bundling.enabled').on;
  const url = PAYMENT_MODE_URL + (isCartBundlingEnabled ? '&cp=true' : '');

  dispatch({
    type: CheckoutActionTypes.CHECKOUT_SET_PAYMENT_MODE,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url,
      params: {
        areacode: areaCode,
        cartId: getCartId(getState()),
        binCode: binCodeParam !== '' ? binCodeParam : undefined,
      },
      enableProgress: showLoader,
      data: {
        paymentMode,
      },
      onSuccess: (data: { [key: string]: any }) => {
        const currentStore = getState();

        batch(() => {
          if (
            currentStore.cart &&
            currentStore.cart.cartData &&
            currentStore.cart.cartData.appliedVouchers &&
            data.appliedVouchers.length < currentStore.cart.cartData.appliedVouchers.length
          ) {
            TrackerCheckout.voucherRemovedWhenPaymentMethodChanged(
              currentStore.cart.cartData.appliedVouchers[0].voucherCode
            );
          }
          const standardFoodShipment = extractShipment(
            data.shipments,
            EShipmentTypes.STANDARD_FOOD
          );
          const currentDataHasStandardFood = isNonEmptyArray(standardFoodShipment.entries);
          const hasDeliverySlot = isNonEmptyObject(standardFoodShipment.deliverySlot);
          if (currentDataHasStandardFood && !hasDeliverySlot) {
            const userSelectedAddress = getSelectedAddress(currentStore);
            dispatch(
              fetchSelectedSlot(
                getAreaCode(currentStore, userSelectedAddress),
                getCartId(currentStore),
                () => {
                  deliverySlotDrawerCallback(currentStore, getState, dispatch);
                }
              ) as any
            );
          } else if (hasDeliverySlot) {
            dispatch(setShipmentDeliverySlot(standardFoodShipment.deliverySlot) as any);
          }
          const transformedData = transfromGetCart(data);
          updateCart(dispatch, transformedData);
          if (hasDeliverySlot) {
            deliverySlotDrawerCallback(currentStore, getState, dispatch);
          }
          dispatch(
            storePaymentDetails(
              data.paymentMode || paymentMode,
              !FEATURES_CONFIG.settings.enableHostedCreditCard ? paymentDetails : {}
            )
          );
          if ((data.paymentMode || paymentMode) !== EPaymentMethods.CREDIT_CARD) {
            dispatch(
              setOrderTotalCurrency({
                orderTotalCurrency: '',
              })
            );
          }
        });
        // at every time successCallback executes we need to read currentStore
      },
      onFailure: (msg: any, error: any) => {
        const { response } = error || { response: '' };
        const { data } = response || { data: [] };
        const { reason } = (data && data[0]) || { reason: '' };
        batch(() => {
          dispatch(
            storePaymentDetails(
              paymentMode,
              !FEATURES_CONFIG.settings.enableHostedCreditCard ? paymentDetails : {}
            )
          );
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message:
                reason !== CHECKOUT_ERRORS_TYPE.PAYMENT_MODE_NOT_AVAILABLE
                  ? msg
                  : i18n.t('checkoutError.PaymentModeNotAvailable'),
            })
          );
        });
      },
    },
  });
};
const deliverySlotDrawerOperation = (store: any, dispatch: any) => {
  const { shipments = [], cart = {} } = store;
  const shipmentType =
    shipments && shipments.find((shipment: any) => shipment.shipmentType === 'STANDARD_FOOD');
  if (shipmentType && cart && cart.cartData) {
    const { posNo, zone } = getAllData(cart.cartData).shipments[0];
    dispatch(deliverySlotDrawerDefaultState({ posNo, zone }) as any);
  }
};
export const storePaymentDetails = (paymentMode: string, paymentDetails: any) => {
  return {
    type: CHECKOUT_SET_PAYMENT_MODE_SUCCESS,
    payload: {
      paymentMode,
      paymentDetails,
    },
  };
};

export const togglePaymentLoading = (loading: boolean) => {
  return {
    type: CheckoutActionTypes.CHECKOUT_PAYMENT_LOADING,
    payload: { loading },
  };
};

export const storePaymentMode = (paymentMode: string) => {
  return {
    type: CartActionTypes.CART_PAYMENT_MODE_SET,
    payload: { paymentMode },
  };
};
