export enum EBlankOrderDataActions {
  GET_BLANK_RETURN_LIST_REQ = 'PostOrder/GET_BLANK_RETURN_LIST_REQ',
  GET_BLANK_RETURN_LIST = 'PostOrder/GET_BLANK_RETURN_LIST',
}
export interface IProducts {
  imgUrl: string;
  name: string;
  sku: string;
  orderedQty: string;
  shippedQty: string;
  deliveredQty: string;
  returnQty: string;
  unitPrice: string;
  vatPercentage: string;
  uom: string;
  reason: string;
}
export interface ICharges {
  type: string;
  value: string;
  label: string;
  currency: string;
}

export interface IReturnListDetails {
  orderID: string;
  orderDate: string;
  awb: string;
  charges: ICharges[];
  consignmentID: string;
  returnID: string;
  returnDate: string;
  reason: string;
  products: IProducts[];
  status: any;
  method: any;
  refund?: any;
  pickUpInfo: ILocation;
}

export interface ILocation {
  date: string;
  addressString: string;
  location: any;
}
export interface IReturnListResponse {
  returns: IReturnListDetails[];
  pagination: any;
}
export interface IReturnEligibility {
  isProductActive: boolean;
  isReturnable: boolean;
  maxReturnableQTY: number;
  productCode: string;
  productUOM: string;
  returnableBy: string;
  returnableQTY: number;
}
export interface IReturnList {
  returns: IReturnListDetails[];
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
}
export enum ERefundMethods {
  wallet = 'WALLET',
  creditcard = 'CREDITCARD',
}
export enum ProductUomType {
  kg = 'kg',
  Kilo = 'Kilo',
  pieces = 'pieces',
  kilo = 'Kilo',
}
export const RETURN_QTY = 'returnQTY';
export const REASON = 'reason';
export const REASON_ID = 'reasonID';
export const RETURN_DATA = 'data';
export const RETURN_IMG = 'returnImg';

export enum ERefundMethodTypes {
  creditcard = 'creditcard',
  share = 'share',
  loyalty = 'loyalty',
  cod = 'COD',
  voucher = 'Voucher',
}
export enum EReturnMethods {
  pickup = 'PICKUP',
}
export enum EReturnRequestStatus {
  returnRequested = 'Return Requested',
  returnCreated = 'Return Created',
  returnPicked = 'Return Picked up',
  itemReceived = 'Item Received',
  returnApproved = 'Return Approved',
  refundInitiated = 'Refund Initiated',
  refunded = 'Refunded',
  returnRejected = 'Return Rejected',
  refundRejected = 'Refund Rejected',
}
