import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import HeaderStyled from './styles/Header.styles';
import { HeaderNewJourney } from '@mafc/common';
import { IAppState } from 'config/redux/reducers';
import Constants from 'config/constants';
import { initStorageManager } from 'utils/customStorage.util';
import attemptLoading from 'utils/combativeLoader';
import useCartRevampEnabled from 'hooks/useCartRevampEnabled';
import { getCartPageUrl } from 'utils/cartRevamp.util';

const CheckoutHeader = lazy(() =>
  attemptLoading(() =>
    import(
      /* webpackChunkName: "CheckoutHeader" */
      './CheckoutHeader'
    )
  )
);

const storage = initStorageManager(() => localStorage);

interface Props {
  isCheckoutPage: boolean;
  isOrderConfirmationPage: boolean;
}
export default function({ isCheckoutPage, isOrderConfirmationPage }: Props) {
  const { newCart } = useSelector((initialAppState: IAppState) => {
    return { cart: initialAppState.cart, newCart: initialAppState.newCart };
  });

  const isCartRevampEnabled = useCartRevampEnabled();
  const { storeId, language } = useSelector((state: IAppState) => state.appConfig);
  const NEW_CART_PAGE = getCartPageUrl(storeId, language, isCartRevampEnabled);
  const onLoginClick = () => {
    const { cartData } = newCart;
    if (!!cartData.totalItems) {
      storage.setItem(Constants.guestCartQuantity, JSON.stringify(cartData.totalItems));
    }
  };
  const getComponent = () => {
    if (isCheckoutPage || isOrderConfirmationPage) {
      return (
        <HeaderStyled>
          <CheckoutHeader isOrderConfirmationPage={isOrderConfirmationPage} />
        </HeaderStyled>
      );
    } else {
      return (
        <HeaderStyled>
          <HeaderNewJourney
            isHeaderApiCall={false}
            isMarketingStrip={false}
            isBottomPane={false}
            isTopPane={false}
            isDealsMenu={false}
            isFixedHeaderOnscroll
            isLanguageChangeEnabled={false}
            loginObj={{
              loginRedirection: `${NEW_CART_PAGE}/merge`,
              onLoginClick,
            }}
          />
        </HeaderStyled>
      );
    }
  };
  return (
    <Suspense fallback={null}>
      <>{getComponent()}</>
    </Suspense>
  );
}
