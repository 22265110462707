enum EPaymentMethods {
  NEW_CARD = 'new_card',
  CREDIT_CARD = 'creditcard',
  COD = 'cod',
  CASHBACK = 'cashback',
  DEBIT_CARD = 'debitcard',
  MOBILE_WALLET = 'mobilewallet',
  MPESA = 'mpesa',
  VOUCHER = 'voucher',
  CARD_ON_DELIVERY = 'cardondelivery',
  SAVED_CARD = 'savedcard',
}

export enum ESelectedPaymentMethods {
  COD = 'COD',
  CASHBACK = 'SECONDARY',
  CARD = 'CARD',
}

export default EPaymentMethods;
