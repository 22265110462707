import IAction from 'config/redux/actions/interface';
import CheckoutActionTypes from '../Checkout.types';
import { ICheckoutState } from '.';

export const setStepStatus = (state: ICheckoutState, action: IAction) => ({
  ...state,
  stepsProgress: {
    ...state.stepsProgress,
    ...action.payload,
  },
});

export default {
  [CheckoutActionTypes.CHECKOUT_SET_STEP_STATUS]: setStepStatus,
};
