import { Dispatch } from 'redux';

import IAction from 'config/redux/actions/interface';
import CartActionTypes from 'pages/Cart/redux/Cart.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { ENABLE_SUBSTITUTION } from 'pages/Cart/constants/apis.constant';

export const enableSubstitution = ({
  cartEntries,
}: {
  cartEntries: Array<{ entryNumber: number; isSubstitute: boolean }>;
}) => (dispatch: Dispatch) => {
  dispatch({
    type: CartActionTypes.CART_ENABLE_PRODUCT_SUBSTITUTION,
    payload: {
      data: { substitutionRequestData: cartEntries },
      method: EApiMiddlewareMethod.POST,
      params: {
        cartEntries,
      },
      url: ENABLE_SUBSTITUTION,
      onSuccess: ({ result }: any) => {
        if (result !== 'success') {
          dispatch(
            updateItemSubstitution({ cartEntries: handleSubstitutionFailure({ cartEntries }) })
          );
        }
      },
      onFailure: () => {
        dispatch(
          updateItemSubstitution({ cartEntries: handleSubstitutionFailure({ cartEntries }) })
        );
      },
    },
  });
};

export const updateItemSubstitution = ({
  cartEntries,
}: {
  cartEntries: Array<{ entryNumber: number; isSubstitute: boolean }>;
}): IAction => {
  return {
    type: CartActionTypes.CART_UPDATE_PRODUCT_SUBSTITUTION_STATE,
    payload: cartEntries,
  };
};

export const handleSubstitutionFailure = ({
  cartEntries,
}: {
  cartEntries: Array<{ entryNumber: number; isSubstitute: boolean }>;
}) => {
  return cartEntries.map(({ entryNumber, isSubstitute }) => ({
    entryNumber,
    isSubstitute: !isSubstitute,
  }));
};
