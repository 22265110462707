import { IAppState } from '@mafc/common';

const isPaymentV2Enabled = (state: IAppState) => {
  const {
    appConfig: { countryConfiguration },
  } = state;

  const {
    paymentMethods: { isWebPaymentArchitectureV2Enabled },
  } = countryConfiguration || {
    paymentMethods: {
      isWebPaymentArchitectureV2Enabled: false,
    },
  };

  return isWebPaymentArchitectureV2Enabled;
};

export default isPaymentV2Enabled;
