import { IAddress } from 'services/user/types/user.types';
import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum EDeliverToActionTypes {
  DELIVER_TO_SET_CACHE = 'DeliverTo/SET_CACHE',
  DELIVER_TO_SET_ERROR = 'DeliverTo/SET_ERROR',
  DELIVER_TO_SET_LOADING = 'DeliverTo/SET_LOADING',
  DELIVER_TO_SET_VALUE = 'DeliverTo/SET_VALUE',
  DELIVER_TO_PUT_ADDRESS = 'DeliverTo/PUT_ADDRESS',
  DELIVER_TO_PUT_ADDRESS_COOKIE = 'DeliverTo/PUT_ADDRESS_COOKIE',
  DELIVERY_TO_SET_DATES_LOADING = 'DeliverTo/SET_DATES_LOADING',
  DELIVER_TO_RESET_VALUE = 'DeliverTo/RESET_VALUE',
}

export interface IDeliverTo {
  cache?: IAddress;
  error?: any;
  loading: boolean;
  value?: IAddress;
  deliveryDates?: any;
}

export type TDeliverToAction = IAction<
  Partial<IDeliverTo>,
  Exclude<
    EDeliverToActionTypes,
    EDeliverToActionTypes.DELIVER_TO_PUT_ADDRESS &
      EDeliverToActionTypes.DELIVER_TO_PUT_ADDRESS_COOKIE
  >
>;
export type TDeliverToDispatch = TDispatch<TDeliverToAction>;
