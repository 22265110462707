import { NewC4JourneyUtils } from '@mafc/common';
import { SHIPMENT_IDENTIFIRES } from 'pages/NewCart/constants/shipments.constants';
import { GTM_SHIPMENTS } from '../../src/pages/Checkout/constants/gtm.constant';
import { CookieUtils, GTMNowJourneyLabels, HelpersUtil, getStore } from '@mafc/common';
import { getHashedEmail } from './cookies.util';

export const getJourneySelection = () => {
  return CookieUtils.getNowJourneyCookie() === 'true'
    ? GTMNowJourneyLabels.now_items
    : GTMNowJourneyLabels.all_items;
};

export const getGTMShipmentType = (identifier: string) => {
  if (identifier === SHIPMENT_IDENTIFIRES.QCOMM) {
    return GTM_SHIPMENTS.QCOMM;
  } else if (identifier === SHIPMENT_IDENTIFIRES.EXPRESS) {
    return GTM_SHIPMENTS.EXPRESS;
  } else if (identifier === SHIPMENT_IDENTIFIRES.NONFOOD) {
    return GTM_SHIPMENTS.STNDARD_NON_FOOD;
  } else if (identifier === SHIPMENT_IDENTIFIRES.SLOTTED) {
    return GTM_SHIPMENTS.STNDARD_FOOD;
  } else if (identifier === SHIPMENT_IDENTIFIRES.DEFAULT) {
    return GTM_SHIPMENTS.ALL;
  } else {
    return GTM_SHIPMENTS.MARKETPLACE;
  }
};

export const getJourneySelectionParams = () => {
  const { serviceType, productType } = NewC4JourneyUtils.extractServiceInfoFromCookie();
  if (serviceType && productType) {
    const serviceTypeInLower = serviceType.toLowerCase();
    const productTypeInLower = productType.toLowerCase();

    return {
      product_type: productTypeInLower,
      service_type: serviceTypeInLower,
      journey_selection: `${serviceTypeInLower}_${productTypeInLower}`,
    };
  } else {
    return {
      journey_selection: getJourneySelection(),
    };
  }
};

export const getJourneySelectionParamsForItem = () => {
  const { serviceType, productType } = NewC4JourneyUtils.extractServiceInfoFromCookie();

  if (serviceType && productType) {
    const serviceTypeInLower = serviceType.toLowerCase();
    const productTypeInLower = productType.toLowerCase();

    return {
      item_level_product_type: productTypeInLower,
      item_level_service_type: serviceTypeInLower,
      item_level_journey_selection: `${serviceTypeInLower}_${productTypeInLower}`,
    };
  }
  return {};
};

export const getUserGTMProps = () => {
  const { appConfig, userData = {} } = getStore().getState();
  const { areaCode, defaultAreaCode, language, storeId, currency } = appConfig;

  const {
    selectedAddress: { town } = { town: null },
    firstName,
    cardNumber = '',
    nationality,
  } = userData;
  const userAreaCode = areaCode || defaultAreaCode;

  const city = userAreaCode && userAreaCode.includes('-') ? userAreaCode.split('-')[1].trim() : '';
  const deliveryCity = town || city;

  const hashedEmail = getHashedEmail();

  return {
    currency,
    currencyCode: currency, // some events may using currencyCode as well
    delivery_city: deliveryCity,
    delivery_area_code: userAreaCode,
    default_delivery_address: HelpersUtil.isDefaultArea(areaCode, defaultAreaCode)
      ? 'true'
      : 'false',
    hashed_email: hashedEmail,
    nationality_id: nationality,
    language,
    logged_in_status: !!firstName ? 'true' : 'false',
    market: storeId,
    module_name: 'c4_main',
    my_club_member_id: cardNumber,
    user_id: hashedEmail,
    unique_user_id: hashedEmail,
  };
};
