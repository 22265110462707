import { GET_VALIDATE_CART_ENTRIES_API } from 'pages/Checkout/constants/apis.constant';
import {
  EValidateCartActionTypes,
  TEntries,
  IValidateCart,
  TValidateCartAction,
  TValidateCartDispatch,
  EValidateCartEntryStockTypes,
} from 'pages/Checkout/types/validateCart.types';
import { TApiMiddlewareDispatch } from 'config/redux/middlewares/api.middleware.types';
import { IAddress } from 'services/user/types/user.types';

import { TDeliverToDispatch } from 'pages/Checkout/types/deliverTo.types';
// import { setDeliverToCache, deliverToPutAddress } from './delivery.action';

import { TScreenDispatch } from 'pages/Checkout/types/screen.types';
import { setScreenToCartList } from './screen.actions';
import { fetchPaymentModes } from './payment.action';
import { IAppState } from 'config/redux/reducers';
import { getAreaCode, getCartId } from 'pages/Cart/helpers/url.helpers';

export const setValidateCartLoading = (loading: IValidateCart['loading']): TValidateCartAction => ({
  type: EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_SET_LOADING,
  payload: { loading },
});

export const setValidateCartError = (error: IValidateCart['error']): TValidateCartAction => ({
  type: EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_SET_ERROR,
  payload: { error },
});

export const setValidateCartEntries = (payload: Partial<IValidateCart>): TValidateCartAction => ({
  type: EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES_SUCCESS,
  payload,
});

export const validateCartEntries = (
  address: IAddress,
  params: {
    isExpressCartValidate?: boolean;
    expressEnable?: boolean;
    successCallback?: () => void;
  } = {}
) => (
  dispatch: TApiMiddlewareDispatch & TValidateCartDispatch & TDeliverToDispatch & TScreenDispatch,
  getState: () => IAppState
) => {
  const store = getState();
  let url = GET_VALIDATE_CART_ENTRIES_API;
  if (params.isExpressCartValidate) {
    url = `${url}&expressEnable=${params.expressEnable}`;
  }

  dispatch(setValidateCartLoading(true));

  // fetchPaymentModes()(dispatch, getState);

  dispatch({
    type: EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES,
    payload: {
      enableProgress: false,
      onSuccess: ({ cartModifications }: { cartModifications: TEntries }) => {
        const modifiedEntries = [...cartModifications].filter(
          ({ statusCode }) =>
            statusCode === EValidateCartEntryStockTypes.UNAVAILABLE ||
            statusCode === EValidateCartEntryStockTypes.NO_STOCK ||
            statusCode === EValidateCartEntryStockTypes.EXPRESS_ONLY
        );

        const modifiedEntriesCount = modifiedEntries.length;

        if (modifiedEntriesCount) {
          dispatch(setScreenToCartList());
          // dispatch(setDeliverToCache(address));
          dispatch(
            setValidateCartEntries({
              entries: cartModifications,
              modifiedEntriesCount,
            })
          );
        } else if (params.isExpressCartValidate) {
          if (!params.expressEnable) {
            // express enable false means move to standard and this is when no items out of stock
            dispatch(setScreenToCartList());
            dispatch(
              setValidateCartEntries({
                entries: cartModifications,
                modifiedEntriesCount,
              })
            );
          } else if (params.successCallback) {
            params.successCallback();
          }
        } else {
          // dispatch(deliverToPutAddress(address) as any);
          dispatch(
            setValidateCartEntries({
              entries: [],
              modifiedEntriesCount: 0,
            })
          );
        }
      },
      onFailure: (errorMessage: any) => dispatch(setValidateCartError(errorMessage)),
      onFinally: () => dispatch(setValidateCartLoading(false)),
      params: {
        areacode: getAreaCode(store, address),
        cartId: getCartId(store),
      },
      url,
    },
  });

  if (!params.isExpressCartValidate) {
    fetchPaymentModes()(dispatch, getState);
  }
};
