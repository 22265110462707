import EApiActionTypes from 'config/redux/types/api.type';

enum CartActionTypes {
  CART_DELETE_PRODUCT = 'Cart/DELETE_ENTRY',
  CART_FETCH_CART_DATA = 'Cart/FETCH_DATA',
  CART_FETCH_CART_LOYALTY_POINTS = 'Cart/FETCH_LOYALTY_POINTS',
  CART_ENABLE_PRODUCT_SUBSTITUTION = 'Cart/ENABLE_PRODUCT_SUBSTITUTION',
  CART_UPDATE_PRODUCT_SUBSTITUTION_STATE = 'Cart/UPDATE_PRODUCT_SUBSTITUTION_STATE',
  CART_TOGGLE_ERROR = 'Cart/TOGGLE_ERROR',
  CART_ADD_TO_CART = 'CART_ADD_TO_CART',
  CART_ADD_UPDATE_WARRANTY = 'CART_ADD_UPDATE_WARRANTY',
  CART_POST_MERGE_CARTS = 'Cart/POST_MERGE_CARTS',
  CART_EARNED_POINTS = 'Cart/CART_EARNED_POINTS',
  CART_PAYMENT_MODE_SET = 'Cart/CART_PAYMENT_MODE_SET',
  CART_RESET_PACKING_CHARGE = 'Cart/CART_RESET_PACKING_CHARGE',
}

export default CartActionTypes;

const CART_FETCH_CART_DATA_START = `${CartActionTypes.CART_FETCH_CART_DATA}${EApiActionTypes.API_START}`;
const CART_FETCH_CART_DATA_SUCCESS = `${CartActionTypes.CART_FETCH_CART_DATA}${EApiActionTypes.API_SUCCESS}`;
const CART_FETCH_CART_DATA_END = `${CartActionTypes.CART_FETCH_CART_DATA}${EApiActionTypes.API_END}`;

const CART_DELETE_PRODUCT_SUCCESS = `${CartActionTypes.CART_DELETE_PRODUCT}${EApiActionTypes.API_SUCCESS}`;

const CART_FETCH_CART_LOYALTY_POINTS_START = `${CartActionTypes.CART_FETCH_CART_LOYALTY_POINTS}${EApiActionTypes.API_START}`;
const CART_FETCH_CART_LOYALTY_POINTS_SUCCESS = `${CartActionTypes.CART_FETCH_CART_LOYALTY_POINTS}${EApiActionTypes.API_SUCCESS}`;
const CART_FETCH_CART_LOYALTY_POINTS_END = `${CartActionTypes.CART_FETCH_CART_LOYALTY_POINTS}${EApiActionTypes.API_END}`;

const CART_ADD_TO_CART_START = `${CartActionTypes.CART_ADD_TO_CART}${EApiActionTypes.API_START}`;
const CART_ADD_TO_CART_SUCCESS = `${CartActionTypes.CART_ADD_TO_CART}${EApiActionTypes.API_SUCCESS}`;
const CART_ADD_TO_CART_END = `${CartActionTypes.CART_ADD_TO_CART}${EApiActionTypes.API_END}`;

const CART_ADD_UPDATE_ITEM_WARRANTY_START = `${CartActionTypes.CART_ADD_UPDATE_WARRANTY}${EApiActionTypes.API_START}`;

const CART_UPDATE_RICH_RELEVANCE = `CART/UPDATE_RICH_RELEVANCE`;

export {
  CART_FETCH_CART_DATA_START,
  CART_FETCH_CART_DATA_END,
  CART_FETCH_CART_DATA_SUCCESS,
  CART_DELETE_PRODUCT_SUCCESS,
  CART_FETCH_CART_LOYALTY_POINTS_START,
  CART_FETCH_CART_LOYALTY_POINTS_SUCCESS,
  CART_FETCH_CART_LOYALTY_POINTS_END,
  CART_ADD_TO_CART_SUCCESS,
  CART_ADD_TO_CART_START,
  CART_ADD_TO_CART_END,
  CART_ADD_UPDATE_ITEM_WARRANTY_START,
  CART_UPDATE_RICH_RELEVANCE,
};
