import { CardType } from 'network/response/ICart';
import { visaRegEx, mastercardRegEx, creditCardExpiryDate } from 'pages/Checkout/constants/regexps';
import { isExisty } from 'utils/helpers.util';
import EPaymentMethods from '../PamentMethods.enum';

export const isValidCreditCard = (value: string) => {
  const cardNum = value.split(' ').join('');
  // Card length check
  if (cardNum.length !== 16) {
    return false;
  }

  // The Luhn Algorithm.
  let nCheck: number = 0;
  let bEven: boolean = false;

  for (let n = cardNum.length - 1; n >= 0; n--) {
    const cDigit = cardNum.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven) {
      const doubleDigit = (nDigit *= 2);
      if (doubleDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
};

export const getCardType = (value: string) => {
  const cardNum = value.split(' ').join('');
  return visaRegEx.test(cardNum) ? '001' : mastercardRegEx.test(cardNum) ? '002' : '';
};

export const validateExpiryDate = (expiryDate: string, updateExpiry?: any) => {
  // ensure basic format is correct

  if (expiryDate.match(creditCardExpiryDate)) {
    const [month, year] = expiryDate.split('/');

    const expiry = new Date(Number(`20${year}`), Number(month));
    const current = new Date();

    const isValid = expiry.getTime() > current.getTime();
    if (updateExpiry && isValid) {
      updateExpiry(expiryDate);
    }
    return isValid;
  }
  return false;
};

export const validateCVV = (cvv: string) => {
  return cvv.length > 2;
};

export const validateCardHolderName = (cnm: string) => {
  const regEx = /^[a-zA-Z][a-zA-Z\s]*$/;
  return cnm.length > 2 && regEx.test(cnm);
};

export const formatCardNumber = (cardNumber: string) => {
  return isExisty(cardNumber) ? cardNumber.replace(/ +/g, '') : '';
};

export const formatExpiryDate = (expiryDate: string) => {
  if (isExisty(expiryDate)) {
    let formatedExpiryDate = expiryDate.split('/');
    if (expiryDate.includes('-')) {
      formatedExpiryDate = expiryDate.split('-');
    }
    // const formatedExpiryDate = expiryDate.split('/');
    // replace year fromat from two digits to four 22 -> 2022
    formatedExpiryDate[1] = `20${formatedExpiryDate[1]}`;
    return formatedExpiryDate.join('-');
  }
  return '';
};

export const isPaymentModeCardType = (mode: string) =>
  mode === EPaymentMethods.CREDIT_CARD || mode === EPaymentMethods.DEBIT_CARD;

export const extractBinFromCard = (cardNumber: string) => {
  return cardNumber.replace(/\s+/g, '').substring(0, 6);
};

export const getSelectedCardBin = (paymentDetails: any) => {
  let binCode = '';
  if (paymentDetails.id) {
    binCode = paymentDetails.bin;
  } else if (paymentDetails.newCardDetails && paymentDetails.newCardDetails.card_number) {
    binCode = extractBinFromCard(paymentDetails.newCardDetails.card_number);
  } else if (paymentDetails.card_number) {
    binCode = extractBinFromCard(paymentDetails.card_number);
  }
  return binCode;
};

export const getCardTypeForIcon = (cardNumber: string) => {
  if (cardNumber.charAt(0) === '4') {
    return CardType.visa;
  }
  if (cardNumber.charAt(0) === '2' || cardNumber.charAt(0) === '5') {
    return CardType.master;
  }
};
