import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import {
  EValidateCartActionTypes,
  TValidateCartAction,
} from 'pages/Checkout/types/validateCart.types';

export const updateValidateCartState = (state: ICheckoutState, action: TValidateCartAction) => {
  return {
    ...state,
    delivery: {
      ...state.delivery,
      validateCart: {
        ...state.delivery.validateCart,
        ...action.payload,
      },
    },
  };
};

export default {
  [EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_SET_LOADING]: updateValidateCartState,
  [EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_SET_ERROR]: updateValidateCartState,
  [EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES_SUCCESS]: updateValidateCartState,
};
