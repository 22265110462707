import EBinDiscountBannerTypes from './binDiscountBanner.type';
import IAction from 'config/redux/actions/interface';

export interface IBinBanner {
  id: string;
  title: string;
  subtitle: string;
  backgroundColor: string;
  titleFontColor: string;
  subtitleFontColor: string;
  imageUrl: string;
}

export interface IBinDiscountBannerState {
  data: IBinBanner[];
}

export const initialIBinDiscountBannerState = {} as IBinDiscountBannerState;

const binDiscountBannerReducer = (
  state: IBinDiscountBannerState = initialIBinDiscountBannerState,
  action: IAction
) => {
  if (action.type === EBinDiscountBannerTypes.BIN_DISCOUNT_BANNER_SET_DATA_SUCCESS) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

export default binDiscountBannerReducer;
