import IAction from 'config/redux/actions/interface';
import ELoyaltyPointsTypes, { CHECKOUT_GET_LOYALTY_POINTS_SUCCESS } from './LoyaltyPoints.types';
export interface ILoyaltyPointState {
  couponsCount?: number;
  vouchersCount?: number;
  isLoading: boolean;
  threshold: number;
  coupons: any[];
  isShare: boolean;
  totalPoints: string;
  totalPointsValue: number;
  vouchers: any[];
  isError: boolean;
  errorMsg: string;
  isThresholdReached: boolean;
  isToggleSwitchedOn: boolean;
}

export interface IUserLoyaltyState extends ILoyaltyPointState {
  currency: string;
  customerId?: string;
}

export const loyaltyPointsInitialState = {
  coupons: [],
  isShare: false,
  totalPoints: '',
  totalPointsValue: 0,
  vouchers: [],
  isLoading: false,
  currency: '',
  threshold: 25,
  isError: false,
  errorMsg: '',
  isThresholdReached: false,
  isToggleSwitchedOn: false,
};

const LoyaltyPointReducer = (
  state: IUserLoyaltyState = loyaltyPointsInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case CHECKOUT_GET_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case ELoyaltyPointsTypes.CHECKOUT_LOYALTY_TOGGLE_SWITCH:
      return {
        ...state,
        isToggleSwitchedOn: payload,
      };
    default:
      return state;
  }
};
export default LoyaltyPointReducer;
