import { IAppState } from 'config/redux/reducers';
import { getAppConfig } from 'helpers/apigee.helpers';
import { TotalPrice } from 'network/response/ICart';
import { getUserEmailFromCookie } from 'utils/cookies.util';

import getSelectedAddress from 'utils/getSelectedAddress.util';
import getFormattedFullAddress from '../components/Delivery/helpers/getFormattedFullAddress';
import { formatCardNumber, formatExpiryDate } from '../components/Payment/helpers/payment.helpers';

const FULL_CHARGE_CURRENCY = 'BIF,CLF,DJF,GNF,ISK,JPY,KMF,KRW,PYG,RWF,UGX,VUV,VND,XAF,XOF,XPF';
const THOUSAND_CHARGE_CURRENCY = 'BHD,IQD,JOD,KWD,LYD,OMR,TND';

const getTransactionType = (fieldsMap: any) => {
  const transactionType = fieldsMap.entry.find((field: any) => field.key === 'transaction_type');
  return transactionType ? transactionType.value : '';
};
export const getCheckoutDotComParams = (state: IAppState, orderData: any, guid: string) => {
  const address = getSelectedAddress(state);
  const currency = getAppConfig(state, ['currency']);
  const storeId = getAppConfig(state, ['storeId']);
  const isCxEnabled = getAppConfig(state, ['features', 'isLocationCXEnable']);

  const fullAddress = getFormattedFullAddress(address, isCxEnabled);

  const transactionFieldValue = getTransactionType(orderData.fieldsMap);
  const { firstName, name = '' } = state.user;
  return {
    bill_to_address_line1: fullAddress,
    fromCheckout: true,
    currency,
    bill_to_forename: firstName,
    bill_to_surname: name.replace(firstName, '').trim(),
    bill_to_address_country: 'US',
    bill_to_address_state: 'CA',
    reference_number: orderData.orderNumber,
    saveAsDefault: false,
    transaction_uuid: guid,
    isSaved: false,
    bill_to_address_postal_code: '94043',
    bill_to_address_city: address.town,
    transaction_type: transactionFieldValue,
    siteId: storeId,
  };
};

export const getDataToBeEncrypt = (
  cardDetails: any,
  totalPriceWithTax: TotalPrice,
  fieldsMap: any
) => {
  const { card_expiry_date, card_cvn, card_number, card_type } = cardDetails;
  const { value, currencyIso } = totalPriceWithTax;
  const transactionFieldValue = getTransactionType(fieldsMap);

  let amount = '0';
  if (!transactionFieldValue.includes('authorization')) {
    amount = convertOrderAmoutOnCurrencyBasis(value, currencyIso).toString();
  }
  return {
    card_cvn,
    card_type,
    card_number: formatCardNumber(card_number),
    card_expiry_date: formatExpiryDate(card_expiry_date),
    bill_to_email: getUserEmailFromCookie(),
    amount,
  };
};

export const convertOrderAmoutOnCurrencyBasis = (amount: number, currency: string) => {
  let convertedAmount;
  if (FULL_CHARGE_CURRENCY.includes(currency)) {
    return amount;
  }
  if (THOUSAND_CHARGE_CURRENCY.includes(currency)) {
    convertedAmount = amount * 1000;
  } else {
    convertedAmount = amount * 100;
  }
  return convertedAmount.toFixed(0);
};
