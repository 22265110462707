import { getGuestSessionToken, getGUID, getUserTokenFromCookie } from 'utils/cookies.util';
import { updateURLForGuestSession } from 'utils/replaceUrlVariables';
import Constants from '../../constants';
import { API_HEADERS_COMMON_CONFIG } from 'config/Api.config';
import axios from 'axios';
import CONSTANTS from 'config/constants';
export default async ({
  requestUrl,
  isAbsUrl = false,
  apiOptions,
  apiVersion = 2,
}: {
  requestUrl: string;
  isAbsUrl?: boolean;
  apiOptions: any;
  apiVersion?: number;
}) => {
  let apiRequestURL: string = requestUrl;
  if (!isAbsUrl) {
    axios.defaults.baseURL = `/v${apiVersion}`;
  } else {
    axios.defaults.baseURL = '/';
  }
  // Sets request "Headers" common attributes

  const store = (window as any).__REDUX_STORE__;
  let appConfig;
  if (store) {
    appConfig = store.getState().appConfig;
    const { userCredentials, storeId } = appConfig;
    const { authToken = '', token = '', userId = '' } = userCredentials || {
      authToken: '',
      token: '',
      userId: '',
    };
    const userToken = authToken || token;
    // Below code added in scenario when user is not logged in and he has added some records in cart and after clicking on place order user will redirect on cart screen but there appConfig is not setup
    axios.defaults.headers.common = {
      ...API_HEADERS_COMMON_CONFIG,
      storeId,
      userId: decodeURIComponent(userId),
      token: userToken,
    };
  } else {
    axios.defaults.headers.common = API_HEADERS_COMMON_CONFIG;
  }

  try {
    if (!getUserTokenFromCookie()) {
      apiRequestURL = updateRequestParamURL(requestUrl);
    }
    const { data } = await axios.request({
      url: apiRequestURL,
      ...apiOptions,
    });
    return Promise.resolve({ data });
  } catch (exception) {
    return Promise.reject(exception);
  }
};

export const updateRequestParamURL = (requestUrl: string) => {
  if (!getUserTokenFromCookie()) {
    // if not logged in start guest session
    const guid = getGUID(); // get cart id from cookie
    const guestToken = getGuestSessionToken();
    if (guid && guestToken !== '') {
      const updatedUrl = updateURLForGuestSession(requestUrl, guid);
      axios.defaults.headers.common.token = axios.defaults.headers.common.token || guestToken;
      axios.defaults.headers.common.userId = Constants.guestUserID.toLowerCase();
      return updatedUrl;
    }
    if (!guid) {
      // means we have empty cart
      throw new Error(CONSTANTS.guidUnavailable);
    }
  }
  return requestUrl;
};
