import {
  CART_TOGGLE_ERROR,
  FETCH_CART_DATA_SUCCESS,
  FETCH_CART_TOGGLE_LOADING,
} from '../constants/cart.types';

export const cartToggleError = ({
  error = true,
  errorMessage = '',
}: {
  error?: boolean;
  errorMessage?: string;
}) => ({
  type: CART_TOGGLE_ERROR,
  payload: { error, errorMessage },
});

export const cartDataSuccess = (data: { [key: string]: any }) => ({
  type: FETCH_CART_DATA_SUCCESS,
  payload: data,
});

export const cartToggleLoading = (data: boolean) => ({
  type: FETCH_CART_TOGGLE_LOADING,
  payload: data,
});
