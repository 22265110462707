import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '@mafc/trolly';

import calculateHeaderHeight, {
  CHECKOUT_HEADER_HEIGHT,
} from 'common/Header/constants/headerHeight.constant';
import {
  NEW_CHECKOUT_PAGE,
  MPGS_3DS_VERIFICATION,
  ORDER_CONFIRMATION,
  ORDER_DETAILS,
  RETURN_DETAILS,
} from 'config/Urls.config';
import { isCurrentPage } from 'utils/url.util';

import { MainContainer } from './PageLayout.styles';
import Header from 'common/Header';
import Notification from 'common/Notification';
import { Footer } from '@mafc/common';
import PageLoader from 'common/PageLoader';

const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const isCheckoutPage = isCurrentPage(NEW_CHECKOUT_PAGE, pathname);
  const is3DSRedirect = isCurrentPage(MPGS_3DS_VERIFICATION, pathname);
  const isOrderConfirmationPage = isCurrentPage(ORDER_CONFIRMATION, pathname);
  const isOrderDetailsPage = isCurrentPage(ORDER_DETAILS, pathname);
  const isReturnDetailsPage = isCurrentPage(RETURN_DETAILS, pathname);

  const getHeaderHeight = () => {
    if (isCheckoutPage) {
      return CHECKOUT_HEADER_HEIGHT;
    } else if (isOrderDetailsPage || isReturnDetailsPage) {
      return 0;
    } else {
      return calculateHeaderHeight(0);
    }
  };
  const orderDetailsMarginLg = isOrderDetailsPage || isReturnDetailsPage ? '120px' : 0;
  const orderDetailsMarginSmScreen = isOrderDetailsPage || isReturnDetailsPage ? '40px' : 0;

  return (
    <Flex flexDirection="column" height="100vh" pt={getHeaderHeight()}>
      <Header
        isCheckoutPage={isCheckoutPage || is3DSRedirect}
        isOrderConfirmationPage={isOrderConfirmationPage}
      />

      <MainContainer
        as="main"
        mt={[
          orderDetailsMarginSmScreen,
          orderDetailsMarginSmScreen,
          orderDetailsMarginSmScreen,
          orderDetailsMarginLg,
        ]}
      >
        <Notification />
        {children}
      </MainContainer>

      <Footer isCheckoutPage={isCheckoutPage || is3DSRedirect} />
      <PageLoader />
    </Flex>
  );
};

export default PageLayout;
