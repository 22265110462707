import { Dispatch } from 'redux';

import { IAppState } from 'config/redux/reducers';
import { ADD_TO_CART_API, UPDATE_QUANTITY_API } from 'pages/Cart/constants/apis.constant';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import EUpdateQuantityTypes from './updateQuantity.type';

import { showPageLoader, hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import { updateCart, fetchCartAction, getEarnedPoints } from 'pages/Cart/redux/actions';
import { hideNotification, showNotification } from 'common/Notification/redux/notification.action';

import Transform from 'utils/TransformData';
import Tracker from 'utils/Tracker';
import qtyRestrictionHandler from 'pages/Cart/redux/actions/qtyRestriction';
import { IQuantityUpdateSuccess } from '../UpdateQuantity.interface';
import i18n from 'config/localisation';
import {
  newDeleteCartEntry,
  newUpdateQuantityCartEntry,
} from 'pages/Cart/redux/actions/newCart.actions';
import APP_CONFIG from 'config/App.config';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { initStorageManager } from 'utils/customStorage.util';
import { COUNTRY_CODE_KEY } from 'pages/Cart/constants/cart.constants';
import {
  deleteCartEntry as deleteCartEntryV3,
  updateQuantityCartEntry as updateQuantityCartEntryV3,
} from 'pages/NewCart/redux/actions/updateQuantity.action';
import { createGrowthBookInstance } from '@mafc/appshell';

const storage = initStorageManager(() => sessionStorage);
export interface IUpdateQuantity {
  cartEntryNumber: number;
  qty: number;
  oldCartState?: any;
  isOffer?: boolean;
  serviceProductCode?: any;
  offerId?: string;
  loadingEntryNumber?: number;
  isNewC4Journey?: boolean;
  intent?: string;
  isOOS?: boolean;
  lm?: boolean;
  bundleId?: string;
  originalQuantity?: number;
  cp?: boolean;
}

export interface IUpdateQuantityTracking {
  actionType?: 'add' | 'remove';
  updateType?:
    | 'button_increment'
    | 'list_increment'
    | 'button_decrement'
    | 'list_decrement'
    | 'button_remove';
}

export const updateEntryToggleLoading = (cartEntryNumber: number | string, loading: boolean) => ({
  type: EUpdateQuantityTypes.CART_UPDATE_ENTRY_TOGGLE_LOADING,
  payload: { cartEntryNumber, loading },
});

export const deleteCartEntry = (
  dispatch: Dispatch,
  { cartEntryNumber, oldCartState = {}, cp }: IUpdateQuantity
) => {
  dispatch(showPageLoader());
  dispatch(hideNotification());
  dispatch({
    type: EUpdateQuantityTypes.CART_UPDATE_QUANTITY,
    payload: {
      enableProgress: false,
      method: EApiMiddlewareMethod.DELETE,
      onSuccess: (data: { [key: string]: any }) => {
        trackCartUpdation(false, oldCartState.cartData, cartEntryNumber);
        updateCart(dispatch, data);
        dispatch(getEarnedPoints() as any);
        dispatch(hidePageLoader());
      },
      onFailure: () => {
        dispatch(hidePageLoader());
      },
      params: {
        cartEntryNumber,
      },
      url: UPDATE_QUANTITY_API + (cp ? `&cp=true` : ''),
    },
  });
};

export const updateQuantityCartEntry = (
  dispatch: any,
  { cartEntryNumber, qty, cp }: IUpdateQuantity,
  entries: any[],
  trackingData: IUpdateQuantityTracking
) => {
  dispatch(updateEntryToggleLoading(cartEntryNumber, true));
  dispatch(hideNotification());
  dispatch({
    type: EUpdateQuantityTypes.CART_UPDATE_QUANTITY,
    payload: {
      enableProgress: false,
      data: `qty=${qty}`,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      method: EApiMiddlewareMethod.PUT,
      onSuccess: ({
        cart,
        entry,
        statusCode,
        maxAllowedQuantity,
        subFamilyName,
      }: IQuantityUpdateSuccess) => {
        trackCartUpdation(trackingData.actionType === 'add', entry);
        // Update affected entry
        dispatch(qtyRestrictionHandler({ statusCode, maxAllowedQuantity, subFamilyName, entry }));
        dispatch({
          type: EUpdateQuantityTypes.CART_UPDATE_ENTRY_DETAIL,
          payload: { entry },
        });
        // Need to update food sub-total for recalculating order threshold
        // TODO: Refactor/flatten cart state and make updating specific data possible
        updateCart(dispatch, cart);
        dispatch(getEarnedPoints());
      },
      onFailure: (msg: any, error: any) => {
        const isOOSItem =
          (((((error && error.response) || {}).data || {}).errors || [])[0] || {}).reason ===
          'notFound';

        if (isOOSItem) {
          // Make sure to update overall cart data including order summary's
          dispatch(fetchCartAction());

          if (entries) {
            // Extracts entry name and url from Cart's entries array
            const { onlineName = '', url = '' } =
              (entries.filter(entry => entry.entryNumber === cartEntryNumber)[0] || {}).product ||
              {};

            dispatch(
              showNotification({
                type: notificationTypes.errorCheckout,
                message: `Unfortunately ${
                  onlineName && url
                    ? `<a href="${url}" style="color:#0E5AA7">${onlineName}</a>`
                    : 'a product'
                } was removed from your cart as it is out of stock.`,
              })
            );
          }
        }
      },
      onFinally: () => {
        dispatch(updateEntryToggleLoading(cartEntryNumber, false));
      },
      params: {
        cartEntryNumber,
      },
      url: UPDATE_QUANTITY_API + (cp ? `&cp=true` : ''),
    },
  });
};

export const updateQuantity = (
  params: IUpdateQuantity,
  trackingData: IUpdateQuantityTracking = {}
) => (dispatch: Dispatch, getState: () => IAppState) => {
  // If "qty" is less than "1" then remove cart entry from Cart;
  // Otherwise, update its quantity
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );
  const isCartBundlingEnabled = growthbook.feature('common.cart_bundling.enabled').on;
  params.cp = isCartBundlingEnabled;
  if (params.isNewC4Journey) {
    params.qty <= 0
      ? deleteCartEntryV3(
          dispatch,
          { oldCartState: getState().newCart, ...params },
          getState().sessionArea
        )
      : updateQuantityCartEntryV3(
          dispatch,
          params,
          getState().newCart.cartData.entries,
          getState().sessionArea,
          trackingData
        );
  } else if (APP_CONFIG.isNewEndpoint) {
    params.qty <= 0
      ? newDeleteCartEntry(
          dispatch,
          { oldCartState: getState().cart, ...params },
          getState().sessionArea
        )
      : newUpdateQuantityCartEntry(
          dispatch,
          params,
          getState().cart.cartData.entries,
          getState().sessionArea,
          trackingData
        );
  } else {
    params.qty <= 0
      ? deleteCartEntry(dispatch, { oldCartState: getState().cart, ...params })
      : updateQuantityCartEntry(dispatch, params, getState().cart.cartData.entries, trackingData);
  }
};

export const trackCartUpdation = (
  isQuantityIncreased: boolean,
  updatedData: { [key: string]: any },
  entryNumber?: number,
  updateType?: string,
  extraObj?: Record<string, string>
) => {
  const countryCode = storage.getItem(COUNTRY_CODE_KEY) || '';
  const updatedEntry = !updatedData.entries ? updatedData : updatedData.entries[entryNumber || 0];
  const prodData = Transform.productDataFrom(
    updatedEntry,
    isQuantityIncreased ? {} : { dimension58: 'manual' },
    false
  );
  const ga4ProdData = Transform.productDataFrom(
    updatedEntry,
    isQuantityIncreased ? {} : { dimension58: 'manual' },
    true,
    countryCode
  );
  const { gtmAddToCart, gtmRemoveFromCart } = Tracker;
  const trackingAction = isQuantityIncreased ? gtmAddToCart : gtmRemoveFromCart;
  trackingAction([prodData], updateType, false, countryCode, extraObj);
  trackingAction([ga4ProdData], updateType, true, countryCode, extraObj);
};

export interface IParams {
  productName: string;
  isMarketPlace: boolean;
  offerId?: string;
  serviceProductCode?: string;
  successCallback?: (data: any) => void;
  failureCallback?: (data: any) => void;
}

export const addProduct = (productId: string, qty: number, params: IParams) => (
  dispatch: Dispatch,
  state: () => IAppState
) => {
  const { areaCode } = state().appConfig;
  const { isMarketPlace = false, offerId, serviceProductCode = '', productName } = params;
  dispatch({
    type: EUpdateQuantityTypes.CART_ADD_ITEM,
    payload: {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      apiVersion: 1,
      url: ADD_TO_CART_API,
      method: EApiMiddlewareMethod.POST,
      params: {
        areaCode,
      },
      data: isMarketPlace
        ? `code=${offerId}&qty=${qty}&serviceProductCode=${serviceProductCode}&isOffer=true`
        : `code=${productId}&qty=${qty}&serviceProductCode=${serviceProductCode}`,
      onSuccess: (cartResponse: { [key: string]: any }) => {
        const { entry, cart, statusCode, maxAllowedQuantity, subFamilyName } = cartResponse;

        if (statusCode === 'success') {
          dispatch(getEarnedPoints() as any);
          updateCart(dispatch, cart);
          trackCartUpdation(true, entry);
          if (params.successCallback) {
            params.successCallback(cartResponse);
          }
        } else {
          dispatch<any>(
            qtyRestrictionHandler({ statusCode, maxAllowedQuantity, subFamilyName, entry })
          );
          if (params.failureCallback) {
            params.failureCallback(productId);
          }
        }
      },
      onFailure: (errorMessage: string, error: any) => {
        const {
          response: {
            data: { meta },
          },
        } = error;

        dispatch(
          showNotification({
            type: notificationTypes.errorCheckout,
            message:
              meta && meta.message ? meta.message : i18n.t('addToCartError', { productName }),
          })
        );
        if (params.failureCallback) {
          params.failureCallback(productId);
        }
      },
    },
  });
};
