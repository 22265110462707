import i18n from 'config/localisation';
import { SlotStatusTypes, SlotStatusEnum } from '../DeliverySlotDrawer.interface';
import EDeliverySlotTypes from '../redux/deliverySlot.type';

const getSlotStatusType = (isSelected: boolean, isAvailable: boolean): SlotStatusTypes =>
  isSelected
    ? SlotStatusEnum.selected
    : isAvailable
    ? SlotStatusEnum.normal
    : SlotStatusEnum.unavailable;
export const getSlotData = (deliverySlot: any) => {
  const { data = null } = deliverySlot;
  return data.slots
    ? {
        ...deliverySlot,
        slots: data.slots,
        selectedSlot: data.reservedSlot,
      }
    : deliverySlot;
};
export const getBottomLabel = (apigeeFlag = false, isAvailable: boolean) => {
  if (isAvailable) {
    return '';
  }
  return apigeeFlag ? i18n.t('deliverySlot.fullyBooked') : i18n.t('deliverySlot.unavailable');
};

const populateResponseSlot = (slot: any) => {
  const { slotAvailable: isAvailable, slotId: deliverySlotCode, day: dayOfWeek } = slot;
  delete slot.slotAvailable;
  delete slot.slotId;
  delete slot.day;
  return { ...slot, isAvailable, deliverySlotCode, dayOfWeek };
};

export const getModifiedResponse = (
  response: any,
  apigeeFlag: boolean,
  type: EDeliverySlotTypes
) => {
  if (apigeeFlag && type === EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS_SUCCESS) {
    if (response.data.reservedSlot) {
      response.data.reservedSlot = populateResponseSlot(response.data.reservedSlot);
    }
    const { slots } = response.data;
    const allSlots = slots.map((slot: any) => {
      const { mafDeliverySlot = [] } = slot.value;
      const mafSlots = mafDeliverySlot.map((deliverySlot: any) =>
        populateResponseSlot(deliverySlot)
      );
      return { ...slot, value: { mafDeliverySlot: mafSlots } };
    });
    return {
      ...response,
      data: {
        ...response.data,
        slots: allSlots,
      },
    };
  }
  return response;
};
export default getSlotStatusType;
