import React from 'react';
import { Notification } from '@mafc/trolly';

import { createMarkup } from 'utils/html.util';
import { isNonEmptyArray } from 'utils/helpers.util';
import useNotification from './hooks/useNotification.hook';

import PageMaxWidth from 'common/PageLayout/PageMaxWidth';
import COLORS from 'theme/colors';
import { notificationTypes } from './redux/notification.type';

const GlobalNotification: React.FC = () => {
  const [type, message, variant] = useNotification();

  // in cart and checkout if any api get failed then error notification is diplayed
  if (!isNonEmptyArray(message) || type === notificationTypes.error) {
    return null;
  }

  const BORDER_COLOR: { [key: string]: string } = {
    success: COLORS.GREEN,
    errorCheckout: COLORS.RED,
  };

  // Notification component will never render again once its "X" button is clicked
  // hence adding random key to force render
  return (
    <PageMaxWidth key={`${Math.random()}`} px={[3, 4, '', 0]}>
      {message.map((item: any, index: number) => (
        <Notification
          variant={type}
          type={variant}
          mt={5}
          key={`notify-${index}`}
          border={`0`}
          borderTop={`3px solid ${BORDER_COLOR[type] || ''}`}
        >
          <div dangerouslySetInnerHTML={createMarkup(item)} />
        </Notification>
      ))}
    </PageMaxWidth>
  );
};

export default GlobalNotification;
