import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const returnMethodsInitialState: any = {
  returnMethods: [],
  cashflag: false,
  pickupThreshold: undefined,
};

const returnMethods = (state: any, payload: any) => {
  return { ...payload };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.RETURN_METHODS]: returnMethods,
};

export default function returnMethodsReducer(state = returnMethodsInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
