import IAction from 'config/redux/actions/interface';
import { IPaymentTypes } from 'services/payments/Payments.types';
import PaymentServiceActionTypes, { InitPaymentActionTypes } from '../paymentService.types';
import { InitPaymentResponse } from 'services/payments/core/PaymentsManagerSDK';

export interface ISession {
  id?: string;
  updateStatus?: string;
  version?: string;
}

export interface ICard {
  brand?: string;
  fundingMethod?: string;
  nameOnCard?: string;
  number?: string;
  scheme?: string;
  expiry?: {
    month: string;
    year: string;
  };
}

export interface IPaymentService {
  ok: boolean;
  message: string;
  card: ICard;
  error?: {};
  otpVerificationData: {
    htmlBodyContent?: string;
    _3dSecureId?: string;
    cardEnrolled?: boolean;
  };
  session: ISession;
  initPayment: InitPaymentResponse;
}
export const paymentServiceInitialState = {
  ok: false,
  error: {},
  otpVerificationData: {},
  session: {},
  card: {},
  initPayment: {},
} as IPaymentService;
export default (state: IPaymentService = paymentServiceInitialState, action: IAction) => {
  switch (action.type) {
    case IPaymentTypes.SESSION_FETCHED_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: {},
      };
    case IPaymentTypes.PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case PaymentServiceActionTypes.PAYMENT_SERVICE_THREE_DS_DATA_SAVE:
      return {
        ...state,
        otpVerificationData: {
          ...state.otpVerificationData,
          ...action.payload,
        },
      };

    case PaymentServiceActionTypes.PAYMENT_SERVICE_INPUT_FIELDS_ERROR_SAVE:
      return {
        ...state,
        error: { ...action.payload },
      };

    case InitPaymentActionTypes.INIT_PAYMENT_SUCCESS:
      return {
        initPayment: {
          ...state.initPayment,
          ...action.payload,
        },
      };
    case InitPaymentActionTypes.INIT_PAYMENT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
