import styled from '@emotion/styled';
import { Box } from '@mafc/trolly';

const NewCardFormStyled = styled(Box)`
  padding: 0;
  transition: height 0.3s ease-in-out;
  border-radius: 4px;
  position: relative;
`;

export default NewCardFormStyled;
