import React from 'react';
import { Text } from '@mafc/trolly';
import logo from 'assets/gif/easyPay.gif';
import { Container, EasyPaisaImage } from './EasyPaisaLoader.style';

const EasyPaisaLoader = () => {
  return (
    <Container width={['90%', null, '50%', null]}>
      <Text.H2 fontWeight="500">Processing your payment</Text.H2>
      <EasyPaisaImage>
        <img src={logo} alt={'Easypaisa loader'} width="100%" height="100%" />
      </EasyPaisaImage>
    </Container>
  );
};

export default EasyPaisaLoader;
