import { IOrderConfirmation } from 'pages/OrderConfirmation/OrderConfirmation.types';
// import { combineReducers } from 'redux';

import addToListReducer, {
  IAddToListState,
  initialAddToListState,
} from 'common/AddToList/redux/addToList.reducer';
import apiReducer, { IApiState, initialApiState } from './api.reducer';
import cartReducer, { ICartState, initialCartState } from 'pages/Cart/redux/reducer';
import newCartReducer, {
  ICartState as INewCartState,
  initialCartState as initialNewCartState,
} from 'pages/NewCart/redux/reducers';

import notificationReducer, {
  INotificationState,
  initialNotificationState,
} from 'common/Notification/redux/notification.reducer';
import orderSummaryReducer, {
  IOrderSummaryState,
  initialOrderSummaryState,
} from 'common/OrderSummary/redux/orderSummary.reducer';
import pageLoaderReducer, {
  initialPageLoaderState,
  IPageLoaderState,
} from 'common/PageLoader/redux/pageLoader.reducer';
// #TODO: Share Cart state may need to be moved later into Cart state once Cart state is refactored
import shareCartReducer, {
  IShareCartState,
  shareCartInitialState,
} from 'pages/Cart/components/ShareCart/redux/shareCart.reducer';
import deliverySlotReducer, {
  IDeliverySlotState,
  deliverySlotInitialState,
} from 'common/DeliverySlot/redux/deliverySlot.reducer';

import userReducer, { IUserState, initialUserState } from 'common/User/redux/User.reducer';

import checkoutReducer, {
  ICheckoutState,
  initialCheckoutState,
} from 'pages/Checkout/redux/reducer';

import LoyaltyPointReducer, {
  ILoyaltyPointState,
  loyaltyPointsInitialState,
} from '../../../pages/Checkout/components/LoyaltyPoints/redux/LoyaltyPoints.reducer';

import userV2Reducer, { userV2InitialState } from 'services/user/redux/user.reducer';
import { IUserState as IUserV2State } from 'services/user/types/user.types';

import shipmentsReducer, {
  shipmentsInitialState,
} from 'services/shipments/redux/shipments.reducer';
import { IShipmentsState } from 'services/shipments/types/shipments.types';

import sessionAreaReducer, {
  sessionAreaInitialState,
} from 'services/sessionArea/redux/sessionArea.reducer';
import { ISessionAreaState } from 'services/sessionArea/types/sessionArea.types';
import confirmOrderReducer, {
  orderConfirmationInitialState,
} from 'pages/OrderConfirmation/redux/orderConfirmation.reducer';
import paymentService, {
  IPaymentService,
  paymentServiceInitialState,
} from 'services/payments/redux/reducer/payments.reducer';
import { ICancellationReasons, IPostOrderList } from 'pages/OrderList/orderData.type';
import postOrderHistoryReducer, {
  postOrderHistoryInitialState,
} from 'pages/OrderList/redux/orderList.reducer';
import returnReducer, { returnsInitialState } from 'pages/ItemDetail/redux/itemDetail.reducer';
import returnReasonsReducer, {
  reasonsInitialState,
} from 'pages/ReturnReasons/redux/ReturnReasons.reducer';
import returnMethodsReducer, {
  returnMethodsInitialState,
} from 'pages/ReturnMethod/redux/ReturnMethod.reducer';
import RefundMethodsReducer, {
  refundMethodsInitialState,
} from 'pages/RefundMethod/redux/RefundMethod.reducer';
import refundInfoReducer, {
  refundInfoInitialState,
} from 'pages/ReviewAndConfirm/redux/ReviewAndConfirm.reducer';
import returnConfirmationReducer, {
  returnConfirmationInitialState,
} from 'pages/ReturnConfirmation/redux/ReturnConfirmation.reducer';
import { IReturnList } from 'pages/ReturnList/returnData.type';
import returnListingReducer, {
  returnsInitialHistory,
} from 'pages/ReturnList/redux/ReturnList.reducer';
import blankReturnsReducer, {
  blankReturnsInitialState,
} from 'pages/ReturnList/redux/BlankReturns.reducer';
import binDiscountBannerReducer, {
  initialIBinDiscountBannerState,
  IBinDiscountBannerState,
} from 'common/BinDiscountBanner/redux/binDiscountBanner.reducer';
import returnEligibleFlagsReducer, {
  eligibleFlagsInitialState,
  IEligibleFlags,
} from 'pages/ReturnReasons/redux/moreDetails.reducer';
import cancellationReasonsReducer, {
  cancellationReasonsInitialState,
} from 'pages/OrderDetail/redux/orderDetail.reducer';
import apigeeLabelReducer, {
  IApigeeLabelState,
  initialLabelState,
} from 'common/ApigeeLabels/redux/ApigeeLabel.reducer';
import multiReturnEligibleFlagsReducer, {
  IMultiReturnEligible,
} from 'pages/ReturnReasons/redux/multiReturnEligibleFlag.reducer';

export interface IAppState extends IShipmentsState, ISessionAreaState {
  appConfig: any;
  addToList: IAddToListState;
  api: IApiState;
  cart: ICartState;
  newCart: INewCartState;
  miniCart: any;
  deliverySlot: IDeliverySlotState;
  notification: INotificationState;
  orderSummary: IOrderSummaryState;
  pageLoader: IPageLoaderState;
  user: IUserState;
  userV2: IUserV2State;
  shareCart: IShareCartState;
  loyalty: ILoyaltyPointState;
  checkout: ICheckoutState;
  sessionArea: string;
  orderHistory: IPostOrderList;
  cancellationReasons: ICancellationReasons;
  orderConfirmation: IOrderConfirmation;
  paymentService: IPaymentService;
  returns: any;
  returnReasons: any;
  returnEligibleFlags: IEligibleFlags;
  multiReturnEligibleFlags: IMultiReturnEligible;
  returnMethods: any;
  refundMethods: any;
  refundInfo: any;
  returnConfirmation: any;
  returnList: IReturnList;
  blankReturn: any;
  userData: any;
  binDiscountBanner: IBinDiscountBannerState;
  deliveryTime?: any;
  apigeeLabels: IApigeeLabelState;
}

export const initialAppState = {
  appConfig: {},
  addToList: initialAddToListState,
  api: initialApiState,
  cart: initialCartState,
  newCart: initialNewCartState,
  deliverySlot: deliverySlotInitialState,
  notification: initialNotificationState,
  orderSummary: initialOrderSummaryState,
  pageLoader: initialPageLoaderState,
  user: initialUserState,
  userV2: userV2InitialState,
  shareCart: shareCartInitialState,
  checkout: initialCheckoutState,
  loyalty: loyaltyPointsInitialState,
  orderHistory: postOrderHistoryInitialState,
  cancellationReasons: cancellationReasonsInitialState,
  orderConfirmation: orderConfirmationInitialState,
  ...shipmentsInitialState,
  ...sessionAreaInitialState,
  paymentService: paymentServiceInitialState,
  returns: returnsInitialState,
  returnReasons: reasonsInitialState,
  returnEligibleFlags: eligibleFlagsInitialState,
  returnMethods: returnMethodsInitialState,
  refundMethods: refundMethodsInitialState,
  refundInfo: refundInfoInitialState,
  returnConfirmation: returnConfirmationInitialState,
  returnList: returnsInitialHistory,
  blankReturn: blankReturnsInitialState,
  binDiscountBanner: initialIBinDiscountBannerState,
  apigeeLabels: initialLabelState,
};

const rootReducer = {
  addToList: addToListReducer,
  api: apiReducer,
  cart: cartReducer,
  newCart: newCartReducer,
  deliverySlot: deliverySlotReducer,
  notification: notificationReducer,
  orderSummary: orderSummaryReducer,
  pageLoader: pageLoaderReducer,
  user: userReducer,
  userV2: userV2Reducer,
  shareCart: shareCartReducer,
  checkout: checkoutReducer,
  loyalty: LoyaltyPointReducer,
  orderHistory: postOrderHistoryReducer,
  cancellationReasons: cancellationReasonsReducer,
  orderConfirmation: confirmOrderReducer,
  ...shipmentsReducer,
  ...sessionAreaReducer,
  paymentService,
  returns: returnReducer,
  returnReasons: returnReasonsReducer,
  returnEligibleFlags: returnEligibleFlagsReducer,
  multiReturnEligibleFlags: multiReturnEligibleFlagsReducer,
  returnMethods: returnMethodsReducer,
  refundMethods: RefundMethodsReducer,
  refundInfo: refundInfoReducer,
  returnConfirmation: returnConfirmationReducer,
  returnList: returnListingReducer,
  blankReturn: blankReturnsReducer,
  binDiscountBanner: binDiscountBannerReducer,
  apigeeLabels: apigeeLabelReducer,
};

export default rootReducer;
