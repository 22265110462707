import React from 'react';

import usePageLoader from './hooks/usePageLoader.hook';

import PageLoaderContainer, { PageLoaderText } from './styles/PageLoaderContainer.styles';

const PageLoader: React.FC = () => {
  const [pageLoader] = usePageLoader();

  return pageLoader.loading ? (
    <PageLoaderContainer>
      <PageLoaderText>{pageLoader.message}</PageLoaderText>
    </PageLoaderContainer>
  ) : null;
};

export default PageLoader;
