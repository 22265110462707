export const createMarkup = (HTMLStr: string): { __html: string } => ({ __html: HTMLStr });

export const setHeader = () => {
  const title = ' - Shop online for Food, Grocery, Mobile, Electronics & More';
  const { hostname } = window.location;
  const [, host = '', tld] = hostname.split('.');

  const isRTL = window.document.dir === 'rtl';
  switch (host) {
    case 'carrefourjordan': {
      if (isRTL) {
        document.title =
          'كارفور الأردن - تسوق منتجات البقالة، الموبايلات، والأجهزة المنزلية أونلاين';
      } else {
        document.title = 'Carrefour Jordan' + title;
      }
      break;
    }
    case 'carrefourlebanon': {
      document.title = 'Carrefour Lebanon' + title;
      break;
    }
    case 'carrefourqatar': {
      if (isRTL) {
        document.title = 'كارفور قطر - تسوق الأطعمة، الهواتف الذكية والإلكترونيات أونلاين';
      } else {
        document.title = 'Carrefour Qatar' + title;
      }
      break;
    }
    case 'carrefouregypt': {
      if (isRTL) {
        document.title = 'كارفور مصر - تسوق منتجات البقالة، الموبايلات، والإلكترونيات أونلاين';
      } else {
        document.title = 'Carrefour Egypt' + title;
      }
      break;
    }
    case 'carrefourksa': {
      if (isRTL) {
        document.title = `كارفور السعودية - تسوق أونلاين البقالة ومنتجات الأطفال ومستحضرات التجميل والمزيد`;
      } else {
        document.title = 'Carrefour Saudi Arabia' + title;
      }
      break;
    }
    case 'carrefour': {
      if (`${host}${tld}` === 'carrefourpk') {
        document.title = `Carrefour Pakistan - Best Prices & Great Offers in Stores`;
      }
      break;
    }
    default:
      break;
  }
};
