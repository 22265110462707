import EPaymentMethods from '../components/Payment/PamentMethods.enum';

export const GTM_SHIPMENTS = {
  ALL: 'all_shipments',
  STNDARD_FOOD: 'std_food_shipment',
  STNDARD_NON_FOOD: 'std_nonfood_shipment',
  MARKETPLACE: 'marketplace_shipment',
  EXPRESS: 'NOW_shipment',
  QCOMM: 'QCOMM_shipment',
};

export const GTM_PAYMENT_METHOD_MAPPING: { [k: string]: string } = {
  [EPaymentMethods.COD]: 'cash_on_delivery',
  [EPaymentMethods.CREDIT_CARD]: 'credit_card',
  [EPaymentMethods.DEBIT_CARD]: 'debit_card',
  [EPaymentMethods.MOBILE_WALLET]: 'mobile_wallet',
  [EPaymentMethods.CARD_ON_DELIVERY]: 'card_on_delivery',
};
