export enum FLAGSHIP_IDENTIFIER {
  TEXT = 'txt',
  OBJECT = 'obj',
  ARRAY = 'arr',
  NUMBER = 'num',
  BOOLEAN = 'bool',
}
export enum FLAGSHIP_EVENTS_GTM {
  VIEWED_EXPERIMENT = 'viewed_experiment',
  PLATFORM = 'web',
  TRANSACTION = 'Transaction',
}
