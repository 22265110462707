import { IAction } from 'config/redux/types/redux.types';

export enum EMergeCartsTypes {
  MERGE_CARTS_GET_REQ = 'MergeCarts/GET_REQ',
  MERGE_CARTS_SET_LOADING = 'MergeCarts/SET_LOADING',
}

export interface IMergeCartsParam {
  areacode: string;
  toMergeCartGuid: string;
  successCallback?: (hasMerged: boolean) => void;
  t: any;
}

export interface IMergeCartsAPIResponse {
  cartData?: { cartModified?: boolean; [K: string]: any };
  showMergeMessage?: boolean;
  // above attributes in v2 response
  // below attributes in v3 response
  cartModified?: boolean;
  cartResponse?: { cartModified?: boolean; [K: string]: any };
  totalUnitCount?: number;
}

export type TMergeCartsLoadingAction = IAction<any, EMergeCartsTypes.MERGE_CARTS_SET_LOADING>;
