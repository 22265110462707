import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

interface IPageLoaderContainerProps {
  transparent?: boolean;
}
// TO BE REPLACED WITH TROLLY COMP
export const PageLoaderContainer = styled.div<IPageLoaderContainerProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: ${({ transparent }: any) => (transparent ? 'transparent' : 'rgba(0, 0, 0, 0.4)')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FadeInBottom = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const PageLoaderText = styled.div`
  color: white;
  font-size: 30px;
  text-align: center;
  animation: ${FadeInBottom} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export default PageLoaderContainer;
