import { IAppState } from 'config/redux/reducers';

export const getAppConfig = (store: IAppState, configKeys: string[]) => {
  const { appConfig } = store;
  let configValue: any;

  for (const key of configKeys) {
    const temp = configValue || appConfig;
    configValue = temp[key];

    if (!configValue) {
      break;
    }
  }

  return configValue;
};
