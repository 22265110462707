const TYPOGRAPHY: { [key: string]: any } = {
  SIZE: {
    10: '10px',
    11: '11px',
    12: '12px',
    13: '13px',
    14: '14px',
    16: '16px',
    18: '18px',
    20: '20px',
  },
  WEIGHT: {
    400: 400,
    500: 500,
    600: 600,
    700: 700,
  },
};

export default TYPOGRAPHY;
