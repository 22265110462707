enum AVAILABLE_PROMOTION_LIST {
  APPLIED_COUPON_API_SUCCESS = 'CHECKOUT/APPLIED_COUPON_API_SUCCESS',
  FETCH = 'CHECKOUT/AVAILABLE_PROMOTION_LIST',
  SUCCESS = 'CHECKOUT/AVAILABLE_PROMOTION_LIST_SUCCESS',
  ERROR = 'CHECKOUT/AVAILABLE_PROMOTION_LIST_FAIL',
  START = 'CHECKOUT/AVAILABLE_PROMOTION_LIST_START',
  END = 'CHECKOUT/AVAILABLE_PROMOTION_LIST_END',
}

export default AVAILABLE_PROMOTION_LIST;
