import { isExisty } from 'utils/helpers.util';

/**
 * This function will replace all static variables with proper values from the configuration
 * @param url URL that contains varibales wrapped with curly braces
 * ex: `/carts/{{StoreId}}/{{language}}/current/
 * @param params object contains the actual value for the matched variables
 * ex: {StoreId: 123, language: 'au'}
 * @returns the modified URL after replacing all variables
 */
const replaceUrlVariables = (url: string, params: { [key: string]: any }): string => {
  const regex = /[^{{}]+(?=}})/g;
  const matches = url.match(regex);

  let modifiedURL = url;

  if (matches) {
    matches.forEach((item: string) => {
      const value = params[item];
      modifiedURL = modifiedURL.replace(
        new RegExp('{{' + item + '}}'),
        isExisty(value) || value === '' ? value : `{{${item}}}`
      );
    });
  }

  return modifiedURL;
};

export const updateURLForGuestSession = (url: string, guid: string): string => {
  let updatedURL = url;
  updatedURL = updatedURL.replace('current', guid);
  return updatedURL;
};

export default replaceUrlVariables;
