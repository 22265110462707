export default {
  // add any qatar specific strings here or to override base strings
  en: {
    fullCartRedemption: 'Congratulations! Your grand total will be covered using cashback',
    points: 'Cashback',
    loyaltyPoints: 'Cashback',
    orderSummaryPointsEarned: "MyClub Cashback points you'll earn with this order",
    orderSummaryShareDiscount: 'Paid by MyCLUB Cashback points',
    redeemPoints: 'Redeem Cashback',
    shareDiscount: 'Cashback Balance',
  },
  ar: {
    fullCartRedemption: 'تهانينا! سيتم تغطية المبلغ الكلي لفاتورة باستخدام نقاط كاش باك',
    points: 'كاش باك',
    loyaltyPoints: 'كاش باك',
    orderSummaryPointsEarned: 'التي ستكسبها من هذا الطلب MyCLUB عدد نقاط',
    orderSummaryShareDiscount: 'تم الدفع بنقاط ماي كلوب كاش باك',
    redeemPoints: 'استرداد النقود',
    shareDiscount: 'كاش باك',
  },
};
