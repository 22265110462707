import ENotificationTypes from './notification.type';
import { INotificationState } from './notification.reducer';

export const showNotification = (payload: INotificationState) => ({
  type: ENotificationTypes.NOTIFICATION_SHOW,
  payload,
});

export const hideNotification = () => ({
  type: ENotificationTypes.NOTIFICATION_HIDE,
});
