import { customStyled, Flex } from '@mafc/trolly';
import { layout, size } from 'styled-system';
export const Container = customStyled(Flex)`
flex-direction: column;
align-items: center;
background-color: ${props => props.theme.colors.white};
padding:${props => props.theme.space[3]}px;
margin:auto;
border-radius:6px;
border:${props => `${props.theme.borders[1]}${props.theme.colors.mercury}`}
${size}
`;

export const EasyPaisaImage = customStyled.div<{ [key: string]: any }>`
margin-top:${props => props.theme.space[3]}px;
${layout}
${size}
`;
