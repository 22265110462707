import IAction from 'config/redux/actions/interface';
import AVAILABLE_PROMOTION_LIST from './AvailablePromotions.type';
import { ICheckoutState } from 'pages/Checkout/redux/reducer';

export interface IVoucherCard {
  title: string;
  description: string;
  code: string;
  imageUrl?: string[];
  binDesc?: string;
  expiringIn?: string;
  termsAndConditions: string[];
  isApplicable: boolean;
  isApplied: boolean;
  bottomMessage: string;
  usagePerCustomer?: number;
  redeemedCount?: number;
}

export interface IAvailablePromotionsTab {
  tabTitle: string;
  noCouponMessage?: string;
  restrictionMessage?: string;
  trackingKey: string;
  coupons: IVoucherCard[];
}

export interface IAvailablePromotions {
  loading: boolean;
  error: boolean;
  appliedCouponApiSuccess: boolean;
  tabData: {
    activeIndex: number;
    data: IAvailablePromotionsTab[];
  };
}

export const initialAvailablePromotions = {
  loading: false,
  error: false,
  appliedCouponApiSuccess: false,
  tabData: {
    activeIndex: 0,
    data: [],
  },
} as IAvailablePromotions;

const availablePromotionsList = (state: ICheckoutState, action: IAction) => ({
  ...state,
  availablePromotions: { ...state.availablePromotions, tabData: action.payload },
});

const availablePromotionsError = (state: ICheckoutState) => ({
  ...state,
  availablePromotions: {
    ...state.availablePromotions,
    tabData: { activeIndex: 0, data: [] },
    error: true,
  },
});

const availablePromotionsStartLoading = (state: ICheckoutState) => ({
  ...state,
  availablePromotions: { ...state.availablePromotions, error: false, loading: true },
});

const availablePromotionsEndLoading = (state: ICheckoutState) => ({
  ...state,
  availablePromotions: { ...state.availablePromotions, loading: false },
});

const appliedCouponApiSuccess = (state: ICheckoutState, action: IAction) => ({
  ...state,
  availablePromotions: { ...state.availablePromotions, appliedCouponApiSuccess: action.payload },
});

export default {
  [AVAILABLE_PROMOTION_LIST.APPLIED_COUPON_API_SUCCESS]: appliedCouponApiSuccess,
  [AVAILABLE_PROMOTION_LIST.SUCCESS]: availablePromotionsList,
  [AVAILABLE_PROMOTION_LIST.START]: availablePromotionsStartLoading,
  [AVAILABLE_PROMOTION_LIST.END]: availablePromotionsEndLoading,
  [AVAILABLE_PROMOTION_LIST.ERROR]: availablePromotionsError,
};
