import React from 'react';
import { Box, Text } from '@mafc/trolly';
import COLORS from 'theme/colors';
import TYPOGRAPHY from 'theme/typography';
import {
  TextFieldContainer,
  TextFieldControl,
  TextFieldLabel,
  TextFieldInput,
  TextFieldUnderline,
} from './styles';

const { Paragraph } = Text;

const CardInput: React.FC<{
  id: string;
  type: string;
  label: string;
  readOnly: boolean;
  variant?: 'normal' | 'error' | 'success';
  error?: { description: string };
  icon?: JSX.Element;
}> = ({ id, label, variant = 'normal', readOnly, type, error, icon }) => {
  return (
    <TextFieldContainer>
      <TextFieldControl>
        <Box sx={{ position: 'relative' }}>
          <TextFieldInput id={id} readOnly={readOnly} type={type} styling={variant} />

          {icon && (
            <Box
              sx={{
                top: '5px',
                position: 'absolute',
                right: '0',
              }}
            >
              {icon}
            </Box>
          )}
        </Box>
        <TextFieldLabel>{label}</TextFieldLabel>
        <TextFieldUnderline styling={variant} />
        {error && (
          <Paragraph color={COLORS.RED} mt={1} fontSize={TYPOGRAPHY.SIZE[10]}>
            {error.description}
          </Paragraph>
        )}
      </TextFieldControl>
    </TextFieldContainer>
  );
};

export default CardInput;
