import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useAction = (action: any) => {
  const dispatch = useDispatch();

  const actionCallBack = useCallback((...args: any[]) => dispatch(action(...args)), [
    action,
    dispatch,
  ]);

  return [actionCallBack];
};

export default useAction;
