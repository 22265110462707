const CART_API_BASE = '/carts/{{StoreId}}/{{language}}/current';

export const GET_CART_API = `${CART_API_BASE}?refreshCart=true&fields=FULL&areacode={{areaCode}}`;
export const ADD_TO_CART_API = `${CART_API_BASE}/entries?isCartRequired=true&fields=FULL&areacode={{areaCode}}`;
export const UPDATE_QUANTITY_API = `${CART_API_BASE}/entries/{{cartEntryNumber}}?isCartRequired=true&fields=FULL&displayCurr={{Currency}}&areacode={{areaCode}}`;
export const DELETE_ITEM_API = `${CART_API_BASE}/entries?refreshCart=true&fields=FULL&entryNumbers={{entryNumbers}}&areacode={{areaCode}}`;
export const LOYALTY_POINTS_API = `${CART_API_BASE}/loyalty-points?fields=DEFAULT`;
export const ENABLE_SUBSTITUTION = `${CART_API_BASE}/substitutionFlag?areacode={{areaCode}}`;
export const ADD_TO_CART = `${CART_API_BASE}/entries?isCartRequired=true&fields=FULL&areacode={{areaCode}}`;
export const GUEST_AUTH_TOKEN = `stores/{{StoreId}}/{{language}}/oauth/token`;
export const ADD_WARRANTY = `${CART_API_BASE}/warranty?isCartRequired=true&fields=FULL&areacode={{areaCode}}`;
export const SHARE_CART_API = `${CART_API_BASE}/basket?fields=FULL&areacode={{areaCode}}`;
export const CART_ADDRESS_VALIDATE = `${CART_API_BASE}/validate?areacode={{areaCode}}&fields=FULL`;
export const UPDATE_CART_ADDRESS = `${CART_API_BASE}?refreshCart=true&areacode={{areaCode}}&fields=FULL`;
export const GET_MERGE_CARTS_API = `${CART_API_BASE}/reactcartmerge?fields=FULL&areacode={{areaCode}}&toMergeCartGuid={{toMergeCartGuid}}`;
export const UPDATE_RR_ORDER_CONFIRM_URI = `/relevance/mafuae/en/reporting`;
export const UPDATE_RICH_RELEVANCE_CART = `&placements={{placements}}?productId={{productIDs}}&areacode={{areaCode}}`;
export const UPDATE_RICH_RELEVANCE_OC = `${UPDATE_RR_ORDER_CONFIRM_URI}?placements={{placements}}&productId={{productIds}}&o={{orderNumber}}&q={{quantity}}&pp={{price}}&areacode={{areaCode}}`;

export const GET_EXPRESS_CART_API = `${CART_API_BASE}/createExpressCart?fields=FULL&areacode={{areaCode}}`;
export const GET_EXPRESS_CART_API_SPLIT_PAYMENT = `${CART_API_BASE}/createExpressCart?fields=FULL&areacode={{areaCode}}&payment_arch=v2`;
