import { AnyAction } from 'redux';
import { EPostOrderDataActions, ICancellationReasons } from '../../OrderList/orderData.type';

export const cancellationReasonsInitialState: any = null;

const cancellationReasons = (state: any, payload: ICancellationReasons) => {
  return payload;
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.GET_CANCELLATION_REASONS]: cancellationReasons,
};

export default function cancellationReasonsReducer(
  state = cancellationReasonsInitialState,
  action: AnyAction
) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
