enum EAddToListTypes {
  ADD_TO_LIST_FETCH_LIST = 'AddToList/FETCH_LIST',
  ADD_TO_LIST_FETCH_LIST_SUCCESS = 'AddToList/FETCH_LIST_SUCCESS',
  ADD_PRODUCT_ONLY = 'AddToList/ADD_PRODUCT_ONLY',
  ADD_PRODUCT_AND_NEW_LIST = 'AddToList/ADD_PRODUCT_AND_NEW_LIST',
  ADD_TO_LIST_TOGGLE_LOADING = 'AddToList/TOGGLE_LOADING',
  ADD_TO_LIST_SET_ERROR = 'AddToList/SET_ERROR',
  ADD_TO_LIST_SET_SUCCESS = 'AddToList/ADD_TO_LIST_SET_SUCCESS',
  ADD_TO_LIST_CLEAR_ERROR_AND_SUCCESS = 'AddToList/CLEAR_ERROR_AND_SUCCESS',
}

export default EAddToListTypes;
