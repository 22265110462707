import { MasterCardNew, VisaCardNew } from '@mafc/icon';
import { Flex } from '@mafc/trolly';

import { CardType } from 'network/response/ICart';
import { getCardTypeForIcon } from 'pages/Checkout/components/Payment/helpers/payment.helpers';
import useCardIconsFeatureFlag from 'pages/Checkout/components/Payment/hooks/useCardIconsFeature';

import IconMada from 'assets/icon-mada.png';
import * as S from './CardIcon.style';

const CardIcons: React.FC<{
  cardNumber: string;
}> = ({ cardNumber }) => {
  const type = getCardTypeForIcon(cardNumber);

  const cardIconsFeatureFlag = useCardIconsFeatureFlag();

  if (type === CardType.visa) {
    return <VisaCardNew />;
  } else if (type === CardType.master) {
    return <MasterCardNew />;
  } else {
    return (
      <Flex alignItems="center">
        {cardIconsFeatureFlag.mada && <S.MadaIcon url={IconMada} name="icon-mada" />}

        <VisaCardNew />

        <MasterCardNew />
      </Flex>
    );
  }
};

export default CardIcons;
