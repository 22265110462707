import UserTypes from './User.type';

export const updateNIC = (textValue: string) => ({
  type: UserTypes.USER_UPDATE_NIC_VALUE,
  payload: textValue,
});

export const resetNIC = () => ({
  type: UserTypes.USER_RESET_NIC_VALUE,
  payload: '',
});

export const toggleNIC = (isEnabled: boolean) => ({
  type: UserTypes.USER__DISABLE_ENABLE_NIC,
  payload: isEnabled,
});

export const userSetAddressChange = (payload: boolean) => ({
  type: UserTypes.USER_SET_ADDRESS_CHANGE,
  payload,
});
