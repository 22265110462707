import createReducer from 'config/redux/helpers/createReducer';
import INewCart from 'pages/NewCart/ICart';

import {
  CART_TOGGLE_ERROR,
  CART_UPDATE_ENTRY_DETAIL,
  CART_UPDATE_ENTRY_TOGGLE_LOADING,
  FETCH_CART_DATA_SUCCESS,
  FETCH_CART_TOGGLE_LOADING,
} from '../constants/cart.types';
import { cartToggleError, cartDataSuccess, cartToggleLoading } from './cart.reducer';
import { updateEntryDetail, updateEntryToggleLoading } from './updateQuantity.reducer';
import { ISubstitutesShipment } from 'pages/NewCart/ISubstitutes.types';
import {
  FETCH_SUBSTITUTES_LOADING_END,
  FETCH_SUBSTITUTES_LOADING_START,
  FETCH_SUBSTITUTES_SUCCESS,
  FETCH_SUBSTITUTES_ERROR,
  SUBSTITUTE_REPLACEMENT_FAIL,
  SUBSTITUTE_REPLACEMENT_LOADING_END,
  SUBSTITUTE_REPLACEMENT_LOADING_START,
  UPDATE_OOS_CURRENT_ITEM_INDEX,
  UPDATE_OOS_SELECTED_ITEMS,
} from '../constants/substitution.type';
import {
  fetchSubstitutesError,
  fetchSubstitutesSuccess,
  fetchSubstitutesLoadingStart,
  fetchSubstitutesLoadingEnd,
  substitutesReplacementLoadingStart,
  substitutesReplacementLoadingEnd,
  updateOOSCurrentItemIndex,
  updateOOSSelectedItems,
  substitutesReplacementError,
} from './substitutions.reducer';

export interface ICartState {
  cartData: INewCart;
  error?: boolean;
  errorMessage?: string;
  errorFetchSubstitutes?: boolean;
  loadingEntries: number[];
  loading: boolean;
  loadingFetchSubstitutes: boolean;
  substitutes: ISubstitutesShipment[];
  errorSubstituteReplacement: boolean;
  loadingSubstituteReplacement: boolean;
  oosSelectedItemsIds: number[];
  oosCurrentItemIndex: number;
}

export const initialCartState = {
  cartData: {} as INewCart,
  loadingEntries: [],
  loading: false,
  errorFetchSubstitutes: false,
  loadingFetchSubstitutes: false,
  substitutes: [],
  errorSubstituteReplacement: false,
  loadingSubstituteReplacement: false,
  oosSelectedItemsIds: [],
  oosCurrentItemIndex: 0,
};

const cartReducer = createReducer(initialCartState, {
  [FETCH_CART_DATA_SUCCESS]: cartDataSuccess,
  [CART_TOGGLE_ERROR]: cartToggleError,
  [FETCH_CART_TOGGLE_LOADING]: cartToggleLoading,

  [FETCH_SUBSTITUTES_SUCCESS]: fetchSubstitutesSuccess,
  [FETCH_SUBSTITUTES_ERROR]: fetchSubstitutesError,
  [FETCH_SUBSTITUTES_LOADING_START]: fetchSubstitutesLoadingStart,
  [FETCH_SUBSTITUTES_LOADING_END]: fetchSubstitutesLoadingEnd,

  [SUBSTITUTE_REPLACEMENT_LOADING_START]: substitutesReplacementLoadingStart,
  [SUBSTITUTE_REPLACEMENT_LOADING_END]: substitutesReplacementLoadingEnd,
  [SUBSTITUTE_REPLACEMENT_FAIL]: substitutesReplacementError,
  [UPDATE_OOS_SELECTED_ITEMS]: updateOOSSelectedItems,
  [UPDATE_OOS_CURRENT_ITEM_INDEX]: updateOOSCurrentItemIndex,

  [CART_UPDATE_ENTRY_DETAIL]: updateEntryDetail,
  [CART_UPDATE_ENTRY_TOGGLE_LOADING]: updateEntryToggleLoading,
});

export default cartReducer;
