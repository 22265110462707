/**
 * This contains the entire logic related to Features Configuration
 */

interface FeaturesConfig {
  __defaults__: { env: string };
  settings: { [K: string]: any };
}

// `Object.seal` so that we can update the value of `settings` later on
const FEATURES_CONFIG: FeaturesConfig = Object.seal({
  // This serve as a fallback
  __defaults__: {
    env: 'uat1',
  },
  settings: {},
});

export default FEATURES_CONFIG;
