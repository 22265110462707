// Order of height values is based on the App breakpoint
export const TOP_PANE_HEIGHT: Array<number | null> = [null, null, null, 32];
export const MAIN_PANE_HEIGHT: Array<number | null> = [164, 108, null, 110];
export const C4_MAIN_PANE_HEIGHT: Array<number | null> = [170, 108, null, 73];
export const CHECKOUT_HEADER_HEIGHT = [56, null, null, 62];

export const accumulateHeights = (spacing: number = 0) => {
  const height: Array<number | null> = [null, null, null, null];

  for (let i = 0; i < 4; i++) {
    let totalHeight = 0;

    if (TOP_PANE_HEIGHT[i]) {
      totalHeight += TOP_PANE_HEIGHT[i] as number;
    }

    const JOURNEY_MAIN_PANE_HEIGHT = C4_MAIN_PANE_HEIGHT;

    if (JOURNEY_MAIN_PANE_HEIGHT[i]) {
      totalHeight += JOURNEY_MAIN_PANE_HEIGHT[i] as number;
    }

    if (totalHeight) {
      height[i] = totalHeight + spacing;
    }
  }

  return height;
};
export default accumulateHeights;
