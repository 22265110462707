import React from 'react';
import LoadingEllipsis from 'common/LoadingEllipsis';
import { useTranslation } from 'react-i18next';

export default function PlaceOrderLoadingText() {
  const { t } = useTranslation();
  return (
    <LoadingEllipsis>
      {t('spcLoader.order')}
      <br />
      {t('spcLoader.wait')}
    </LoadingEllipsis>
  );
}
