const apiV3 = `${process.env.REACT_APP_RETAIL_SSO_BASE}/v3/`;

export const FETCH_SELECTED_DELIVERY_SLOT_API =
  '/carts/{{StoreId}}/{{language}}/{{cartId}}/selected-slots?areacode={{areaCode}}';
export const FETCH_SLOTS_API =
  '/stores/{{StoreId}}/{{language}}/deliveryslots?areacode={{areaCode}}';
export const FETCH_SLOTS_API_NEW = `${apiV3}carts/{{StoreId}}/{{language}}/delivery-slots`;
export const PUT_SELECTED_DELIVERY_SLOT_API =
  '/carts/{{StoreId}}/{{language}}/{{cartId}}/delivery-slots?areacode={{areaCode}}';
export const PUT_SELECTED_DELIVERY_SLOT_API_NEW = `/carts/{{StoreId}}/{{language}}/current/updateDeliverySlot?areacode={{areaCode}}&slotCode={{slotCode}}`;
export const GET_DEFAULT_DRAWER_STATE = `${apiV3}carts/{{StoreId}}/{{language}}/deliveryDrawer?pos-no={{posNo}}&zone-id={{zone}}`;
