import { EScreenActionTypes, TScreenAction, EScreens } from 'pages/Checkout/types/screen.types';

export const setScreenToAddressList = (): TScreenAction => ({
  type: EScreenActionTypes.DELIVER_TO_SCREEN_ADDRESS_LIST,
  payload: EScreens.ADDRESS_LIST,
});

export const setScreenToCartList = (): TScreenAction => ({
  type: EScreenActionTypes.DELIVER_TO_SCREEN_CART_LIST,
  payload: EScreens.CART_LIST,
});

export const setScreenToAddressForm = (): TScreenAction => ({
  type: EScreenActionTypes.DELIVER_TO_SCREEN_ADDRESS_FORM,
  payload: EScreens.ADDRESS_FORM,
});

export const setScreenToHide = (): TScreenAction => ({
  type: EScreenActionTypes.DELIVER_TO_SCREEN_HIDE,
  payload: '',
});

export const setScreen = (screen?: EScreens): TScreenAction => ({
  type: EScreenActionTypes.SET_SCREEN,
  payload: screen || '',
});
