enum UserTypes {
  USER_GET_DATA = 'USER/GET_DATA',
  USER_GET_DATA_SUCCESS = 'USER/GET_DATA_SUCCESS',
  USER_UPDATE_DELIVERY_ADDRESS = 'USER/UPDATE_DELIVERY_ADDRESS',
  USER_UPDATE_NEWLY_ADDED_ADDRESS = 'USER/UPDATE_NEWLY_ADDED_ADDRESS',
  USER_UPDATE_NIC_VALUE = 'USER/UPDATE_NIC_VALUE',
  USER_RESET_NIC_VALUE = 'USER/USER_RESET_NIC_VALUE',
  USER__DISABLE_ENABLE_NIC = 'USER/USER__DISABLE_ENABLE_NIC',
  USER_SET_ADDRESS_CHANGE = 'USER/USER_SET_ADDRESS_CHANGE',
}

export default UserTypes;
