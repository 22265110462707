export interface IMKPNote {
  message: string;
  sellerId: string;
}

export interface IMKP {
  notes: IMKPNote[];
}

export const SET_MKP_NOTE = 'MKP_SET_VDAY_NOTE';
export const CLEAR_MKP_NOTE = 'MKP_CLEAR_VDAY_NOTE';
export const CREATE_MKP_NOTE = 'MKP_CREATE_VDAY_NOTE';
