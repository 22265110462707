import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { isUserShareMember, handleCriteoCarouselResponse } from '@mafc/common';
import CONSTANTS from 'config/constants';
import { IAppState } from 'config/redux/reducers';
import { FETCH_CART_DATA, FETCH_CART_ADTECH_CAROUSEL } from '../constants/cart.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { getCustomCookie, getGUID, getUserTokenFromCookie } from 'utils/cookies.util';
import { GET_CART_API } from 'pages/NewCart/constants/api.constants';
import { setShipments } from 'services/shipments/redux/shipments.actions';
import { setCartOrderSummaryData } from 'common/OrderSummary/redux/orderSummary.action';
import { cartDataSuccess, cartToggleError, cartToggleLoading } from './cart.actions';
import { cartReponseShaper, mergeAllShipmentEntries } from './cartAction.helper';
import { INewCartResponse, INewCartShipment } from 'pages/NewCart/ICart';
import {
  updateCart as oldUpdateCart,
  updateMiniCart,
} from 'pages/Cart/redux/actions/getCart.action';
import { transformBundleEntries, transfromGetCart } from 'pages/Cart/redux/actions/newCart.actions';
import { TRACKING_VIEW_BASKET, ADTECH_CAROUSEL_API } from 'pages/Cart/constants/new.apis.constant';
import { getTrackingData } from 'pages/Checkout/helpers/orderAction.helper';
import { ETrackingTypes } from 'pages/Checkout/types/screen.types';
import constants from 'config/constants';
import { createGrowthBookInstance } from '@mafc/appshell';
import { checkoutType } from 'pages/Cart/helpers/url.helpers';
import {
  CHECKOUT_TYPE_TO_SHIPMENT,
  SHIPMENT_CHECKOUT_TYPE,
} from 'pages/Checkout/constants/checkout.constant';
import { getAvailableServiceTypes } from 'pages/Cart/helpers/getServiceType.helper';

export const fetchCart = (successCallback?: () => void, completedCallback?: () => void) => (
  dispatch: any,
  getState: () => IAppState
) => {
  const { newCart } = getState();
  if (newCart && newCart.error) {
    dispatch(cartToggleError({ error: false }));
  }

  dispatch(fetchCartAction(successCallback, completedCallback));
};

export const viewBasketTracking = () => (dispatch: Dispatch, getState: () => IAppState) => {
  const state = getState() || {};
  const { entries } = mergeAllShipmentEntries(state);
  const trackingData = getTrackingData(entries, false);
  const userId = getCustomCookie(constants.mafUserId) || '';
  const visitorId = getCustomCookie(constants.mafSessionId);
  dispatch({
    type: ETrackingTypes.VIEW_BASKET,
    payload: {
      headers: { userId, visitorId },
      apiVersion: 1,
      method: EApiMiddlewareMethod.POST,
      data: trackingData,
      url: TRACKING_VIEW_BASKET,
    },
  });
};

export const fetchAdtechCarousel = (intent = 'EXPRESS') => (dispatch: Dispatch) => {
  const userId = getCustomCookie(constants.mafUserId) || '';
  const visitorId = getCustomCookie(constants.mafSessionId);

  dispatch({
    type: FETCH_CART_ADTECH_CAROUSEL,
    payload: {
      headers: { userId, visitorId, intent },
      apiVersion: 2,
      method: EApiMiddlewareMethod.GET,
      url: ADTECH_CAROUSEL_API,
      onSuccess: (data: { data: any }) => {
        dispatch(handleCriteoCarouselResponse(data.data.carousel));
      },
    },
  });
};

export const fetchCartAction = (
  successCallback?: () => void,
  completedCallback?: () => void,
  calledOnCheckout = false
) => (dispatch: Dispatch, getState: () => IAppState) => {
  const store = getState();
  const growthbook = createGrowthBookInstance(
    {
      ...store.appConfig.growthBook,
    },
    store
  );

  const isCartBundlingEnabled = growthbook.feature('common.cart_bundling.enabled').on;
  const nsp = getAvailableServiceTypes(getState, growthbook);

  const guid = getGUID();

  if (getUserTokenFromCookie() || guid) {
    let url = guid ? `${GET_CART_API}&guid=${guid}` : GET_CART_API;
    url = url + (isCartBundlingEnabled ? '&cp=true' : '');
    const isShareMember = isUserShareMember(store);
    batch(() => {
      dispatch(cartToggleLoading(true));

      dispatch({
        type: FETCH_CART_DATA,
        payload: {
          apiVersion: 3,
          enableProgress: false,
          method: EApiMiddlewareMethod.GET,
          params: {
            lm: isShareMember,
            nsp: calledOnCheckout ? nsp : '',
          },
          onSuccess: (data: INewCartResponse) => {
            if (calledOnCheckout) {
              const checkedOutShipment = checkoutType();
              const shouldNotFilterShipment =
                SHIPMENT_CHECKOUT_TYPE.DEFAULT === checkedOutShipment ||
                checkedOutShipment.includes(SHIPMENT_CHECKOUT_TYPE.MKP);

              if (!shouldNotFilterShipment) {
                data.shipments = data.shipments.filter(
                  (shipment: INewCartShipment) =>
                    shipment.shipmentType === CHECKOUT_TYPE_TO_SHIPMENT[checkedOutShipment] &&
                    shipment.entries.length > 0
                );
              }
            }
            // dispatching it to support on checkoutpage as checkout page consumes only `cart` redux key not the `newCart`
            const transformedData = transfromGetCart(data);
            oldUpdateCart(dispatch, transformedData);

            updateCart(dispatch, cartReponseShaper(data));
            // dispatch(getEarnedPoints() as any);
            if (successCallback) {
              successCallback();
            }
          },
          url,
          onFailure: (errorMessage: string) => {
            dispatch(cartToggleError({ errorMessage }));
          },
          onFinally: () => {
            dispatch(cartToggleLoading(false));
            if (completedCallback) {
              completedCallback();
            }
          },
        },
      });
    });
  } else {
    // Making cart loading false if it got enabled from useCart hook
    dispatch(cartToggleLoading(false));
    dispatch(cartToggleError({ errorMessage: CONSTANTS.guidUnavailable }));
    if (completedCallback) {
      completedCallback();
    }
  }
};

export const updateCart = (dispatch: Dispatch, data: { [key: string]: any }) => {
  const {
    guid,
    subTotal,
    shipments,
    totalDiscounts,
    totalPriceWithTax,
    totalItems: totalUnitCount,
  } = data;
  batch(() => {
    dispatch(cartDataSuccess(data));

    dispatch(setShipments(shipments || []));

    dispatch(
      setCartOrderSummaryData({
        subTotal,
        totalDiscounts,
        totalPriceWithTax,
        totalUnitCount,
      })
    );
    updateMiniCart(data, dispatch);

    if (guid !== undefined) {
      dispatch(cartToggleError({ error: false, errorMessage: '' }));
    }
  });
};
