import EOrderSummaryTypes from './orderSummary.type';
import IAction from 'config/redux/actions/interface';
import { IFinalPrice, IPrice } from 'utils/price.util';
import {
  CodFee,
  DeliveryMode,
  LoyaltyVoucher,
  AppliedVoucher,
  CarrefourDeliveryCost,
  DeliveryCost,
} from 'network/response/ICart';
import { MarketPlaceDeliveryCostType } from '../interfaces/marketplace.interface';

export interface IOrderSummaryAmount {
  currencyIso: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface MarketPlaceCodCost {
  paymentCost: IPrice;
  shopName: string;
  shopId: string;
}

export interface IOrderSummaryState {
  carrefourDeliveryCost: CarrefourDeliveryCost;
  deliveryCost: IPrice;
  deliveryMode: DeliveryMode;
  expressFee?: IPrice;
  subTotal: IPrice;
  cartTotal: IPrice;
  totalDiscounts: IPrice;
  totalPriceWithTax: IPrice;
  freeDelivery: boolean;
  freeDeliveryMessage: string;
  freeDeliveryThreshold: number;
  totalItems: number;
  totalUnitCount: number;
  codFee: CodFee;
  loyaltyPoints?: number;
  appliedVouchers: AppliedVoucher[];
  loyaltyVouchers: LoyaltyVoucher[];
  paymentMode: string;
  marketPlaceDeliveryCost?: MarketPlaceDeliveryCostType;
  subtotalMarketPlace?: IPrice;
  orderPaymentCost?: IPrice;
  marketplacePaymentCost?: MarketPlaceCodCost[];
  totalCodFee: DeliveryCost;
  totalAppliedDiscounts: IFinalPrice;
  totalSavings?: IFinalPrice;
  totalBundleDiscount: IFinalPrice;
  serviceFee?: IFinalPrice;
  totalServiceFee?: IFinalPrice;
  totalSurCharge?: IFinalPrice;
  totalDeliveryCost?: IFinalPrice;
  totalFees?: IFinalPrice;
  orderTotalCurrency?: string;
}

export interface ICartOrderSummaryState {
  subTotal: IPrice;
  totalPriceWithTax: IPrice;
  totalDiscounts: IPrice;
  totalUnitCount: number;
}

export const initialOrderSummaryState = {} as IOrderSummaryState;

export const defaultPlasticBagPackingInfoForNoBag = {
  calculated: true,
  quantity: 0,
  totalCharges: {
    currencyIso: 'AED',
    currencyName: 'AED',
    formattedValue: 'AED 0',
    priceType: 'BUY',
    value: 0,
  },
};

const orderSummaryReducer = (
  state: IOrderSummaryState = initialOrderSummaryState,
  action: IAction
) => {
  switch (action.type) {
    case EOrderSummaryTypes.ORDER_SUMMARY_SET_DATA:
      return {
        ...state,
        ...action.payload,
      };
  }

  return state;
};

export default orderSummaryReducer;
