import { Entry, Product } from 'network/response/ICart';
import { Category } from 'pages/Checkout/types/validateCart.types';
import { IConfirmationOrderDetailsResponse } from 'pages/OrderDetail/orderData.type';
import { EShipmentTypes, ISellersEntry, IShipment } from 'services/shipments/types/shipments.types';
import { trimString } from 'utils/helpers.util';
import { initStorageManager } from 'utils/customStorage.util';
import { COUNTRY_CODE_KEY } from 'pages/Cart/constants/cart.constants';
import Transform from 'utils/TransformData';

const storage = initStorageManager(() => sessionStorage);

export const generateProductData = (
  entry: Entry,
  shipmentType: string,
  deliverySlot: any,
  orderNumber: string,
  ga4: boolean = false
) => {
  const {
    product: {
      brandName = '',
      code = '',
      name = '',
      isExpressProduct = false,
      isSubstituted = false,
      mainOffer: { isOffer = false, code: mainOfferCode = '' } = {},
      stock: { stockLevel = '' } = {},
      soldByWeight = false,
      supplier = '',
      warranty = '',
      mainOffer = { code: '', shopCode: '' },
      onlineName = '',
    } = {} as Product,
    basePrice,
    offer: { price: { differenceInPercentForPromo = '' } = {} } = {},
    quantity,
    totalPrice,
    bundleId,
    campaignId,
  } = entry;
  const countryCode = storage.getItem(COUNTRY_CODE_KEY) || undefined;
  const isOfferProduct = shipmentType === EShipmentTypes.MARKETPLACE && isOffer;
  const categories = entry.product.categories || [];
  let productAttributes: any = {};
  if (!ga4) {
    productAttributes = {
      name: name
        .toLowerCase()
        .split(' ')
        .join('_'),
      price: soldByWeight ? totalPrice.value : basePrice.value,
      quantity: soldByWeight ? 1 : quantity,
      id: code,
      ...Transform.getBundleData(bundleId, campaignId),
      category: categories
        .map((category: Category) => {
          return category.code;
        })
        .slice(0, 2)
        .join(','),
      brand: brandName,
      variant: isExpressProduct ? 'express' : '',
      dimension7: shipmentType === EShipmentTypes.STANDARD_FOOD ? 'food' : 'non_food',
      dimension14: stockLevel ? 'in_stock' : 'out_of_stock',
      // TODO: Need to verify
      dimension22: deliverySlot && deliverySlot.dayOfWeek,
      dimension23: isOfferProduct ? 'TRUE' : 'FALSE',
      // TODO: Need to verify
      dimension37: deliverySlot && deliverySlot.pos,
      dimension39: '',
      dimension52: shipmentType === EShipmentTypes.MARKETPLACE ? 'TRUE' : 'FALSE',
      dimension80: '',
      dimension81: '',
      dimension93: code,
      dimension94: orderNumber,
      dimension108: isExpressProduct,
      dimension60: shipmentType,
      dimension73: isSubstituted,
      dimension20: warranty,
      dimension109: shipmentType === EShipmentTypes.CNC ? 'Click-and-collect' : 'home delivery',
    };
    if (isOfferProduct) {
      productAttributes.dimension51 = differenceInPercentForPromo;
      productAttributes.dimension54 = mainOfferCode;
    }
    if (shipmentType === EShipmentTypes.STANDARD_FOOD || shipmentType === EShipmentTypes.CNC) {
      productAttributes.dimension61 =
        deliverySlot &&
        deliverySlot.beginTime &&
        deliverySlot.endTime &&
        `${deliverySlot.beginTime} ${deliverySlot.endTime}`;
    }
    if (shipmentType === EShipmentTypes.MARKETPLACE) {
      productAttributes.dimension53 = supplier;
    }
  } else {
    productAttributes = {
      item_name: trimString(onlineName),
      item_id: `${code}_${countryCode}`,
      ...Transform.getBundleData(bundleId, campaignId),
      price: soldByWeight ? totalPrice.value : basePrice.value,
      quantity: soldByWeight ? 1 : quantity,
      item_brand: `${shipmentType === EShipmentTypes.STANDARD_FOOD ? 'Food' : 'NonFood'}_${
        brandName ? brandName : ''
      }`,
      item_category: '',
      item_category2: '',
      currency: totalPrice.currencyName,
      item_variant: `now=${isExpressProduct ? true : false}_spon=false_var=_end`,
      discount:
        (totalPrice.originalPrice && totalPrice.originalPrice.value - totalPrice.value) || '',
      item_list_name: '',
      affiliation:
        shipmentType === EShipmentTypes.MARKETPLACE
          ? trimString(`${mainOffer.code}|${mainOffer.shopCode}`)
          : 'Carrefour',
    };
  }

  return productAttributes;
};

export const generateNewProductData = (entry: Entry, shipmentType: string) => {
  const {
    product: {
      code = '',
      soldByWeight = false,
      onlineName = '',
      measure,
      mainOffer = { code: '', shopCode: '' },
    } = {} as Product,
    basePrice,
    quantity,
    totalPrice,
    bundleId,
    campaignId,
  } = entry;

  const countryCode = storage.getItem(COUNTRY_CODE_KEY) || undefined;

  return {
    item_name: trimString(onlineName),
    item_id: `${code}_${countryCode}`,
    ...Transform.getBundleData(bundleId, campaignId),
    price: soldByWeight ? totalPrice.value : basePrice.value,
    quantity: soldByWeight ? quantity / 1000 : quantity,
    measure,
    affiliation:
      shipmentType === EShipmentTypes.MARKETPLACE
        ? trimString(`${mainOffer.code}|${mainOffer.shopCode}`)
        : 'Carrefour',
  };
};

export const getShipmentAllEntries = (shipment: IShipment) => {
  let entries: Entry[] = [];

  if (shipment.shipmentType === EShipmentTypes.MARKETPLACE) {
    shipment.sellersEntries.forEach((sellerEntry: ISellersEntry) => {
      entries = [...entries, ...sellerEntry.entries];
    });
  } else {
    entries = shipment.entries;
  }
  return entries;
};

export const getPurchasedProductsData = (
  orderDetailResponse: IConfirmationOrderDetailsResponse,
  ga4: boolean = false
) => {
  const productIDs: string[] = [];
  const quantities: string[] = [];
  const prices: number[] = [];
  const gaProductList: any[] = [];
  const gaProductListNew: any[] = [];

  const { shipments = [], code: orderNumber } = orderDetailResponse;

  shipments.forEach((shipment: IShipment) => {
    const entries: Entry[] = getShipmentAllEntries(shipment);
    const { deliverySlot = null, shipmentType = '' } = shipment;

    entries.forEach((entry: Entry) => {
      const { product, totalPrice = { value: 0 } } = entry;

      productIDs.push(product.code);
      prices.push(totalPrice.value);
      quantities.push('1');
      gaProductList.push(generateProductData(entry, shipmentType, deliverySlot, orderNumber, ga4));
      if (ga4) {
        gaProductListNew.push(Transform.productDataFrom(entry, {}, true));
      }
    });
  });

  return {
    productIDs,
    prices,
    quantities,
    gaProductList,
    gaProductListNew,
  };
};
