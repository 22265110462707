import { Dispatch } from 'redux';

import EShareCartTypes, { IApiResponse } from './shareCart.type';
import { SHARE_CART_API } from '../../../constants/apis.constant';

export const generateUrl = () => (dispatch: Dispatch) => {
  dispatch({
    type: EShareCartTypes.SHARE_CART_GENERATE_URL,
    payload: {
      enableProgress: false,
      onSuccess: ({ url, ...restData }: IApiResponse) => {
        dispatch(updateShareCartUrl(url, restData));
      },
      onFailure: () => dispatch(toggleVisibility(false)),
      url: SHARE_CART_API,
    },
  });
};

export const toggleVisibility = (visible: boolean) => ({
  type: EShareCartTypes.SHARE_CART_TOGGLE_VISIBLE,
  payload: visible,
});

export const updateShareCartUrl = (url: string, extraData: any = {}) => ({
  type: EShareCartTypes.SHARE_CART_GENERATE_URL_SUCCESS,
  payload: {
    ...extraData,
    shareUrl: url,
  },
});
