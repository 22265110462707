export interface I3DSDATA {
  data?: I3DSDATASUCCESS;
  errorData?: {
    code: string;
    message: string;
  };
  result: string;
}

export interface I3DSDATASUCCESS {
  _3dSecureId: string;
  cardEnrolled: boolean;
  htmlBodyContent?: string;
}

export interface ITRANSACTIONCOMPLETE {
  data?: {
    amount: number;
    cardNumber: string;
    cardType: string;
    currency: string;
    orderId: string;
    trnasactionReference: string;
  };
  errorData?: {
    code: string;
    message: string;
  };
  result: string;
}

export enum PaymentServiceActionTypes {
  PAYMENT_SERVICE_THREE_DS_ENROLLMENT = 'PAYMENT_SERVICE/THREE_DS_ENROLLMENT',
  PAYMENT_SERVICE_THREE_DS_DATA_SAVE = 'PAYMENT_SERVICE/THREE_DS_DATA_SAVE',
  PAYMENT_SERVICE_COMPLETE_TRANSACTION = 'PAYMENT_SERVICE/COMPLETE_TRANSACTION',
  PAYMENT_SERVICE_INPUT_FIELDS_ERROR_SAVE = 'PAYMENT_SERVICE/INPUT_FIELDS_ERROR_SAVE',
}

export enum InitPaymentActionTypes {
  INIT_PAYMENT_SUCCESS = 'INIT_PAYMENT_SUCCESS',
  INIT_PAYMENT_FAILURE = 'INIT_PAYMENT_FAILURE',
}
export default PaymentServiceActionTypes;
