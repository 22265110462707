import EPageLoaderTypes from './pageLoader.type';
import { ReactNode } from 'react';

export const showPageLoader = (message?: string | ReactNode) => ({
  type: EPageLoaderTypes.PAGE_LOADER_SHOW,
  payload: message,
});

export const hidePageLoader = () => ({
  type: EPageLoaderTypes.PAGE_LOADER_HIDE,
});
