import PaymentService from './../core/PaymentService.abstract';
import {
  ESupportedPaymentStrategy,
  ICardConfiguration,
  PaymentService_EVENTS,
  IPaymentServiceMessage,
  InputEventType,
  MasterCardStatusType,
  MasterCardSessionErrors,
} from '../Payments.types';
import installScript from '../utils/scriptLoader';
import { PaymentServiceScriptInstallError, PaymentServiceNotReadyError } from '../core/errors';
import FEATURES_CONFIG from 'config/features';
import i18n from 'config/localisation';
import MPGSLog from '../utils/logger';
import { debounce } from 'debounce';

import { reloadPage } from 'utils/url.util';
import { handleExpiryDateValidations } from 'pages/Checkout/components/Payment/components/NewCardForm/MasterCardForm/MasterCardForm.helper';
declare global {
  interface Window {
    PaymentSession: any;
  }
}

type MPGSListener = (event: string, payload?: any) => void;

export default class MasterCard extends PaymentService {
  MPGS_VERSION = 56;
  MPGS_URI = `${FEATURES_CONFIG.settings.mpgsBaseURL ||
    process.env.REACT_APP_MPGS_BASE}/form/version/${this.MPGS_VERSION}/merchant/${
    FEATURES_CONFIG.settings.masterCardMerchantKey
  }/session.js`;

  name = ESupportedPaymentStrategy.masterCard;
  paymentSession: any = {};
  private eventListener: MPGSListener | null = null;
  private cardConfig!: ICardConfiguration;

  constructor(config: { cardConfig: ICardConfiguration }) {
    super();
    this.updateListenerAboutFormUpdate = this.updateListenerAboutFormUpdate.bind(this);
    this.cardConfig = config.cardConfig;
    MPGSLog('MasterCard service created');
  }

  get isEnabledFromConfig() {
    return FEATURES_CONFIG.settings.enableMasterCard;
  }

  setupSession() {
    MPGSLog('MasterCard service setting up session');
    this.paymentSession.configure({
      fields: {
        card: this.cardConfig,
      },
      frameEmbeddingMitigation: ['javascript'],
      // locale: i18n.language === 'ar' ? 'ar_AE' : 'en_US',
      locale: 'en_US',
      callbacks: {
        initialized: (response: any) => {
          this.triggerEvent(
            PaymentService_EVENTS.hostedSessionInitialized,
            response.status === 'ok'
          );
        },
        formSessionUpdate: debounce(this.updateListenerAboutFormUpdate, 1000),
      },
      interaction: {
        displayControl: {
          formatCard: 'EMBOSSED',
          invalidFieldCharacters: 'ALLOW',
        },
      },
    });
  }

  setupInputListener() {
    MPGSLog('MasterCard service setting input listener');
    this.paymentSession.onBlur(
      [
        'card.nameOnCard',
        'card.number',
        'card.expiryMonth',
        'card.expiryYear',
        'card.securityCode',
      ],
      (selector: any) => {
        this.triggerEvent(PaymentService_EVENTS.cardInputEvent, {
          event: PaymentService_EVENTS.cardInputEvent,
          payload: {
            actionType: InputEventType.onBlur,
            inputParamSelector: selector,
          },
        });
      }
    );
  }

  installEventListener(listener: (event: string, payload: any) => void) {
    this.eventListener = listener;
  }

  initiateCapture() {
    this.paymentSession.updateSessionFromForm('card');
    // this.paymentSession.updateSessionFromForm('card'); //not working somehow will check it later calling ms function directly for now
  }

  loadScript() {
    MPGSLog('MasterCard service attempting to load script');
    const self = this;
    installScript(this.MPGS_URI)
      .then(() => {
        const { PaymentSession } = window;

        if (!PaymentSession) {
          throw new PaymentServiceNotReadyError('PaymentSession not available');
        }
        self.paymentSession = PaymentSession;
        self.setupSession();
        self.setupInputListener();
      })
      .catch(e => {
        if (!(e instanceof PaymentServiceScriptInstallError)) {
          throw e;
        }
        throw new PaymentServiceScriptInstallError(this.MPGS_URI);
      });
  }

  private updateListenerAboutFormUpdate(response: any) {
    const updatedResponse = handleExpiryDateValidations(response);
    const { status, session, sourceOfFunds } = updatedResponse;
    let message: IPaymentServiceMessage = {
      event: PaymentService_EVENTS.receivedSessionResponse,
      status,
    } as IPaymentServiceMessage;
    switch (status) {
      case MasterCardStatusType.ok:
        const { type, provided } = sourceOfFunds;
        // success case
        message = {
          ...message,
          payload: {
            session,
            ok: true,
            message: 'FORM_UPDATE_SUCCESS',
            type,
            card: provided,
            securityCodeProvided: !!provided.card.securityCode,
          },
        };
        break;
      case MasterCardStatusType.fieldInError:
        message = {
          ...message,
          payload: {
            ok: false,
            error: updatedResponse.errors,
            message: `${status}`,
            containsValidationErrors: status === 'fields_in_error',
          },
        };
        break;
      case MasterCardStatusType.requestTimeOut:
      case MasterCardStatusType.systemError:
        if (updatedResponse.errors.message.startsWith(MasterCardSessionErrors.sessionExpired)) {
          reloadPage(i18n.t('errorSessionExpiry'));
          return;
        } else {
          message = {
            ...message,
            payload: {
              ok: false,
              message: `${updatedResponse.errors.message}`,
              error: updatedResponse.errors,
            },
          };
        }
        break;
    }
    this.triggerEvent(PaymentService_EVENTS.receivedSessionResponse, message);
  }

  private triggerEvent(eventName: string, payload: any) {
    if (this.eventListener) {
      this.eventListener(eventName, payload);
    }
  }
}
