import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import IAction from 'config/redux/actions/interface';

export interface IPromo {
  loading: boolean;
  error: string;
  errorPromoCode: string;
}

export const promoLoadingStart = (state: ICheckoutState, action: IAction) => {
  return { ...state, promo: { error: '', loading: true, errorPromoCode: '' } };
};

export const promoLoadingReset = (state: ICheckoutState, action: IAction) => {
  return { ...state, promo: { error: '', loading: false, errorPromoCode: '' } };
};

export const promoLoadingEnd = (state: ICheckoutState, action: IAction) => {
  return { ...state, promo: { ...state.promo, loading: false } };
};

export const applyPromoFail = (state: ICheckoutState, action: IAction) => {
  const {
    payload: { t, error, errorPromoCode },
  } = action;
  return {
    ...state,
    promo: { loading: false, error: error || t('promoCodeInvalid'), errorPromoCode },
  };
};
