import { isTestNodeEnv } from 'utils/env.util';

const isProdEnv = (): boolean => process.env.REACT_APP_ENV === 'prod';

declare global {
  interface Window {
    ENABLE_MPGS_LOGIN: any;
  }
}

export default function MPGSLog(...args: any) {
  if (isProdEnv() || isTestNodeEnv()) {
    return;
  }
}
