import {
  ESessionAreaActionTypes,
  TSessionAreaAction,
  ISessionAreaState,
  TSessionArea,
} from '../types/sessionArea.types';

export const sessionAreaInitialState = {
  sessionArea: '',
} as ISessionAreaState;

export default {
  sessionArea: (
    state: TSessionArea = sessionAreaInitialState.sessionArea,
    action: TSessionAreaAction
  ): TSessionArea => {
    switch (action.type) {
      case ESessionAreaActionTypes.SESSION_AREA_SET_STATE:
        return action.payload;

      default:
        return state;
    }
  },
};
