import { Dispatch } from 'redux';
import { hidePageLoader, showPageLoader } from 'common/PageLoader/redux/pageLoader.action';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { MPesaTypes, MPesaInitResponse, MPesaTransactResponse } from './mpesa.types';
import history from 'routes/customHistory';
import { showNotification } from 'common/Notification/redux/notification.action';
import i18n from 'config/localisation';
import { MPESA_TRANSACT_INIT, MPESA_TRANSACT_FINAL } from 'pages/Checkout/constants/apis.constant';
import { IAppState } from '@mafc/common';
import { notificationTypes } from 'common/Notification/redux/notification.type';

const { REACT_APP_PAYMENT_WALLET_API_KEY: PAYMENT_SERV_API_KEY } = process.env;

const mpesaNextTansaction = (response: MPesaInitResponse) => (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  const {
    data: { responseCode },
  } = response;
  const state = getState();
  const { storeId } = state.appConfig;
  dispatch({
    type: MPesaTypes.PAYMENT_SERVICE_INITIATE_M_PESA_TRANSACTION,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url: MPESA_TRANSACT_FINAL,
      isAbsUrl: true,
      headers: {
        'x-api-key': PAYMENT_SERV_API_KEY,
      },
      data: {
        sid: responseCode,
        paymentWallet: 'ipaympesa',
        siteId: storeId,
      },
      onSuccess: (transactResponse: MPesaTransactResponse) => {
        dispatch(hidePageLoader());
        if (transactResponse.meta.code === '000') {
          const { orderId } = transactResponse.data;
          history.push(`/app/checkout/orderConfirmation/${orderId}?orderType=mpesa`);
        } else {
          // failure
          dispatch(handleError(transactResponse.meta.message));
        }
      },
      onFailure: () => {
        dispatch(hidePageLoader());
        dispatch(handleError(i18n.t('errorPlacingOrder')));
      },
      timeout: 900000,
    },
  });
};

export const initiateMpesaTransaction = ({
  orderFieldMap,
  orderNumber,
}: {
  orderFieldMap: { [key: string]: string };
  orderNumber: string;
  [key: string]: any;
}) => (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch(showPageLoader('Please approve the transaction on your mobile'));
  const state = getState();
  const { storeId } = state.appConfig;
  const { orderId, emailAddress, mobileAccountNo, transactionAmount } = orderFieldMap;
  dispatch({
    type: MPesaTypes.PAYMENT_SERVICE_INITIATE_M_PESA_TRANSACTION,
    payload: {
      isAbsUrl: true,
      method: EApiMiddlewareMethod.POST,
      url: MPESA_TRANSACT_INIT,
      headers: {
        'X-Api-Key': PAYMENT_SERV_API_KEY,
      },
      data: {
        emailAddress,
        mobileAccountNo,
        transactionAmount,
        signature: '',
        paymentWallet: 'ipaympesa',
        siteId: storeId,
        autopay: 0,
      },
      params: { orderId },
      onSuccess: (response: MPesaInitResponse) => {
        if (response.meta.code === '000') {
          dispatch<any>(mpesaNextTansaction(response));
        } else {
          // failure
          dispatch(handleError(response.meta.message));
        }
      },
      onFailure: () => {
        dispatch(hidePageLoader());
        dispatch(handleError(i18n.t('mpesaNoAccount')));
      },
      timeout: 80000,
    },
  });
};

const handleError = (message: string) =>
  showNotification({
    type: notificationTypes.errorCheckout,
    message,
  });
