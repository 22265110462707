import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { hideNotification, showNotification } from 'common/Notification/redux/notification.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import {
  IUpdateQuantity,
  IUpdateQuantityTracking,
  trackCartUpdation,
} from 'pages/Cart/components/UpdateQuantity/redux/updateQuantity.action';
import {
  DELETE_ITEM_FROM_CART_API,
  UPDATE_QUANTITY_API,
} from 'pages/NewCart/constants/api.constants';
import { IQuantityUpdateSuccess } from 'pages/Cart/components/UpdateQuantity/UpdateQuantity.interface';
import qtyRestrictionHandler from 'pages/Cart/redux/actions/qtyRestriction';
import { getGUID } from 'utils/cookies.util';
import {
  CART_UPDATE_ENTRY_DETAIL,
  CART_UPDATE_ENTRY_TOGGLE_LOADING,
  CART_UPDATE_QUANTITY,
} from '../constants/cart.types';
import { fetchCart, updateCart } from './fetchCart.actions';
import { cartReponseShaper } from './cartAction.helper';
import { Dispatch } from 'redux';
import { hidePageLoader, showPageLoader } from 'common/PageLoader/redux/pageLoader.action';

export const updateEntryToggleLoading = (cartEntryNumber: number | string, loading: boolean) => ({
  type: CART_UPDATE_ENTRY_TOGGLE_LOADING,
  payload: { cartEntryNumber, loading },
});

export const updateQuantityCartEntry = (
  dispatch: any,
  updateQtyParams: IUpdateQuantity,
  entries: any[],
  areaCode: string,
  trackingData: IUpdateQuantityTracking
) => {
  const guid = getGUID();
  let apiUrl = guid ? `${UPDATE_QUANTITY_API}&guid=${guid}` : UPDATE_QUANTITY_API;
  apiUrl = updateQtyParams.cp ? `${apiUrl}&cp=${updateQtyParams.cp}` : apiUrl;
  const {
    cartEntryNumber,
    qty,
    isOffer = false,
    offerId = '',
    loadingEntryNumber,
    intent,
    lm,
    bundleId,
    originalQuantity,
  } = updateQtyParams;
  const journeyIntent = intent
    ? {
        intent,
      }
    : {};
  dispatch(
    updateEntryToggleLoading(loadingEntryNumber ? loadingEntryNumber : cartEntryNumber, true)
  );
  dispatch(hideNotification());
  dispatch({
    type: CART_UPDATE_QUANTITY,
    payload: {
      apiVersion: 3,
      enableProgress: false,
      data: {
        productId: cartEntryNumber,
        quantity: qty,
        ...journeyIntent,
        offerId: isOffer ? `offer_${offerId}` : offerId,
        ...(bundleId && { bundleId }),
        ...(originalQuantity && { originalQuantity }),
      },
      headers: { 'content-type': 'application/json' },
      method: EApiMiddlewareMethod.PUT,
      onSuccess: ({
        cart,
        entry,
        statusCode,
        maxAllowedQuantity,
        subFamilyName,
      }: IQuantityUpdateSuccess) => {
        trackCartUpdation(
          trackingData.actionType === 'add',
          entry,
          undefined,
          trackingData.updateType,
          {
            cartValue: cart.subTotal.value,
          }
        );
        // Update affected entry
        dispatch(qtyRestrictionHandler({ statusCode, maxAllowedQuantity, subFamilyName, entry }));
        dispatch({
          type: CART_UPDATE_ENTRY_DETAIL,
          payload: { entry },
        });
        updateCart(dispatch, cartReponseShaper(cart));
        // dispatch(getEarnedPoints());
      },
      onFailure: (msg: any, error: any) => {
        const isOOSItem =
          (((((error && error.response) || {}).data || {}).errors || [])[0] || {}).reason ===
          'notFound';
        if (isOOSItem) {
          // Make sure to update overall cart data including order summary's
          dispatch(fetchCart());
          if (entries) {
            // Extracts entry name and url from Cart's entries array
            const { onlineName = '', url = '' } =
              (entries.filter(entry => entry.entryNumber === cartEntryNumber)[0] || {}).product ||
              {};
            dispatch(
              showNotification({
                type: notificationTypes.errorCheckout,
                message: `Unfortunately ${
                  onlineName && url
                    ? `<a href="${url}" style="color:#0E5AA7">${onlineName}</a>`
                    : 'a product'
                } was removed from your cart as it is out of stock.`,
              })
            );
          }
        }
      },
      onFinally: () => {
        dispatch(
          updateEntryToggleLoading(loadingEntryNumber ? loadingEntryNumber : cartEntryNumber, false)
        );
      },
      params: {
        areaCode,
        lm,
      },
      url: apiUrl,
    },
  });
};

export const deleteCartEntry = (
  dispatch: Dispatch,
  {
    cartEntryNumber,
    offerId,
    intent,
    oldCartState = {},
    isOOS = false,
    lm = false,
    bundleId,
    originalQuantity,
    cp,
  }: IUpdateQuantity,
  areaCode: string
) => {
  const guid = getGUID();
  let url = guid ? `${DELETE_ITEM_FROM_CART_API}&guid=${guid}` : DELETE_ITEM_FROM_CART_API;
  url = cp ? `${url}&cp=${cp}` : url;
  const journeyIntent = intent
    ? {
        intent,
      }
    : {};
  dispatch(showPageLoader());
  dispatch(hideNotification());
  dispatch({
    type: CART_UPDATE_QUANTITY,
    payload: {
      apiVersion: 3,
      enableProgress: false,
      method: EApiMiddlewareMethod.DELETE,
      onSuccess: (data: { [key: string]: any }) => {
        let index = 0;
        oldCartState.cartData.entries.map(({ entryNumber }: any, counter: number) => {
          if (entryNumber === cartEntryNumber) {
            index = counter;
          }
          return null;
        });
        if (!isOOS) {
          trackCartUpdation(false, oldCartState.cartData, index, 'button_remove', {
            cartValue: data.cart.subTotal.value,
          });
        }
        updateCart(dispatch, cartReponseShaper(data.cart));
        // dispatch(getEarnedPoints() as any);
        dispatch(hidePageLoader());
      },
      onFailure: () => {
        dispatch(hidePageLoader());
      },
      data: {
        deleteItems: [
          {
            productId: cartEntryNumber,
            offerId: offerId ? `offer_${offerId}` : '',
            ...(bundleId && { bundleId }),
            ...(originalQuantity && { originalQuantity }),
            ...journeyIntent,
          },
        ],
      },
      params: {
        areaCode,
        lm,
      },
      url,
    },
  });
};

export const deleteCartEntries = (
  dispatch: Dispatch,
  entries: Array<{ cartEntryNumber: number; offerId: string }>,
  areaCode: string,
  lm: boolean
) => {
  const guid = getGUID();
  const url = guid ? `${DELETE_ITEM_FROM_CART_API}&guid=${guid}` : DELETE_ITEM_FROM_CART_API;

  dispatch(hideNotification());
  dispatch({
    type: CART_UPDATE_QUANTITY,
    payload: {
      apiVersion: 3,
      enableProgress: false,
      method: EApiMiddlewareMethod.DELETE,
      onSuccess: (data: { [key: string]: any }) => {
        updateCart(dispatch, cartReponseShaper(data.cart));
      },
      onFailure: () => {
        dispatch(hidePageLoader());
      },
      data: {
        deleteItems: entries.map(({ cartEntryNumber, offerId }) => ({
          productId: cartEntryNumber,
          offerId: offerId ? `offer_${offerId}` : '',
        })),
      },
      params: {
        areaCode,
        lm,
      },
      url,
    },
  });
};
