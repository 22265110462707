export const DEFAULT = 'DEFAULT';

const EXPRESS = 'EXPRESS';

const QCOMM = 'QCOMM';

const NONFOOD = 'NONFOOD';

const SLOTTED = 'SLOTTED';

export const SHIPMENT_IDENTIFIRES = {
  DEFAULT,
  QCOMM,
  EXPRESS,
  NONFOOD,
  SLOTTED,
};
