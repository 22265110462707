import { Dispatch } from 'redux';
import {
  CREATE_ORDER_URL_EASY_PAISA,
  CREATE_ORDER_URL_V2,
  SPLIT_PAYMENT_API,
} from '../../constants/apis.constant';
import CheckoutActionTypes, {
  CHECKOUT_CREATE_ORDER_SUCCESS,
  CHECKOUT_SPLIT_PAYMENT_SERVICE_START,
  CHECKOUT_SPLIT_PAYMENT_SERVICE_SUCCESS,
} from '../Checkout.types';
import EApiMiddlewareMethod from 'config/redux/middlewares/api.middleware.enum';
import { hidePageLoader } from 'common/PageLoader/redux/pageLoader.action';
import { showNotification, hideNotification } from 'common/Notification/redux/notification.action';
import { fetchCart } from 'pages/Cart/redux/actions';
import { IAppState } from 'config/redux/reducers';
import OrderErrors, {
  alertMessageKey,
  getAlertMessageExpress,
} from 'pages/Checkout/types/orderError.types';
import { getCartId, goToCartHandler } from 'pages/Cart/helpers/url.helpers';
import history from 'routes/customHistory';
import extractShipment from 'services/shipments/helpers/extractShipment';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import { toggleVisibility, fetchSlots } from 'common/DeliverySlot/redux/deliverySlot.action';
import { isExisty, isNonEmptyObject } from 'utils/helpers.util';
import { setShipments } from 'services/shipments/redux/shipments.actions';
import { initStorageManager } from 'utils/customStorage.util';
import { getAreacode, fetchCncSlots } from './cnc.action';
import EPaymentMethods, {
  ESelectedPaymentMethods,
} from 'pages/Checkout/components/Payment/PamentMethods.enum';
import { createVDayNote } from './mkp.action';
import { removeRedeemedLoyaltyPoints } from 'pages/Checkout/components/LoyaltyPoints/redux/LoyaltyPoints.actions';
import Constants from 'config/constants';
import { notificationTypes } from 'common/Notification/redux/notification.type';
import { batch } from 'react-redux';
import isPaymentV2Enabled from 'pages/Checkout/components/Payment/helpers/isPaymentV2Enabled';
import randomString from 'utils/randomString';
import {
  GATEWAY_TYPE,
  ORDER_ID,
  TRANSACTION_DATA,
  TRANSACTION_ID,
} from 'pages/Checkout/constants/checkout.constant';
import { isNewOrderConfirmationApiDeployed } from 'pages/OrderConfirmation/utils/orderConfirmation.transform';
import { getCardTypeForIcon } from 'pages/Checkout/components/Payment/helpers/payment.helpers';
import { getTrackingData, modifiyOrderData } from 'pages/Checkout/helpers/orderAction.helper';
import { TRACKING_TRANSACTION } from 'pages/Cart/constants/new.apis.constant';
import { getCustomCookie } from 'utils/cookies.util';
import { ETrackingTypes } from 'pages/Checkout/types/screen.types';

const storage = initStorageManager(() => sessionStorage);
const lStorage = initStorageManager(() => localStorage);

export const createOrderSuccess = (
  dispatch: Dispatch<any>,
  orderData: any,
  state: any,
  successCallback?: (data: any) => void
) => {
  const transformOrderDetailsData = helperCreateOrderTransform(orderData, state);
  const isNewOrderConfirmationApi: boolean = isNewOrderConfirmationApiDeployed(state);
  if (!isNewOrderConfirmationApi) {
    storage.setItem(Constants.orderDetails, JSON.stringify(transformOrderDetailsData));
  }
  lStorage.removeItem(Constants.redeemedSharePoints);
  // here the call for initate api
  dispatch({ type: CHECKOUT_CREATE_ORDER_SUCCESS, payload: orderData });
  dispatch(createVDayNote(orderData.orderNumber));
  dispatch(hidePageLoader());
  if (successCallback) {
    successCallback(orderData);
  }
};
export const helperCreateOrderTransform = (orderData: any, state: IAppState) => {
  const { orderWsDto: orderDetails, paymentModeSelected: paymentMode } = orderData;
  const {
    code,
    cartId,
    deliveryAddress,
    subTotal,
    totalPrice,
    deliveryCost,
    loyaltyVouchers,
    totalItems,
    user,
    paymentInfo,
    isNewUser,
  } = orderDetails;
  const {
    shipments = [],
    orderSummary,
    checkout: { payment: { paymentDetails = {} } = {} },
  } = state;
  // once we use saved cards then we have to modify this

  const {
    carrefourDeliveryCost,
    marketPlaceDeliveryCost,
    appliedVouchers = [],
    totalAppliedDiscounts,
    totalFees,
    codFee,
  } = orderSummary;
  const { newCardDetails: { card_number = '' } = {} } = paymentDetails;
  const type = getCardTypeForIcon(card_number);
  const updatedPaymentInfo = paymentInfo
    ? paymentInfo
    : {
        billingAddress: {
          ...deliveryAddress,
        },
        cardType: {
          code: type,
          name: type,
        },
      };
  return {
    code,
    cartId,
    deliveryAddress,
    appliedVouchers,
    subTotal,
    totalPrice,
    deliveryCost,
    loyaltyVouchers,
    totalItems,
    user,
    paymentMode,
    shipments,
    carrefourDeliveryCost,
    marketPlaceDeliveryCost,
    paymentInfo: updatedPaymentInfo,
    totalAppliedDiscounts,
    totalFees,
    codFee,
    isNewUser,
  };
};
export const createOrder = ({
  params = {},
}: {
  params: {
    successCallback?: (data: any) => void;
    failureCallback?: (data: any) => void;
    [key: string]: any;
  };
  hasClickAndCollect?: boolean;
}) => (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const {
    successCallback,
    failureCallback,
    paymentModeSelected,
    isCheckoutDotComFlow,
    isCheckoutDotComSupported,
    ...restParams
  } = params;
  const url =
    paymentModeSelected === EPaymentMethods.MOBILE_WALLET
      ? CREATE_ORDER_URL_EASY_PAISA
      : CREATE_ORDER_URL_V2;

  const store = getState();
  const isWebPaymentArchitectureV2Enabled = isPaymentV2Enabled(store);
  lStorage.removeItem(Constants.secondaryPaymentCheckStatus);
  const transactionData = getTrackingData(store.cart.cartData.entries);
  sessionStorage.setItem('transactionData', JSON.stringify(transactionData));
  sessionStorage.setItem(TRANSACTION_DATA, JSON.stringify(transactionData));
  lStorage.removeItem(TRANSACTION_ID);
  lStorage.removeItem(ORDER_ID);
  dispatch({
    type: CheckoutActionTypes.CHECKOUT_CREATE_ORDER,
    payload: {
      method: EApiMiddlewareMethod.POST,
      url,
      params: {
        cartId: getCartId(getState()),
        paymentModeSelected,
        ...restParams,
      },
      onSuccess: (data: any) => {
        let orderData = { ...data };
        orderData = modifiyOrderData(store, orderData, isCheckoutDotComFlow);
        const {
          orderNumber,
          orderWsDto: { cartId, paymentMode, totalPrice },
        } = orderData;
        batch(() => {
          if (isWebPaymentArchitectureV2Enabled) {
            dispatch(
              initPaymentService({
                orderId: orderNumber,
                totalPrice,
                cartId,
                paymentMode,
                orderData,
                isCheckoutDotComSupported,
                successClback: () => {
                  const currentState = getState();
                  createOrderSuccess(dispatch, orderData, currentState, successCallback);
                },
                failureClback: (error: any, errors: any) => {
                  dispatch(handleCreateOrderErrors(errors, params));
                  dispatch(hidePageLoader());
                  if (failureCallback) {
                    failureCallback(error);
                  }
                },
              })
            );
          } else {
            const currentState = getState();
            createOrderSuccess(dispatch, orderData, currentState, successCallback);
          }
        });
      },
      onFailure: (
        error: any,
        { response: { data: { errors = [] } = {} } = { data: { errors: '' } } }: any
      ) => {
        dispatch(handleCreateOrderErrors(errors, params));
        dispatch(hidePageLoader());
        if (failureCallback) {
          failureCallback(error);
        }
      },
    },
  });
};

export const trackTransaction = (transactionId: string) => (dispatch: Dispatch) => {
  const userId = getCustomCookie(Constants.mafUserId) || '';
  const visitorId = getCustomCookie(Constants.mafSessionId);

  const trackingData = JSON.parse(sessionStorage.getItem(TRANSACTION_DATA) || '{}');

  dispatch({
    payload: {
      headers: {
        userId,
        visitorId,
      },
      apiVersion: 1,
      method: EApiMiddlewareMethod.POST,
      data: { ...trackingData, 'transaction-id': transactionId },
      url: TRACKING_TRANSACTION,
    },
    type: ETrackingTypes.TRACKING_TRANSACTION,
  });
};

export const initPaymentService = (params: {
  successClback?: (data: any) => void;
  failureClback?: (error: any, errors: any) => void;
  [key: string]: any;
}) => (dispatch: Dispatch<any>, getState: () => IAppState) => {
  const state = getState();
  const { storeId: siteId, currency } = state.appConfig;
  const {
    successClback,
    failureClback,
    paymentMode,
    orderId,
    cartId,
    orderData,
    totalPrice,
    isCheckoutDotComSupported,
  } = params;
  const { value } = totalPrice;
  const { orderFieldMap = {} } = orderData;
  let payMode = orderFieldMap.payment_method || paymentMode;
  payMode =
    payMode.toLowerCase() === EPaymentMethods.CASHBACK
      ? ESelectedPaymentMethods.CASHBACK
      : payMode.toUpperCase();

  let paymentGateway = '';

  if (ESelectedPaymentMethods.CARD.toLowerCase() === payMode.toLowerCase()) {
    paymentGateway = isCheckoutDotComSupported ? GATEWAY_TYPE.CHECKOUT : GATEWAY_TYPE.CYBER_SOURCE;
  }

  dispatch({
    type: CHECKOUT_SPLIT_PAYMENT_SERVICE_START,
    payload: {
      isAbsUrl: true,
      method: EApiMiddlewareMethod.POST,
      url: SPLIT_PAYMENT_API,
      data: {
        siteId,
        payMode,
        currency,
        paymentGateway,
        cartId,
        orderId,
        payRequestBody: {
          amount: value,
        },
      },
      params: {
        requestTime: Date.now(),
        requestId: randomString(20, 'n'),
      },
      headers: {
        'channel-code': process.env.REACT_APP_CHANNEL_CODE,
        'x-api-key': process.env.REACT_APP_SECRET_KEY,
      },
      onSuccess: (data: any) => {
        dispatch({ type: CHECKOUT_SPLIT_PAYMENT_SERVICE_SUCCESS });
        lStorage.setItem(
          Constants.secondaryPaymentCheckStatus,
          data.paymentGatewayRequest || false
        );
        if (successClback) {
          successClback(data);
        }
      },
      onFailure: (
        error: any,
        { response: { data: { errors = [] } = {} } = { data: { errors: '' } } }: any
      ) => {
        if (failureClback) {
          failureClback(error, errors);
        }
      },
    },
  });
};

export const removeCashbackPoints = (callback?: () => void) => async (
  dispatch: Dispatch,
  getState: () => IAppState
) => {
  // remove redeemed loyalPoints on create order failure
  const {
    cart: {
      cartData: { loyaltyVouchers = [], totalPrice: { value: cartTotalAmount = 0 } = {} } = {},
    } = {},
    loyalty: { totalPointsValue = 0 } = {},
  } = getState();
  const voucher = isExisty(loyaltyVouchers) ? loyaltyVouchers[0] : null;
  const redeemed = isExisty(voucher);
  if (redeemed) {
    const isFullCartRedemption = totalPointsValue >= cartTotalAmount;
    await dispatch(removeRedeemedLoyaltyPoints(isFullCartRedemption) as any);
    if (callback) {
      callback();
    }
  } else {
    if (callback) {
      callback();
    }
  }
};

export const handleCreateOrderErrors = (errors: any[], params: any) => (
  dispatch: Dispatch<any>,
  getState: () => IAppState
) => {
  const error = errors[0] || {};
  switch (error.subject) {
    case OrderErrors.CART_EMPTY:
      alert(error.message);
      dispatch(setShipments([]));
      goToCartHandler();
      dispatch(removeCashbackPoints());
      break;

    case OrderErrors.CART_NOT_CALCULATED:
      dispatch(cartNotCalculated(params, error));
      dispatch(showNotification({ type: notificationTypes.errorCheckout, message: error.message }));
      dispatch(dispatchCheckoutError(error));
      dispatch(removeCashbackPoints());
      break;

    case OrderErrors.EXPIRED_SLOT_STANDARD:
    case OrderErrors.EXPIRED_SLOT_CNC:
      const store = getState();
      alert(error.message);
      if (!store.deliverySlot.slots) {
        if (error.subject === OrderErrors.EXPIRED_SLOT_STANDARD) {
          dispatch(fetchSlots(getAreacode(store)) as any);
        } else {
          dispatch(fetchCncSlots());
        }
      }
      dispatch(toggleVisibility(true)); // Show slot change drawer
      dispatch(dispatchCheckoutError(error));
      dispatch(removeCashbackPoints());
      break;

    case OrderErrors.EXPIRED_SLOT_EXPRESS:
      dispatch(
        removeCashbackPoints(() => {
          history.go(0); // refresh checkout
          storage.setItem(alertMessageKey, getAlertMessageExpress());
        })
      );

      break;

    case OrderErrors.PMV_101:
    case OrderErrors.PMV_102:
    case OrderErrors.PMV_103:
    case OrderErrors.EXPIRED_SLOT_QCOMM:
      dispatch(dispatchCheckoutError(error));
      break;

    case OrderErrors.EXPIRED_SLOT:
    default:
      dispatch(
        removeCashbackPoints(() => {
          dispatch(deliverySlotExpired());
          dispatch(
            showNotification({ type: notificationTypes.errorCheckout, message: error.message })
          );
          dispatch(dispatchCheckoutError(error));
        })
      );
      break;
  }
};

const cartNotCalculated = (params: any, error: any) => (
  dispatch: Dispatch<any>,
  getState: () => IAppState
) => {
  const { placeOrderError } = getState().checkout;
  if (!placeOrderError.subject || placeOrderError.subject !== OrderErrors.CART_NOT_CALCULATED) {
    // if this error happened for the first time hit the cart api and then try to place order
    dispatch(
      fetchCart(() => {
        dispatch(createOrder({ params }));
      })
    );
  } else {
    // if error happened again then return to cart page post showing the alert
    alert(error.message);
    goToCartHandler();
  }
};

export const deliverySlotExpired = () => (dispatch: Dispatch, getState: () => IAppState) => {
  const { shipments } = getState();
  const expressShipment = extractShipment(shipments, EShipmentTypes.EXPRESS);
  const standardFoodShipment = extractShipment(shipments, EShipmentTypes.STANDARD_FOOD);
  const cncShipment = extractShipment(shipments, EShipmentTypes.CNC);

  const isExpressPresent = isNonEmptyObject(expressShipment);
  const isStandardFoodPresent = isNonEmptyObject(standardFoodShipment);
  const isCncPresent = isNonEmptyObject(cncShipment);

  if ((isExpressPresent && isStandardFoodPresent) || isExpressPresent) {
    history.go(0); // refresh checkout
    storage.setItem(alertMessageKey, getAlertMessageExpress());
    return;
  }
  if (isStandardFoodPresent || isCncPresent) {
    dispatch(toggleVisibility(true));
  }
};

export const clearCreateOrderErrors = () => (
  dispatch: Dispatch<any>,
  getState: () => IAppState
) => {
  const {
    checkout: { placeOrderError },
  } = getState();
  const isError = isNonEmptyObject(placeOrderError);
  if (isError) {
    dispatch(dispatchCheckoutError({}));
    dispatch(hideNotification());
  }
};

export const dispatchCheckoutError = (payload: any): { type: string; payload: any } => ({
  type: CheckoutActionTypes.CHECKOUT_PLACE_ORDER_ERROR,
  payload,
});

export default createOrder;
