/**
 * This contains the entire logic related to App Cookie + Configuration
 *
 * NOTES:
 * It reads the browser cookie `storeInfo` in order to retrieve the current site's store info
 * The value of this cookie comes in the format of "mafuae|en|AED"
 */

import Cookies from 'universal-cookie';

// Reads the api cookie
const getApiInfoCookie = (): string => new Cookies().get(APP_CONFIG.__cookie__.cartApiCookie) || '';

// Reads the cookie and splits its value
const getStoreInfoCookie = (): string[] =>
  (new Cookies().get(APP_CONFIG.__cookie__.storeInfoCookieName) || '').split('|');

// Extracts a value from the splitted cookie value
const extractStoreInfoCookieValue = (
  indexPosition: number,
  propertyKey: keyof typeof APP_CONFIG.__defaults__
): string =>
  APP_CONFIG.__cookie__.storeInfoCookieValue[indexPosition] || APP_CONFIG.__defaults__[propertyKey];

// Not flexible but having it only for the sake of default values
export enum EStoreID {
  MAFEGY = 'mafegy',
  MAFJOR = 'mafjor',
  MAFLBN = 'maflbn',
  MAFQAT = 'mafqat',
  MAFSAU = 'mafsau',
  MAFUAE = 'mafuae',
  MAFPAK = 'mafpak',
  MAFKEN = 'mafken',
  MAFBHR = 'mafbhr',
  MAFOMN = 'mafomn',
  MAFKWT = 'mafkwt',
}

const APP_CONFIG = Object.freeze({
  __defaults__: {
    currency: 'AED',
    language: 'en',
    storeId: EStoreID.MAFUAE,
    cartApi: '',
  },

  __cookie__: {
    cartApiCookie: 'cart_api',
    get appInfoCookieValue(): ReturnType<typeof getApiInfoCookie> {
      return getApiInfoCookie();
    },
    storeInfoCookieName: 'storeInfo',
    get storeInfoCookieValue(): ReturnType<typeof getStoreInfoCookie> {
      return getStoreInfoCookie();
    },
  },

  get currency(): string {
    return extractStoreInfoCookieValue(2, 'currency');
  },

  get language(): string {
    return extractStoreInfoCookieValue(1, 'language');
  },

  get storeId(): string {
    return extractStoreInfoCookieValue(0, 'storeId');
  },

  get isNewEndpoint(): boolean {
    return this.__cookie__.appInfoCookieValue === 'v2' ? true : false;
  },

  get decimalPlaces(): number {
    return this.storeId === EStoreID.MAFBHR || this.storeId === EStoreID.MAFKWT ? 3 : 2;
  },
});

export default APP_CONFIG;
