import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';
import {
  ICachedItem,
  ICheckMultiItemReturnability,
  IMultiItemSelectionData,
} from 'pages/OrderDetail/orderData.type';

export const returnsInitialState: any = {
  check: undefined,
  multiCheck: null,
  multiItemSelectionData: {
    activeIndex: 0,
    selectedItemCodes: [],
    selectedItemIndex: 0,
  },
  multiItemCachedData: {},
};

const returnCheck = (state: any, payload: any) => {
  return { ...state, check: payload };
};

const multiReturnCheck = (state: any, payload: ICheckMultiItemReturnability) => {
  return { ...state, multiCheck: payload };
};

const multiReturnStepSelection = (state: any, payload: number) => {
  return {
    ...state,
    multiItemSelectionData: {
      ...state.multiItemSelectionData,
      activeIndex: payload,
    },
  };
};

const multiReturnSelectionData = (state: any, payload: IMultiItemSelectionData) => {
  return {
    ...state,
    multiItemSelectionData: {
      ...state.multiItemSelectionData,
      ...payload,
    },
  };
};

const multiReturnUpdateCacheData = (state: any, payload: Record<string, ICachedItem>) => {
  return {
    ...state,
    multiItemCachedData: payload,
  };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.RETURN_CHECK]: returnCheck,
  [EPostOrderDataActions.MULTI_RETURN_CHECK]: multiReturnCheck,
  [EPostOrderDataActions.MULTI_RETURN_STEP_SELECTION]: multiReturnStepSelection,
  [EPostOrderDataActions.MULTI_RETURN_SELECTION_DATA]: multiReturnSelectionData,
  [EPostOrderDataActions.MULTI_RETURN_UPDATE_CACHE_DATA]: multiReturnUpdateCacheData,
};

export default function returnReducer(state = returnsInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
