import React, { useRef, useEffect } from 'react';
import { IFrameContainer, IFrameInner } from './ControlledIFrame.styles';
import iframeURLChange from './iframe.util';
import MPGSLog from 'services/payments/utils/logger';
import usePaymentService from 'services/payments';
import { MPGS_3DS_VERIFICATION } from 'config/Urls.config';
import useAction from 'hooks/useAction';
import { save3dsData } from 'services/payments/redux/actions/payments.action';

const ControlledIFrame: React.FC<{
  html: string;
}> = ({ html }) => {
  const refIframe = useRef<any>(null);
  const is3DSScreenPresentedOnce = useRef<boolean>(false);
  const { updateStoreWith3DSResponse } = usePaymentService();
  const [connected3DSDataClear] = useAction(save3dsData);

  useEffect(() => {
    refIframe.current.contentDocument.open();
    refIframe.current.contentDocument.write(html);
    refIframe.current.contentDocument.close();
    iframeURLChange(refIframe.current, (capturedLocation: Location) => {
      if (
        capturedLocation.search.length > 0 &&
        capturedLocation.pathname === MPGS_3DS_VERIFICATION
      ) {
        MPGSLog(capturedLocation.search);
        updateStoreWith3DSResponse(capturedLocation.search);
      } else if (capturedLocation.pathname.includes('/app/') && is3DSScreenPresentedOnce.current) {
        connected3DSDataClear({ htmlBodyContent: '' });
      } else {
        is3DSScreenPresentedOnce.current = true;
      }
    });
  }, [html, updateStoreWith3DSResponse, connected3DSDataClear]);

  return html ? (
    <IFrameContainer>
      <IFrameInner
        as="iframe"
        height={['64%', null, '45%']}
        width={['100%', null, '40%']}
        ref={refIframe}
      />
    </IFrameContainer>
  ) : null;
};

export default ControlledIFrame;
