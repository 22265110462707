import React, { useEffect } from 'react';
import { Router as ReactRouter, Redirect, Switch, Route } from 'react-router-dom';
import { useFlagship, HitType } from '@flagship.io/react-sdk';

import {
  NEW_CART_PAGE,
  NEW_CHECKOUT_PAGE,
  LOGIN_TO_CHECKOUT_PAGE,
  ORDER_DETAILS,
  ORDER_CONFIRMATION,
  MPGS_3DS_VERIFICATION,
  SHIPMENT_DETAILS,
  ITEM_DETAILS,
  ORDER_SUMMARY,
  RETURN_REASONS,
  RETURN_METHOD,
  REFUND_METHOD,
  REVIEW_AND_CONFIRM,
  RETURN_CONFIRMATION,
  RETURN_DETAILS,
  MORE_RETURN_DETAILS,
  SELECT_ITEM_TO_RETURN,
  ORDER_CONFIRMATION_V3,
} from 'config/Urls.config';
import USER_CONFIG from 'config/User.config';
import { removeGUID } from 'utils/cookies.util';

import PageLayout from 'common/PageLayout';
import DynamicLoader from './DynamicLoader';
import AuthenticatedRoute from './AuthenticatedRoute';
import history from 'routes/customHistory';
import { CompletionScreen } from 'services/payments';
import useNewC4Journey from 'hooks/useNewC4Journey';
import { useGTMForFlagshipCampaigns } from 'pages/Cart/hooks/useGTMForFlagshipCampaigns.hook';

const CustomRouter = ReactRouter as any;

const CustomRoute = Route as any;

const CustomRedirect = Redirect as any;

const CustomSwitch = Switch as any;

const Checkout = DynamicLoader(() =>
  import(
    /* webpackChunkName: "Checkout" */
    /* webpackPrefetch: true */
    'pages/Checkout'
  )
);
const OrderConfirmation = DynamicLoader(() =>
  import(
    /* webpackChunkName: "OrderConfirmation" */
    'pages/OrderConfirmation/OrderConfirmation'
  )
);
const Cart = DynamicLoader(() =>
  import(
    /* webpackChunkName: "Cart" */
    /* webpackPrefetch: true */ 'pages/Cart'
  )
);

const NewCart = DynamicLoader(() =>
  import(
    /* webpackChunkName: "NewCart" */
    /* webpackPrefetch: true */ 'pages/NewCart'
  )
);

const OrderDetails = DynamicLoader(() =>
  import(
    /* webpackChunkName: "OrderDetail" */
    'pages/OrderDetail'
  )
);

const OrderList = DynamicLoader(() =>
  import(
    /* webpackChunkName: "OrderList" */
    'pages/OrderList'
  )
);
const OrderSummary = DynamicLoader(() =>
  import(
    /* webpackChunkName: "OrderSummary" */
    'pages/OrderSummary'
  )
);

const ItemDetail = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ItemDetails" */
    'pages/ItemDetail'
  )
);

const SelectItemToReturn = DynamicLoader(() =>
  import(
    /* webpackChunkName: "SelectItemToReturns" */
    'pages/SelectItemToReturn'
  )
);

const ReturnReasons = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ReturnReasons" */
    'pages/ReturnReasons'
  )
);

const ReturnMethod = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ReturnMethod" */
    'pages/ReturnMethod'
  )
);

const RefundMethod = DynamicLoader(() =>
  import(
    /* webpackChunkName: "RefundMethod" */
    'pages/RefundMethod'
  )
);

const ReviewAndConfirm = DynamicLoader(() =>
  import(
    /* webpackChunkName: "Review&Confirm" */
    'pages/ReviewAndConfirm'
  )
);

const ReturnConfirmation = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ReturnConfirmation" */
    'pages/ReturnConfirmation'
  )
);

const ReturnList = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ReturnList" */
    'pages/ReturnList'
  )
);
const ReturnDetails = DynamicLoader(() =>
  import(
    /* webpackChunkName: "ReturnDetails" */
    'pages/ReturnDetails'
  )
);
const cartCheck = () => {
  // Add more checks related to cart page here
  // indexOf vs includes: https://jsperf.com/includes-vs-indexof/4
  const hasLoggedOut = window.location.href.indexOf('logout=true') !== -1;
  if (hasLoggedOut) {
    removeGUID(); // Client-side cart cookie cleanup
  }

  return hasLoggedOut;
};

const checkoutCheck = () => {
  // Add more checks related to checkout page here
  return !USER_CONFIG.isLoggedIn;
};

const orderDetailCheck = () => !USER_CONFIG.isLoggedIn;
const orderConfirmationCheck = () => !USER_CONFIG.isLoggedIn;

const Router: React.FC = () => {
  const isNewC4Journey = useNewC4Journey();
  useGTMForFlagshipCampaigns();
  const { hit: fsHit, visitorId } = useFlagship();
  useEffect(() => {
    if (visitorId) {
      fsHit.send({
        type: HitType.PAGE,
        documentLocation: window.location.href,
      });
    }
  }, [visitorId, window.location.href]);
  if (
    window.location &&
    window.location.search &&
    window.location.search.includes('?isWebView=true')
  ) {
    return <div>loading...</div>;
  }
  return (
    <CustomRouter history={history}>
      <PageLayout>
        <CustomSwitch>
          <CustomRoute exact path="/health">
            <div>cpr frontend is up</div>
          </CustomRoute>
          <AuthenticatedRoute exact path={`${NEW_CART_PAGE}/:action?`} nonAuthCheck={cartCheck}>
            {isNewC4Journey ? <NewCart /> : <Cart />}
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path={ORDER_CONFIRMATION_V3} nonAuthCheck={orderDetailCheck}>
            <OrderConfirmation />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path={`${NEW_CHECKOUT_PAGE}/:type?`}
            nonAuthCheck={checkoutCheck}
            redirectUrl={LOGIN_TO_CHECKOUT_PAGE}
          >
            <Checkout />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={RETURN_CONFIRMATION} nonAuthCheck={orderDetailCheck}>
            <ReturnConfirmation />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={REVIEW_AND_CONFIRM} nonAuthCheck={orderDetailCheck}>
            <ReviewAndConfirm />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={REFUND_METHOD} nonAuthCheck={orderDetailCheck}>
            <RefundMethod />
          </AuthenticatedRoute>

          <AuthenticatedRoute path={RETURN_METHOD} nonAuthCheck={orderDetailCheck}>
            <ReturnMethod />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={RETURN_REASONS} nonAuthCheck={orderDetailCheck}>
            <ReturnReasons />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path={ITEM_DETAILS} nonAuthCheck={orderDetailCheck}>
            <ItemDetail />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={SELECT_ITEM_TO_RETURN} nonAuthCheck={orderDetailCheck}>
            <SelectItemToReturn />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={SHIPMENT_DETAILS} nonAuthCheck={orderDetailCheck}>
            <OrderDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={ORDER_SUMMARY} nonAuthCheck={orderDetailCheck}>
            <OrderSummary />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={MORE_RETURN_DETAILS} nonAuthCheck={orderDetailCheck}>
            <ReturnDetails />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={RETURN_DETAILS} nonAuthCheck={orderDetailCheck}>
            <ReturnList />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={ORDER_DETAILS} nonAuthCheck={orderDetailCheck}>
            <OrderList />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={ORDER_CONFIRMATION} nonAuthCheck={orderConfirmationCheck}>
            <OrderConfirmation />
          </AuthenticatedRoute>
          <AuthenticatedRoute path={MPGS_3DS_VERIFICATION} nonAuthCheck={checkoutCheck}>
            <CompletionScreen />
          </AuthenticatedRoute>
          <CustomRedirect to={NEW_CART_PAGE} />
        </CustomSwitch>
      </PageLayout>
    </CustomRouter>
  );
};

export default Router;
