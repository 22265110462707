import { GTMEventCategories } from '@mafc/common';
import { GTMEvents } from 'constants/gtm.constants';
import Tracker from 'utils/Tracker';
import { initStorageManager } from 'utils/customStorage.util';

export let lastEvent = '';

export enum CncGTMEvents {
  cnc_coachmark = 'coach_mark_loaded_cnc',
  home_coachmark = 'coach_mark_loaded',
  cnc_enabled = 'collect_from_store_enabled',
  cnc_disabled = 'collect_from_store_disabled',
  cnc_order = 'placed_order_collect_from_store_enabled',
  cnc_available = 'collect_from_store_loaded',
  cnc_change = 'collect_from_store_change',
  cnc_change_store = 'collect_from_store_select',
  cnc_change_slot = 'collect_from_store_timeslot',
  cnc_change_store_label = 'collect_from_store_change',
}

export const CncTrack = (eventLabel: string, extraObj: any = {}) => {
  // console.log('<<<I am called>>>>', eventLabel, extraObj);
  let eventCategory = GTMEventCategories.user_engagement;
  switch (eventLabel) {
    case CncGTMEvents.cnc_coachmark:
    case CncGTMEvents.home_coachmark:
    case CncGTMEvents.cnc_order:
      eventCategory = GTMEventCategories.enhanced_ecommerce;
  }
  Tracker.gtmCustomEvent({
    eventCategory,
    eventAction: GTMEvents.checkout_progress,
    eventLabel,
    extraObj,
  });
};

export const formatSlotInfo = ({
  deliveryDate = '',
  beginTime = '',
  endTime = '',
}: {
  deliveryDate: string;
  beginTime: string;
  endTime: string;
}) => {
  const [date = '', month = ''] = deliveryDate.trim().split(' ');
  const beginT = beginTime.replace(/\s/g, '');
  const endT = endTime.replace(/\s/g, '');
  return month + '_' + date + '_' + beginT + '_' + endT;
};

const storage = initStorageManager(() => localStorage);

export const setDefaultSlot = (slot: string) => storage.setItem('defaultSlot', slot + '');
export const getDefaultSlot = () => storage.getItem('defaultSlot');
