import ERROR_CODES from 'constants/errorCodeMapping';
import i18n from 'config/localisation';

export const extractErrorProps = (errObj: {
  [K: string]: any;
}): { reason: string; subject: string; message: string; errorCode: string } => {
  const { reason = '', subject = '', message = '', errorCode = '' } =
    (((errObj.response || {}).data || {}).errors || [])[0] || {};
  return { reason, subject, message, errorCode };
};

export const getErrorMessage = (errObj: { [K: string]: any }, overrideMessage: string = '') => {
  const { errorCode, message } = extractErrorProps(errObj);

  const defaultMessage = message || i18n.t('somethingWentWrong');

  let errorMessage;

  if (errorCode) {
    const i18Key = ERROR_CODES[errorCode];
    errorMessage = i18Key ? i18n.t(i18Key) : message || defaultMessage;
  } else {
    errorMessage = overrideMessage || defaultMessage;
  }

  return errorMessage;
};
