import { IAppState } from 'config/redux/reducers';
import { TSessionArea } from 'services/sessionArea/types/sessionArea.types';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import { IAddress } from 'services/user/types/user.types';
import { setCustomCookie, getAddressIdFromCookie } from './cookies.util';
import Constants from 'config/constants';
import { isNonEmptyObject } from './helpers.util';

export const getAddressList = (state: IAppState) => {
  const { userV2: { addresses = [] } = { addresses: [] } } = state;
  return addresses;
};

export const getSessionArea = (state: IAppState): [TSessionArea] => {
  const {
    appConfig: { areaCode },
    sessionArea,
  } = state;
  const area = sessionArea ? sessionArea : areaCode;
  return [area || ''];
};

export const findUserAddress = (addressesList: IAddress[], sessionArea: string) => {
  const storedAddressId = getAddressIdFromCookie();
  let selectedAddress = addressesList.find((address: IAddress) => address.id === storedAddressId);

  // Fallback if address id cookie does not exist
  if (!selectedAddress) {
    selectedAddress = addressesList.find((address: IAddress) => {
      const { deliveryArea, streetName = '' } = address;
      return (
        (deliveryArea && deliveryArea.code === sessionArea) || streetName.includes(sessionArea)
      );
    });
  }

  return selectedAddress;
};

export const setUserAddressIdCookie = (userData: any = {}) => {
  const { selectedAddress, defaultAddress } = userData;
  const address = selectedAddress || defaultAddress;

  if (isNonEmptyObject(address) && address.id) {
    setCustomCookie(Constants.userAddressId, selectedAddress.id);
  }
};

export const getShipmentData = (shipments: any, shipmentType: string) => {
  let shipmentData = { exists: false, fee: 0 };
  const shipment =
    (shipments && shipments.filter((item: any) => item.shipmentType === shipmentType)) || [];
  if (shipment && shipment.length) {
    if (shipmentType !== EShipmentTypes.MARKETPLACE) {
      shipmentData = {
        exists: true,
        fee: (shipment[0].standardFee && shipment[0].standardFee.value) || '',
      };
    } else {
      const { marketPlaceDeliveryCost = [] } = shipment[0];
      const totalDeliveryFee = marketPlaceDeliveryCost.reduce((sum: number, current: any) => {
        return current.deliveryCost && sum + current.deliveryCost.value;
      }, 0);
      shipmentData = {
        exists: true,
        fee: totalDeliveryFee,
      };
    }
  }
  let shipmentDetail = '';
  if (shipmentData.exists) {
    shipmentDetail = shipmentData.exists + '|delivery_fee=' + shipmentData.fee;
  } else {
    shipmentDetail = 'false';
  }
  return shipmentDetail;
};

const getSelectedAddress = (state: IAppState) => {
  const { userData } = state;
  if (userData && userData.selectedAddress) {
    return userData.selectedAddress;
  }
  const addressesList: IAddress[] = getAddressList(state);
  const [sessionArea] = getSessionArea(state);
  return findUserAddress(addressesList, sessionArea);
};
export default getSelectedAddress;
