import { MarketPlaceDeliveryCostType } from 'common/OrderSummary/interfaces/marketplace.interface';
import EApiActionTypes from 'config/redux/types/api.type';
import { CarrefourDeliveryCost, DeliveryCost, PlasticBagPackingInfo } from 'network/response/ICart';
import { IShipment } from 'services/shipments/types/shipments.types';
import { IAddress } from 'services/user/types/user.types';
import { IFinalPrice } from 'utils/price.util';

type URIParamKeys = 'transactionId' | 'currentOrderID' | 'onSuccessCallback' | 'onFailureCallback';

export interface IDeliveryAddress {
  billingAddress: boolean;
  country: {
    isocode: string;
    name: string;
  };
  defaultAddress: boolean;
  deliveryArea: {
    code: string;
    foodDeliveryStore: boolean;
    isSlotsAvailable: boolean;
    name: string;
    posFoodName: string;
    posNonFoodName: string;
  };
  landmark?: string;
  firstName: string;
  id: string;
  lastName: string;
  line1: string;
  phone: string;
  region: {
    countryIso: string;
    isocode: string;
    name: string;
  };
  shippingAddress: boolean;
  streetName: string;
  town: string;
}

interface IPostOrderPaymentInfo {
  cardNumber: string;
  cardType: {
    [Key: string]: string;
  };
  id: string;
}

interface IPostOrderPrice {
  currencyIso: string;
  currencyName: string;
  formattedValue: string;
  priceType: string;
  value: number;
}

export interface ImageContainerData {
  substitutionItem?: any;
  item?: any;
  currency: any;
  isDeliveryDone?: boolean;
  opacity?: number;
  status: string;
}
interface ICurrencyValue {
  currencyIso: string;
  currencyName: string;
  formattedValue: string;
  priceStringValue: string;
  priceType: string;
  value: number;
}

export interface IAppliedVoucherDetails {
  code: string;
  currency: {
    active: boolean;
    isocode: string;
    name: string;
    symbol: string;
  };
  description: string;
  freeShipping: boolean;
  name: string;
  value: number;
  valueFormatted: string;
  valueString: string;
  voucherCode: string;
}

export enum EPostOrderDataActions {
  GET_ORDER_DETAILS_REQ = 'PostOrder/GET_ORDER_DETAILS_REQ',
  GET_ORDER_DETAILS = 'PostOrder/GET_ORDER_DETAILS',
  GET_CONSIGNMENT_CANCELLATION_AVAILABILITY = 'PostOrder/GET_CONSIGNMENT_CANCELLATION_AVAILABILITY',
  CONSIGNMENT_CANCELLATION_AVAILABILITY = 'PostOrder/CONSIGNMENT_CANCELLATION_AVAILABILITY',
  CANCEL_CONSIGNMENT_REQ = 'PostOrder/CANCEL_CONSIGNMENT_REQ',
  CANCEL_CONSIGNMENT = 'PostOrder/CANCEL_CONSIGNMENT',
  SEND_ORDER_DETAILS_TO_CITRUS = 'PostOrder/SEND_ORDER_DETAILS_TO_CITRUS',
  GET_SECONDARY_PAYMENT_STATUS = 'PostOrder/GET_SECONDARY_PAYMENT_STATUS',
  GET_PAYMENT_TRANSACTION_STATUS = 'PostOrder/GET_PAYMENT_TRANSACTION_STATUS',
}

// Secondary payment status
export const GET_SECONDARY_PAYMENT_STATUS_START = `${EPostOrderDataActions.GET_SECONDARY_PAYMENT_STATUS}${EApiActionTypes.API_START}`;
export const GET_SECONDARY_PAYMENT_STATUS_SUCCESS = `${EPostOrderDataActions.GET_SECONDARY_PAYMENT_STATUS}${EApiActionTypes.API_SUCCESS}`;
// payment transaction status
export const GET_PAYMENT_TRANSACTION_STATUS_START = `${EPostOrderDataActions.GET_PAYMENT_TRANSACTION_STATUS}${EApiActionTypes.API_START}`;
export const GET_PAYMENT_TRANSACTION_STATUS_SUCCESS = `${EPostOrderDataActions.GET_PAYMENT_TRANSACTION_STATUS}${EApiActionTypes.API_SUCCESS}`;

export type PostOrderDetailsInfo = {
  [Key in URIParamKeys]?: any;
};

export interface PaymentModeStatusList {
  paymentOption: string;
  paymentMode: string;
  status: string;
}

export interface ILoyaltyPointsVoucher {
  barCode: string;
  currencyValue: ICurrencyValue;
  date: number | string;
  transactionUid: number | string;
  voucherCode: string;
  voucherValue: ICurrencyValue;
}

export interface IPostOrderDetailsCitrusInfo {
  orders: [
    {
      id: string;
      sessionId: string;
      orderDate: string;
      orderItems: Array<{
        gtin: string;
        quantity: number;
        regularUnitPrice: number;
        totalOrderItemPriceAfterDiscounts: number;
      }>;
    }
  ];
}

export interface IPostOrderDetailsResponse {
  code: string;
  deliveryAddress: IDeliveryAddress;
  loyaltyPoints?: number;
  paymentInfo?: IPostOrderPaymentInfo;
  paymentMode: string;
  subTotal: IPostOrderPrice;
  totalPrice: IPostOrderPrice;
  consignments: any[];
  orderDate: string;
  otpThreshold: number;
  deliveryCost?: ICurrencyValue;
  loyaltyVouchers?: ILoyaltyPointsVoucher[];
  appliedVouchers?: IAppliedVoucherDetails[];
  orderPaymentCost?: IPostOrderPrice;
  sustainabilityConsent?: boolean;
  packingCharges?: PlasticBagPackingInfo;
}
export interface IConfirmationOrderDetailsResponse {
  code: string;
  cartId: string;
  convertedLoyaltyPointsValue: number;
  codFee: DeliveryCost;
  deliveryAddress: IAddress;
  loyaltyPoints?: number;
  packingCharges: PlasticBagPackingInfo;
  paymentInfo?: IPostOrderPaymentInfo;
  paymentMode: string;
  subTotal: IPostOrderPrice;
  sustainabilityConsent: boolean;
  totalPrice: IPostOrderPrice;
  consignments: any[];
  orderDate: string;
  otpThreshold: number;
  deliveryCost?: ICurrencyValue;
  loyaltyVouchers?: ILoyaltyPointsVoucher[];
  appliedVouchers: IAppliedVoucherDetails[];
  orderPaymentCost?: IPostOrderPrice;
  shipments: IShipment[];
  carrefourDeliveryCost?: CarrefourDeliveryCost;
  marketPlaceDeliveryCost?: MarketPlaceDeliveryCostType;
  totalItems: number;
  user: any;
  totalAppliedDiscounts?: IFinalPrice;
  totalBundleDiscount?: IFinalPrice;
  totalFees: IFinalPrice;
  totalPriceWithTax: IFinalPrice;
  isNewUser?: boolean;
}

export interface IPostOrderData {
  currentOrder?: IPostOrderDetailsResponse;
}

export interface ITextStyling {
  text?:
    | string
    | { variant?: string; string: string }
    | Array<{ variant?: string; string: string }>;
  variant?: string;
}

export interface ISecondaryViewData {
  variant: 'card';
  contents?: IPostOrderPaymentInfo;
}
export interface ITransformedPaymentsData {
  title: ITextStyling | string;
  variant?: string;
  secondaryTitle?: ITextStyling | string;
  secondaryView?: ISecondaryViewData;
  key: string;
}

export interface IPaymentSectionProps {
  sectionData: Array<ITransformedPaymentsData | undefined>;
}

export interface ICheckMultiItemReturnabilityEligibleItemStatus {
  category: string;
  subCategory: string;
  description: string;
}

export interface ICheckMultiItemReturnabilityEligibleItem {
  productName: string;
  productCode: string;
  productImage: string;
  express: boolean;
  maxReturnableQTY: number;
  displayQuantity: string;
  soldByWeight: boolean;
  weightUnit: string;
  amount: number;
  displayAmount: string;
  status: ICheckMultiItemReturnabilityEligibleItemStatus;
}

export interface ICheckMultiItemReturnability {
  returnEligible: boolean;
  currency: string;
  serialReturner: null | boolean;
  itemsEligibleForSSR: ICheckMultiItemReturnabilityEligibleItem[];
  itemsEligibleForC4C: ICheckMultiItemReturnabilityEligibleItem[];
  itemsNotEligibleForReturn: ICheckMultiItemReturnabilityEligibleItem[];
}

export interface ICachedItem extends ICheckMultiItemReturnabilityEligibleItem {
  reasonLabel?: string;
  returnReason?: string;
  images?: string[];
  actualReturnQty?: any;
  originalIndex: number;
}

export interface IMultiItemSelectionData {
  activeIndex: number;
  selectedItemIndex: number;
  selectedItemCodes: string[];
  refundPaymentType: string;
}

export enum EConsignmentStatus {
  processing = 'processing',
  dispatched = 'dispatched',
  delivered = 'delivered',
  cancelled = 'cancelled',
  deliveryFailed = 'deliveryfailed',
  pendingdelivery = 'pendingdelivery',
}

export enum EShipmentType {
  standard = 'Standard',
  express = 'Express',
  CNC = 'CNC',
}
export enum EProductType {
  service = 'SERVICE',
}
export enum PaymentMethodType {
  cod = 'cod',
  creditCard = 'creditcard',
  debitCard = 'debitcard',
  samsungPay = 'samsungpay',
  applePay = 'applepay',
  mobilewallet = 'mobilewallet',
}
export enum E_ITEM_STATUS {
  OOS = 'OOS',
  CANCELLED = 'Cancelled',
}
