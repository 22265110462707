import { ICheckoutState, initialCheckoutState } from 'pages/Checkout/redux/reducer/index';
import { ECncActionTypes, TCncAction } from 'pages/Checkout/types/cnc.types';

import { EValidateCartActionTypes } from 'pages/Checkout/types/validateCart.types';
import EApiActionTypes from 'config/redux/types/api.type';

export const updateCncState = (state: ICheckoutState, action: TCncAction) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      cnc: {
        ...state.shipment.cnc,
        ...action.payload,
      },
    },
  };
};

export const enableLoading = (state: ICheckoutState) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      cnc: {
        ...state.shipment.cnc,
        loading: true,
      },
    },
  };
};

export const disableLoading = (state: ICheckoutState) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      cnc: {
        ...state.shipment.cnc,
        loading: false,
      },
    },
  };
};

export const resetCNCShipment = (state: ICheckoutState) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      cnc: {
        ...initialCheckoutState.shipment.cnc,
      },
    },
  };
};

export default {
  [ECncActionTypes.SHIPMENT_CNC_SET_ERROR]: updateCncState,
  [ECncActionTypes.SHIPMENT_CNC_SET_LOADING]: updateCncState,
  [ECncActionTypes.SHIPMENT_CNC_SET_ACTIVE_TAB]: updateCncState,
  [ECncActionTypes.SHIPMENT_CNC_SET_VALIDATE_STORE]: updateCncState,
  [ECncActionTypes.SHIPMENT_CNC_RESET]: resetCNCShipment,
  [`${EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES}${EApiActionTypes.API_START}`]: enableLoading,
  [`${EValidateCartActionTypes.DELIVER_TO_VALIDATE_CART_GET_ENTRIES}${EApiActionTypes.API_END}`]: disableLoading,
};
