import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum EScreenActionTypes {
  DELIVER_TO_SCREEN_ADDRESS_FORM = 'DeliverTo/Screen/ADDRESS_FORM',
  DELIVER_TO_SCREEN_ADDRESS_LIST = 'DeliverTo/Screen/ADDRESS_LIST',
  DELIVER_TO_SCREEN_CART_LIST = 'DeliverTo/Screen/CART_LIST',
  DELIVER_TO_SCREEN_HIDE = 'DeliverTo/Screen/HIDE',
  SET_SCREEN = 'SET_SCREEN',
}

export enum ETrackingTypes {
  VIEW_BASKET = 'VIEW_BASKET',
  TRACKING_TRANSACTION = 'TRACKING_TRANSACTION',
}
export enum EScreens {
  ADDRESS_FORM = 'ADDRESS_FORM',
  ADDRESS_LIST = 'ADDRESS_LIST',
  CART_LIST = 'CART_LIST',
  CNC_PICKUP_STORES = 'CNC_PICKUP_STORES',
  CNC_PICKUP_STORES_CHANGE = 'CNC_PICKUP_STORES_CHANGE',
  CNC_VALIDATE_CART = 'CNC_VALIDATE_CART',
}

export type TScreen = EScreens | '';

export type TScreenAction = IAction<TScreen, EScreenActionTypes>;
export type TScreenDispatch = TDispatch<TScreenAction>;
