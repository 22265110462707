// import APP_CONFIG from 'config/App.config';
import i18n from 'config/localisation';
import APP_CONFIG from 'config/App.config';
import { CurrencyCodes } from 'pages/Checkout/constants/checkout.constant';

export interface IFinalPrice {
  currencyIso?: string;
  currencyName?: string;
  formattedValue?: string;
  mafCurrency?: string;
  value: number;
  soldByWeight?: boolean;
}

export interface IPrice {
  absoluteDiscount?: number;
  currencyIso?: string;
  currencyName?: string;
  dayLeft?: number;
  differenceInPercentForPromo?: number;
  discountPrice?: IFinalPrice;
  endDatePromotion?: string;
  finalPrice?: IFinalPrice;
  formatedEndPromotion?: number;
  formattedValue?: string;
  mafCurrency?: string;
  originalPrice?: IFinalPrice;
  priceType?: string;
  soldByWeight?: boolean;
  value: number;
}

// REF: https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-currency-string-in-javascript
export const prettifyPrice = (target: number, fixed: boolean = true) => {
  const fixedDecimalValues = fixed ? target.toFixed(APP_CONFIG.decimalPlaces) : target + '';
  const [wholeNum, decimalNum] = fixedDecimalValues.split('.');
  return `${Number(wholeNum).toLocaleString()}.${decimalNum}`;
};

// NOTE: We might consider using the native toLocaleString API later
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
export const getFormattedPrice = (
  { value = 0, currencyIso = '', soldByWeight = false, currencyName = '' }: IPrice | IFinalPrice,
  showPerKG: boolean = false,
  showCurrency: boolean = true,
  isPrefix: boolean = false
) => {
  if (!showCurrency) {
    if (showPerKG && soldByWeight) {
      return `${prettifyPrice(value)}/${i18n.t('KG_LABEL')}`.trim();
    }
    return `${prettifyPrice(value)}`.trim();
  }
  const currency = currencyName ? currencyName : currencyIso;
  const price = `${isPrefix ? currency : ''} ${prettifyPrice(value)} ${!isPrefix ? currency : ''}`;

  if (showPerKG && soldByWeight) {
    return `${price}/${i18n.t('KG_LABEL')}`.trim();
  }

  return `${price}`.trim();
};

export const getFormattedPriceInUSD = (
  { value = 0 }: IPrice | IFinalPrice,
  currencyRate: number = 0,
  isPrefix: boolean = true
) => {
  const valueInUSD = getPriceInUSD(value, currencyRate);
  const currency = CurrencyCodes.USD;
  const price = `${isPrefix ? currency : ''} ${prettifyPrice(valueInUSD)} ${
    !isPrefix ? currency : ''
  }`;

  return `${price}`.trim();
};
export const getPriceInUSD = (price: number = 0, currencyRate: number = 0) => {
  return price * currencyRate;
};

export const getWasPrice = (price: IPrice): string | null => {
  if (!price) {
    return null;
  }
  const { discountPrice, finalPrice, originalPrice, value = 0 } = price;

  if (discountPrice && originalPrice && originalPrice.value !== value) {
    return getFormattedPrice(originalPrice, false, true, true);
  }

  if (finalPrice && finalPrice.value !== value) {
    return getFormattedPrice(finalPrice, false, true, true);
  }

  return null;
};
