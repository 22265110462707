const API_BASE = 'carts/{{StoreId}}/{{language}}';
const CART_API_BASE = `${API_BASE}/STANDARD`;

export const GET_CART_API = `${CART_API_BASE}/getCart?areaCode={{areaCode}}&lm={{lm}}&nsp={{nsp}}`;

export const FETCH_SUBSTITUTES_API = `${CART_API_BASE}/substituteProducts?areaCode={{areaCode}}&lm={{lm}}`;

export const REPLACE_SUBSTITUTE_API = `${CART_API_BASE}/substitution?areaCode={{areaCode}}`;

export const UPDATE_QUANTITY_API = `${CART_API_BASE}/updateItem?isCartRequired=true&areaCode={{areaCode}}&lm={{lm}}`;

export const DELETE_ITEM_FROM_CART_API = `${CART_API_BASE}/deleteItem?isCartRequired=true&areaCode={{areaCode}}&lm={{lm}}`;
