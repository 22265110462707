import { matchPath } from 'react-router';
import { LOGIN_PAGE } from '../config/Urls.config';
import history from 'routes/customHistory';
export const isCurrentPage = (url: string, checkAgainst = '') => {
  // TODO- evaluate the guard below. When would the window be undefined in a browser?
  if (typeof window === 'undefined' && !checkAgainst) {
    return false;
  }

  const match = matchPath(checkAgainst || window.location.href, {
    path: url,
    exact: false,
    strict: false,
  });
  return !!match;
};

export const openURL = (url: string) => {
  window.open(url, '_blank');
};

export const openLoginPage = (redirectTo?: string) => {
  let url = LOGIN_PAGE;
  if (redirectTo) {
    url += `?redirect=${redirectTo}`;
  }
  window.location.replace(url);
};

export const reloadPage = (message?: string) => {
  if (!!message) {
    alert(message);
  }
  history.go(0);
};
