import EPageLoaderTypes from './pageLoader.type';
import IAction from 'config/redux/actions/interface';
import { ReactNode } from 'react';

export const initialPageLoaderState = {
  loading: false,
  message: null,
};

export interface IPageLoaderState {
  loading: boolean;
  message?: string | ReactNode;
}

const pageLoaderReducer = (state = initialPageLoaderState, action: IAction) => {
  switch (action.type) {
    case EPageLoaderTypes.PAGE_LOADER_SHOW:
      return { loading: true, message: action.payload };
    case EPageLoaderTypes.PAGE_LOADER_HIDE:
      return initialPageLoaderState;
  }

  return state;
};

export default pageLoaderReducer;
