/**
 * This contains the entire logic related to Urls Cookie + Configuration
 */

import APP_CONFIG from './App.config';

const URLS_CONFIG = Object.freeze({
  get urlPrefix(): string {
    return `/${APP_CONFIG.storeId}/${APP_CONFIG.language}`;
  },
});

export default URLS_CONFIG;

export const MY_ACCOUNT_PAGE = `/my-account`;

export const LOGIN_PAGE = `${URLS_CONFIG.urlPrefix}/v1/login`; // To be change later
export const CART_PAGE = `${URLS_CONFIG.urlPrefix}/cart`;
export const SHARE_PAGE = `${URLS_CONFIG.urlPrefix}/loyalty-card/myClub`;
export const LOGIN_TO_CHECKOUT_PAGE = `${LOGIN_PAGE}/checkout`;

// My Account pages
export const SHOPPING_LIST_PAGE = `${MY_ACCOUNT_PAGE}/wishlist`;
export const REFER_A_FRIEND = `${MY_ACCOUNT_PAGE}/referral`;
export const MY_ACCOUNT_ORDERS_PAGE = `${MY_ACCOUNT_PAGE}/orders`;
export const MY_ACCOUNT_LOYALTY_PAGE = `${MY_ACCOUNT_PAGE}/loyalty`;
export const MY_ACCOUNT_LOGOUT = `${URLS_CONFIG.urlPrefix}/logout`;
export const MY_ACCOUNT_BUY_AGAIN_PAGE = `${URLS_CONFIG.urlPrefix}/buyAgain`;

// React pages
export const NEW_CART_PAGE = '/app/cart';
export const NEW_CHECKOUT_PAGE = '/app/checkout';
export const LOGIN_TO_NEW_CART_PAGE = `${LOGIN_PAGE}?redirect=${NEW_CART_PAGE}/merge`;
export const LOGIN_TO_NEW_CHECKOUT_PAGE = `${LOGIN_PAGE}?redirect=${NEW_CART_PAGE}/checkout`;
export const ORDER_DETAILS = '/app/orders';
export const RETURN_DETAILS = '/app/returns';
export const MORE_RETURN_DETAILS =
  '/app/returns/order/shipment/item/:returnID/:orderID/:shipmentID/:itemID';
export const ORDER_SUMMARY = '/app/orders/:orderID';
export const SHIPMENT_DETAILS = '/app/orders/shipment/:orderID/:shipmentID';
export const SELECT_ITEM_TO_RETURN = `${SHIPMENT_DETAILS}/selectItem`;
export const ITEM_DETAILS = '/app/orders/shipment/item/:orderID/:shipmentID/:itemID';
export const RETURN_REASONS = `${ITEM_DETAILS}/returnreasons`;
export const RETURN_METHOD = `${ITEM_DETAILS}/returnMethod`;
export const PICKUP_DETAILS = `${ITEM_DETAILS}/pickupDetails`;
export const REFUND_METHOD = `${ITEM_DETAILS}/refundMethod`;
export const REVIEW_AND_CONFIRM = `${ITEM_DETAILS}/reviewandconfirm`;
export const RETURN_CONFIRMATION = `${ITEM_DETAILS}/returnconfirmation`;
export const ORDER_CONFIRMATION = `${NEW_CHECKOUT_PAGE}/orderConfirmation/:orderID`;
export const ORDER_CONFIRMATION_V3 = `${NEW_CHECKOUT_PAGE}/orderConfirmation`;
export const MPGS_3DS_VERIFICATION = '/app/3dsredirect/';

// help center pages
export const TermsAndConditions = `${URLS_CONFIG.urlPrefix}/termsandconditions`;
export const ReturnPolicies = `${URLS_CONFIG.urlPrefix}/returnandexchange`;

// React param types
export enum EUrlParams {
  EXPRESS = 'express',
}
