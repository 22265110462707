export enum MPesaTypes {
  PAYMENT_SERVICE_INITIATE_M_PESA_TRANSACTION = 'PAYMENT_SERVICE/INITIATE_M_PESA_TRANSACTION',
}

export interface MPesaInitResponse {
  meta: {
    code: string;
    message: string;
  };
  data: {
    responseCode: string;
    account̰Num: string;
    orderId: string;
    amount: string;
    paybill: string;
    responseDesc: string;
  };
}

// {
//   "meta": {
//       "statusCode": "000",
//       "message": "SUCCESS"
//   },
//   "data": {
//       "sid": "MAFCAR3151C160680062381030579CARREFOUR",
//       "orderId": "MAF122",
//       "amount": "1.00",
//       "account̰Num": "DT0335083023E",
//       "paybill": "510800",
//       "text": "A Payment request has been sent to the MPESA number +254712010370"
//   }
// }

export interface MPesaTransactResponse {
  meta: {
    code: string;
    message: string;
  };
  data: {
    responseCode: string;
    account̰Num: string;
    orderId: string;
    amount: string;
    paybill: string;
    text: string;
    responseDesc: string;
  };
}
