import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import IAction from 'config/redux/actions/interface';
import { CHECKOUT_CREATE_ORDER_SUCCESS } from '../Checkout.types';
import EPaymentMethods from 'pages/Checkout/components/Payment/PamentMethods.enum';
import CheckoutActionTypes from '../Checkout.types';
import FEATURES_CONFIG from 'config/features';
import { getDataToBeEncrypt } from 'pages/Checkout/helpers/checkoutDotCom.helper';
import encrypt from 'pages/Checkout/helpers/encryption.helper';

// const createOrderStart = (state: ICheckoutState, action: IAction) => {
//   return {
//     ...state,
//     orderDetails: {
//       loading: true,
//     },
//   };
// };

const getCardData = (state: ICheckoutState, payload: any) => {
  const { enableHostedCreditCard } = FEATURES_CONFIG.settings;
  const {
    payment: { paymentDetails, paymentMode },
  } = state;

  let cardData = {};
  const isNewCreditCard =
    paymentMode.type === EPaymentMethods.CREDIT_CARD &&
    !paymentDetails.saved &&
    !enableHostedCreditCard;

  if (isNewCreditCard) {
    cardData = paymentDetails.newCardDetails;
  }
  if (payload.isCheckoutDotComFlow) {
    const data = getDataToBeEncrypt(
      cardData,
      payload.orderWsDto.totalPriceWithTax,
      payload.fieldsMap
    );
    const encData = encrypt(data, payload.orderNumber, payload.guid);
    cardData = {
      data: encData,
    };
  }

  return cardData;
};

const createOrderSuccess = (state: ICheckoutState, action: IAction) => {
  const { payload } = action;

  if (
    (payload.paymentModeSelected === EPaymentMethods.COD ||
      payload.paymentModeSelected === EPaymentMethods.CARD_ON_DELIVERY ||
      payload.paymentModeSelected === EPaymentMethods.CASHBACK) &&
    payload.success
  ) {
    return {
      // todo:will have to check the change for it updating just cc for now
      ...state,
      nonCardRedirectionURI: payload.successData.redirectUrl,
      orderDetails: {
        ...state.orderDetails,
        orderNumber: payload.orderNumber,
      },
    };
  }

  const cardData = getCardData(state, payload);
  // common handling for all countries in case of card payment mode
  return {
    ...state,
    orderDetails: {
      orderWsDto: {
        ...payload.orderWsDto,
      },
      orderNumber: payload.orderNumber,
      transactionType: payload.orderFieldMap.transaction_type,
      signature: payload.orderFieldMap.signature,
      orderFieldMap: {
        ...payload.orderFieldMap,
        ...cardData,
      },
      cybersourceURL: payload.cybersource_request_url,
    },
  };
};

const createOrderError = (state: ICheckoutState, action: IAction) => ({
  ...state,
  placeOrderError: { ...action.payload },
});

export default {
  [CHECKOUT_CREATE_ORDER_SUCCESS]: createOrderSuccess,
  [CheckoutActionTypes.CHECKOUT_PLACE_ORDER_ERROR]: createOrderError,
};
