import { IAddress } from 'services/user/types/user.types';

const getFormattedFullAddress = (
  { building, appartment, landmark, streetName, deliveryArea, town }: IAddress,
  isLocationCXEnable: boolean = false
) => {
  return isLocationCXEnable
    ? [appartment, building, streetName, ...(landmark ? [landmark] : []), town]
        .join(', ')
        .substr(0, 150)
    : [...(landmark ? [landmark] : []), streetName, deliveryArea && deliveryArea.name, town]
        .join(', ')
        .substr(0, 150);
};

export default getFormattedFullAddress;
