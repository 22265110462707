import PageMaxWidth from 'common/PageLayout/PageMaxWidth';
import { customStyled } from '@mafc/trolly';

export const ConfirmationContainer = customStyled(PageMaxWidth)(
  ({ theme: { space, maxLayoutWidth } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: space[2],
    marginBottom: space[2],
    maxWidth: `${maxLayoutWidth}px !important;`,
  })
);
