import crypto from 'crypto';

export const IV_LENGHT = 16;
export const KEY_LENGTH = 32;

export const getHMAC256 = (message: string, secret: string) => {
  const hmac = crypto.createHmac('sha256', secret);
  const data = hmac.update(message);
  const genHmac = data.digest('base64');
  return genHmac;
};

export const getEncryptionKey = (uuId: string, orderNumber: string) => {
  const keyHashInBase64 = getHMAC256(uuId + orderNumber, orderNumber);
  return keyHashInBase64.substring(0, KEY_LENGTH);
};

export const getIV = (orderNumber: string) => {
  const ivHashInBase64 = getHMAC256(orderNumber, orderNumber);
  return ivHashInBase64.substring(0, IV_LENGHT);
};
