//  REF: https://github.com/nhn/tui.code-snippet/tree/v2.0.0

/**
 * Check whether the given variable is existing or not.
 * If the given variable is not null and not undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is existy?
 */
export const isExisty = (target: any): boolean => !isUndefined(target) && !isNull(target);

/**
 * Check whether the given variable is a function or not.
 * If the given variable is a function, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is function?
 * @memberof module:type
 */
export const isFunction = (target: any): boolean => target instanceof Function;

/**
 * Check whether the given variable is null or not.
 * If the given variable(arguments[0]) is null, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is null?
 */
export const isNull = (target: any): boolean => target === null || target === '';

/**
 * Check whether the given variable is a number or not.
 * If the given variable is a number, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is number?
 */
export const isNumber = (target: any): boolean =>
  typeof target === 'number' || target instanceof Number;

/**
 * Check whether the given variable is an object or not.
 * If the given variable is an object, return true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is object?
 */
export const isObject = (target: any) => target === Object(target);

/**
 * Check whether the given variable is undefined or not.
 * If the given variable is undefined, returns true.
 * @param {*} target - Target for checking
 * @returns {boolean} Is undefined?
 */
export const isUndefined = (target: any): boolean => target === undefined;

/**
 * Check whether the given variable is a valid non-empty Array
 * @param {*} target - Target for checking
 * @returns {boolean} Is non-empty Array?
 */
export const isNonEmptyArray = (target: any[]): boolean => !!target && target.length > 0;

/**
 * Convert the given variable into decimal point
 * @param {*} target - Target for checking
 * @param {number} decimalPoint - Decimal point
 * @returns {string | number}
 */
export const setDecimalPoint = (target: any, decimalPoint = 2): string | number =>
  isNumber(target) ? target.toFixed(decimalPoint) : target;

/**
 * Round up/down to at most 2 decimal places
 * @param {*} target - Target for checking
 * @returns {string | number}
 * REF: https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
 * REF: https://stackoverflow.com/questions/41259253/how-to-round-down-number-2-decimal-places
 */
export const roundDecimalNumber = (target: any, isRoundUp = true): string | number =>
  isNumber(target) ? Math[isRoundUp ? 'round' : 'floor']((target + 0.00001) * 100) / 100 : target;

/**
 * Return the correct Noun based on the given "target" variable
 * @param {number} target - Target for checking
 * @param {string} singularNoun - Will return if "target" is only one
 * @param {string} pluralNoun - Will return if "target" is more than one
 * @returns {string}
 */
export const getProperNoun = (target: number, singularNoun: string, pluralNoun: string): string =>
  target < 2 ? singularNoun : pluralNoun;

/**
 * Return the length of enumerable properties of the given object
 * @param {object} target - Target for checkout
 */
export const countObjectProperties = (target: object) => Object.keys(target).length;

/**
 * Taken from packages/checkout/src/pages/Checkout/components/Delivery/NewAddress/redux/NewAddress.action.ts
 * @param {object} params
 */
export const qs = (params: object) => {
  return Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
};

/**
 * Check whether the given variable is a valid non-empty Object
 * @param {*} target - Target for checking
 * @returns {boolean} Is non-empty Object?
 */
export const isNonEmptyObject = (target: any): boolean =>
  target && countObjectProperties(target) > 0;

export const parseQuery = (queryString: string) => {
  if (!queryString) {
    return {};
  }
  const query: any = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (const item of pairs) {
    const pair = item.split('=');
    query[(window as any).decodeURIComponent(pair[0])] = (window as any).decodeURIComponent(
      pair[1] || ''
    );
  }
  return query;
};

/**
 * Check whether the given variable is a String
 * @param {*} candidate - Target for checking
 * @returns {boolean} Is a string
 */
export const isAString = (candidate: any) =>
  typeof candidate === 'string' || candidate instanceof String;

export const captalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);
};

export const trimString = (str: string, count: number = 100) =>
  (str && str.substring(0, count)) || '';

export const noop = () => {
  // do nothing, empty function
};
