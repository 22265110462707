import english from './en.json';
import arabic from './ar.json';
import georgian from './ge.json';

import countries from './countries';
import API_CONFIG from 'config/Api.config';
interface LocaleJSON {
  translation: {
    [key: string]: any;
  };
}

// When adding new locales, append to the following list (after importing)
// ... use only  ISO codes as language keys
interface SupportedLanguages {
  en: LocaleJSON;
  ar: LocaleJSON;
  ka: LocaleJSON;
}

const resources: SupportedLanguages = {
  en: {
    translation: { ...english, ...countries(API_CONFIG.StoreId.substring(3)) },
  },
  ar: {
    translation: { ...arabic, ...countries(API_CONFIG.StoreId.substring(3)) },
  },
  ka: {
    translation: { ...georgian, ...countries(API_CONFIG.StoreId.substring(3)) },
  },
};

export default resources;
