import { customStyled, Box } from '@mafc/trolly';
import { layout, flex, space, size } from 'styled-system';

export const IFrameContainer = customStyled(Box)`
  position: fixed;
  display:flex;
  justify-content:center;
  align-items:center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 9999;
  ${layout}
  ${space}
  ${flex}
`;

export const IFrameInner = customStyled.div<{ [key: string]: any }>`
  display:flex;
  z-index: 99999;
  ${layout}
  ${space}
  ${flex}
  ${size}
`;
