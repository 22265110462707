import { Dispatch } from 'redux';

import { TApiMiddlewareDispatch } from 'config/redux/middlewares/api.middleware.types';
import {
  IPickupStores,
  EPickupStoresActionTypes,
  TPickupStoresAction,
  TPickupStoresDispatch,
  IPickupStoresApiResponse,
  IPointOfService,
} from '../types/PickupStores.types';
import { GET_PICKUP_STORES_API } from '../constants/apis.constant';

import {
  setCNCSlotAndStore,
  validateCart,
  setCNCTabClicked,
  setActiveTab,
} from 'pages/Checkout/redux/actions/cnc.action';
import { setScreen } from 'pages/Checkout/redux/actions/screen.actions';
import i18n from 'config/localisation';
import { EShipmentTabs } from 'pages/Checkout/types/cnc.types';
import { showNotification } from 'common/Notification/redux/notification.action';
import { notificationTypes } from 'common/Notification/redux/notification.type';

export const setPickupStoresError = (error: IPickupStores['error']): TPickupStoresAction => ({
  type: EPickupStoresActionTypes.PICKUP_STORES_SET_ERROR,
  payload: { error },
});

export const getPickupStoresList = (successCallback?: () => void) => (
  dispatch: TApiMiddlewareDispatch & TPickupStoresDispatch
) => {
  dispatch({
    type: EPickupStoresActionTypes.PICKUP_STORES_GET_LIST,
    payload: {
      enableProgress: false,
      onSuccess: ({ pointOfServices: stores }: IPickupStoresApiResponse) => {
        if (!stores || !stores.length) {
          dispatch(setPickupStoresError('error'));
          dispatch(setActiveTab(EShipmentTabs.HOME_DELIVERY_TAB) as any);
          dispatch(setCNCTabClicked(false) as any);
          dispatch(
            showNotification({
              type: notificationTypes.errorCheckout,
              message: i18n.t('cnc.pickupStores.noStoreAvailable'),
            }) as any
          );
          return;
        }
        dispatch({
          type: EPickupStoresActionTypes.PICKUP_STORES_GET_LIST_SUCCESS,
          payload: {
            stores,
          },
        });
        if (successCallback) {
          successCallback();
        }
      },
      onFailure: (error: any) => {
        dispatch(setPickupStoresError(error));
      },
      url: GET_PICKUP_STORES_API,
    },
  });
};

export const savePickupStore = (
  cncStore: IPointOfService['name'],
  successCallback?: () => void
) => (dispatch: Dispatch) => {
  dispatch(setPickupStoresError(''));

  dispatch(
    validateCart({
      cncStore,
      successCartCallback: () => {
        dispatch(
          setCNCSlotAndStore({
            cncStore,
            onSuccessCallback: () => {
              dispatch(setScreen() as any);
              dispatch(setSelectedStore(''));

              if (successCallback) {
                successCallback();
              }
            },
            onFailureCallback: () => {
              const error = `${i18n.t('cnc.pickupStores.selectStoreErrorHeader')}. ${i18n.t(
                'cnc.pickupStores.selectStoreErrorMsg'
              )}`;
              dispatch(setPickupStoresError(error));
            },
          }) as any
        );
      },
      onFailureCallback: errMsg => {
        dispatch(setPickupStoresError(errMsg));
      },
    }) as any
  );
};

export const setSelectedStore = (
  selectedStore: IPickupStores['selectedStore'],
  selectedStoreObj = {}
) => ({
  type: EPickupStoresActionTypes.PICKUP_STORES_SET_SELECTED_STORE,
  payload: { selectedStore, selectedStoreObj },
});
