import IAction from 'config/redux/actions/interface';
import { getModifiedResponse } from '../helpers/getSlotStatusType.helper';
import EDeliverySlotTypes, {
  IDeliverySlots,
  ISelectedDeliverySlots,
  ISlotItem,
} from './deliverySlot.type';

export interface IDeliverySlotState extends IDeliverySlots, ISelectedDeliverySlots {
  deliveryInfo?: string;
  error?: string;
  loading: boolean;
  visible: boolean;
  showCoachMark: boolean;
  showDrawerCoachMark: boolean;
  data: any;
  defaultDrawer: any;
  pickupSelectedSlot: ISlotItem;
}

export const deliverySlotInitialState = {
  loading: false,
  visible: false,
  showCoachMark: true,
  showDrawerCoachMark: false,
  slotValidationRequired: true,
  data: {},
  defaultDrawer: {},
  pickupSelectedSlot: {} as ISlotItem,
} as IDeliverySlotState;

const deliverySlotReducer = (
  state: IDeliverySlotState = deliverySlotInitialState,
  action: IAction
) => {
  switch (action.type) {
    case EDeliverySlotTypes.DELIVERY_SLOT_FETCH_SLOTS_SUCCESS:
    case EDeliverySlotTypes.DELIVERY_SLOT_SET_SELECTED_SLOT:
    case EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_VISIBLE:
    case EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_LOADING:
    case EDeliverySlotTypes.DELIVERY_SLOT_SET_ERROR:
    case EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_COACH_MARK:
    case EDeliverySlotTypes.DELIVERY_SLOT_TOGGLE_DRAWER_COACH_MARK:
      const apigeFlag = action.payload.apigeFlag || false;
      const modifiedResponse = getModifiedResponse(action.payload, apigeFlag, action.type);
      return {
        ...state,
        ...modifiedResponse,
      };
    case EDeliverySlotTypes.DELIVERY_SLOT_SET_DEFAULT_DRAWER_STATE:
      return { ...state, defaultDrawer: action.payload };
    case EDeliverySlotTypes.DELIVERY_SLOT_PICKUP_SELECTED_SLOT:
      return { ...state, pickupSelectedSlot: action.payload };
    case EDeliverySlotTypes.DELIVERY_SLOT_RESET:
      return { ...deliverySlotInitialState };
  }

  return state;
};

export default deliverySlotReducer;
