// tslint:disable: max-classes-per-file

class CPRError extends Error {
  meta: any;
  constructor(errMsg: string | undefined, meta?: any) {
    super(errMsg);
    this.name = 'CPRError';
    this.meta = meta;
  }
}

class PaymentManagerInitError extends CPRError {
  constructor(policy: string, meta?: any) {
    super('Attempted setting an unsupported policy: ' + policy, meta);
    this.name = 'PaymentManagerInitializationError';
  }
}

class PaymentManagerInternalError extends CPRError {
  constructor(message: string, meta?: any) {
    super(message, meta);
    this.name = 'PaymentManagerInternalError';
  }
}

class PaymentServiceStrategyError extends CPRError {
  constructor(message: string, meta?: any) {
    super(message, meta);
    this.name = 'PaymentServiceStrategyError';
  }
}

class PaymentServiceMethodError extends CPRError {
  constructor(methodName: string, meta?: any) {
    super('Strategy does not implement the method ' + methodName, meta);
    this.name = 'PaymentServiceMethodError';
  }
}

class PaymentHookError extends CPRError {
  constructor(message: string, meta?: any) {
    super(message, meta);
    this.name = 'PaymentHookError';
  }
}

class PaymentServiceScriptInstallError extends CPRError {
  constructor(uri: string) {
    super('Error loading script from ' + uri);
    this.name = 'PaymentServiceScriptInstallError';
  }
}

class PaymentServiceNotReadyError extends CPRError {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentServiceNotReadyError';
  }
}

class PaymentServiceResponseError extends CPRError {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentServiceResponseError';
  }
}

class PaymentServiceHookError extends CPRError {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentServiceHookError';
  }
}

export {
  PaymentManagerInitError,
  PaymentServiceStrategyError,
  PaymentServiceMethodError,
  PaymentHookError,
  PaymentServiceScriptInstallError,
  PaymentServiceNotReadyError,
  CPRError,
  PaymentServiceResponseError,
  PaymentServiceHookError,
  PaymentManagerInternalError,
};
