// TODO: Needs re-evaluation later

import createReducer from 'config/redux/helpers/createReducer';
import ICreditCard from '../../components/Payment/CreditCard.interface';
import CheckoutActionTypes, {
  CHECKOUT_APPLY_PROMO_START,
  CHECKOUT_APPLY_PROMO_END,
  CHECKOUT_APPLY_PROMO_FAIL,
  CHECKOUT_REMOVE_PROMO_START,
  CHECKOUT_REMOVE_PROMO_END,
} from '../Checkout.types';

import paymentReducer from './payment.reducer';
import stepsProgressReducer from './stepsProgress.reducer';
import orderReducer from './order.reducer';
import deliveryReducer from './delivery.reducer';
import expressReducer from './express.reducer';
import cncReducer from './cnc.reducer';
import pickupStoresReducer from '../../components/PickupStores/redux/PickupStores.reducer';

import {
  promoLoadingStart,
  promoLoadingEnd,
  applyPromoFail,
  promoLoadingReset,
  IPromo,
} from './promo.reducer';

import IStepsProgress from '../../interfaces/stepsProgress.interface';
import UserAddress from 'network/response/UserAddress';

import { IDeliverTo } from 'pages/Checkout/types/deliverTo.types';
import { TScreen } from 'pages/Checkout/types/screen.types';
import { IValidateCart } from 'pages/Checkout/types/validateCart.types';
import { IExpress } from 'pages/Checkout/types/express.types';
import { ICnc, EShipmentTabs } from 'pages/Checkout/types/cnc.types';
import { IMKP } from 'pages/Checkout/types/mkp.types';
import mkpReducer from './mkp.reducer';
import paymentArchitectureReducer from './paymentArchitecture.reducer';
import {
  EPaymentArchitectureTypes,
  IPaymentArchitecture,
} from '../actions/paymentArchitecture.action';
import availablePromotionsReducer, {
  IAvailablePromotions,
  initialAvailablePromotions,
} from '../../components/AvailablePromotions/redux/AvailablePromotions.reducer';
export interface IDelivery {
  deliveryAddress?: UserAddress;
  loading: boolean;
  deliverTo: IDeliverTo;
  screen: TScreen;
  validateCart: IValidateCart;
}
export interface ICheckoutState {
  availablePromotions: IAvailablePromotions;
  promo: IPromo;
  payment: {
    creditCards: {
      list: ICreditCard[];
    };
    loading: boolean;
    modes: string[];
    paymentMode: {
      type: string;
      loading: boolean;
    };
    secondary: string[];
    paymentDetails: any;
  };
  stepsProgress: IStepsProgress;
  orderDetails: {
    orderFieldMap: any;
    cybersourceURL: string;
    orderNumber: string;
    transactionType?: string;
    transactionAmount?: number;
    code?: string;
    orderWsDto?: any;
    signature?: string;
  };
  delivery: IDelivery;
  shipment: {
    express: IExpress;
    cnc: ICnc;
    mkp: IMKP;
  };
  placeOrderError: any;
  nonCardRedirectionURI?: string;
  isSurchargeSupported?: boolean;
  paymentArchitecture: IPaymentArchitecture;
}

export const initialCheckoutState = {
  availablePromotions: initialAvailablePromotions,
  promo: {
    loading: false,
    error: '',
    errorPromoCode: '',
  },
  payment: {
    loading: false,
    modes: [],
    paymentMode: { type: '', loading: false },
    paymentDetails: {},
    secondary: [],
    creditCards: {
      list: [],
    },
  },
  stepsProgress: {
    deliverTo: false,
    shipments: false,
    payment: false,
  },
  orderDetails: {
    orderFieldMap: null,
    cybersourceURL: '',
    orderNumber: '',
  },
  delivery: {
    loading: false,
    deliverTo: {
      loading: false,
    },
    screen: '',
    validateCart: {
      loading: false,
      entries: [],
      modifiedEntriesCount: 0,
    },
  },
  shipment: {
    express: {
      loading: false,
    },
    cnc: {
      activeTab: EShipmentTabs.HOME_DELIVERY_TAB,
      loading: false,
      isTabClicked: false,
      pickupStores: {
        loading: false,
        selectedStore: '',
      },
    },
    mkp: {
      notes: [],
    },
  },
  placeOrderError: {},
  nonCardRedirectionURI: undefined,
  paymentArchitecture: {
    pv: EPaymentArchitectureTypes.V1,
  },
} as ICheckoutState;

const checkoutReducer = createReducer(initialCheckoutState, {
  [CHECKOUT_APPLY_PROMO_START]: promoLoadingStart,
  [CheckoutActionTypes.CHECKOUT_RESET_PROMO]: promoLoadingReset,
  [CHECKOUT_APPLY_PROMO_END]: promoLoadingEnd,
  [CHECKOUT_APPLY_PROMO_FAIL]: applyPromoFail,
  [CHECKOUT_REMOVE_PROMO_START]: promoLoadingStart,
  [CHECKOUT_REMOVE_PROMO_END]: promoLoadingEnd,
  ...paymentReducer,
  ...stepsProgressReducer,
  ...orderReducer,
  ...deliveryReducer,
  ...expressReducer,
  ...cncReducer,
  ...mkpReducer,
  ...pickupStoresReducer,
  ...paymentArchitectureReducer,
  ...availablePromotionsReducer,
});

export default checkoutReducer;
