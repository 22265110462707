interface ICustomMemory {
  [key: string]: string;
}

interface IStorageManager {
  getItem: (itemKey: string) => string | null;
  setItem: (itemKey: string, value: string) => void;
  removeItem: (itemKey: string) => void;
  clear: () => void;
}

type StorageFactory = () => Storage;

const hydrateCustomMemory = (): { [key: string]: string } => {
  const memorySnapshot: { [key: string]: string } = {}; // rehydrate this
  return memorySnapshot;
};

let customMemory: ICustomMemory = hydrateCustomMemory();

export function initStorageManager(targetStorage: StorageFactory): IStorageManager {
  const isCompatible = (candidateStorage: Storage) => {
    try {
      const testKeyString = '__test_custom_storage_key__';
      candidateStorage.setItem(testKeyString, testKeyString);
      candidateStorage.removeItem(testKeyString);
      return true;
    } catch (e) {
      return false;
    }
  };
  const storage = targetStorage();
  const isStorageCompatible = isCompatible(storage);

  const getItem = (itemKey: string) => {
    if (isStorageCompatible) {
      return storage.getItem(itemKey);
    }
    if (customMemory.hasOwnProperty(itemKey)) {
      return customMemory[itemKey];
    }
    return null;
  };

  const setItem = (itemKey: string, value: string) => {
    if (isStorageCompatible) {
      storage.setItem(itemKey, value);
    } else {
      customMemory[itemKey] = String(value);
    }
  };

  const removeItem = (itemKey: string) => {
    if (isStorageCompatible) {
      storage.removeItem(itemKey);
    } else {
      delete customMemory[itemKey];
    }
  };

  const clear = () => {
    if (isStorageCompatible) {
      storage.clear();
    } else {
      customMemory = {};
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    clear,
  };
}
