import { AnyAction } from 'redux';
import { EPostOrderDataActions } from '../../OrderList/orderData.type';

export const refundInfoInitialState: any = {
  undefined,
};

const refundInfo = (state: any, payload: any) => {
  return { ...payload };
};

const actionHandlers: { [Key: string]: any } = {
  [EPostOrderDataActions.REFUND_DETAILS]: refundInfo,
};

export default function refundInfoReducer(state = refundInfoInitialState, action: AnyAction) {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  return (actionHandler && actionHandler(state, payload)) || state;
}
