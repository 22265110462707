import { IAction, TDispatch } from 'config/redux/types/redux.types';

export enum EExpressActionTypes {
  SHIPMENT_EXPRESS_SET_ERROR = 'Shipment/Express/SET_ERROR',
  SHIPMENT_EXPRESS_SET_LOADING = 'Shipment/Express/SET_LOADING',
  SHIPMENT_EXPRESS_GET_EXPRESS_ENABLE = 'Shipment/Express/GET_EXPRESS_ENABLE', // API[GET METHOD]
}

export interface IExpress {
  error?: any;
  loading: boolean;
}

export type TExpressAction = IAction<
  Partial<IExpress>,
  Exclude<EExpressActionTypes, EExpressActionTypes.SHIPMENT_EXPRESS_GET_EXPRESS_ENABLE>
>;
export type TExpressDispatch = TDispatch<TExpressAction>;
