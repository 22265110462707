export default {
  userToken: 'reactUserToken',
  userId: 'userId',
  mafUserId: 'maf-user-id',
  guestSessionToken: 'guest-session-token',
  guestUserID: 'anonymous',
  guidUnavailable: 'bucket-id-not-present',
  guestCartQuantity: 'guestCartQuantity',
  redeemedSharePoints: 'redeemedSharePoints',
  secondaryPaymentCheckStatus: 'checkStatus',
  reactAuthToken: 'authToken',
  userAddressId: 'maf-user-address-id',
  gaId: '_ga',
  mafSessionId: 'maf-session-id',
  orderDetails: 'orderDetails',
};
