export default {
  // add any egypt specific strings here or to override base strings
  en: {
    fullCartRedemption: 'Congratulations! Your grand total will be covered using MyCLUB Points',
    loyaltyPoints: 'MyCLUB Pts.',
    orderSummaryPointsEarned: "MyCLUB points you'll earn with this order",
    orderSummaryShareDiscount: 'Paid by MyCLUB points',
    points: 'MyCLUB Points',
    redeemPoints: 'Redeem',
    shareDiscount: 'Use your MyCLUB points',
    confirmationSent: 'Details sent to',
    orderConfirmationMessage:
      "We're reviewing your order now. You will receive a notification soon!",
  },
  ar: {
    fullCartRedemption: 'تهانينا! سيتم تغطية المبلغ الإجمالي باستخدام نقاط ماي كلوب',
    loyaltyPoints: 'نقاط ماي كلوب',
    orderSummaryPointsEarned: 'التي ستكسبها من هذا الطلب MyCLUB عدد نقاط',
    orderSummaryShareDiscount: 'تم الدفع بنقاط ماي كلوب',
    points: 'نقاط ماي كلوب',
    redeemPoints: 'استبدال',
    shareDiscount: 'رصيد نقاط ماي كلوب',
    confirmationSent: 'تم إرسال التفاصيل إلى',
    orderConfirmationMessage: 'نعمل حالياً على مراجعة طلبك، سنتواصل معك قريباً.',
  },
};
