import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import { EScreenActionTypes, TScreenAction } from 'pages/Checkout/types/screen.types';

export const updateScreenState = (state: ICheckoutState, action: TScreenAction) => {
  return {
    ...state,
    delivery: {
      ...state.delivery,
      screen: action.payload,
    },
  };
};

export default {
  [EScreenActionTypes.DELIVER_TO_SCREEN_ADDRESS_LIST]: updateScreenState,
  [EScreenActionTypes.DELIVER_TO_SCREEN_CART_LIST]: updateScreenState,
  [EScreenActionTypes.DELIVER_TO_SCREEN_ADDRESS_FORM]: updateScreenState,
  [EScreenActionTypes.DELIVER_TO_SCREEN_HIDE]: updateScreenState,
  [EScreenActionTypes.SET_SCREEN]: updateScreenState,
};
