import { IAction, TDispatch } from '../types/redux.types';

export enum EApiMiddlewareMethods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export interface IApiMiddlewarePayload {
  apiVersion?: 1 | 2 | 3;
  data?: object | string; // Usually used with "POST|PUT" methods
  headers?: object; // Override headers
  isAbsUrl?: boolean; // Set this to true for absolute URLs
  label?: string; // Module/feature specific label
  method?: EApiMiddlewareMethods;
  onSuccess?: (...args: any) => void | IAction;
  onFailure?: (...args: any[]) => void | IAction;
  onFinally?: () => void | IAction;
  params?: object; // Usually used with "GET|DELETE" methods
  url: string;
  enableProgress?: boolean;
}

export type TApiMiddlewareAction = IAction<IApiMiddlewarePayload>;
export type TApiMiddlewareDispatch = TDispatch<TApiMiddlewareAction>;
