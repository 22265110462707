import EApiActionTypes from 'config/redux/types/api.type';

const NEW_CART_PREFIX = 'NEW_';

/**
 * SUBSTITUTION CART ACTIONS
 */
export const FETCH_SUBSTITUTES = `${NEW_CART_PREFIX}Cart/FETCH_SUBSTITUTIONS`;

export const FETCH_SUBSTITUTES_ERROR = `${FETCH_SUBSTITUTES}${EApiActionTypes.API_FAIL}`;

export const FETCH_SUBSTITUTES_LOADING_START = `${FETCH_SUBSTITUTES}${EApiActionTypes.API_START}`;

export const FETCH_SUBSTITUTES_LOADING_END = `${FETCH_SUBSTITUTES}${EApiActionTypes.API_END}`;

export const FETCH_SUBSTITUTES_SUCCESS = `${FETCH_SUBSTITUTES}${EApiActionTypes.API_SUCCESS}`;

export const UPDATE_OOS_SELECTED_ITEMS = `${NEW_CART_PREFIX}Cart/UPDATE_OOS_SELECTED_ITEMS`;

export const UPDATE_OOS_CURRENT_ITEM_INDEX = `${NEW_CART_PREFIX}Cart/UPDATE_OOS_CURRENT_ITEM_INDEX`;

export const SUBSTITUTE_REPLACEMENT = `${NEW_CART_PREFIX}Cart/SUBSTITUTE_REPLACEMENT`;

export const SUBSTITUTE_REPLACEMENT_LOADING_START = `${SUBSTITUTE_REPLACEMENT}${EApiActionTypes.API_START}`;

export const SUBSTITUTE_REPLACEMENT_LOADING_END = `${SUBSTITUTE_REPLACEMENT}${EApiActionTypes.API_END}`;

export const SUBSTITUTE_REPLACEMENT_FAIL = `${SUBSTITUTE_REPLACEMENT}${EApiActionTypes.API_FAIL}`;
