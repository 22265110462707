import EApiActionTypes from 'config/redux/types/api.type';

enum CheckoutActionTypes {
  CHECKOUT_FETCH_CREDIT_CARDS = 'Checkout/FETCH_CREDIT_CARDS',
  CHECKOUT_APPLY_PROMO = 'Checkout/CHECKOUT_APPLY_PROMO',
  CHECKOUT_REMOVE_PROMO = 'Checkout/CHECKOUT_REMOVE_PROMO',
  CHECKOUT_RESET_PROMO = 'Checkout/CHECKOUT_RESET_PROMO',
  CHECKOUT_FETCH_PAYMENT_MODE = 'Checkout/CHECKOUT_FETCH_PAYMENT_MODE',
  CHECKOUT_SET_PAYMENT_MODE = 'Checkout/CHECKOUT_SET_PAYMENT_MODE',
  CHECKOUT_SET_STEP_STATUS = 'Checkout/SET_STEP_STATUS',
  CHECKOUT_CREATE_ORDER = 'Checkout/CREATE_ORDER',
  CHECKOUT_PAYMENT_LOADING = 'Checkout/PAYMENT_LOADING',
  CHECKOUT_PLACE_ORDER_ERROR = 'CHECKOUT/PLACE_ORDER/ERROR',
  CHECKOUT_SPLIT_PAYMENT_SERVICE = 'CHECKOUT/SPLIT_PAYMENT',
  CHECKOUT_PAYMENT_ARCHITECTURE = 'CHECKOUT/PAYMENT_ARCHITECTURE',
}

export default CheckoutActionTypes;

// fetch user saved credit cards types
const CHECKOUT_FETCH_CREDIT_CARDS_START = `${CheckoutActionTypes.CHECKOUT_FETCH_CREDIT_CARDS}${EApiActionTypes.API_START}`;
const CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS = `${CheckoutActionTypes.CHECKOUT_FETCH_CREDIT_CARDS}${EApiActionTypes.API_SUCCESS}`;
const CHECKOUT_FETCH_CREDIT_CARDS_END = `${CheckoutActionTypes.CHECKOUT_FETCH_CREDIT_CARDS}${EApiActionTypes.API_END}`;

// Apply promo code typea
const CHECKOUT_APPLY_PROMO_START = `${CheckoutActionTypes.CHECKOUT_APPLY_PROMO}${EApiActionTypes.API_START}`;
const CHECKOUT_APPLY_PROMO_END = `${CheckoutActionTypes.CHECKOUT_APPLY_PROMO}${EApiActionTypes.API_END}`;
const CHECKOUT_APPLY_PROMO_FAIL = `${CheckoutActionTypes.CHECKOUT_APPLY_PROMO}${EApiActionTypes.API_FAIL}`;

// Remove promo code typea
const CHECKOUT_REMOVE_PROMO_START = `${CheckoutActionTypes.CHECKOUT_REMOVE_PROMO}${EApiActionTypes.API_START}`;
const CHECKOUT_REMOVE_PROMO_END = `${CheckoutActionTypes.CHECKOUT_REMOVE_PROMO}${EApiActionTypes.API_END}`;

// Fetch payment modes
const CHECKOUT_FETCH_PAYMENT_MODE_START = `${CheckoutActionTypes.CHECKOUT_FETCH_PAYMENT_MODE}${EApiActionTypes.API_START}`;
const CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS = `${CheckoutActionTypes.CHECKOUT_FETCH_PAYMENT_MODE}${EApiActionTypes.API_SUCCESS}`;

// Set Payment Mode
const CHECKOUT_SET_PAYMENT_MODE_START = `${CheckoutActionTypes.CHECKOUT_SET_PAYMENT_MODE}${EApiActionTypes.API_START}`;
const CHECKOUT_SET_PAYMENT_MODE_SUCCESS = `${CheckoutActionTypes.CHECKOUT_SET_PAYMENT_MODE}${EApiActionTypes.API_SUCCESS}`;

// Crete order
const CHECKOUT_CREATE_ORDER_SUCCESS = `${CheckoutActionTypes.CHECKOUT_CREATE_ORDER}${EApiActionTypes.API_SUCCESS}`;

// Split Payment
const CHECKOUT_SPLIT_PAYMENT_SERVICE_START = `${CheckoutActionTypes.CHECKOUT_SPLIT_PAYMENT_SERVICE}${EApiActionTypes.API_START}`;
const CHECKOUT_SPLIT_PAYMENT_SERVICE_SUCCESS = `${CheckoutActionTypes.CHECKOUT_SPLIT_PAYMENT_SERVICE}${EApiActionTypes.API_SUCCESS}`;

// fetch payment architecture
const CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS = `${CheckoutActionTypes.CHECKOUT_PAYMENT_ARCHITECTURE}${EApiActionTypes.API_SUCCESS}`;

export {
  CHECKOUT_FETCH_CREDIT_CARDS_START,
  CHECKOUT_FETCH_CREDIT_CARDS_SUCCESS,
  CHECKOUT_FETCH_CREDIT_CARDS_END,
  CHECKOUT_APPLY_PROMO_START,
  CHECKOUT_APPLY_PROMO_END,
  CHECKOUT_APPLY_PROMO_FAIL,
  CHECKOUT_REMOVE_PROMO_START,
  CHECKOUT_REMOVE_PROMO_END,
  CHECKOUT_FETCH_PAYMENT_MODE_START,
  CHECKOUT_FETCH_PAYMENT_MODE_SUCCESS,
  CHECKOUT_SET_PAYMENT_MODE_START,
  CHECKOUT_SET_PAYMENT_MODE_SUCCESS,
  CHECKOUT_CREATE_ORDER_SUCCESS,
  CHECKOUT_SPLIT_PAYMENT_SERVICE_START,
  CHECKOUT_SPLIT_PAYMENT_SERVICE_SUCCESS,
  CHECKOUT_FETCH_PAYMENT_ARCHITECTURE_SUCCESS,
};
