import IAction from 'config/redux/actions/interface';
import { ICartState } from './index';

export const cartToggleError = (cartState: ICartState, action: IAction) => {
  return { ...cartState, error: action.payload.error, errorMessage: action.payload.errorMessage };
};

export const fetchedCartData = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    cartData: {
      ...cartState.cartData,
      ...action.payload,
    },
  };
};

export const setCartPaymentModeData = (cartState: ICartState, action: IAction) => {
  return {
    ...cartState,
    cartData: {
      ...cartState.cartData,
      ...action.payload,
    },
  };
};

export const resetCartPackingData = (cartState: ICartState, action: IAction) => {
  const { cartData } = cartState;
  // eslint-disable-next-line
  const { packingCharges = {}, sustainabilityConsent, ...restCartData } = cartData;
  return {
    ...cartState,
    cartData: {
      ...restCartData,
    },
  };
};
