import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import { CLEAR_MKP_NOTE, SET_MKP_NOTE } from 'pages/Checkout/types/mkp.types';

export const setMKPNotesState = (state: ICheckoutState, action: any) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      mkp: {
        notes: [...action.payload],
      },
    },
  };
};

export const clearMKPNotesState = (state: ICheckoutState, action: any) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      mkp: {
        notes: [],
      },
    },
  };
};

export default {
  [SET_MKP_NOTE]: setMKPNotesState,
  [CLEAR_MKP_NOTE]: clearMKPNotesState,
};
