/**
 * Following function recursively attempts to load the dynamically loaded components.
 * This progressively increments the loading interval by 1.5x.
 * @param lazyComponent: target component wrapped in React.lazy
 * @param attemptsLeft: the number of times the component load should be attempted, before throwing an error | defaults to 3
 * @param interval: minimum time interval before reloads. This is progressively incremented on a per attempt basis | defaults to 1 second/1000ms
 */
export default function attemptLoading(lazyComponent: any, attemptsLeft = 3, interval = 1000): any {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // maximum retries have been reached
            reject(error);
            return;
          }
          attemptLoading(lazyComponent, attemptsLeft - 1, interval * 1.5).then(resolve, reject);
        }, interval);
      });
  });
}
