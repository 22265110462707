import crypto from 'crypto';
import { getEncryptionKey, getIV } from './cryptoHelper';

const encrypt = (dataToBeEncrypted: any, orderNumber: string, uuId: string) => {
  const iv = getIV(orderNumber);
  const encryptionKey = getEncryptionKey(uuId, orderNumber);
  const stringifyData = JSON.stringify(dataToBeEncrypted);

  const cipher = crypto.createCipheriv('AES-256-CBC', encryptionKey, iv);
  let encrypted = cipher.update(stringifyData, 'utf8', 'hex');
  encrypted += cipher.final('hex');
  return encrypted;
};

export default encrypt;
