import { ICheckoutState } from 'pages/Checkout/redux/reducer/index';
import { EPickupStoresActionTypes, TPickupStoresAction } from '../types/PickupStores.types';

export const updatePickupStoresState = (state: ICheckoutState, action: TPickupStoresAction) => {
  return {
    ...state,
    shipment: {
      ...state.shipment,
      cnc: {
        ...state.shipment.cnc,
        pickupStores: {
          ...state.shipment.cnc.pickupStores,
          ...action.payload,
        },
      },
    },
  };
};

export default {
  [EPickupStoresActionTypes.PICKUP_STORES_SET_ERROR]: updatePickupStoresState,
  [EPickupStoresActionTypes.PICKUP_STORES_GET_LIST_SUCCESS]: updatePickupStoresState,
  [EPickupStoresActionTypes.PICKUP_STORES_SET_SELECTED_STORE]: updatePickupStoresState,
};
