import { IAppState } from '@mafc/common';
import { createGrowthBookInstance } from '@mafc/appshell';
import { GROWTHBOOK_FLAGS } from 'pages/NewCart/constants/growthbook.constants';
import { LOYALTY_POINT_TYPE } from './loyaltyPoints.constants';

export const loyaltyPointResponseShaper = (response: any) => {
  const { points: outerPoints = {} } = (response && response.data) || {};
  const { points = '0', converted_points = '0' } = outerPoints;

  return {
    totalPoints: points,
    totalPointsValue: converted_points ? parseFloat(converted_points).toFixed(2) : 0,
  };
};

export const isShareEnabledGrowthBook = (state: IAppState) => {
  const growthBook = createGrowthBookInstance(
    {
      ...state.appConfig.growthBook,
    },
    state
  );

  const { value: pointType } = growthBook.feature(GROWTHBOOK_FLAGS.LOYALTY_POINT_TYPE);

  // @ts-ignore
  return pointType === LOYALTY_POINT_TYPE.SHARE;
};
