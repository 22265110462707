import { createGrowthBookInstance } from '@mafc/appshell';
import { IAppState } from 'config/redux/reducers';
import { GrowthBook } from '@growthbook/growthbook-react';

const getIsRapidNotAvailable = (store: IAppState) => {
  const isRapidNotExists =
    store.deliveryTime &&
    store.deliveryTime.qcommSlot &&
    typeof store.deliveryTime.qcommSlot === 'object' &&
    Object.keys(store.deliveryTime.qcommSlot).length === 0;

  const isRapidBusyHour = !!(
    store.deliveryTime &&
    store.deliveryTime.qcommSlot &&
    store.deliveryTime.qcommSlot.busyStatus &&
    store.deliveryTime.qcommSlot.busyStatus.isBusy
  );

  const isRapidOfflineHour = !!(
    store.deliveryTime &&
    store.deliveryTime.qcommSlot &&
    !store.deliveryTime.qcommSlot.isAvailable &&
    store.deliveryTime.qcommSlot.nextAvailableSlot
  );

  return isRapidNotExists || isRapidBusyHour || isRapidOfflineHour;
};

export const getAvailableServiceTypes = (getState: () => IAppState, growthbook?: GrowthBook) => {
  const store = getState();
  let growthbookInstance = growthbook;
  if (!growthbookInstance) {
    growthbookInstance = createGrowthBookInstance(
      {
        ...store.appConfig.growthBook,
      },
      store
    );
  }

  const whiteListedServiceTypes = growthbookInstance.feature(
    'common.whitelisted_service_type.config'
  ).value || ['food', 'express', 'nonfood'];

  if (getIsRapidNotAvailable(store)) {
    whiteListedServiceTypes.splice(whiteListedServiceTypes.indexOf('QCOMM'), 1);
  }
  const nsp = whiteListedServiceTypes.join(',');

  return nsp || '';
};
