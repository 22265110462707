import 'babel-polyfill';
import 'config/features'; // Initialize Feature Toggle

import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './config/localisation';
import Router from 'routes';
import * as serviceWorker from './serviceWorker';

import GlobalStyles from 'assets/global.styles';
import rootReducer, { initialAppState } from 'config/redux/reducers';

import resources from './config/localisation/strings';
import { setHeader } from 'utils/html.util';
import { withCSRWrapper } from '@mafc/appshell';

const App = () => {
  setHeader();
  return (
    <>
      {GlobalStyles}
      <Router />
    </>
  );
};

const MainApp = withCSRWrapper(App, rootReducer, initialAppState);
MainApp.defaultProps = {
  resources,
  pageProps: { withLayout: false },
};
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(<MainApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
