import { ICartState } from 'pages/Cart/redux/reducer/index';
import IAction from 'config/redux/actions/interface';
import EUpdateQuantityTypes from './updateQuantity.type';

const immutableUpdateCartEntries = (
  cartState: { [key: string]: any },
  updatedEntries: { [key: string]: any }
) => {
  return {
    ...cartState,
    ...{
      cartData: {
        ...cartState.cartData,
        entries: updatedEntries,
      },
    },
  };
};

export const updateEntryDetail = (cartState: ICartState, action: IAction) => {
  const {
    cartData: { entries },
  } = cartState;

  if (!entries) {
    return cartState;
  }

  const {
    payload: { entry },
  } = action;
  const updatedEntries = entries.map(item =>
    item.entryNumber === entry.entryNumber ? entry : item
  );

  return immutableUpdateCartEntries(cartState, updatedEntries);
};

export const updateEntryToggleLoading = (cartState: ICartState, action: IAction) => {
  const {
    payload: { cartEntryNumber, loading },
  } = action;

  let loadingEntries = [...cartState.loadingEntries];

  if (loading) {
    loadingEntries.push(cartEntryNumber);
  } else {
    loadingEntries = loadingEntries.filter(item => item !== cartEntryNumber);
  }

  return {
    ...cartState,
    loadingEntries,
  };
};

export default {
  [EUpdateQuantityTypes.CART_UPDATE_ENTRY_DETAIL]: updateEntryDetail,
  [EUpdateQuantityTypes.CART_UPDATE_ENTRY_TOGGLE_LOADING]: updateEntryToggleLoading,
};
