enum EDeliverySlotTypes {
  DELIVERY_SLOT_FETCH_SELECTED_SLOT = 'DeliverySlot/FETCH_SELECTED_SLOT',
  DELIVERY_SLOT_PUT_SELECTED_SLOT = 'DeliverySlot/PUT_SELECTED_SLOT',
  DELIVERY_SLOT_FETCH_SLOTS = 'DeliverySlot/FETCH_SLOTS',
  DELIVERY_SLOT_FETCH_SLOTS_SUCCESS = 'DeliverySlot/FETCH_SLOTS/SUCCESS',
  DELIVERY_SLOT_TOGGLE_LOADING = 'DeliverySlot/TOGGLE_LOADING',
  DELIVERY_SLOT_TOGGLE_VISIBLE = 'DeliverySlot/TOGGLE_VISIBLE',
  DELIVERY_SLOT_SET_ERROR = 'DeliverySlot/SET_ERROR',
  DELIVERY_SLOT_SET_SELECTED_SLOT = 'DeliverySlot/SET_SELECTED_SLOT',
  DELIVERY_SLOT_RESET = 'DeliverySlot/RESET_REDUCER_STATE',
  DELIVERY_SLOT_TOGGLE_COACH_MARK = 'DeliverySlot/TOGGLE_COACH_MARK',
  DELIVERY_SLOT_TOGGLE_DRAWER_COACH_MARK = 'DeliverySlot/TOGGLE_DRAWER_COACH_MARK',
  DELIVERY_SLOT_SET_DEFAULT_DRAWER_STATE = 'DeliverySlot/SET_DEFAULT_DRAWER_STATE',
  DELIVERY_SLOT_GET_DEFAULT_DRAWER_STATE = 'DeliverySlot/GET_DEFAULT_DRAWER_STATE',
  DELIVERY_SLOT_PICKUP_SELECTED_SLOT = 'DeliverySlot/DELIVERY_SLOT_PICKUP_SELECTED_SLOT',
}

export default EDeliverySlotTypes;

export interface ISlotItem {
  beginTime: string;
  dayOfWeek: string;
  deliveryDate: string;
  deliverySlotCode: string;
  deliveryMessage?: string;
  endTime: string;
  expired: boolean;
  isAvailable: boolean;
  pos: string;
  selected: boolean;
}

export type SlotType = ISlotItem[];

export interface ISlotsItem {
  key: string;
  value: {
    mafDeliverySlot: SlotType;
  };
}

export type SlotsType = ISlotsItem[];

export interface IDeliveryArea {
  code: string;
  foodDeliveryStore: boolean;
  isSlotsAvailable: boolean;
  name: string;
  posFoodName?: string;
  posNonFoodName?: string;
}

// export interface IDeliveryAddress {
//   appartment: string;
//   billingAddress: boolean;
//   building: string;
//   country: {
//     isocode: string;
//   };
//   defaultAddress: boolean;
//   deliveryArea: IDeliveryArea;
//   firstName: string;
//   id: string;
//   landline: string;
//   landmark: string;
//   lastName: string;
//   latitude: string;
//   line1: string;
//   longitude: string;
//   nickname: string;
//   phone: string;
//   postalCode: string;
//   region: {
//     countryIso: string;
//     isocode: string;
//     name: string;
//   };
//   shippingAddress: boolean;
//   ssoType: string;
//   streetName: string;
//   town: string;
// }

export interface IDeliverySlots {
  maxDeliverySlots: number;
  nextAvailableDeliverySlot: ISlotItem;
  preferredSlotRange: number;
  slots: SlotsType;
}

export interface ISelectedDeliverySlots {
  alertMessages?: string;
  deliveryArea: IDeliveryArea;
  selectedSlot: ISlotItem;
  slotValidationRequired: boolean;
  type: string;
  deliveryMessage: string;
}
