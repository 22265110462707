import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@mafc/trolly';
import { CardCvv } from '@mafc/icon';
import NewCardFormStyled from 'pages/Checkout/components/Payment/styles/NewCardForm.styles';
import usePaymentService from 'services/payments';
import { InputEventType, PaymentService_EVENTS } from 'services/payments/Payments.types';
import {
  processMPGSPaymentSessionDetails,
  savePaymentDetails,
} from 'services/payments/redux/actions/payments.action';
import { handleAllInputError } from './MasterCardForm.helper';
import CardInput from 'services/payments/mastercard/CardInput';
import ControlledIFrame from 'common/ControlledIFrame';
import { setStepStatus } from 'pages/Checkout/redux/actions/stepsProgress.action';
import { EStepsProgress } from 'pages/Checkout/interfaces/stepsProgress.interface';
import { IAppState } from 'config/redux/reducers';
import FEATURES_CONFIG from 'config/features';
import useAction from 'hooks/useAction';
import { setPaymentMode } from 'pages/Checkout/redux/actions/payment.action';
import COLORS from 'theme/colors';
import TYPOGRAPHY from 'theme/typography';
import { VoucherType } from 'network/response/ICart';
import usePayment from '../../../hooks/usePayment';

import { extractBinFromCard } from '../../../helpers/payment.helpers';
import CardIcons from '../../CardIcons';
import { isNonEmptyObject } from 'utils/helpers.util';

export const ERROR_INITIAL_STATE = {
  cardNumber: '',
  expiryMonth: '',
  expiryYear: '',
  securityCode: '',
};

interface IProps {
  disabled: boolean;
}
const fieldIdMapping: { [key: string]: string } = {
  'cardholder-name': 'nameOnCard',
  'card-number': 'cardNumber',
  'expiry-month': 'expiryMonth',
  'expiry-year': 'expiryYear',
  'security-code': 'securityCode',
};
const { Paragraph } = Text;
let lastFocusedElement = '';
const MasterCardForm = ({ disabled }: IProps) => {
  const { error: paymentServiceErrorData } = useSelector(
    (state: IAppState) => state.paymentService
  );
  const dispatch = useDispatch();
  const [error, updateError] = useState({ ...ERROR_INITIAL_STATE });

  const [height, setheight] = useState('0');
  const containerEl: any = useRef(null);
  const { t } = useTranslation();
  const {
    captureSession,
    initService,
    registerServiceListener,
    html3Ds,
    reload,
    saveSessionData,
  } = usePaymentService(); // mastercard
  const [setPaymentAction] = useAction(setPaymentMode);

  const {
    payment: { paymentDetails },
  } = usePayment();
  const handleInputEvent = (inputPayload: any) => {
    switch (inputPayload.actionType) {
      case InputEventType.onBlur:
        const inputElementId: string = inputPayload.inputParamSelector.split('#')[1];
        lastFocusedElement = fieldIdMapping[inputElementId];
        toggleSaveCard();
        break;
      case InputEventType.onFocus:
        break;
    }
  };
  const { disableBinCode = false } = FEATURES_CONFIG.settings;

  const formFieldUpdateHandler = (response: any) => {
    const {
      event,
      payload: { ok, containsValidationErrors, error: errorPayload },
    } = response;
    if (event) {
      switch (event) {
        case PaymentService_EVENTS.cardInputEvent:
          handleInputEvent(response.payload);
          break;
        case PaymentService_EVENTS.receivedSessionResponse: // save session data and for error handling
          if (ok) {
            const sessionData = { ...response.payload, card: response.payload.card.card };
            saveSessionData(sessionData);
            const cardInfo = sessionData.card;
            if (!disableBinCode && isNonEmptyObject(cardInfo) && cardInfo.securityCode) {
              const cardNo = sessionData.card.number;
              const binCode = extractBinFromCard(cardNo);
              const sessionPaymentDetails = processMPGSPaymentSessionDetails(sessionData) || {};
              setPaymentAction('creditcard', sessionPaymentDetails, binCode);
            }
            dispatch(savePaymentDetails(sessionData)); // save card and session details in state
          }
          if (containsValidationErrors) {
            handleValidationErrors(errorPayload);
          }

          break;
      }
    }
  };
  const handleValidationErrors = (errorPayload: any) => {
    const shouldHandleError =
      !errorPayload.cardNumber || !errorPayload.expiryMonth || !errorPayload.expiryYear;
    if (shouldHandleError) {
      dispatch(setStepStatus(EStepsProgress.payment, true));
    } else {
      dispatch(setStepStatus(EStepsProgress.payment, false));
    }
    if (['cardNumber', 'expiryMonth', 'expiryYear', 'securityCode'].includes(lastFocusedElement)) {
      updateError(handleAllInputError(errorPayload, t));
    }
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      registerServiceListener(formFieldUpdateHandler);
      initService();
    }
  });

  useEffect(() => {
    // slide down the form
    setheight(containerEl.current.scrollHeight + 4);
    reload();
  }, [reload]);

  useEffect(() => {
    const errorObject = handleAllInputError(paymentServiceErrorData, t);
    updateError(errorObject);
  }, [paymentServiceErrorData, t]);

  const toggleSaveCard = () => {
    captureSession();
  };
  const { appliedVoucher } = usePayment();

  const { card_number: cardNumber = '' } = paymentDetails.newCardDetails || { card_number: '' };
  return (
    <NewCardFormStyled height={height} ref={containerEl}>
      <Box p={3}>
        <Flex justifyContent="space-between" flexWrap="wrap" mb={3}>
          <Box width={[1, 1, 1 / 2]} pr={[0, 0, 3]}>
            <CardInput
              id="cardholder-name"
              label={t('paymentForm.cardHolderName')}
              type="text"
              readOnly
            />
          </Box>
          <Box width={[1, 1, 1 / 2]} pr={[0, 0, 3]}>
            <CardInput
              id="card-number"
              type="text"
              label={t('paymentForm.cardNumber')}
              readOnly
              error={error.cardNumber ? { description: error.cardNumber } : undefined}
              variant={error.cardNumber ? 'error' : 'normal'}
              icon={<CardIcons cardNumber={cardNumber} />}
            />
            {!!(
              appliedVoucher &&
              appliedVoucher.type === VoucherType.bin &&
              appliedVoucher.description
            ) && (
              <Paragraph color={COLORS.GREEN} mt={1} fontSize={TYPOGRAPHY.SIZE[10]}>
                {appliedVoucher.description}
              </Paragraph>
            )}
          </Box>
        </Flex>
        <Flex justifyContent="space-between" flexWrap="wrap" mb={3}>
          <Box width={[1 / 2, 1 / 4]} pr={3}>
            <CardInput
              id="expiry-month"
              type="text"
              readOnly
              label={t('paymentForm.expiryMonth')}
              error={error.expiryMonth ? { description: error.expiryMonth } : undefined}
              variant={error.expiryMonth ? 'error' : 'normal'}
            />
          </Box>
          <Box width={[1 / 2, 1 / 4]} pr={3}>
            <CardInput
              id="expiry-year"
              readOnly
              type="text"
              label={t('paymentForm.expiryYear')}
              error={error.expiryYear ? { description: error.expiryYear } : undefined}
              variant={error.expiryYear ? 'error' : 'normal'}
            />
          </Box>
          <Box width={[1 / 2, 1 / 4]} pr={3}>
            <CardInput
              id="security-code"
              readOnly
              type="text"
              icon={<CardCvv />}
              label={t('paymentForm.cvv')}
              error={error.securityCode ? { description: error.securityCode } : undefined}
              variant={error.securityCode ? 'error' : 'normal'}
            />
          </Box>
        </Flex>
      </Box>
      {html3Ds && !disabled && <ControlledIFrame html={html3Ds} />}
    </NewCardFormStyled>
  );
};

export default MasterCardForm;
