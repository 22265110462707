import IAction from '../actions/interface';

const createReducer = (initialState: object, handlers: { [key: string]: any }) => {
  return (state = initialState, action: IAction) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

export default createReducer;
